import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  TextField,
  Typography,
  Box,
  Switch,
  FormControlLabel,
  CircularProgress,
  ImageList,
  ImageListItem,
  Skeleton,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import { generateAndProcessImage } from './functions';
import { Mosaic } from 'react-loading-indicators';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import DownloadIcon from '@mui/icons-material/GetApp';
import PrintIcon from '@mui/icons-material/Print';
import { useSnackbar } from './SnackbarHandler';
import { collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { db } from './firebase';
import { keyframes } from '@emotion/react';
import InfoBox from './InfoBox';
import CopyIcon from '@mui/icons-material/FileCopy';
import { Palette as PaletteIcon } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import CodeIcon from '@mui/icons-material/Code';
import {
  Cake,
  Castle,
  Anchor,
  Flag,
  Store,
  DirectionsBoat,
  EmojiEvents,
  Security,
  Agriculture,
  Pets,
  AccountBalance,
  LocalGroceryStore,
  Public,
  History,
} from '@mui/icons-material';

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;
const themes = [
  {
    id: 1,
    name: 'Prinsesstårta',
    description: 'En klassisk svensk bakelse',
    icon: Cake,
  },
  {
    id: 2,
    name: 'Stormaktstiden',
    description: 'Sveriges storhetstid på 1600-talet',
    icon: History,
  },
  { id: 3, name: 'Lejon', description: 'Ett mäktigt lejon', icon: Pets },
  {
    id: 4,
    name: 'Stockholms Blodbad',
    description: 'Historisk händelse från 1520',
    icon: Castle,
  },
  {
    id: 5,
    name: 'Drottning Kristina',
    description: 'Sveriges drottning 1632-1654',
    icon: EmojiEvents,
  },
  { id: 6, name: 'Viking', description: 'En nordisk viking', icon: Anchor },
  { id: 7, name: 'Gamla Stan', description: 'Stockholms historiska centrum', icon: AccountBalance },
  { id: 8, name: 'Vikingaskepp', description: 'Ett mäktigt vikingaskepp', icon: DirectionsBoat },
  { id: 9, name: 'Gustav Vasa', description: 'Sveriges första Vasakung', icon: EmojiEvents },
  { id: 10, name: 'Riddare', description: 'En medeltida riddare', icon: Security },
  {
    id: 11,
    name: 'Slottet Tre Kronor',
    description: 'Det gamla slottet i Stockholm',
    icon: Castle,
  },
  { id: 12, name: 'Medeltida Torg', description: 'Ett livligt medeltida torg', icon: Store },
];
const ColoringLesson = ({ user, theme, styles }) => {
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [customMode, setCustomMode] = useState(false);
  const [customPrompt, setCustomPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [buttonKey, setButtonKey] = useState(0); // Use key to force re-render and restart animation
  const { showSnackbar } = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    fetchImages();
  }, [user.uid]);

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleCopyPrompt = () => {
    if (selectedImage) {
      navigator.clipboard.writeText(selectedImage.prompt);
      showSnackbar('Prompt kopierad!', 'success');
    }
  };

  const handleImageClick = image => {
    setSelectedImage(image);
    setDialogOpen(true);
  };

  const fetchImages = async () => {
    try {
      const imagesRef = collection(db, 'images');
      const q = query(
        imagesRef,
        where('userId', '==', user.uid),
        orderBy('createdAt', 'desc'),
        limit(17) // Add this line to limit to 16 images
      );
      const querySnapshot = await getDocs(q);
      const images = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setGeneratedImages(images);
    } catch (error) {
      console.error('Fel vid hämtning av bilder:', error);
      showSnackbar('Kunde inte hämta bilder. Vänligen försök igen.', 'error');
    }
  };
  const handleThemeSelect = item => {
    setSelectedTheme(item);
    setButtonKey(prev => prev + 1); // Change key to trigger animation
  };

  const handleGenerate = async () => {
    setIsLoading(true);
    const basePrompt = customMode
      ? `generera en målarbild av en ${customPrompt} som elever ska fylla i med färg själva. så det måste finnas mycket tomrum i bilden.`
      : `generera en målarbild av en ${selectedTheme.name} (${selectedTheme.description})  som elever ska fylla i med färg själva. så det måste finnas mycket tomrum i bilden.`;

    try {
      await generateAndProcessImage(basePrompt, user.uid, {
        size: '1024x1024',
        model: 'dall-e-3',
        quality: 'standard',
        responseFormat: 'url',
      });

      await fetchImages();
      showSnackbar('Målarbild genererad!', 'success');
    } catch (error) {
      console.error('Error generating coloring page:', error);
      showSnackbar('Kunde inte generera målarbild. Försök igen.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = async () => {
    if (generatedImages.length > 0) {
      try {
        const response = await fetch(generatedImages[0].imageUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `malarbild-studera.ai-${Date.now()}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        showSnackbar('Bild nedladdad!', 'success');
      } catch (error) {
        console.error('Error downloading image:', error);
        showSnackbar('Kunde inte ladda ner bilden. Försök igen.', 'error');
      }
    }
  };

  const handlePrint = () => {
    if (generatedImages.length > 0) {
      const printWindow = window.open('', '_blank');
      printWindow.document.write(`
        <html>
          <head>
            <title>Skriv ut målarbild</title>
            <style>
              body {
                margin: 0;
                padding: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 100vh;
              }
              img {
                max-width: 100%;
                height: auto;
              }
              @media print {
                body {
                  padding: 0;
                }
              }
            </style>
          </head>
          <body>
            <img src="${generatedImages[0].imageUrl}" alt="Målarbild" />
            <script>
              window.onload = () => {
                window.print();
                window.onafterprint = () => window.close();
              }
            </script>
          </body>
        </html>
      `);
      printWindow.document.close();
    }
  };

  const renderImageOrSkeleton = () => {
    if (isLoading) {
      return (
        <Fade in={true} timeout={2000}>
          <Box sx={{ position: 'relative', width: '100%', height: '500px' }}>
            <Skeleton
              variant='rectangular'
              width='100%'
              height={500}
              animation='wave'
              sx={{ borderRadius: '7px' }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Mosaic color={['#33CCCC', '#33CC36', '#B8CC33', '#1097DA']} size='large' />
            </Box>
          </Box>
        </Fade>
      );
    }

    if (generatedImages.length > 0) {
      const latestImage = generatedImages[0];
      return (
        <Box
          onClick={() => handleImageClick(latestImage)}
          sx={{ cursor: 'pointer', textAlign: 'center' }}
        >
          <img
            src={latestImage.imageUrl}
            alt={latestImage.prompt}
            loading='lazy'
            style={{
              width: '700px',
              borderRadius: '7px',
              marginBottom: '16px',
            }}
          />
        </Box>
      );
    }

    return null;
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: '100%', md: '85%' },
          margin: '0 auto',
        }}
      >
        <Typography
          variant='h1'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
        >
          Generera målarbilder
        </Typography>

        <InfoBox
          icon={PaletteIcon}
          title='Powerpoint Instructions'
          instructions={[
            'Generera målarbilder som eleven färglägger',
            'Skriv in egna instruktioner till AI-modellen',
            'Ladda ned eller skriv ut bild',
          ]}
          theme={theme}
          styles={styles}
        />

        <Box sx={{ ...styles.freshBox, padding: '30px' }}>
          <Dialog
            open={dialogOpen}
            onClose={handleCloseDialog}
            maxWidth='md'
            fullWidth
            PaperProps={{
              sx: {
                borderRadius: '20px',
                backgroundColor: theme.palette.mode === 'dark' ? '#22303C' : 'white',
                backgroundImage: theme.palette.mode === 'dark' ? 'none' : 'white',
                padding: { xs: '0px', md: '20px' },
              },
            }}
          >
            <DialogTitle>
              <IconButton
                onClick={handleCloseDialog}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: theme.palette.mode === 'dark' ? 'white' : 'black',
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent>
              <Grid container spacing={6}>
                <Grid item xs={12} md={7}>
                  <Box sx={{ ...styles.freshBox }}>
                    <img
                      src={selectedImage?.imageUrl}
                      alt={selectedImage?.prompt}
                      style={{ width: '100%', height: 'auto', borderRadius: '4px' }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Typography variant='h6' gutterBottom>
                    Information
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, mt: 3 }}>
                    <CalendarTodayIcon sx={{ mr: 1 }} />
                    <Typography variant='body2'>
                      Skapad: {selectedImage?.createdAt.toDate().toLocaleString()}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <AspectRatioIcon sx={{ mr: 1 }} />
                    <Typography variant='body2'>Storlek: {selectedImage?.size}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <CodeIcon sx={{ mr: 1 }} />
                    <Typography variant='body2'>AI-modell: {selectedImage?.model}</Typography>
                  </Box>
                  <Typography variant='subtitle1' gutterBottom sx={{ mt: 2 }}>
                    Prompt:
                  </Typography>
                  <Typography variant='body2' paragraph>
                    {selectedImage?.prompt}
                  </Typography>
                  <Button
                    startIcon={<CopyIcon />}
                    onClick={handleCopyPrompt}
                    variant='outlined'
                    sx={{
                      borderRadius: '30px',
                      textTransform: 'none',
                      marginRight: '10px',
                      marginBottom: '10px',
                      verticalAlign: 'middle',
                      fontWeight: '400',
                      borderBottom: theme.palette.mode === 'dark' ? 'none' : undefined,
                      '&:hover': {
                        borderBottom: theme.palette.mode === 'dark' ? 'none' : undefined,
                        '& .MuiButton-startIcon': {
                          color: theme.palette.mode === 'dark' ? 'primary.main' : 'white',
                        },
                      },
                    }}
                  >
                    Kopiera prompt
                  </Button>

                  <Button
                    startIcon={<DownloadIcon />}
                    onClick={handleDownload}
                    variant='outlined'
                    sx={{
                      borderRadius: '30px',
                      textTransform: 'none',
                      marginRight: '10px',
                      marginBottom: '10px',
                      verticalAlign: 'middle',
                      fontWeight: '400',
                      borderBottom: theme.palette.mode === 'dark' ? 'none' : undefined,
                      '&:hover': {
                        borderBottom: theme.palette.mode === 'dark' ? 'none' : undefined,
                        '& .MuiButton-startIcon': {
                          color: theme.palette.mode === 'dark' ? 'primary.main' : 'white',
                        },
                      },
                    }}
                  >
                    Ladda ner
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>

          <Typography
            variant='h1'
            gutterBottom
            sx={{ fontWeight: 'bold', fontSize: '1.4em', mb: 6, mt: 2, textAlign: 'center' }}
          >
            Välj ett exempel:
          </Typography>

          <Grid container spacing={2} sx={{ mb: 4 }}>
            {themes.map(item => {
              const IconComponent = item.icon;
              const isSelected = selectedTheme?.id === item.id;

              return (
                <Grid item xs={12} sm={6} md={3} key={item.id}>
                  <Box
                    onClick={() => handleThemeSelect(item)}
                    sx={{
                      ...styles.freshBox,
                      backgroundColor: isSelected
                        ? theme.palette.mode === 'dark'
                          ? 'rgba(16, 151, 218, 0.15)'
                          : 'rgba(139, 92, 246, 0.15)'
                        : theme.palette.mode === 'dark'
                          ? 'rgba(255, 255, 255, 0.05)'
                          : 'rgba(223, 222, 225, 0.19)',
                      borderRadius: '12px',
                      padding: '16px',
                      height: '110px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      transition: 'all 0.3s ease',
                      border: isSelected
                        ? `2px solid ${theme.palette.mode === 'dark' ? '#1097DA' : '#6b46c1'}`
                        : '2px solid transparent',
                      '&:hover': {
                        backgroundColor:
                          theme.palette.mode === 'dark'
                            ? 'rgba(255, 255, 255, 0.1)'
                            : 'rgba(139, 92, 246, 0.1)',
                        transform: 'translateY(-2px)',
                        '& .icon': {
                          color: theme.palette.mode === 'dark' ? 'rgb(16, 151, 218)' : '#6b46c1',
                        },
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: 2,
                        transform: isSelected ? 'scale(1.1)' : 'scale(1)',
                        transition: 'transform 0.3s ease',
                      }}
                    >
                      <IconComponent
                        className='icon'
                        sx={{
                          fontSize: '2rem',
                          color: isSelected
                            ? theme.palette.mode === 'dark'
                              ? '#1097DA'
                              : '#6b46c1'
                            : theme.palette.mode === 'dark'
                              ? 'rgba(255, 255, 255, 0.7)'
                              : 'rgba(0, 0, 0, 0.7)',
                          transition: 'color 0.3s ease',
                        }}
                      />
                    </Box>
                    <Typography
                      variant='h6'
                      sx={{
                        textAlign: 'center',
                        mb: 1,
                        fontSize: '1.1rem',
                        fontWeight: isSelected ? '700' : '600',
                        color: isSelected
                          ? theme.palette.mode === 'dark'
                            ? '#1097DA'
                            : '#6b46c1'
                          : 'inherit',
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>

          <Box sx={{ mb: 4, textAlign: 'center' }}>
            <Typography
              variant='h1'
              gutterBottom
              sx={{ fontWeight: 'bold', fontSize: '1.4em', mb: 2, mt: 9, textAlign: 'center' }}
            >
              ...eller skriv in egna instruktioner:
            </Typography>

            <FormControlLabel
              control={
                <Switch checked={customMode} onChange={e => setCustomMode(e.target.checked)} />
              }
              label='Anpassade instruktioner'
            />

            {customMode && (
              <TextField
                fullWidth
                multiline
                rows={4}
                value={customPrompt}
                onChange={e => setCustomPrompt(e.target.value)}
                placeholder='T.ex. ett lejon som sover på savannen med sina ungar i bakgrunden.'
                sx={{ mt: 2 }}
              />
            )}
            <Box>
              <Button
                key={buttonKey} // Key changes force component remount, restarting animation
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                onClick={handleGenerate}
                disabled={isLoading || (!customMode && !selectedTheme)}
                startIcon={!isLoading ? <GenerateIcon /> : <CircularProgress size={20} />}
                sx={{
                  mt: 6,
                  animation: `${pulseAnimation} 0.4s ease-in-out`, // Animation always runs on mount
                  '&:not(:disabled)': {
                    transform: 'translateY(0)',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                    },
                  },
                }}
              >
                {isLoading ? 'Genererar målarbild...' : 'Generera målarbild'}
              </Button>
            </Box>

            <Box sx={{ mt: 4 }}>
              <Button
                variant='outlined'
                color='primary'
                startIcon={<DownloadIcon />}
                onClick={handleDownload}
              >
                Ladda ned
              </Button>
              <Button
                variant='outlined'
                color='primary'
                startIcon={<PrintIcon />}
                onClick={handlePrint}
                sx={{
                  ml: 2,
                }}
              >
                Skriv ut
              </Button>
            </Box>
          </Box>

          <Box sx={{ mb: 4 }}>{renderImageOrSkeleton()}</Box>

          <Box sx={{ textAlign: 'center' }}>
            <Button
              startIcon={<KeyboardDoubleArrowDownIcon />}
              variant='outlined'
              onClick={() => setShowHistory(!showHistory)}
              sx={{ mb: 2 }}
            >
              {showHistory ? 'Dölj tidigare bilder' : 'Visa tidigare bilder'}
            </Button>
          </Box>
          {showHistory && generatedImages.length > 1 && (
            <ImageList variant='quilted' cols={4} gap={8}>
              {generatedImages.slice(1).map(image => (
                <ImageListItem
                  key={image.id}
                  onClick={() => handleImageClick(image)}
                  sx={{ cursor: 'pointer' }}
                >
                  <img
                    src={image.imageUrl}
                    alt={image.prompt}
                    loading='lazy'
                    style={{ borderRadius: '7px' }}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ColoringLesson;
