import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  Avatar,
  CircularProgress,
  FormControl,
  Divider,
  Select,
  MenuItem,
  useTheme,
  Slider,
} from '@mui/material';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import ImageIcon from '@mui/icons-material/Image';
import PrintIcon from '@mui/icons-material/Print';
import { handleMysticImageWritingLesson } from './printFunctions';
import { Mosaic } from 'react-loading-indicators';
import { callApi } from './api';
import { generateAndProcessImage } from './functions';
import { useSnackbar } from './SnackbarHandler';
import GradeSelector, { gradeInstructions } from './GradeSelector';
import LanguageSelector from './LanguageSelector';
import ImageGenerationModal from './ImageGenerationModal';
import { getStyles } from '../index';
import { ThemeSelector } from './ThemeSelector';
import { GiMagicLamp } from 'react-icons/gi';

import InfoBox from './InfoBox';

const MysticImageLesson = ({ user }) => {
  const [mysteryText, setMysteryText] = useState(null);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const [generatedImage, setGeneratedImage] = useState('/api/placeholder/400/400');
  const [themeColor, setThemeColor] = useState('#8a59f4');

  const { showSnackbar } = useSnackbar();

  const [themeType, setThemeType] = useState('generateWithAi');
  const [customTheme, setCustomTheme] = useState('');
  const [lineCount, setLineCount] = useState(9); // Default to 15 lines
  const [lineHeight, setLineHeight] = useState(50);
  const [grade, setGrade] = useState('åk6');
  const [language, setLanguage] = useState('svenska');

  const theme = useTheme();
  const styles = getStyles(theme);

  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const [imageGenerationType, setImageGenerationType] = useState(null);

  const handleImageButtonClick = type => {
    setImageGenerationType(type);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
  };

  const handleGenerateWithInstructions = async () => {
    handleCloseModal();

    if (imageGenerationType === 'topic') {
      await handleGenerateImage();
    } else if (imageGenerationType === 'facts') {
      await handleGenerateImage();
    }
  };

  const handleLineCountChange = (event, newValue) => {
    setLineCount(newValue);
  };

  const LINE_HEIGHT_SCALE = 5;
  const pixelsToSliderValue = pixels => Math.round(pixels / LINE_HEIGHT_SCALE);
  const sliderValueToPixels = value => value * LINE_HEIGHT_SCALE;

  const handleLineHeightChange = (event, newValue) => {
    setLineHeight(sliderValueToPixels(newValue));
  };

  const handleGenerateLesson = async () => {
    setIsGeneratingLesson(true);
    try {
      const themePrompt =
        themeType === 'customTheme'
          ? customTheme
          : themeType === 'generateWithAi'
            ? '[RANDOM]'
            : themeType;

      const response = await callApi(
        [
          {
            role: 'system',
            content: 'Du är en assistent som genererar mystiska och fantasifulla scenarier.',
          },
          {
            role: 'user',
            content: `Generera JSON med ett mystiskt scenario på ${language} ${
              themePrompt !== '[RANDOM]' ? `relaterat till temat "${themePrompt}"` : ''
            }.
            ${gradeInstructions[grade]}
            
            Exempel på svar:
            {
              "scenario": {
                "description": "I den förtrollade skogen står en urgammal portal gjord av glödande kristaller. Månskenet dansar över dess yta och skapar mystiska mönster på marken.",
                "prompt": "A magical ancient crystal portal in an enchanted forest, with moonlight creating mysterious patterns, mystical atmosphere, ethereal lighting"
              }
            }`,
          },
        ],
        4000,
        true,
        user.uid
      );

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setMysteryText(data.scenario);
      showSnackbar('Nytt mystiskt scenario har genererats', 'success');
    } catch (error) {
      console.error('Error generating lesson:', error);
      showSnackbar('Kunde inte generera nytt scenario', 'error');
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleGenerateImage = async () => {
    if (!mysteryText) {
      showSnackbar('Generera ett scenario först', 'warning');
      return;
    }

    // Combine the original prompt with any additional instructions
    const enhancedPrompt = imageInstructions
      ? `${mysteryText.prompt}. Använd INTE text på bilden. Fler instruktioner: ${imageInstructions}`
      : mysteryText.prompt;

    setIsGeneratingImage(true);
    try {
      const imageUrl = await generateAndProcessImage(enhancedPrompt, user.uid, {
        size: '1024x1024',
        model: 'dall-e-3',
        quality: 'standard',
      });

      setGeneratedImage(imageUrl);
      showSnackbar('Bild har genererats!', 'success');
    } catch (error) {
      console.error('Error generating image:', error);
      showSnackbar('Kunde inte generera bild', 'error');
    } finally {
      setIsGeneratingImage(false);
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: '100%', md: '87%' },
          margin: '0 auto',
        }}
      >
        <Typography
          variant='h1'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
        >
          Skriv om den mystiska bilden
        </Typography>

        <InfoBox
          icon={GiMagicLamp}
          title='Powerpoint Instructions'
          instructions={[
            'Eleven beskriver vad som händer på bilden eller skriver en berättelse om den',
            'Lägg till fantasieggande AI-genererade bilder',
            'Skriv ut lektionen',
          ]}
          theme={theme}
          styles={styles}
        />

        <Box sx={{ ...styles.freshBox, padding: '30px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <ThemeSelector
                value={themeType}
                onChange={e => setThemeType(e.target.value)}
                theme={theme}
                customTheme={customTheme}
                onCustomThemeChange={e => setCustomTheme(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                Språk:
              </Typography>
              <Box sx={{ mt: 1, mb: 3 }}>
                <LanguageSelector language={language} setLanguage={setLanguage} />
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                Årskurs:
              </Typography>
              <Box sx={{ mt: 1, mb: 3 }}>
                <GradeSelector grade={grade} setGrade={setGrade} />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                Antal rader:
              </Typography>
              <Box sx={{ px: 2, mt: 2 }}>
                <Slider
                  value={lineCount}
                  onChange={handleLineCountChange}
                  step={1}
                  marks
                  min={1}
                  max={20}
                  valueLabelDisplay='auto'
                  sx={{
                    color: theme.palette.mode === 'light' ? '#8b5cf6' : 'primary',
                    '& .MuiSlider-markLabel': {
                      color: theme.palette.text.primary,
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                Radavstånd (pixlar):
              </Typography>
              <Box sx={{ px: 2, mt: 2 }}>
                <Slider
                  value={pixelsToSliderValue(lineHeight)}
                  onChange={handleLineHeightChange}
                  step={1}
                  marks
                  min={0}
                  max={15}
                  valueLabelDisplay='auto'
                  valueLabelFormat={value => `${sliderValueToPixels(value)}px`}
                  sx={{
                    color: theme.palette.mode === 'light' ? '#8b5cf6' : 'primary',
                    '& .MuiSlider-markLabel': {
                      color: theme.palette.text.primary,
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ mb: 4, mt: 4 }}>
            <Button
              variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
              color='primary'
              onClick={handleGenerateLesson}
              disabled={isGeneratingLesson}
              startIcon={!isGeneratingLesson ? <GenerateIcon /> : <CircularProgress size={20} />}
              sx={{ mr: 2, mt: 6 }}
            >
              {isGeneratingLesson ? 'Genererar...' : 'Generera nytt scenario'}
            </Button>

            {mysteryText && (
              <>
                <Box>
                  <Divider sx={{ mt: 5, mb: 5 }} />

                  <Button
                    variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                    color='primary'
                    onClick={() => handleImageButtonClick('topic')}
                    disabled={isGeneratingImage}
                    startIcon={!isGeneratingImage ? <ImageIcon /> : <CircularProgress size={20} />}
                    sx={{ mr: 2 }}
                  >
                    {isGeneratingImage ? 'Genererar bild...' : 'Generera bild'}
                  </Button>

                  <ImageGenerationModal
                    open={showImageModal}
                    onClose={handleCloseModal}
                    onGenerate={handleGenerateWithInstructions}
                    isGenerating={
                      imageGenerationType === 'topic' ? isGeneratingImage : isGeneratingImage
                    }
                    instructions={imageInstructions}
                    setInstructions={setImageInstructions}
                    generationType={imageGenerationType}
                    theme={theme}
                  />

                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={() =>
                      handleMysticImageWritingLesson(
                        [{ image: generatedImage }],
                        lineCount,
                        lineHeight,
                        themeColor
                      )
                    }
                    startIcon={<PrintIcon />}
                  >
                    Skriv ut
                  </Button>
                </Box>

                <Box sx={{ mt: 3 }}>
                  <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500' }}>
                    Färgtema:
                  </Typography>

                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 3 }}>
                    <div
                      style={{ display: 'inline-block', position: 'relative', marginRight: '10px' }}
                    >
                      <div
                        style={{
                          width: '60px',
                          height: '20px',
                          borderRadius: '7px',
                          backgroundColor: themeColor,
                          cursor: 'pointer',
                        }}
                      />
                      <input
                        type='color'
                        value={themeColor}
                        onChange={e => setThemeColor(e.target.value)}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          opacity: 0,
                          cursor: 'pointer',
                        }}
                      />
                    </div>

                    <Typography variant='body2'>{themeColor}</Typography>
                  </Box>
                </Box>
              </>
            )}
          </Box>

          {mysteryText && (
            <Box sx={{ mt: 8 }}>
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '20px',
                  mb: 1,
                }}
              >
                Scenario:
              </Typography>

              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '20px',
                  mb: 3,
                  fontStyle: 'italic',
                  color: theme.palette.mode === 'dark' ? 'white' : '#555',
                }}
              >
                {mysteryText.description}
              </Typography>

              <Box
                sx={{
                  ...styles.freshBox,
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 3,
                  borderRadius: '13px',
                  border: `5px solid ${themeColor}`,
                  backgroundColor: theme.palette.mode === 'dark' ? '#1a2027' : '#f9f9f94f',
                }}
              >
                <Box sx={{ position: 'relative', width: 400, height: 400, mt: 5 }}>
                  <Avatar
                    src={generatedImage}
                    alt='Mystisk bild'
                    variant='rounded'
                    sx={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '13px',
                      bgcolor: '#f2f2f2',
                    }}
                  />
                  {isGeneratingImage && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#f2f2f2',
                        borderRadius: 1,
                      }}
                    >
                      <Mosaic color={['#33CCCC', '#33CC36', '#B8CC33', '#1097DA']} size='small' />
                    </Box>
                  )}
                </Box>

                <Box sx={{ width: '100%', mt: 8 }}>
                  {Array(lineCount)
                    .fill(0)
                    .map((_, lineIndex) => (
                      <Box
                        key={lineIndex}
                        sx={{
                          borderBottom: `2px solid ${themeColor}`,
                          mb: `${lineHeight}px`,
                          position: 'relative',
                          '&::after': {
                            content: '""',
                            position: 'absolute',
                            top: -10,
                            left: 0,
                            right: 0,
                            height: '1px',
                            backgroundColor: 'rgba(0,0,0,0.2)',
                          },
                        }}
                      />
                    ))}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MysticImageLesson;
