import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  MenuItem,
  CircularProgress,
  FormControl,
  Fade,
  Select,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
} from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { callApi } from './api'; // Adjust import path as needed
import { getStyles } from '../index';
import { useTheme } from '@mui/material/styles';
import { marked } from 'marked';
import ProgressBar from './ProgressBar'; // Import the ProgressBar component
import PrintIcon from '@mui/icons-material/Print';
import EventNoteIcon from '@mui/icons-material/EventNote';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import InfoBox from './InfoBox';

import LanguageSelector from './LanguageSelector';

const LessonPlan = ({ user }) => {
  const [lessonTime, setLessonTime] = useState('45 min');
  const [subject, setSubject] = useState('');
  const [area, setArea] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [lessonPlan, setLessonPlan] = useState('');
  const [language, setLanguage] = useState('svenska');
  const [sections, setSections] = useState({
    Mål: true,
    Uppvärmning: true,
    Inledning: true,
    Huvudaktivitet: true,
    Avslutning: true,
    Material: true,
    Hemläxa: false,
    Utvärdering: false,
    Differentiering: false,
    Reflektion: false,
  });
  const [editorContent, setEditorContent] = useState(''); // New state for editor content
  const theme = useTheme();
  const [progress, setProgress] = useState(0);
  const editorRef = useRef(null);

  const styles = getStyles(theme);
  const lessonTimes = ['30 min', '45 min', '60 min', '75 min', '90 min'];

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleSectionChange = event => {
    setSections({
      ...sections,
      [event.target.name]: event.target.checked,
    });
  };

  const handleGeneratePlan = async () => {
    setIsGenerating(true);
    setProgress(0);
    try {
      const selectedSections = Object.entries(sections)
        .filter(([_, isSelected]) => isSelected)
        .map(([section]) => section.replace('_', ' '))
        .join(', ');

      const stopSimulation = simulateProgress(0, 90, 15000);

      const response = await callApi(
        [
          {
            role: 'system',
            content: `You are an assistant that generates lesson plans in the language of ${language}.`,
          },
          {
            role: 'user',
            content: `Generate a lesson plan in the language of ${language} with the following details:
            Lektionstid: ${lessonTime}
            Ämne: ${subject}
            Specifikt område: ${area}
            Include sections for: ${selectedSections}. Use markdown to make headers big, sub headers smaller etc.`,
          },
        ],
        4000,
        true,
        user.uid
      );
      setProgress(95);
      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      setLessonPlan(messageContent);

      // Convert Markdown to HTML
      const htmlContent = marked(messageContent);
      setProgress(100);
      setEditorContent(htmlContent); // Update editor content state
    } catch (error) {
      console.error('Error generating lesson plan:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleEditorChange = (content, editor) => {
    setEditorContent(content); // Update editor content state
  };

  return (
    <>
      <Box sx={styles.container}>
        <Box
          sx={{
            ...styles.innerContainer,
            width: { xs: '100%', md: '87%' },
            margin: '0 auto',
          }}
        >
          <Typography
            variant='h1'
            gutterBottom
            sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
          >
            Lektionsplanering
          </Typography>

          <InfoBox
            icon={EventNoteIcon}
            title='Powerpoint Instructions'
            instructions={[
              'Generera en lektionsplan baserat på ämne och område',
              'Skräddarsy vilka sektioner du vill inkludera',
              'Skriv ut lektionsplanen',
            ]}
            theme={theme}
            styles={styles}
          />

          <Box sx={{ ...styles.freshBox, padding: '30px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500' }}>
                  Lektionstid:
                </Typography>
                <TextField
                  select
                  fullWidth
                  value={lessonTime}
                  onChange={e => setLessonTime(e.target.value)}
                  sx={{ mb: 2, mt: 1 }}
                >
                  {lessonTimes.map(time => (
                    <MenuItem key={time} value={time}>
                      {time}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant='formLabelSinUnderline'
                  sx={{ fontWeight: '500', display: 'block' }}
                >
                  Språk:
                </Typography>
                <Box sx={{ mt: 1, mb: 2 }}>
                  <LanguageSelector language={language} setLanguage={setLanguage} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant='formLabelSinUnderline'
                  sx={{ fontWeight: '500', display: 'block' }}
                >
                  Ämne:
                </Typography>
                <TextField
                  fullWidth
                  placeholder='T.ex. Historia'
                  value={subject}
                  onChange={e => setSubject(e.target.value)}
                  sx={{ mb: 2, mt: 1 }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  variant='formLabelSinUnderline'
                  sx={{ fontWeight: '500', display: 'block' }}
                >
                  Specifikt område:
                </Typography>
                <TextField
                  fullWidth
                  placeholder='T.ex. Vikingatiden'
                  value={area}
                  onChange={e => setArea(e.target.value)}
                  sx={{ mb: 2, mt: 1 }}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Typography
                  variant='formLabelSinUnderline'
                  sx={{ fontWeight: '500', display: 'block' }}
                >
                  Sektioner:
                </Typography>

                <FormGroup sx={{ mb: 3, mt: 1 }}>
                  <Grid container spacing={0}>
                    {Object.entries(sections).map(([section, isChecked]) => (
                      <Grid item xs={12} sm={6} md={4} key={section}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isChecked}
                              onChange={handleSectionChange}
                              name={section}
                            />
                          }
                          label={section.replace('_', ' ')}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Grid>
            </Grid>
            <Button
              variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
              onClick={handleGeneratePlan}
              disabled={isGenerating}
              startIcon={
                !isGenerating ? (
                  <GenerateIcon style={{ color: '#fff' }} />
                ) : (
                  <CircularProgress size={16} />
                )
              }
            >
              {isGenerating ? 'Genererar lektionsplan...' : 'Generera lektionsplan'}
            </Button>

            <Box>
              {editorContent && (
                <Box>
                  <Button
                    variant='contained'
                    startIcon={<PrintIcon />}
                    sx={{ mt: 3 }}
                    onClick={() => {
                      if (editorRef.current) {
                        const content = editorRef.current.getContent();
                        const printWindow = window.open('', '_blank');
                        printWindow.document.write(`
            <html>
              <head>
                <style>
                  body { 
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 
                               Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
                    line-height: 1.6;
                  }
                </style>
              </head>
              <body>${content}</body>
            </html>
          `);
                        printWindow.document.close();
                        printWindow.print();
                      }
                    }}
                  >
                    Skriv ut
                  </Button>
                </Box>
              )}
            </Box>
            {isGenerating && (
              <Box sx={{ mt: 6 }}>
                <ProgressBar progress={progress} label='Genererar lektionsplan...' />
              </Box>
            )}

            <Fade in={true} timeout={1000}>
              <Box sx={{ mt: 8 }}>
                <Editor
                  apiKey='fxkb0ty514q6ak7t7lo34gojjloffnpw3n9fr8tdz3swjxi3'
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  value={editorContent} // Changed from initialValue to value
                  onEditorChange={handleEditorChange}
                  init={{
                    height: 600,
                    menubar: false,

                    plugins: [
                      'advlist',
                      'autolink',
                      'lists',
                      'link',
                      'image',
                      'charmap',
                      'preview',
                      'anchor',
                      'searchreplace',
                      'visualblocks',
                      'code',
                      'fullscreen',
                      'insertdatetime',
                      'media',
                      'table',
                      'code',
                      'help',
                      'wordcount',
                    ],
                    toolbar:
                      'undo redo | blocks | ' +
                      'bold italic forecolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style:
                      'body { font-family:Helvetica,Arial,sans-serif; font-size:15px }',
                    skin: theme.palette.mode === 'dark' ? 'oxide-dark' : 'oxide',
                    content_css: theme.palette.mode === 'dark' ? 'dark' : 'default',
                  }}
                />
              </Box>
            </Fade>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LessonPlan;
