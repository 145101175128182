import React from 'react';
import {
  Typography,
  FormControl,
  Select,
  MenuItem,
  ListSubheader,
  Grid,
  Box,
  TextField,
} from '@mui/material';

// Icons
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import ScienceIcon from '@mui/icons-material/Science';
import PublicIcon from '@mui/icons-material/Public';
import CalculateIcon from '@mui/icons-material/Calculate';
import TranslateIcon from '@mui/icons-material/Translate';
import LanguageIcon from '@mui/icons-material/Language';
import NatureIcon from '@mui/icons-material/Nature';
import WavesIcon from '@mui/icons-material/Waves';
import PeopleIcon from '@mui/icons-material/People';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import PaletteIcon from '@mui/icons-material/Palette';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import CelebrationIcon from '@mui/icons-material/Celebration';
import EggIcon from '@mui/icons-material/EggAlt';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import FilterVintageIcon from '@mui/icons-material/FilterVintage';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import ForestIcon from '@mui/icons-material/Forest';
import NightlightIcon from '@mui/icons-material/Nightlight';
import EmojiFoodBeverageIcon from '@mui/icons-material/EmojiFoodBeverage';
import SpaIcon from '@mui/icons-material/Spa';

const defaultSubjects = [
  { value: 'generateWithAi', label: 'Låt AI välja', Icon: AutoAwesomeIcon },
  { value: 'customTheme', label: 'Skriv in ett tema själv', Icon: EditIcon },

  // Additional themes will be inserted here

  // Seasonal section
  { type: 'subheader', label: 'Säsongsteman' },
  { value: 'halloween', label: 'Halloween', Icon: CoronavirusIcon },
  { value: 'winter', label: 'Vinter', Icon: AcUnitIcon },
  { value: 'summer', label: 'Sommar', Icon: BeachAccessIcon },
  { value: 'spring', label: 'Vår', Icon: FilterVintageIcon },
  { value: 'fall', label: 'Höst', Icon: ForestIcon },
  { value: 'newYear', label: 'Nyår', Icon: CelebrationIcon },

  // Religious section
  { type: 'subheader', label: 'Religiösa högtider' },
  { value: 'ramadan', label: 'Ramadan', Icon: NightlightIcon },
  { value: 'chanukah', label: 'Chanukka', Icon: EmojiFoodBeverageIcon },
  { value: 'christmas', label: 'Jul', Icon: HolidayVillageIcon },
  { value: 'easter', label: 'Påsk', Icon: EggIcon },
  { value: 'vesak', label: 'Vesak', Icon: SpaIcon },

  // School subjects section
  { type: 'subheader', label: 'Skolämnen' },
  { value: 'historia', label: 'Historia', Icon: HistoryIcon },
  { value: 'vetenskap', label: 'Vetenskap', Icon: ScienceIcon },
  { value: 'geografi', label: 'Geografi', Icon: PublicIcon },
  { value: 'matematik', label: 'Matematik', Icon: CalculateIcon },
  { value: 'svenska', label: 'Svenska', Icon: TranslateIcon },
  { value: 'engelska', label: 'Engelska', Icon: LanguageIcon },
  { value: 'biologi', label: 'Biologi', Icon: NatureIcon },
  { value: 'fysik', label: 'Fysik', Icon: WavesIcon },
  { value: 'kemi', label: 'Kemi', Icon: ScienceIcon },
  { value: 'samhällskunskap', label: 'Samhällskunskap', Icon: PeopleIcon },
  { value: 'idrott', label: 'Idrott och hälsa', Icon: FitnessCenterIcon },
  { value: 'musik', label: 'Musik', Icon: MusicNoteIcon },
  { value: 'bild', label: 'Bild', Icon: PaletteIcon },
  { value: 'slöjd', label: 'Slöjd', Icon: ContentCutIcon },
];

const ThemeSelector = ({
  value,
  onChange,
  theme,
  customTheme,
  onCustomThemeChange,
  additionalThemes = [],
}) => {
  // Insert additional themes after customTheme
  const allSubjects = [
    ...defaultSubjects.slice(0, 2), // AI and custom theme options
    ...additionalThemes, // Additional themes
    ...defaultSubjects.slice(2), // Rest of default subjects
  ];

  return (
    <>
      <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
        Tema:
      </Typography>
      <FormControl fullWidth margin='normal' sx={{ mb: 3, mt: 1 }}>
        <Select value={value} onChange={onChange}>
          {allSubjects.map((item, index) => {
            if (item.type === 'subheader') {
              return (
                <ListSubheader
                  key={`subheader-${index}`}
                  sx={{
                    backgroundColor: theme.palette.mode === 'dark' ? '#1E2A3A' : '#f5f5f5',
                    lineHeight: '32px',
                    paddingTop: '3px',
                    paddingBottom: '3px',
                    fontSize: '15px',
                    fontWeight: 600,
                  }}
                >
                  {item.label}
                </ListSubheader>
              );
            }
            return (
              <MenuItem key={item.value} value={item.value} sx={{ py: 1 }}>
                <Grid container alignItems='center' spacing={2}>
                  <Grid item>
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {item.Icon && <item.Icon sx={{ fontSize: 22, color: 'gray' }} />}
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography>{item.label}</Typography>
                  </Grid>
                </Grid>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      {value === 'customTheme' && (
        <>
          <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
            Skriv in eget tema:
          </Typography>
          <TextField
            fullWidth
            value={customTheme}
            onChange={onCustomThemeChange}
            placeholder='T.ex. Eiffeltornet'
            variant='outlined'
            sx={{ mb: 2, mt: 1 }}
          />
        </>
      )}
    </>
  );
};

export { ThemeSelector };
