import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  FormLabel,
  Fade,
  TextField,
  CircularProgress,
  Grid,
  Avatar,
  Dialog,
  Modal,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoBox from './InfoBox';
import { GiTeacher } from 'react-icons/gi';
import LanguageSelector from './LanguageSelector';
import { handlePrintQuizResults } from './printFunctions';
import { callApi } from './api'; // Adjust import path as needed
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import HistoryIcon from '@mui/icons-material/History';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { useSnackbar } from './SnackbarHandler';
import ScienceIcon from '@mui/icons-material/Science';
import ProgressBar from './ProgressBar'; // Import the ProgressBar component
import ImageGenerationModal from './ImageGenerationModal';
import BrushIcon from '@mui/icons-material/Brush';
import PublicIcon from '@mui/icons-material/Public';
import CalculateIcon from '@mui/icons-material/Calculate';
import Logger from './Logger';
import { FourSquare } from 'react-loading-indicators';
import TranslateIcon from '@mui/icons-material/Translate';
import PptxGenerator from './PptxGenerator';
import LanguageIcon from '@mui/icons-material/Language';
import NatureIcon from '@mui/icons-material/Nature';
import WavesIcon from '@mui/icons-material/Waves';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import QuestionTypeSelector from './quiz/QuestionTypeSelector';
import ImageIcon from '@mui/icons-material/Image';
import PeopleIcon from '@mui/icons-material/People';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import PaletteIcon from '@mui/icons-material/Palette';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import QuizIcon from '@mui/icons-material/Quiz';
import EditIcon from '@mui/icons-material/Edit';
import { generateAndProcessImage } from './functions';
import { generateImage } from './api'; // Add this import
import { db, storage } from './firebase'; // Add this import
import { collection, addDoc } from 'firebase/firestore'; // Add this import
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Add this import
import { useSearchParams } from 'react-router-dom';
import { ThemeSelector } from './ThemeSelector';
import PrintIcon from '@mui/icons-material/Print';

const GrejOfTheDay = ({ user, theme, styles, grade }) => {
  const [searchParams] = useSearchParams();

  const urlToTheme = {
    jul: 'christmas',
    host: 'fall',
    var: 'spring',
    vinter: 'winter',
    sommar: 'summer',
    pask: 'easter',
    halloween: 'halloween',
    nyar: 'newYear',
  };

  const [lessonTheme, setLessonTheme] = useState(() => {
    const urlTheme = searchParams.get('tema');
    return urlToTheme[urlTheme] || urlTheme || 'generateWithAi';
  });

  const [language, setLanguage] = useState('svenska');
  const [highLevelFactCount, setHighLevelFactCount] = useState(4);
  const [triviaPerFactCount, setTriviaPerFactCount] = useState(3);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [lessonData, setLessonData] = useState(null);
  const [themeColor, setThemeColor] = useState('#000000');
  const [isGeneratingTopicImage, setIsGeneratingTopicImage] = useState(false);
  const [isGeneratingFactImages, setIsGeneratingFactImages] = useState(false);
  const [progress, setProgress] = useState(0);
  const [lessonProgress, setLessonProgress] = useState(0); // Add this new state
  const [questionType, setQuestionType] = useState('multipleChoice');
  const [isGeneratingQuiz, setIsGeneratingQuiz] = useState(false);
  const [quizData, setQuizData] = useState(null);
  const [generatedFactImages, setGeneratedFactImages] = useState(new Set());
  const { showSnackbar } = useSnackbar();

  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const [imageGenerationType, setImageGenerationType] = useState(null);

  const [openAiInstructions, setOpenAiInstructions] = useState('');

  const [customTheme, setCustomTheme] = useState('');

  const topicSectionRef = useRef(null);
  const factsSectionRef = useRef(null);

  const handleImageButtonClick = type => {
    setImageGenerationType(type);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
  };

  const handleGenerateWithInstructions = async () => {
    handleCloseModal();

    if (imageGenerationType === 'topic') {
      await handleGenerateTopicImage();
    } else if (imageGenerationType === 'facts') {
      await handleGenerateFactImages();
    }
  };

  const getGradeInstructions = grade => {
    switch (grade) {
      case 'åk1':
        return 'For students in grade 1 (ages 7), the lesson should be very simple, with easy-to-understand concepts.';
      case 'åk2':
        return 'For students in grade 2 (ages 8), the lesson should be simple, focusing on building basic knowledge.';
      case 'åk3':
        return 'For students in grade 3 (ages 9), the lesson should be simple, but with slightly more detailed information.';
      case 'åk4':
        return 'For students in grade 4 (ages 10), the lesson should start introducing medium-level difficulty concepts.';
      case 'åk5':
        return 'For students in grade 5 (ages 11), the lesson should present a medium level of difficulty, incorporating more complex ideas.';
      case 'åk6':
        return 'For students in grade 6 (ages 12), the lesson should include medium difficulty with deeper explanations.';
      case 'åk7':
        return 'For students in grade 7 (ages 13), the lesson should be challenging, with a focus on critical thinking and more advanced topics.';
      case 'åk8':
        return 'For students in grade 8 (ages 14), the lesson should be quite challenging, with advanced concepts requiring a strong understanding of the subject.';
      case 'firstRing':
        return 'For students in the first year of high school (ages 16), the lesson should be at a high difficulty level, preparing them for complex subjects.';
      case 'secondRing':
        return 'For students in the second year of high school (ages 17), the lesson should challenge them with in-depth and detailed topics.';
      case 'thirdRing':
        return 'For students in the third year of high school (ages 18), the lesson should be very advanced, reflecting near-collegiate difficulty.';
      default:
        return 'Grade not specified, adjust the difficulty and content accordingly.';
    }
  };

  const scrollToSection = ref => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const simulateProgress = (startProgress, endProgress, duration, setProgressFn) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgressFn(currentProgress);
      }
    }, stepDuration);

    // Return a cleanup function that can be called to stop the simulation
    return () => {
      clearInterval(intervalId);
    };
  };
  const handleGenerateTopicImage = async () => {
    setIsGeneratingTopicImage(true);
    scrollToSection(topicSectionRef);
    try {
      const prompt = `Create a image of ${lessonData.topic}. Do not add any text inside the image. ${imageInstructions}`;

      const imageUrl = await generateAndProcessImage(prompt, user.uid, {
        size: '1024x1024',
        model: 'dall-e-3',
        quality: 'standard',
      });

      setLessonData(prevData => ({
        ...prevData,
        topicImage: imageUrl,
      }));

      showSnackbar('Bild för temat är nu genererad.', 'success');
    } catch (error) {
      showSnackbar('Något gick fel när vi försökte generera bilder. Försök igen.', 'error');
    } finally {
      setIsGeneratingTopicImage(false);
    }
  };

  const handleGenerateFactImages = async () => {
    setIsGeneratingFactImages(true);
    setGeneratedFactImages(new Set());
    scrollToSection(factsSectionRef);
    try {
      const entries = Object.entries(lessonData.highLevelFacts);
      let completedCount = 0;
      const totalCount = entries.length;

      for (const [key, value] of entries) {
        try {
          const prompt = `Create an image for the fact: "${key}" within the topic of "${lessonData.topic}". Do not add any text inside the image. ${imageInstructions}`;

          const imageUrl = await generateAndProcessImage(prompt, user.uid, {
            size: '1024x1024',
            model: 'dall-e-3',
            quality: 'standard',
          });

          setLessonData(prevData => ({
            ...prevData,
            highLevelFacts: {
              ...prevData.highLevelFacts,
              [key]: {
                ...prevData.highLevelFacts[key],
                image: imageUrl,
              },
            },
          }));

          setGeneratedFactImages(prev => new Set([...prev, key]));
          completedCount++;
          showSnackbar(`Genererat bild ${completedCount} av ${totalCount}`, 'info');
        } catch (error) {
          console.error(`Error generating image for fact "${key}":`, error);
          showSnackbar(`Kunde inte generera bild för "${key}"`, 'warning');
          continue;
        }
      }

      showSnackbar('Alla bilder har genererats!', 'success');
    } catch (error) {
      console.error('Error in image generation process:', error);
      showSnackbar('Ett fel uppstod i bildgenereringsprocessen', 'error');
    } finally {
      setIsGeneratingFactImages(false);
    }
  };

  const handleGenerateLesson = async () => {
    setIsGeneratingLesson(true);
    setLessonProgress(0);
    let stopProgressSimulation = null;
    try {
      const generateTheme = lessonTheme === 'generateWithAi';
      const themeToUse = lessonTheme === 'customTheme' ? customTheme : lessonTheme;
      const gradeSpecificInstructions = getGradeInstructions(grade);

      Logger.log('gradeSpecificInstructions: ', gradeSpecificInstructions);

      stopProgressSimulation = simulateProgress(0, 90, 15000, setLessonProgress);

      const response = await callApi(
        [
          {
            role: 'system',
            content: `You are an assistant that generates interesting school lessons ${gradeSpecificInstructions}. ${openAiInstructions}`,
          },
          {
            role: 'user',
            content: `Generate a JSON object with a lesson for kids to learn new facts about a topic in "${language}". 
            It should be exactly ${triviaPerFactCount} few and short interesting facts per high-level fact about the topic. 
            The amount of high-level facts should be exactly ${highLevelFactCount}. 
            Include empty image placeholders (null) for "topicImage" and "image" fields in each high-level fact. 
            Prioritize novelty and uniqueness in each generation. Do NOT repeat themes from previous responses. ${
              generateTheme
                ? 'Generate a COMPLETELY UNIQUE and RANDOM theme.'
                : lessonTheme === 'customTheme'
                  ? `Generate content about the specific topic "${themeToUse}".`
                  : `Generate a specific and UNIQUE topic WITHIN the chosen domain "${themeToUse}".`
            } Example response:
{
  "topic": "Gravitation",
  "clue": "En kraft som håller oss kvar på jorden",
  "topicImage": null,
  "highLevelFacts": {
    "Tyngdkraft på jorden": {
      "image": null,
      "facts": [
        "fact 1",
        "fact 2",
        "fact 3",
        "fact 4",
        "fact 5"
      ]
    },
    "Gravitation i rymden": {
      "image": null,
      "facts": [
        "fact 1",
        "fact 2",
        "fact 3",
        "fact 4",
        "fact 5"
      ]
    },
    "Hur gravitation upptäcktes": {
      "image": null,
      "facts": [
        "fact 1",
        "fact 2",
        "fact 3",
        "fact 4",
        "fact 5"
      ]
    }
  }
}`,
          },
        ],
        4000,
        true,
        user.uid
      );

      // Stop the progress simulation
      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setLessonProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);

      setLessonProgress(100);
      await new Promise(resolve => setTimeout(resolve, 800)); // Longer delay for visibility

      setLessonData(data);

      showSnackbar('Grej of the day har blivit genererad.', 'success');
    } catch (error) {
      console.error('Error generating lesson:', error);
      showSnackbar('Nåt gick fel. Vänligen försök igen.', 'error');
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleQuestionTypeChange = event => {
    setQuestionType(event.target.value);
  };

  const handleGenerateQuiz = async () => {
    setIsGeneratingQuiz(true);
    setProgress(0);

    let stopProgressSimulation = null;

    try {
      stopProgressSimulation = simulateProgress(0, 90, 15000, setProgress);

      const response = await callApi(
        [
          {
            role: 'system',
            content: 'You are an assistant that generates quizzes based on lesson content.',
          },
          {
            role: 'user',
            content: `Generate a quiz with 10 questions based on the following lesson content. The question type should be ${questionType}. Here's the lesson content:

Topic: ${lessonData.topic}
Clue: ${lessonData.clue}

High-Level Facts:
${Object.entries(lessonData.highLevelFacts)
  .map(
    ([section, data]) => `
${section}:
${data.facts.join('\n')}
`
  )
  .join('\n')}

 Do not use any markdown formatting.  Ensure all questions are returned in a single JSON array. Format example:
{
  "questions": [
    {
      "question": "Question text here",
      "type": "${questionType}",
      "options": ["A. Option A", "B. Option B", "C. Option C", "D. Option D"], // Eller "Sant" / "Falskt" om questionType är true-or-false. 
      "correctAnswer": "Correct answer here"
    },
    // ... more questions
  ]
}`,
          },
        ],
        4000,
        true,
        user.uid
      );

      // Stop the progress simulation
      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(95);

      console.log('response: ', response);
      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setProgress(100);

      await new Promise(resolve => setTimeout(resolve, 800)); // Longer delay for visibility

      setQuizData(data);
    } catch (error) {
      console.error('Error generating quiz:', error);
    } finally {
      setIsGeneratingQuiz(false);
    }
  };

  const isGeneratingAnyImages = isGeneratingTopicImage || isGeneratingFactImages;

  return (
    <>
      <Box sx={styles.container}>
        <Box
          sx={{
            ...styles.innerContainer,
            width: { xs: '100%', md: '87%' },
            margin: '0 auto',
          }}
        >
          <Typography
            variant='h1'
            gutterBottom
            sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
          >
            Grej of the Day
          </Typography>
          <InfoBox
            icon={GiTeacher}
            title='Powerpoint Instructions'
            instructions={[
              'Generera grej of the day baserat på valt tema',
              'Lägg till AI-genererade bilder',
              'Ladda ned som powerpoint',
            ]}
            theme={theme}
            styles={styles}
          />
          <Box sx={{ ...styles.freshBox, padding: '30px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box sx={{ mt: 1 }}>
                  <ThemeSelector
                    value={lessonTheme}
                    onChange={e => setLessonTheme(e.target.value)}
                    theme={theme}
                    customTheme={customTheme}
                    onCustomThemeChange={e => setCustomTheme(e.target.value)}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                  Språk:
                </Typography>
                <Box sx={{ mt: 0.5 }}>
                  <LanguageSelector language={language} setLanguage={setLanguage} />
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                  Antal fakta-sektioner:
                </Typography>
                <FormControl fullWidth margin='normal' sx={{ mb: 3, mt: 1 }}>
                  <Select
                    value={highLevelFactCount}
                    onChange={e => setHighLevelFactCount(e.target.value)}
                  >
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                  Antal kuriositeter per fakta-sektion:
                </Typography>
                <FormControl fullWidth margin='normal' sx={{ mb: 3, mt: 1 }}>
                  <Select
                    value={triviaPerFactCount}
                    onChange={e => setTriviaPerFactCount(e.target.value)}
                  >
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4} disabled>
                      4
                    </MenuItem>
                    <MenuItem value={5} disabled>
                      5
                    </MenuItem>
                    <MenuItem value={6} disabled>
                      6
                    </MenuItem>
                    <MenuItem value={7} disabled>
                      7
                    </MenuItem>
                    <MenuItem value={8} disabled>
                      8
                    </MenuItem>
                    <MenuItem value={9} disabled>
                      9
                    </MenuItem>
                    <MenuItem value={10} disabled>
                      10
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                  Specifika instruktioner till AI:n:
                </Typography>
                <FormControl fullWidth margin='normal' sx={{ mb: 3, mt: 1 }}>
                  <TextField
                    multiline
                    rows={2}
                    value={openAiInstructions}
                    onChange={e => setOpenAiInstructions(e.target.value)}
                    placeholder='T.ex. Ta endast med information mellan åren 1933-1936.'
                    variant='outlined'
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Box>
              <Button
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                color='primary'
                onClick={handleGenerateLesson}
                disabled={isGeneratingLesson || isGeneratingAnyImages}
                sx={{ mt: 2 }}
                startIcon={
                  !isGeneratingLesson ? (
                    <GenerateIcon style={{ color: '#fff' }} />
                  ) : (
                    <CircularProgress style={{ color: '#fff' }} size={16} />
                  )
                }
              >
                {isGeneratingLesson ? 'Genererar grej of the day...' : 'Generera grej of the day'}
              </Button>
            </Box>

            {isGeneratingLesson && (
              <Box sx={{ mt: 7 }}>
                <ProgressBar progress={lessonProgress} label='Genererar grej of the day...' />
              </Box>
            )}

            {lessonData && (
              <>
                <Divider sx={{ mt: 5, mb: 3 }} />
                <Button
                  variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                  color='primary'
                  onClick={() => handleImageButtonClick('topic')}
                  disabled={isGeneratingTopicImage || isGeneratingFactImages}
                  sx={{ mt: 2 }}
                  startIcon={
                    !isGeneratingTopicImage ? (
                      <ImageIcon style={{ color: '#fff' }} />
                    ) : (
                      <CircularProgress style={{ color: '#fff' }} size={16} />
                    )
                  }
                >
                  {isGeneratingTopicImage ? 'Genererar...' : 'Lägg till temabild'}
                </Button>

                <Button
                  variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                  color='primary'
                  onClick={() => handleImageButtonClick('facts')}
                  disabled={isGeneratingTopicImage || isGeneratingFactImages}
                  sx={{ mt: 2, ml: 2 }}
                  startIcon={
                    !isGeneratingFactImages ? (
                      <ImageIcon style={{ color: '#fff' }} />
                    ) : (
                      <CircularProgress style={{ color: '#fff' }} size={16} />
                    )
                  }
                >
                  {isGeneratingFactImages ? 'Genererar...' : 'Lägg till faktabilder'}
                </Button>

                <ImageGenerationModal
                  open={showImageModal}
                  onClose={handleCloseModal}
                  onGenerate={handleGenerateWithInstructions}
                  isGenerating={
                    imageGenerationType === 'topic'
                      ? isGeneratingTopicImage
                      : isGeneratingFactImages
                  }
                  instructions={imageInstructions}
                  setInstructions={setImageInstructions}
                  generationType={imageGenerationType}
                  theme={theme}
                />

                <PptxGenerator
                  lessonData={{
                    ...lessonData,
                    quizData: quizData, // Pass quiz data if you want to include it
                  }}
                  theme={theme}
                  disabled={isGeneratingAnyImages}
                />

                <Box sx={{ mt: 4 }}>
                  <Typography
                    variant='h3'
                    sx={{ fontWeight: 'bold', textAlign: 'center', mb: 4, mt: 8 }}
                  >
                    Grej of the day
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {/* Clue Section */}
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '500px',
                          backgroundColor: theme.palette.mode === 'dark' ? '#15202b' : '#f5f5f5',
                          borderRadius: '10px',
                        }}
                      >
                        <HelpOutlineIcon sx={{ fontSize: 90, mb: 2 }} />
                        <Typography variant='h4' sx={{ textAlign: 'center' }}>
                          {lessonData.clue}...
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 4 }}>
                        <KeyboardDoubleArrowDownIcon
                          sx={{
                            fontSize: '4rem',
                            color: '#6424c1',
                            //animation: 'bounce 2s infinite',
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} ref={topicSectionRef}>
                      {/* Topic Section */}
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '500px',
                          flexDirection: 'column',
                          backgroundColor: theme.palette.mode === 'dark' ? '#15202b' : '#e0e0e0',
                          borderRadius: '10px',
                        }}
                      >
                        <Typography variant='h3' sx={{ fontWeight: 'bold' }}>
                          {lessonData.topic}
                        </Typography>

                        <Box sx={{ position: 'relative', mt: 7 }}>
                          <Avatar
                            variant='rounded'
                            src={
                              lessonData.topicImage
                                ? lessonData.topicImage
                                : '/api/placeholder/800/400'
                            }
                            alt='Topic Image'
                            sx={{
                              width: '200px',
                              height: '200px',
                              borderRadius: '100%',
                            }}
                          />
                          {isGeneratingTopicImage && (
                            <Box
                              sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#d7d7d7',
                                borderRadius: '100%',
                              }}
                            >
                              <FourSquare color='#8b5cf6' size='medium' text='' textColor='' />
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 4 }}>
                        <KeyboardDoubleArrowDownIcon
                          sx={{
                            fontSize: '4rem',
                            color: '#6424c1',
                            //animation: 'bounce 2s infinite',
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        mt: 8,
                        '& p': {
                          lineHeight: '27px',
                        },
                        '& li': {
                          marginBottom: '12px',
                          lineHeight: '25px',
                          '& strong': {
                            fontWeight: '600',
                          },
                        },
                        '& h1': {
                          color: themeColor,
                        },
                        '& h2': {
                          marginTop: '60px',
                          marginBottom: '0px',
                          color: themeColor,
                        },
                        '& h3': {
                          marginTop: '40px',
                          marginBottom: '0px',
                          color: themeColor,
                        },
                        '& hr': {
                          height: '1px',
                          border: 'none',
                          backgroundColor: theme => theme.palette.divider,
                          marginTop: '50px',
                          marginBottom: '50px',
                        },
                        '& ul, & ol': {
                          '& li::marker': {
                            color: themeColor,
                          },
                        },
                      }}
                    >
                      {/* High-Level Facts */}
                      <Grid container spacing={4} ref={factsSectionRef}>
                        {Object.keys(lessonData.highLevelFacts).map((section, index) => (
                          <Grid item xs={12} md={6} key={index}>
                            <Typography variant='h6' sx={{ fontWeight: 'bold', mb: 2 }}>
                              {section}
                            </Typography>

                            {/* High-Level Fact Image */}
                            <Box sx={{ position: 'relative', mb: 2 }}>
                              <Avatar
                                variant='rounded'
                                src={
                                  lessonData.highLevelFacts[section].image
                                    ? lessonData.highLevelFacts[section].image
                                    : '/api/placeholder/600/300'
                                }
                                alt={`${section} Image`}
                                sx={{
                                  width: '100%',
                                  height: 'auto',
                                  aspectRatio: '16 / 9',
                                  backgroundColor: '#e0e0e0',
                                  borderRadius: '10px',
                                }}
                              />
                              {isGeneratingFactImages && !generatedFactImages.has(section) && (
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#d7d7d7',
                                    borderRadius: '10px',
                                  }}
                                >
                                  <FourSquare color='#8b5cf6' size='medium' text='' textColor='' />
                                </Box>
                              )}
                            </Box>

                            <ul>
                              {lessonData.highLevelFacts[section].facts.map((fact, idx) => (
                                <li key={idx}>
                                  <Typography variant='body1'>{fact}</Typography>
                                </li>
                              ))}
                            </ul>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Divider sx={{ mt: 8, mb: 8 }} />
                <Box sx={{ width: '70%' }}>
                  <Typography variant='h5' sx={{ fontWeight: 'bold', mb: 2 }}>
                    Quiz — Testa elevens kunskap en vecka senare
                  </Typography>

                  <QuestionTypeSelector
                    selected={questionType}
                    setQuestionType={setQuestionType}
                    theme={theme}
                  />

                  <Box>
                    <Button
                      variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                      color='primary'
                      onClick={handleGenerateQuiz}
                      disabled={isGeneratingQuiz}
                      startIcon={
                        !isGeneratingQuiz ? (
                          <QuizIcon style={{ color: '#fff' }} />
                        ) : (
                          <CircularProgress size={16} />
                        )
                      }
                      sx={{ mb: 5 }}
                    >
                      {isGeneratingQuiz ? 'Genererar quiz...' : 'Generera quiz baserat på GOTD'}
                    </Button>
                  </Box>

                  {isGeneratingQuiz && (
                    <ProgressBar progress={progress} label='Genererar quiz...' />
                  )}
                </Box>
                {quizData && (
                  <Box sx={{ mt: 4 }}>
                    <Box sx={{ mb: 4 }}>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() => handlePrintQuizResults(lessonData.topic, quizData.questions)}
                        startIcon={<PrintIcon />}
                      >
                        Skriv ut quiz
                      </Button>
                    </Box>

                    {quizData.questions.map((question, index) => (
                      <Box key={index} sx={{ mb: 5 }}>
                        <Typography
                          variant='h6'
                          sx={{ fontWeight: 'bold' }}
                        >{`${index + 1}. ${question.question}`}</Typography>
                        {question.type === 'multipleChoice' && (
                          <Box sx={{ mt: 1 }}>
                            {question.options.map((option, optionIndex) => (
                              <Typography key={optionIndex} variant='body1'>
                                {option}
                              </Typography>
                            ))}
                          </Box>
                        )}
                        {question.type === 'trueFalse' && (
                          <Typography variant='body1'>Sant eller Falskt?</Typography>
                        )}

                        <Typography variant='body2' sx={{ fontStyle: 'italic', mt: 1 }}>
                          Rätt svar: {question.correctAnswer}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                )}
              </>
            )}
          </Box>{' '}
        </Box>{' '}
      </Box>
    </>
  );
};

export default GrejOfTheDay;
