import React from 'react';
import { FormControl, Select, MenuItem, Grid, Typography } from '@mui/material';
import ReactCountryFlag from 'react-country-flag';

// Reuse the languageToFlag mapping for flags
const languageToFlag = {
  amhariska: 'et',
  arabiska: 'sa',
  bengali: 'bd',
  'brasiliansk-portugisiska': 'br',
  dari: 'af',
  danska: 'dk',
  engelska: 'gb',
  farsi: 'ir',
  finska: 'fi',
  franska: 'fr',
  grekiska: 'gr',
  hebreiska: 'il',
  hindi: 'in',
  indonesiska: 'id',
  italienska: 'it',
  japanska: 'jp',
  kinesiska: 'cn',
  koreanska: 'kr',
  nederländska: 'nl',
  norska: 'no',
  polska: 'pl',
  portugisiska: 'pt',
  punjabi: 'in',
  rumänska: 'ro',
  ryska: 'ru',
  somali: 'so',
  spanska: 'es',
  svenska: 'se',
  swahili: 'tz',
  tagalog: 'ph',
  tamil: 'in',
  telugu: 'in',
  thai: 'th',
  tjeckiska: 'cz',
  turkiska: 'tr',
  tyska: 'de',
  ungerska: 'hu',
  urdu: 'pk',
  vietnamesiska: 'vn',
};

const LanguageSelector = ({ language, setLanguage }) => {
  return (
    <FormControl fullWidth margin='normal' sx={{ mb: 0, mt: 0 }}>
      <Select
        value={language}
        onChange={e => setLanguage(e.target.value)}
        renderValue={selected => (
          <Grid container alignItems='center' spacing={1}>
            <Grid item>
              {languageToFlag[selected] && (
                <ReactCountryFlag
                  countryCode={languageToFlag[selected].toUpperCase()}
                  svg
                  style={{
                    width: '20px',
                    height: 'auto',
                  }}
                />
              )}
            </Grid>
            <Grid item>
              <Typography>{selected.charAt(0).toUpperCase() + selected.slice(1)}</Typography>
            </Grid>
          </Grid>
        )}
      >
        <MenuItem value='amhariska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='ET' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Amhariska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='arabiska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='SA' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Arabiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='bengali'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='BD' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Bengali</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='brasiliansk-portugisiska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='BR' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Brasiliansk portugisiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='dari'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='AF' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Dari</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='danska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='DK' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Danska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='engelska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='GB' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Engelska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='farsi'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='IR' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Farsi</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='finska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='FI' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Finska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='franska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='FR' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Franska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='grekiska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='GR' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Grekiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='hebreiska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='IL' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Hebreiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='hindi'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='IN' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Hindi</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='indonesiska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='ID' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Indonesiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='italienska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='IT' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Italienska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='japanska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='JP' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Japanska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='kinesiska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='CN' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Kinesiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='koreanska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='KR' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Koreanska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='nederländska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='NL' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Nederländska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='norska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='NO' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Norska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='polska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='PL' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Polska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='portugisiska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='PT' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Portugisiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='punjabi'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='IN' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Punjabi</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='rumänska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='RO' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Rumänska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='ryska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='RU' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Ryska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='somali'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='SO' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Somali</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='spanska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='ES' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Spanska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='svenska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='SE' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Svenska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='swahili'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='TZ' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Swahili</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='tagalog'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='PH' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Tagalog</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='tamil'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='IN' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Tamil</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='telugu'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='IN' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Telugu</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='thai'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='TH' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Thai</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='tjeckiska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='CZ' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Tjeckiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='tyska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='DE' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Tyska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='ungerska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='HU' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Ungerska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='urdu'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='PK' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Urdu</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value='vietnamesiska'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <ReactCountryFlag countryCode='VN' svg style={{ width: '20px', height: 'auto' }} />
            </Grid>
            <Grid item>
              <Typography>Vietnamesiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
