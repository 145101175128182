import React, { useState } from 'react';
import pptxgen from 'pptxgenjs';
import { Button } from '@mui/material';
import PresentationIcon from '@mui/icons-material/Slideshow';
import CircularProgress from '@mui/material/CircularProgress';

const PptxGenerator = ({ lessonData, theme, disabled }) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const THEME = {
    colors: {
      primary: '#6424c1',
      secondary: '#f5f5f5',
      text: '#333333',
      white: '#FFFFFF',
      black: '#000000',
      titleSlide: '#1a237e', // Deep indigo background for first slide
    },
    fonts: {
      heading: 'Arial',
      body: 'Arial',
    },
  };

  const generatePresentation = async () => {
    try {
      setIsGenerating(true);
      const pres = new pptxgen();

      pres.layout = 'LAYOUT_16x9';
      pres.author = 'Studera.ai';

      // Title slide with vertical centering and new design
      let slide = pres.addSlide();
      slide.background = { color: THEME.colors.titleSlide };

      // Container for vertically centered content
      slide.addText(
        [
          {
            text: 'Grej of the Day\n\n',
            options: {
              fontSize: 44,
              color: THEME.colors.white,
              bold: true,
              fontFace: THEME.fonts.heading,
            },
          },
          {
            text: lessonData.clue + '...',
            options: {
              fontSize: 32,
              color: THEME.colors.white,
              fontFace: THEME.fonts.body,
            },
          },
        ],
        {
          x: 0.5,
          y: '30%', // Position at 30% from top
          w: '90%',
          h: '40%', // Take up 40% of vertical space
          align: 'center',
          valign: 'middle', // Vertical alignment within container
        }
      );

      // Topic reveal
      slide = pres.addSlide();
      slide.background = { color: THEME.colors.primary };
      slide.addText(lessonData.topic, {
        x: 0.5,
        y: 1.5,
        w: '90%',
        fontSize: 60,
        color: THEME.colors.white,
        bold: true,
        align: 'center',
        fontFace: THEME.fonts.heading,
      });

      if (lessonData.topicImage) {
        slide.addImage({
          path: lessonData.topicImage,
          x: 2,
          y: 2.5,
          w: 6,
          h: 3,
        });
      }

      // Fact slides
      Object.entries(lessonData.highLevelFacts).forEach(([section, data]) => {
        slide = pres.addSlide();
        slide.background = { color: THEME.colors.white };

        // Section title
        slide.addText(section, {
          x: 0.5,
          y: 0.5,
          w: '90%',
          fontSize: 32,
          color: THEME.colors.primary,
          bold: true,
          fontFace: THEME.fonts.heading,
        });

        const startY = data.image ? 1.3 : 1.5;
        const textWidth = data.image ? '45%' : '90%';
        const textX = data.image ? 5 : 0.5;

        if (data.image) {
          slide.addImage({
            path: data.image,
            x: 0.5,
            y: 1.3,
            w: 4,
            h: 3,
          });
        }

        // Bullet points with body font
        data.facts.forEach((fact, index) => {
          const yPos = startY + index * 0.8;
          slide.addText(fact, {
            x: textX,
            y: yPos,
            w: textWidth,
            fontSize: 18,
            color: THEME.colors.text,
            bullet: true,
            breakLine: true,
            fontFace: THEME.fonts.body,
          });
        });
      });

      await pres.writeFile({ fileName: `${lessonData.topic} - Studera.ai.pptx` });

      setNotification({
        open: true,
        message: 'Presentation generated successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Failed to generate presentation:', error);
      setNotification({
        open: true,
        message: 'Failed to generate presentation. Please try again.',
        severity: 'error',
      });
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <>
      <Button
        variant='contained'
        color='primary'
        onClick={generatePresentation}
        disabled={isGenerating || disabled}
        startIcon={isGenerating ? <CircularProgress size={20} /> : <PresentationIcon />}
        sx={{ mt: 2, ml: 2 }}
      >
        {isGenerating ? 'Genererar...' : 'Generera powerpoint'}
      </Button>
    </>
  );
};

export default PptxGenerator;
