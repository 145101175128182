import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  TextField,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ImageList,
  Fade,
  ImageListItem,
  Box,
  Alert,
  Snackbar,
  Skeleton,
  Dialog,
  Tooltip,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import { generateImage } from './api';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import DownloadIcon from '@mui/icons-material/GetApp';
import CopyIcon from '@mui/icons-material/FileCopy';
import { useSnackbar } from './SnackbarHandler';
import CloseIcon from '@mui/icons-material/Close';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ImageIcon from '@mui/icons-material/Image'; // Add this import
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import CodeIcon from '@mui/icons-material/Code';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import Suggestions from './Suggestions';
import LinearProgress from '@mui/material/LinearProgress';
import { Mosaic } from 'react-loading-indicators';
import { db, storage } from './firebase';
import { collection, addDoc, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { generateAndProcessImage } from './functions';

const ImageGenerator = ({ user }) => {
  const [prompt, setPrompt] = useState('');
  const [size, setSize] = useState('1024x1024');
  const [model, setModel] = useState('dall-e-3');
  const [numImages, setNumImages] = useState(1);
  const [quality, setQuality] = useState('standard');
  const [responseFormat, setResponseFormat] = useState('url');
  const [isLoading, setIsLoading] = useState(false);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);

  useEffect(() => {
    fetchImages();
  }, [user.uid]);

  const fetchImages = async () => {
    try {
      const imagesRef = collection(db, 'images');
      const q = query(
        imagesRef,
        where('userId', '==', user.uid),
        orderBy('createdAt', 'desc'),
        limit(12)
      );
      const querySnapshot = await getDocs(q);
      const images = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setGeneratedImages(images);
    } catch (error) {
      console.error('Fel vid hämtning av bilder:', error);
      setError('Kunde inte hämta bilder. Vänligen försök igen.');
    }
  };

  const handleImageClick = image => {
    setSelectedImage(image);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const downloadImage = async (imageUrl, filename) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      showSnackbar('Bild nedladdad!', 'success');
    } catch (error) {
      console.error('Error downloading image:', error);
      showSnackbar('Kunde inte ladda ner bilden', 'error');
    }
  };

  const handleDownload = () => {
    if (selectedImage) {
      downloadImage(selectedImage.imageUrl, `studera-ai-${selectedImage.id}.png`);
    }
  };

  const handleCopyPrompt = () => {
    if (selectedImage) {
      navigator.clipboard.writeText(selectedImage.prompt);
    }
  };

  const handleSelect = selected => {
    setPrompt(selected.value);
  };

  const handleGenerate = async () => {
    setIsLoading(true);
    setError(null);

    try {
      // Generate images one by one
      for (let i = 0; i < numImages; i++) {
        await generateAndProcessImage(prompt, user.uid, {
          size,
          model,
          quality,
          responseFormat: 'url',
        });
      }

      // Fetch updated images after all generations are complete
      showSnackbar('Bild genererad!', 'success');
      await fetchImages();
    } catch (error) {
      console.error('Error generating or processing image:', error);
      const errorMessage = error.response?.data?.details || error.message;
      showSnackbar('Bild kunde ej genereras. Försök igen. Felmeddelande: ', errorMessage, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const renderImageOrSkeleton = (item, index) => {
    if (isLoading && index < numImages) {
      return (
        <Fade in={true} timeout={2000}>
          <Box
            sx={{
              position: 'relative',
              width: 194,
              height: 164,
            }}
          >
            <Skeleton
              variant='rectangular'
              width={194}
              height={164}
              animation='wave'
              sx={{ borderRadius: '7px' }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Mosaic color={['#33CCCC', '#33CC36', '#B8CC33', '#1097DA']} size='small' />
            </Box>
          </Box>
        </Fade>
      );
    }
    return (
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          '&:hover .hover-actions': {
            opacity: 1,
          },
        }}
      >
        <img
          src={item.imageUrl}
          alt={item.prompt}
          loading='lazy'
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            borderRadius: '7px',
          }}
        />
        <Box
          className='hover-actions'
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '8px',
            opacity: 0,
            transition: 'opacity 0.2s ease-in-out',
            background: 'linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%)',
            borderTopLeftRadius: '7px',
            borderTopRightRadius: '7px',
          }}
        >
          <Tooltip title='Ladda ned' placement='top'>
            <IconButton
              size='small'
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                backdropFilter: 'blur(4px)',
                marginRight: '4px',
                padding: '4px',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                },
              }}
              onClick={e => {
                e.stopPropagation();
                downloadImage(item.imageUrl, `generated-image-${item.id}.png`);
              }}
            >
              <DownloadIcon sx={{ fontSize: 16, color: 'white' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title='Kopiera prompt' placement='top'>
            <IconButton
              size='small'
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                backdropFilter: 'blur(4px)',
                padding: '4px',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                },
              }}
              onClick={e => {
                e.stopPropagation();
                navigator.clipboard.writeText(item.prompt);
                showSnackbar('Prompt kopierad!', 'success');
              }}
            >
              <CopyIcon sx={{ fontSize: 16, color: 'white' }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    );
  };

  const renderPlaceholder = () => (
    <ImageList sx={{ width: '100%', height: 'calc(100vh - 200px)' }} cols={3} rowHeight={164}>
      {[1].map(item => (
        <ImageListItem key={item} sx={{ width: '224px', borderRadius: '5px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: 164,
              width: '100%',
              backgroundColor:
                theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.04)',
              borderRadius: '7px',
            }}
          >
            <ImageIcon
              sx={{
                fontSize: 24,
                color:
                  theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.2)',
                mb: 0.5,
              }}
            />
            <Typography
              variant='caption'
              sx={{
                color:
                  theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.4)',
                fontSize: '0.7rem',
                textAlign: 'center',
                px: 1,
              }}
            >
              Din bild hamnar här
            </Typography>
          </Box>
        </ImageListItem>
      ))}
    </ImageList>
  );

  return (
    <Box sx={{ ...styles.container }}>
      <Box sx={{ ...styles.innerContainer }}>
        <Typography variant='h1' sx={{ ...styles.header, mb: 3 }}>
          Generera bilder med AI
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Box sx={{ ...styles.freshBox, padding: '30px', mt: 2 }}>
              <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                Prompt
              </Typography>
              <TextField
                placeholder='Generera en futuristisk bild av Stockholm med Selma Lagerlöf i bakgrunden, läsandes en bok.'
                value={prompt}
                fullWidth
                sx={{ mt: 1, mb: 1 }}
                onChange={e => setPrompt(e.target.value)}
                multiline
                rows={6}
              />

              <Box sx={{ mb: 3 }}>
                <Suggestions
                  listType='generateImageIdeas'
                  maxSuggestions={6}
                  onSelect={handleSelect}
                  randomize={true}
                />
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500' }}>
                    Storlek
                  </Typography>
                  <FormControl fullWidth sx={{ mt: 1 }}>
                    <Select value={size} onChange={e => setSize(e.target.value)}>
                      <MenuItem value='256x256'>256x256 (kvadrat)</MenuItem>
                      <MenuItem value='512x512'>512x512 (kvadrat)</MenuItem>
                      <MenuItem value='1024x1024'>1024x1024 (kvadrat)</MenuItem>
                      <MenuItem value='1024x1792'>1024x1792 (porträtt)</MenuItem>
                      <MenuItem value='1792x1024'>1792x1024 (landskap)</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500' }}>
                    Modell
                  </Typography>
                  <FormControl fullWidth sx={{ mt: 1, mb: 2 }}>
                    <Select value={model} onChange={e => setModel(e.target.value)}>
                      <MenuItem value='dall-e-3'>DALL-E 3</MenuItem>
                      <MenuItem value='dall-e-2'>DALL-E 2</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500' }}>
                    Antal bilder
                  </Typography>
                  <TextField
                    type='number'
                    placeholder='1-10'
                    disabled={true}
                    value={numImages}
                    fullWidth
                    sx={{ mt: 1, mb: 2 }}
                    onChange={e =>
                      setNumImages(Math.max(1, Math.min(10, parseInt(e.target.value) || 1)))
                    }
                    inputProps={{ min: 1, max: 10 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500' }}>
                    Kvalitet
                  </Typography>
                  <FormControl disabled={true} fullWidth sx={{ mt: 1 }}>
                    <Select
                      disabled={true}
                      value={quality}
                      onChange={e => setQuality(e.target.value)}
                    >
                      <MenuItem value='standard'>Standard</MenuItem>
                      <MenuItem value='hd'>HD</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Button
                variant={theme.palette.mode === 'light' ? 'purp' : 'blue'}
                onClick={handleGenerate}
                disabled={isLoading}
                sx={{ mt: 3, width: '100%' }}
                startIcon={!isLoading ? <GenerateIcon /> : <CircularProgress size={16} />}
              >
                {isLoading ? 'Genererar bild...' : 'Generera bild'}
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} md={8}>
            <ImageList
              sx={{ width: '100%', height: 'calc(100vh - 200px)' }}
              cols={3}
              rowHeight={164}
            >
              {isLoading &&
                Array.from(new Array(numImages)).map((_, index) => (
                  <ImageListItem
                    key={`skeleton-${index}`}
                    sx={{ width: '204px', borderRadius: '5px' }}
                  >
                    {renderImageOrSkeleton(null, index)}
                  </ImageListItem>
                ))}
              {generatedImages.map((item, index) => (
                <ImageListItem
                  onClick={() => handleImageClick(item)}
                  key={item.id}
                  sx={{
                    width: '204px',
                    borderRadius: '5px',
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  {renderImageOrSkeleton(item, index + numImages)}
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>

          <Dialog
            open={dialogOpen}
            onClose={handleCloseDialog}
            maxWidth='md'
            fullWidth
            PaperProps={{
              sx: {
                borderRadius: '20px',
                backgroundColor: theme.palette.mode === 'dark' ? '#22303C' : 'white',
                backgroundImage: theme.palette.mode === 'dark' ? 'none' : 'white',
                padding: { xs: '0px', md: '20px' },
              },
            }}
          >
            <DialogTitle>
              <IconButton
                onClick={handleCloseDialog}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: theme.palette.mode === 'dark' ? 'white' : 'black',
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent>
              <Grid container spacing={6}>
                <Grid item xs={12} md={7}>
                  <Box sx={{ ...styles.freshBox }}>
                    <img
                      src={selectedImage?.imageUrl}
                      alt={selectedImage?.prompt}
                      style={{ width: '100%', height: 'auto', borderRadius: '4px' }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Typography variant='h6' gutterBottom>
                    Information
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, mt: 3 }}>
                    <CalendarTodayIcon sx={{ mr: 1 }} />
                    <Typography variant='body2'>
                      Skapad: {selectedImage?.createdAt.toDate().toLocaleString()}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <AspectRatioIcon sx={{ mr: 1 }} />
                    <Typography variant='body2'>Storlek: {selectedImage?.size}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <CodeIcon sx={{ mr: 1 }} />
                    <Typography variant='body2'>AI-modell: {selectedImage?.model}</Typography>
                  </Box>
                  <Typography variant='subtitle1' gutterBottom sx={{ mt: 2 }}>
                    Prompt:
                  </Typography>
                  <Typography variant='body2' paragraph>
                    {selectedImage?.prompt}
                  </Typography>
                  <Button
                    startIcon={<CopyIcon />}
                    onClick={handleCopyPrompt}
                    variant='outlined'
                    sx={{
                      borderRadius: '30px',
                      textTransform: 'none',
                      marginRight: '10px',
                      marginBottom: '10px',
                      verticalAlign: 'middle',
                      fontWeight: '400',
                      borderBottom: theme.palette.mode === 'dark' ? 'none' : undefined,
                      '&:hover': {
                        borderBottom: theme.palette.mode === 'dark' ? 'none' : undefined,
                        '& .MuiButton-startIcon': {
                          color: theme.palette.mode === 'dark' ? 'primary.main' : 'white',
                        },
                      },
                    }}
                  >
                    Kopiera prompt
                  </Button>

                  <Button
                    startIcon={<DownloadIcon />}
                    onClick={() => {
                      if (selectedImage) {
                        downloadImage(selectedImage.imageUrl, `studera-ai-${selectedImage.id}.png`);
                      }
                    }}
                    variant='outlined'
                    sx={{
                      borderRadius: '30px',
                      textTransform: 'none',
                      marginRight: '10px',
                      marginBottom: '10px',
                      verticalAlign: 'middle',
                      fontWeight: '400',
                      borderBottom: theme.palette.mode === 'dark' ? 'none' : undefined,
                      '&:hover': {
                        borderBottom: theme.palette.mode === 'dark' ? 'none' : undefined,
                        '& .MuiButton-startIcon': {
                          color: theme.palette.mode === 'dark' ? 'primary.main' : 'white',
                        },
                      },
                    }}
                  >
                    Ladda ner
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </Grid>
      </Box>
    </Box>
  );
};

export default ImageGenerator;
