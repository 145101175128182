import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  ListItem,
  ListItemText,
  IconButton,
  Card,
  CardContent,
  Paper,
  Chip,
  ListItemIcon, // Ensure ListItemIcon is imported if used
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import StarIcon from '@mui/icons-material/Star';

import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Helmet } from 'react-helmet-async';
// **Newly Added Icon Imports**
import { features } from './app/functions';

import { Link } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { motion } from 'framer-motion';
import { getAIModelImage } from './app/TestComponents2';

import UniversitySection from './UniversitySection';
import MediaSection from './MediaSection';
import FAQSection from './FAQSection';
import Logger from './app/Logger';
import TestimonialsSection from './TestimonialsSection';
import {
  GradientBackground,
  StyledButton,
  FeatureSection,
  FeatureList,
  ResponsiveVideo,
} from './StyledComponents';

import Header from './Header';
import Footer from './Footer';

const ModernStuderaAIWebsite = ({ toggleDarkMode, isDark }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Studera.AI - Revolutionera ditt lärande med AI</title>
        <meta
          name='description'
          content='Upptäck framtidens lärande med Studera.AI. Personliga AI-lärare, quiz, flashcards och mer. Effektivisera dina studier och nå dina mål snabbare med vår innovativa AI-plattform.'
        />
      </Helmet>

      <Header />

      <Box sx={{ bgcolor: 'background.default', color: 'text.primary', minHeight: '100vh' }}>
        <GradientBackground className='gradient-background'>
          <Container maxWidth='xl' sx={{ pt: { xs: 10, md: 20 }, pb: 15, position: 'relative' }}>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={12} md={6}>
                <Box sx={{ pl: { xs: 0, md: 4 } }}>
                  <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                  >
                    <Box sx={{ display: 'flex', mb: 2 }}>
                      <Chip
                        label={
                          <span>
                            Studera.ai{' '}
                            <span style={{ opacity: 0.7 }}>
                              &nbsp;•&nbsp; Revolutionerar lärande med AI
                            </span>
                          </span>
                        }
                        sx={{
                          backgroundColor: 'rgba(255, 255, 255, 0.1)',
                          color: 'white',
                          fontWeight: '600',
                          fontSize: '0.8rem',
                          padding: '2px 14px',

                          borderRadius: '16px',
                          '& .MuiChip-label': {
                            padding: '0 4px',
                          },
                        }}
                      />
                    </Box>

                    <Typography
                      variant='h2'
                      component='h1'
                      gutterBottom
                      sx={{
                        fontWeight: 900,
                        letterSpacing: -1,
                        fontSize: { xs: '2.5rem', md: '3.35rem' },
                      }}
                    >
                      AI-verktyg för elever, lärare & pedagoger
                    </Typography>
                    <Typography
                      variant='h5'
                      sx={{ mb: 4, opacity: 0.8, fontSize: '1.3rem', fontWeight: '500' }}
                    >
                      Med hjälp av AI revolutionerar vi arbetet för elever, lärare & pedagoger -
                      oavsett kunskapsnivå.
                    </Typography>

                    <Typography variant='h5' sx={{ mb: 4, opacity: 0.8, fontSize: '1.3rem' }}>
                      <TypeAnimation
                        sequence={[
                          'Skapa lektioner med AI',
                          1000,
                          'Skapa kurser med AI',
                          1000,
                          'Låt AI betygsätta uppsatser',
                          1000,
                          'Skapa egna AI-karaktärer',
                          1000,
                          'Skapa AI-genererade quiz',
                          1000,
                          'Lär dig nya språk med AI',
                          1000,

                          'Träna på gamla högskoleprov',
                          1000,
                          'Träna på AI-genererade högskoleprov',
                          1000,
                          'Generera anpassade flashcards',
                          1000,
                          'Chatta med AI-lärare i realtid',
                          1000,
                          'Generera text med AI',
                          1000,
                          'Förbättra ditt ordförråd',
                          1000,
                          'Simulera debatter',
                          1000,
                          'Omvandla YouTube-föreläsningar till quiz',
                          1000,
                          'Analysera om en text är AI-genererad',
                          1000,
                          'Få förklaringar på komplexa ämnen',
                          1000,
                          'Visualisera koncept med AI',
                          1000,
                          'Röstsamtal med AI-karaktärer',
                        ]}
                        speed={50}
                        deletionSpeed={90}
                        style={{ fontSize: '1.3rem' }}
                        repeat={Infinity}
                      />
                    </Typography>
                    <Box
                      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', mb: 4 }}
                    >
                      <Typography variant='body2' sx={{ color: 'white', mb: 1 }}>
                        Används av både lärare, pedagoger och elever.
                      </Typography>
                      <Box>
                        {[...Array(5)].map((_, index) => (
                          <StarIcon key={index} sx={{ color: 'gold', fontSize: 20 }} />
                        ))}
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <Link to='/app' style={{ textDecoration: 'none' }}>
                        <StyledButton
                          variant='blue'
                          color='primary'
                          size='large'
                          endIcon={<KeyboardDoubleArrowRightIcon />}
                          sx={{ borderRadius: '28px', padding: '13px 50px', fontSize: '20px' }}
                        >
                          Använd gratis nu
                        </StyledButton>
                      </Link>

                      {/*
                      <Link to='/ai-verktyg-larare' style={{ textDecoration: 'none' }}>
                        <StyledButton
                          variant='blue'
                          color='primary'
                          size='large'
                          endIcon={<KeyboardDoubleArrowRightIcon />}
                          sx={{ borderRadius: '28px', padding: '13px 50px', fontSize: '20px' }}
                        >
                          För lärare
                        </StyledButton>
                      </Link>

                      <Link to='/ai-verktyg-elever' style={{ textDecoration: 'none' }}>
                        <StyledButton
                          variant='blue'
                          color='primary'
                          size='large'
                          endIcon={<KeyboardDoubleArrowRightIcon />}
                          sx={{ borderRadius: '28px', padding: '13px 50px', fontSize: '20px' }}
                        >
                          För elever
                        </StyledButton>
                      </Link>

*/}
                    </Box>
                  </motion.div>
                </Box>
              </Grid>

              {/* Image on the right */}
              <Grid
                item
                xs={12}
                md={6}
                sx={{ position: 'relative' }}
                sx={{ display: { xs: 'none', md: 'block' } }}
              >
                <motion.div
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.8, delay: 0.2 }}
                >
                  <Link to='/app' style={{ textDecoration: 'none' }}>
                    <Box
                      component='img'
                      src={theme.palette.mode === 'dark' ? '/img/d.png' : '/img/l.png'}
                      alt='Studera med AI'
                      sx={{
                        width: 'calc(100% + 100px)', // Stretch the image beyond its container
                        height: 'auto',
                        borderRadius: '7px',
                        position: 'relative',
                        right: '-50px', // Moves the image to touch the scrollbar
                      }}
                    />
                  </Link>
                </motion.div>
              </Grid>
            </Grid>
          </Container>
        </GradientBackground>
        <UniversitySection bgcolor='background.default' showHeadline={false} />

        <Box sx={{ bgcolor: 'background.paper', py: 8, position: 'relative' }}>
          <Container maxWidth='lg'>
            <Typography
              variant='h2'
              align='center'
              sx={{ fontWeight: 'bold', fontSize: '2.3em', mb: 2 }}
            >
              Upptäck möjligheterna med Studera.ai
            </Typography>
            <Typography variant='subtitle1' align='center' color='text.secondary' sx={{ mb: 4 }}>
              Utforska några av de populäraste funktionerna
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <KeyboardDoubleArrowDownIcon
                sx={{
                  fontSize: '4rem',
                  color: '#6424c1',
                  //animation: 'bounce 2s infinite',
                }}
              />
            </Box>
          </Container>
          {/*
          <style>
            {`
      @keyframes bounce {
        0%, 20%, 50%, 80%, 100% {
          transform: translateY(0);
        }
        40% {
          transform: translateY(-30px);
        }
        60% {
          transform: translateY(-15px);
        }
      }
    `}
          </style>
          */}
        </Box>

        {features
          .filter(feature => !feature.hideOnStartpage)
          .map((feature, index) => {
            const hasModels = Boolean(feature.models && feature.models.length > 0);
            const isImageRight = index % 2 === 0;

            return (
              <FeatureSection
                key={index}
                id={feature.title.toLowerCase().replace(/ /g, '-')}
                sx={{
                  bgcolor: index % 2 === 0 ? 'background.default' : 'background.paper',
                  padding: { xs: '30px 0px', md: '80px 0px' },
                }}
              >
                <Container>
                  <Grid
                    container
                    spacing={6}
                    alignItems='center'
                    direction={isImageRight ? 'row' : 'row-reverse'}
                  >
                    {/* Text Content */}
                    <Grid
                      item
                      xs={12}
                      md={hasModels ? 12 : 6}
                      sx={{
                        textAlign: hasModels ? 'center' : 'left',
                      }}
                    >
                      <Typography
                        variant='h3'
                        gutterBottom
                        sx={{
                          fontWeight: 'bold',
                          fontSize: { xs: '1.6em', md: '2.3em' },
                          textAlign: hasModels ? 'center' : 'left',
                        }}
                      >
                        {feature.title}
                      </Typography>
                      <Typography
                        variant='h6'
                        paragraph
                        color='text.secondary'
                        sx={{
                          textAlign: hasModels ? 'center' : 'left',
                        }}
                      >
                        {feature.description}
                      </Typography>

                      {hasModels ? (
                        // If feature has models, render them
                        <Grid
                          container
                          spacing={2}
                          justifyContent='center'
                          sx={{ marginTop: hasModels ? '20px' : 'inherit' }}
                        >
                          {feature.models.map((model, idx) => (
                            <Grid item xs={12} sm={6} md={4} key={idx}>
                              <Card
                                sx={{
                                  height: '100%',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  padding: 2,
                                  boxShadow: 'none',
                                  transition: 'transform 0.3s, box-shadow 0.3s',
                                  '&:hover': {
                                    transform: 'translateY(-10px)',
                                  },
                                }}
                              >
                                <CardContent sx={{ textAlign: 'center' }}>
                                  <Box
                                    component='img'
                                    src={getAIModelImage(model.name)}
                                    alt={model.name}
                                    sx={{ width: 60, height: 60, mb: 2, borderRadius: '9px' }}
                                  />
                                  <Typography variant='h6' gutterBottom>
                                    {model.name}
                                  </Typography>
                                  <Typography variant='body2' color='text.secondary'>
                                    {model.description}
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        // If feature has details, render them
                        <FeatureList
                          sx={{
                            backgroundColor:
                              theme.palette.mode === 'dark' ? 'transparent' : 'inherit',
                          }}
                        >
                          {feature.details
                            .filter(detail => detail.showInFeaturePage !== true)
                            .map((detail, idx) => (
                              <ListItem
                                key={idx}
                                alignItems='flex-start'
                                sx={{ marginLeft: '0px', paddingLeft: '0px' }}
                              >
                                <ListItemIcon
                                  sx={{
                                    '& > svg': {
                                      fontSize: '34px',
                                      color: theme.palette.mode === 'dark' ? '#9575F7' : '#6424c1',
                                    },
                                    minWidth: '40px',
                                    marginRight: '15px',
                                    marginLeft: '0px',
                                  }}
                                >
                                  {detail.icon}
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <Typography sx={{ fontWeight: '600', fontSize: '17px' }}>
                                      {detail.text.split(':')[0]}
                                    </Typography>
                                  }
                                  secondary={
                                    <Typography
                                      sx={{
                                        fontSize: '15px',
                                        color: theme.palette.mode === 'dark' ? '#8899a6' : 'gray',
                                      }}
                                    >
                                      {detail.text.split(':')[1]}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            ))}
                        </FeatureList>
                      )}

                      <Box
                        mt={4}
                        display='flex'
                        justifyContent={hasModels ? 'center' : 'flex-start'}
                      >
                        <Link
                          to={
                            feature.goDirectlyToApp
                              ? '/app'
                              : feature.customLink
                                ? `/funktioner/${feature.customLink}`
                                : `/funktioner/${feature.title.toLowerCase().replace(/ /g, '-').replace(/å/g, 'a').replace(/ä/g, 'a').replace(/ö/g, 'o')}`
                          }
                          style={{ textDecoration: 'none' }}
                        >
                          <StyledButton
                            variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                            color='primary'
                            endIcon={<ArrowForwardIcon />}
                          >
                            Lär dig mer
                          </StyledButton>
                        </Link>
                      </Box>
                    </Grid>

                    {/* Image or Video Content */}
                    {!hasModels && (
                      <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                          position: 'relative',
                          display: { xs: 'none', md: 'block' },
                        }}
                      >
                        {feature.video ? (
                          // If feature has a video, render the video
                          <Paper
                            elevation={3}
                            sx={{
                              borderRadius: 2,
                              overflow: 'hidden',
                              background: 'none',
                              backgroundImage: 'none',
                              boxShadow: 'none',
                            }}
                          >
                            <ResponsiveVideo
                              light={feature.video.light}
                              dark={feature.video.dark}
                              theme={theme}
                              alt={`${feature.title} demonstration`}
                            />
                          </Paper>
                        ) : feature.img ? (
                          // If feature has an image, render the image
                          <motion.div
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.8, delay: 0.2 }}
                          >
                            <Link to='/app' style={{ textDecoration: 'none' }}>
                              <Box
                                component='img'
                                src={
                                  theme.palette.mode === 'dark'
                                    ? feature.img.dark
                                    : feature.img.light
                                }
                                alt={`${feature.title} demonstration`}
                                sx={{
                                  width: 'calc(100% + 100px)', // Stretch the image beyond its container
                                  height: 'auto',
                                  borderRadius: '7px',
                                  position: 'relative',
                                  ...(isImageRight
                                    ? { right: '-50px' } // Moves the image to touch the scrollbar on the right
                                    : { left: '-50px' }), // Moves the image to start at the left edge
                                }}
                              />
                            </Link>
                          </motion.div>
                        ) : null}
                      </Grid>
                    )}
                  </Grid>
                </Container>
              </FeatureSection>
            );
          })}

        <Container
          maxWidth='xl'
          sx={{
            paddingTop: 15,
            paddingBottom: 15,
            textAlign: 'center',

            backgroundImage:
              'url("/img/hepp.svg"), radial-gradient(circle, rgb(106, 80, 243), rgb(62, 46, 141) 82%)',
          }}
        >
          <Typography
            variant='h3'
            gutterBottom
            sx={{ fontWeight: 'bold', fontSize: '2.3em', color: 'white' }}
          >
            Redo att revolutionera ditt lärande?
          </Typography>
          <Typography variant='h6' sx={{ mb: 4, color: '#E7E7E8' }}>
            Anslut dig till 3000+ lärare och elever som effektiviserat sitt arbete med Studera.ai
          </Typography>
          <Link to='/app' style={{ textDecoration: 'none' }}>
            <StyledButton
              variant='contained'
              color='primary'
              size='large'
              endIcon={<ArrowForwardIcon />}
            >
              Prova Studera.AI gratis nu
            </StyledButton>
          </Link>
        </Container>

        <TestimonialsSection />
        {/*<MediaSection  bgcolor='background.default'  />*/}

        <Footer />
      </Box>

      {!isMobile && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 9999,
          }}
        >
          <IconButton onClick={toggleDarkMode}>
            {isDark ? <LightModeIcon /> : <DarkModeIcon />}
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default ModernStuderaAIWebsite;
