import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  Avatar,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  TextField,
  useTheme,
  Slider,
} from '@mui/material';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import ImageIcon from '@mui/icons-material/Image';
import PrintIcon from '@mui/icons-material/Print';
import { handlePrintWritingLesson } from './printFunctions';
import { OrbitProgress } from 'react-loading-indicators';
import { callApi } from './api';
import { generateAndProcessImage } from './functions';
import { useSnackbar } from './SnackbarHandler';
import GradeSelector, { gradeInstructions } from './GradeSelector';
import LanguageSelector from './LanguageSelector';
import SubjectIcon from '@mui/icons-material/Subject';
import ImageGenerationModal from './ImageGenerationModal';
import { getStyles } from '../index';
import { ThemeSelector } from './ThemeSelector';
import { Mosaic } from 'react-loading-indicators';
import InfoBox from './InfoBox';

const WritingLesson = ({ user }) => {
  const [words, setWords] = useState([]);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [generatedImages, setGeneratedImages] = useState(new Set());
  const [themeColor, setThemeColor] = useState('#8a59f4');
  const { showSnackbar } = useSnackbar();

  const [themeType, setThemeType] = useState('generateWithAi');
  const [customTheme, setCustomTheme] = useState('');
  const [wordCount, setWordCount] = useState(5);
  const [lineCount, setLineCount] = useState(4);
  const [lineHeight, setLineHeight] = useState(50); // New state for line height (in pixels)
  const [grade, setGrade] = useState('åk6');
  const [language, setLanguage] = useState('svenska');

  const theme = useTheme();
  const styles = getStyles(theme);

  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const [imageGenerationType, setImageGenerationType] = useState(null);

  const handleImageButtonClick = type => {
    setImageGenerationType(type);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
  };

  const handleGenerateWithInstructions = async () => {
    handleCloseModal();

    if (imageGenerationType === 'topic') {
      await handleGenerateImages();
    } else if (imageGenerationType === 'facts') {
      await handleGenerateImages();
    }
  };

  // Handler for line count slider
  const handleLineCountChange = (event, newValue) => {
    setLineCount(newValue);
  };

  const LINE_HEIGHT_SCALE = 5; // Each slider unit represents 5 pixels

  // Convert actual pixels to slider value
  const pixelsToSliderValue = pixels => Math.round(pixels / LINE_HEIGHT_SCALE);

  // Convert slider value to actual pixels
  const sliderValueToPixels = value => value * LINE_HEIGHT_SCALE;

  // Updated handler for line height slider
  const handleLineHeightChange = (event, newValue) => {
    // Convert slider value to actual pixels before storing
    setLineHeight(sliderValueToPixels(newValue));
  };

  const handleGenerateLesson = async () => {
    setIsGeneratingLesson(true);
    try {
      const themePrompt =
        themeType === 'customTheme'
          ? customTheme
          : themeType === 'generateWithAi'
            ? '[RANDOM]'
            : themeType;

      const response = await callApi(
        [
          {
            role: 'system',
            content: 'Du är en assistent som genererar ord för skrivövningar.',
          },
          {
            role: 'user',
            content: `Generera JSON med exakt ${wordCount} ord på ${language} ${themePrompt !== '[RANDOM]' ? `relaterade till temat "${themePrompt}"` : ''}.
          ${gradeInstructions[grade]}
          
          Exempel på svar:
          {
            "words": [
              { "word": "katt" },
              { "word": "hund" },
              { "word": "häst" }
            ]
          }`,
          },
        ],
        4000,
        true,
        user.uid
      );

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      const wordsWithImages = data.words.map(wordObj => ({
        ...wordObj,
        image: '/api/placeholder/200/200',
      }));

      setWords(wordsWithImages);
      showSnackbar('Nya ord har genererats', 'success');
    } catch (error) {
      console.error('Error generating lesson:', error);
      showSnackbar('Kunde inte generera nya ord', 'error');
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleGenerateImages = async () => {
    setIsGeneratingImages(true);
    setGeneratedImages(new Set());

    try {
      const updatedWords = [...words];

      for (const [index, word] of words.entries()) {
        try {
          const prompt = `Create a simple, clear image representing the ${language} word "${word.word}". Style: clean, colorful, suitable for children's education. No text in the image. ${imageInstructions}`;

          const imageUrl = await generateAndProcessImage(prompt, user.uid, {
            size: '1024x1024',
            model: 'dall-e-3',
            quality: 'standard',
          });

          updatedWords[index] = {
            ...updatedWords[index],
            image: imageUrl,
          };

          setGeneratedImages(prev => new Set([...prev, index]));
          setWords(updatedWords);
          showSnackbar(`Genererat bild ${index + 1} av ${words.length}`, 'info');
        } catch (error) {
          console.error(`Error generating image for word "${word.word}":`, error);
          showSnackbar(`Kunde inte generera bild för "${word.word}"`, 'error');
          continue;
        }
      }
      showSnackbar('Alla bilder har genererats!', 'success');
    } catch (error) {
      console.error('Error in image generation process:', error);
      showSnackbar('Ett fel uppstod när bilderna skulle genereras', 'error');
    } finally {
      setIsGeneratingImages(false);
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: '100%', md: '87%' },
          margin: '0 auto',
        }}
      >
        <Typography
          variant='h1'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
        >
          Generera skrivövningar
        </Typography>

        <InfoBox
          icon={SubjectIcon}
          title='Powerpoint Instructions'
          instructions={[
            'Generera skrivövningar med kontroll över antal rader och radavstånd',
            'Lägg till AI-genererade bilder för varje ord',
            'Skriv ut lektionen',
          ]}
          theme={theme}
          styles={styles}
        />

        <Box sx={{ ...styles.freshBox, padding: '30px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <ThemeSelector
                value={themeType}
                onChange={e => setThemeType(e.target.value)}
                theme={theme}
                customTheme={customTheme}
                onCustomThemeChange={e => setCustomTheme(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                Språk:
              </Typography>
              <Box sx={{ mt: 1 }}>
                <LanguageSelector language={language} setLanguage={setLanguage} />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                Årskurs:
              </Typography>
              <Box sx={{ mt: 1, mb: 3 }}>
                <GradeSelector grade={grade} setGrade={setGrade} />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                Antal ord:
              </Typography>
              <FormControl fullWidth margin='normal' sx={{ mb: 3, mt: 1 }}>
                <Select value={wordCount} onChange={e => setWordCount(e.target.value)}>
                  <MenuItem value={3}>3 ord</MenuItem>
                  <MenuItem value={4}>4 ord</MenuItem>
                  <MenuItem value={5}>5 ord</MenuItem>
                  <MenuItem value={6}>6 ord</MenuItem>
                  <MenuItem value={8}>8 ord</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                Antal rader per ord:
              </Typography>
              <Box sx={{ px: 2, mt: 2 }}>
                <Slider
                  value={lineCount}
                  onChange={handleLineCountChange}
                  step={1}
                  marks
                  min={1}
                  max={8}
                  valueLabelDisplay='auto'
                  sx={{
                    color: theme.palette.mode === 'light' ? '#8b5cf6' : 'primary',
                    '& .MuiSlider-markLabel': {
                      color: theme.palette.text.primary,
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                Radavstånd (pixlar):
              </Typography>
              <Box sx={{ px: 2, mt: 2 }}>
                <Slider
                  // Convert stored pixels back to slider value
                  value={pixelsToSliderValue(lineHeight)}
                  onChange={handleLineHeightChange}
                  step={1}
                  marks
                  min={0}
                  max={15} // Max value of 10 will represent 50 pixels
                  valueLabelDisplay='auto'
                  // Add custom value label to show actual pixels
                  valueLabelFormat={value => `${sliderValueToPixels(value)}px`}
                  sx={{
                    color: theme.palette.mode === 'light' ? '#8b5cf6' : 'primary',
                    '& .MuiSlider-markLabel': {
                      color: theme.palette.text.primary,
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ mb: 4, mt: 4 }}>
            <Button
              variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
              color='primary'
              onClick={handleGenerateLesson}
              disabled={isGeneratingLesson}
              startIcon={!isGeneratingLesson ? <GenerateIcon /> : <CircularProgress size={20} />}
              sx={{ mr: 2, mt: 6 }}
            >
              {isGeneratingLesson ? 'Genererar...' : 'Generera nya ord'}
            </Button>

            {words.length > 0 && (
              <>
                <Button
                  variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                  color='primary'
                  onClick={() => handleImageButtonClick('facts')}
                  disabled={isGeneratingImages}
                  startIcon={!isGeneratingImages ? <ImageIcon /> : <CircularProgress size={20} />}
                  sx={{ mr: 2, mt: 6 }}
                >
                  {isGeneratingImages ? 'Genererar bilder...' : 'Generera bilder'}
                </Button>

                <ImageGenerationModal
                  open={showImageModal}
                  onClose={handleCloseModal}
                  onGenerate={handleGenerateWithInstructions}
                  isGenerating={
                    imageGenerationType === 'topic' ? isGeneratingImages : isGeneratingImages
                  }
                  instructions={imageInstructions}
                  setInstructions={setImageInstructions}
                  generationType={imageGenerationType}
                  theme={theme}
                />

                <Button
                  variant='outlined'
                  color='primary'
                  onClick={() => handlePrintWritingLesson(words, lineCount, lineHeight, themeColor)}
                  startIcon={<PrintIcon />}
                  sx={{ mt: 6 }}
                >
                  Skriv ut
                </Button>
              </>
            )}
          </Box>

          {words.length > 0 && (
            <>
              <Grid container spacing={3}>
                {words.map((word, index) => (
                  <>
                    <Typography
                      sx={{
                        fontWeight: '500',
                        fontSize: '23px',
                        display: 'block',
                        mt: 8,
                        ml: 2,
                        textTransform: 'capitalize',
                      }}
                    >
                      {word.word}
                    </Typography>
                    <Grid item xs={12} key={index}>
                      <Box
                        sx={{
                          ...styles.freshBox,
                          p: 2,
                          display: 'flex',
                          alignItems: 'center',
                          gap: 3,
                          borderRadius: '13px',
                          border: `5px solid ${themeColor}`,
                          backgroundColor: theme.palette.mode === 'dark' ? '#1a2027' : '#f9f9f94f',
                        }}
                      >
                        <Box sx={{ position: 'relative', width: 200, height: 200, flexShrink: 0 }}>
                          <Avatar
                            src={word.image}
                            alt={word.word}
                            variant='rounded'
                            sx={{
                              width: '100%',
                              height: '100%',
                              borderRadius: '13px',
                              bgcolor: '#f2f2f2',
                            }}
                          />
                          {isGeneratingImages && !generatedImages.has(index) && (
                            <Box
                              sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0, // Add this
                                width: '100%', // Add this
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#f2f2f2',
                                borderRadius: 1,
                              }}
                            >
                              <Mosaic
                                color={['#33CCCC', '#33CC36', '#B8CC33', '#1097DA']}
                                size='small'
                              />
                            </Box>
                          )}
                        </Box>

                        <Box sx={{ flex: 1, mt: 7 }}>
                          {Array(lineCount)
                            .fill(0)
                            .map((_, lineIndex) => (
                              <Box
                                key={lineIndex}
                                sx={{
                                  borderBottom: '2px solid black',
                                  mb: `${lineHeight}px`, // Now using the actual pixel value
                                  position: 'relative',
                                  '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    top: -10,
                                    left: 0,
                                    right: 0,
                                    height: '1px',
                                    backgroundColor: 'rgba(0,0,0,0.2)',
                                  },
                                }}
                              />
                            ))}
                        </Box>
                      </Box>
                    </Grid>
                  </>
                ))}
              </Grid>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default WritingLesson;
