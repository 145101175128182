import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/system';
import { Box, Button, List } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
export const GradientBackground = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(135deg, #200660 0%, #3D1368 100%)',
  color: theme.palette.common.white,
  position: 'relative',
  overflow: 'hidden',
  backgroundImage:
    'url("/img/hepp.svg"), radial-gradient(circle, rgb(106, 80, 243), rgb(62, 46, 141) 82%)',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'url("/api/placeholder/1920/1080") center/cover no-repeat',
    opacity: 0.1,
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '50px',
  padding: '12px 30px',
  fontWeight: 'bold',
  textTransform: 'none',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 20px rgba(107, 70, 193, 0.4)',
  },
}));

export const FeatureSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(10, 0),
  background: theme.palette.background.default,
}));

export const FeatureList = styled(List)(({ theme }) => ({
  '& .MuiListItem-root, & .MuiListItemIcon-root, & .MuiListItemText-root': {
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'default',
    },
  },
  '& *': {
    pointerEvents: 'none',
    transition: 'none !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
      color: 'inherit !important',
      transform: 'none !important',
      boxShadow: 'none !important',
    },
  },
}));

export const ResponsiveVideo = ({ light, dark, alt, maxWidth = 500, theme, styled = false }) => {
  const videoRef = useRef(null);

  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const videoSrc = theme.palette.mode === 'dark' ? dark : light;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      if (isVisible) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [isVisible]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [videoSrc]);

  const videoStyle = {
    width: '100%',
    height: 'auto',
    borderRadius: '13px',
    ...(styled && {
      border: '2px solid #e8e8e8',
      boxShadow:
        'rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px',
    }),
  };

  return (
    <Link to='/app' style={{ textDecoration: 'none' }}>
      <div ref={containerRef} style={{ maxWidth: `${maxWidth}px`, margin: '0 auto' }}>
        <video ref={videoRef} key={videoSrc} loop muted playsInline style={videoStyle}>
          <source src={videoSrc} type='video/mp4' />
          {alt}
        </video>
      </div>
    </Link>
  );
};
