import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Avatar,
  Grid,
  CircularProgress,
  IconButton,
  Paper,
  Divider,
  TextField,
  useTheme,
} from '@mui/material';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import ImageIcon from '@mui/icons-material/Image';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { callApi, generateImage } from './api';
import PrintIcon from '@mui/icons-material/Print';
import { OrbitProgress } from 'react-loading-indicators';
import WindowIcon from '@mui/icons-material/Window';
import { generateAndProcessImage } from './functions';
import LanguageSelector from './LanguageSelector';
import { handlePrint } from './printFunctions';
import { useSnackbar } from './SnackbarHandler';
import Voice from './Voice';
import InfoBox from './InfoBox';
import { useSearchParams } from 'react-router-dom';
import { ThemeSelector } from './ThemeSelector';
import ImageGenerationModal from './ImageGenerationModal';
import GradeSelector, { gradeInstructions } from './GradeSelector';

import { getStyles } from '../index';

const TermsGrid = ({ user }) => {
  const [searchParams] = useSearchParams();

  const urlToTheme = {
    jul: 'christmas',
    host: 'fall',
    var: 'spring',
    vinter: 'winter',
    sommar: 'summer',
    pask: 'easter',
    halloween: 'halloween',
    nyar: 'newYear',
  };

  const [gridTheme, setGridTheme] = useState(() => {
    const urlTheme = searchParams.get('tema');
    return urlToTheme[urlTheme] || urlTheme || 'generateWithAi';
  });

  const [language, setLanguage] = useState('svenska');
  const [secondLanguage, setSecondLanguage] = useState('arabiska');
  const [terms, setTerms] = useState([]);
  const [storyTheme, setStoryTheme] = useState('');
  const [isGeneratingTerms, setIsGeneratingTerms] = useState(false);
  const [themeImage, setThemeImage] = useState('/api/placeholder/100/100/');
  const [themeTranslation, setThemeTranslation] = useState('');
  const [isGeneratingTermImages, setIsGeneratingTermImages] = useState(false);
  const [isGeneratingThemeImage, setIsGeneratingThemeImage] = useState(false);
  const [themeColor, setThemeColor] = useState('#5e5e5e');
  const theme = useTheme();
  const styles = getStyles(theme);
  const [isEditingTheme, setIsEditingTheme] = useState(false);
  const [generatedTermImages, setGeneratedTermImages] = useState(new Set());
  const { showSnackbar } = useSnackbar();

  const [customTheme, setCustomTheme] = useState('');

  const [grade, setGrade] = useState('åk6');

  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const [imageGenerationType, setImageGenerationType] = useState(null);

  const handleImageButtonClick = type => {
    setImageGenerationType(type);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
  };

  const handleGenerateWithInstructions = async () => {
    handleCloseModal();

    if (imageGenerationType === 'topic') {
      await handleGenerateThemeImage();
    } else if (imageGenerationType === 'facts') {
      await handleGenerateTermImages();
    }
  };

  const handlePrintClick = () => {
    handlePrint(terms, storyTheme, themeImage, themeTranslation, themeColor);
  };

  const handleGenerateThemeImage = async () => {
    setIsGeneratingThemeImage(true);
    try {
      const prompt = `Create an image representing the theme "${storyTheme}". Do not include any text in the image. ${imageInstructions}`;

      const imageUrl = await generateAndProcessImage(prompt, user.uid, {
        size: '1024x1024',
        model: 'dall-e-3',
        quality: 'standard',
      });

      setThemeImage(imageUrl);
      showSnackbar('Temabild har genererats', 'success');
    } catch (error) {
      console.error('Error generating theme image:', error);
      showSnackbar('Kunde inte generera temabild. Försök igen.', 'error');
    } finally {
      setIsGeneratingThemeImage(false);
    }
  };

  const handleGenerateTermImages = async () => {
    setIsGeneratingTermImages(true);
    setGeneratedTermImages(new Set());
    try {
      const totalTerms = terms.length;
      let completedCount = 0;

      // Create a copy of terms to update
      const updatedTerms = [...terms];

      for (const [index, term] of terms.entries()) {
        try {
          const prompt = `Create an image representing the word "${term.word}" (${term.translation}). Do not include any text in the image. ${imageInstructions}`;

          const imageUrl = await generateAndProcessImage(prompt, user.uid, {
            size: '1024x1024',
            model: 'dall-e-3',
            quality: 'standard',
          });

          // Update the specific term with its new image
          updatedTerms[index] = {
            ...updatedTerms[index],
            image: imageUrl,
          };

          // Update state to show progress
          setTerms(updatedTerms);
          setGeneratedTermImages(prev => new Set([...prev, index]));

          completedCount++;
          showSnackbar(`Genererat bild ${completedCount} av ${totalTerms}`, 'info');
        } catch (error) {
          console.error(`Error generating image for term "${term.word}":`, error);
          showSnackbar(`Kunde inte generera bild för "${term.word}"`, 'warning');
          continue;
        }
      }

      showSnackbar('Alla bilder har genererats!', 'success');
    } catch (error) {
      console.error('Error in image generation process:', error);
      showSnackbar('Ett fel uppstod i bildgenereringsprocessen', 'error');
    } finally {
      setIsGeneratingTermImages(false);
    }
  };

  const handleGenerateTerms = async () => {
    setIsGeneratingTerms(true);
    try {
      const generateTheme = gridTheme === 'generateWithAi';
      const gradeInstructionsText = gradeInstructions[grade];
      const themeToUse = gridTheme === 'customTheme' ? customTheme : gridTheme;

      let response = await callApi(
        [
          {
            role: 'system',
            content:
              'You are an assistant that generates terms for language lessons, optionally including a theme.',
          },
          {
            role: 'user',
            content: `Generate JSON with ${generateTheme ? 'a random theme and ' : ''} exactly 10 terms related to the theme "${generateTheme ? '[RANDOM]' : themeToUse}" in "${language}" with translations in "${secondLanguage}". Include the theme (if generated), its translation in ${secondLanguage}, each term, and its translation. 
${gradeInstructionsText}.
Important: Ensure that the theme (if generated) is in ${language} and its translation is accurately provided in ${secondLanguage}.

Example response:
{
  "theme": "theme in ${language}",
  "themeTranslation": "accurate translation of the theme in ${secondLanguage}",
  "terms": [
    { "word": "word in ${language}", "translation": "translation in ${secondLanguage}",
    "exampleSentence": "Example sentence using the word in ${language}",
      "translatedExampleSentence": "Translation of the example sentence in ${secondLanguage}"


     },
    { 
      "word": "kock", 
    "translation": "chef",
      "exampleSentence": "Example sentence using the word in ${language}",
      "translatedExampleSentence": "Translation of the example sentence in ${secondLanguage}"

     }
  ]
}`,
          },
        ],
        4000,
        true,
        user.uid
      );

      console.log('response:', response);

      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);

      const themeTranslation = data.themeTranslation || gridTheme;

      setTerms(
        data.terms.map(term => ({
          ...term,
          image: '/api/placeholder/100/100/000000',
        }))
      );
      setStoryTheme(data.theme);
      setThemeTranslation(themeTranslation);

      showSnackbar('Glosor i lådor har genererats.', 'success');
    } catch (error) {
      console.error('Error generating terms:', error);
    } finally {
      setIsGeneratingTerms(false);
    }
  };

  const handleSaveTerm = (index, newWord, newTranslation) => {
    const updatedTerms = [...terms];
    updatedTerms[index] = { ...updatedTerms[index], word: newWord, translation: newTranslation };
    setTerms(updatedTerms);
  };
  const handleEditTheme = () => {
    setIsEditingTheme(true);
  };

  const handleSaveTheme = (newStoryTheme, newThemeTranslation) => {
    setStoryTheme(newStoryTheme);
    setThemeTranslation(newThemeTranslation);
    setIsEditingTheme(false);
  };

  const TermBox = ({
    term,
    index,
    isGeneratingTermImages,
    generatedTermImages,
    themeColor,
    onSave,
    theme,
  }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [localWord, setLocalWord] = useState(term.word);
    const [localTranslation, setLocalTranslation] = useState(term.translation);
    const [isHovered, setIsHovered] = useState(false);

    const isLoading = isGeneratingTermImages && !generatedTermImages.has(index);

    const handleSave = () => {
      onSave(index, localWord, localTranslation);
      setIsEditing(false);
    };

    return (
      <Box
        sx={{
          padding: '10px',
          textAlign: 'center',
          border: `2px solid ${themeColor}`,
          borderRadius: '8px',
          backgroundColor: 'transparent',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'relative',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered && !isEditing && (
          <IconButton
            sx={{ position: 'absolute', top: 5, right: 5 }}
            onClick={() => setIsEditing(true)}
          >
            <EditIcon />
          </IconButton>
        )}
        {isEditing ? (
          <>
            <TextField
              value={localWord}
              onChange={e => setLocalWord(e.target.value)}
              variant='standard'
              sx={{
                '& .MuiInputBase-input': {
                  textAlign: 'center',
                  fontWeight: '600',
                },
              }}
            />
            <Box sx={{ position: 'relative', margin: '10px auto' }}>
              <Avatar src={term.image} alt={term.word} sx={{ width: 80, height: 80 }} />
              {isLoading && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '5px',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                  }}
                >
                  <OrbitProgress color='#8b5cf6' size='medium' text='' textColor='' />
                </Box>
              )}
            </Box>
            <TextField
              value={localTranslation}
              onChange={e => setLocalTranslation(e.target.value)}
              variant='standard'
              sx={{
                '& .MuiInputBase-input': {
                  textAlign: 'center',
                  fontWeight: '600',
                },
              }}
            />
            <Button
              startIcon={<SaveIcon />}
              variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
              color='primary'
              onClick={handleSave}
              sx={{ mt: 1 }}
            >
              Spara
            </Button>
          </>
        ) : (
          <>
            <Box sx={{ position: 'absolute', top: 10, left: 10, zIndex: 1 }}>
              <Voice content={term.word} fontSize={25} userId={user.uid} language={language} />
            </Box>
            <Box sx={{ position: 'absolute', bottom: 10, left: 10, zIndex: 1 }}>
              <Voice
                content={term.translation}
                fontSize={25}
                userId={user.uid}
                language={secondLanguage}
              />
            </Box>

            <Typography variant='subtitle1' sx={{ fontWeight: '600', fontSize: '18px' }}>
              {term.word}
            </Typography>
            <Box sx={{ position: 'relative', margin: '10px auto' }}>
              <Avatar
                src={term.image}
                alt={term.word}
                sx={{ width: 80, height: 80, bgcolor: '#f7f7f7' }}
              />
              {isLoading && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '5px',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                  }}
                >
                  <OrbitProgress color='#8b5cf6' size='small' text='' textColor='' />
                </Box>
              )}
            </Box>
            <Typography variant='subtitle1' sx={{ fontWeight: '600', fontSize: '18px' }}>
              {term.translation}
            </Typography>
          </>
        )}
      </Box>
    );
  };

  const ThemeBox = () => {
    const [isHovered, setIsHovered] = useState(false);
    const [localStoryTheme, setLocalStoryTheme] = useState(storyTheme);
    const [localThemeTranslation, setLocalThemeTranslation] = useState(themeTranslation);

    return (
      <Box
        sx={{
          padding: '20px',
          border: `2px solid ${themeColor}`,
          borderRadius: '8px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.palette.mode === 'dark' ? '#15202b' : '#f9f9f9',
          position: 'relative',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered && !isEditingTheme && (
          <IconButton sx={{ position: 'absolute', top: 5, right: 5 }} onClick={handleEditTheme}>
            <EditIcon />
          </IconButton>
        )}
        {isEditingTheme ? (
          <>
            <TextField
              value={localStoryTheme}
              onChange={e => setLocalStoryTheme(e.target.value)}
              variant='standard'
              sx={{
                '& .MuiInputBase-input': {
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: '24px',
                  color: themeColor,
                },
              }}
            />
            <Box sx={{ position: 'relative', margin: '10px 0' }}>
              <Avatar src={themeImage} alt={localStoryTheme} sx={{ width: 60, height: 60 }} />
              {isGeneratingThemeImage && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '5px',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                    borderRadius: '50%',
                  }}
                >
                  <OrbitProgress color='#8b5cf6' size='medium' text='' textColor='' />
                </Box>
              )}
            </Box>
            <TextField
              value={localThemeTranslation}
              onChange={e => setLocalThemeTranslation(e.target.value)}
              variant='standard'
              sx={{
                '& .MuiInputBase-input': {
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  color: themeColor,
                },
              }}
            />
            <Button
              startIcon={<SaveIcon />}
              variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
              color='primary'
              onClick={() => handleSaveTheme(localStoryTheme, localThemeTranslation)}
              sx={{ mt: 1 }}
            >
              Spara
            </Button>
          </>
        ) : (
          <>
            <Box sx={{ position: 'absolute', top: 10, left: 10, zIndex: 1 }}>
              <Voice content={storyTheme} fontSize={25} userId={user.uid} language={language} />
            </Box>
            <Box sx={{ position: 'absolute', bottom: 10, left: 10, zIndex: 1 }}>
              <Voice
                content={themeTranslation}
                fontSize={25}
                userId={user.uid}
                language={secondLanguage}
              />
            </Box>
            <Typography variant='h5' fontWeight='bold' sx={{ color: `${themeColor}` }}>
              {storyTheme}
            </Typography>
            <Box sx={{ position: 'relative', margin: '10px 0' }}>
              <Avatar
                src={themeImage}
                alt={storyTheme}
                sx={{ width: 80, height: 80, bgcolor: '#f1f1f1' }}
              />
              {isGeneratingThemeImage && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '5px',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                    borderRadius: '50%',
                  }}
                >
                  <OrbitProgress color='#8b5cf6' size='small' text='' textColor='' />
                </Box>
              )}
            </Box>
            <Typography variant='h6' fontWeight='bold' sx={{ color: `${themeColor}` }}>
              {themeTranslation}
            </Typography>
          </>
        )}
      </Box>
    );
  };

  const ExampleSentences = () => {
    return (
      <Box sx={{ mt: 12, width: '100%' }}>
        <Typography
          variant='h6'
          sx={{ mb: 3, fontWeight: 'bold', textAlign: 'center', fontSize: '25px' }}
        >
          Exempelmeningar
        </Typography>
        <Grid container spacing={3}>
          {terms.map((term, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Box
                sx={{
                  ...styles.freshBox,
                  padding: '25px',
                  position: 'relative',
                  transition: 'transform 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                  },
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    right: '45px',
                    bottom: '-15px',
                    transform: 'translateY(-50%)',
                  }}
                >
                  <Voice
                    content={term.exampleSentence}
                    fontSize={24}
                    userId={user.uid}
                    language={language}
                  />
                </Box>

                <Box
                  sx={{
                    position: 'absolute',
                    right: '5px',
                    bottom: '-15px',
                    transform: 'translateY(-50%)',
                  }}
                >
                  <Voice
                    content={term.translatedExampleSentence}
                    fontSize={24}
                    userId={user.uid}
                    language={secondLanguage}
                  />
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Avatar
                    src={term.image}
                    alt={term.word}
                    sx={{
                      width: 40,
                      height: 40,
                      mr: 2,
                      bgcolor: '#f1f1f1',
                    }}
                  />
                  <Typography
                    variant='h6'
                    sx={{
                      fontWeight: 'bold',
                      color: themeColor,
                    }}
                  >
                    {term.word} / {term.translation}
                  </Typography>
                </Box>
                <Box sx={{ position: 'relative', mb: 2 }}>
                  <Typography variant='body1' sx={{ fontStyle: 'italic', mb: 1 }}>
                    {term.exampleSentence}
                  </Typography>
                  <Typography variant='body1' sx={{ fontStyle: 'italic' }}>
                    {term.translatedExampleSentence}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  return (
    <>
      <Box sx={styles.container}>
        <Box
          sx={{
            ...styles.innerContainer,
            width: { xs: '100%', md: '87%' },
            margin: '0 auto',
          }}
        >
          <Typography
            variant='h1'
            gutterBottom
            sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
          >
            Glosor i lådor
          </Typography>

          <InfoBox
            icon={WindowIcon}
            title='Powerpoint Instructions'
            instructions={[
              'Generera glosor i lådor samt exempelmeningar för varje ord',
              'Spela upp ljud för att höra uttalet',
              'Lägg till AI-genererade bilder',
              'Skriv ut lektionen',
            ]}
            theme={theme}
            styles={styles}
          />

          <Box sx={{ ...styles.freshBox, padding: '30px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box>
                  <ThemeSelector
                    value={gridTheme}
                    onChange={e => setGridTheme(e.target.value)}
                    theme={theme}
                    customTheme={customTheme}
                    onCustomThemeChange={e => setCustomTheme(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                  Årskurs:
                </Typography>
                <Box sx={{ mt: 1, mb: 3 }}>
                  <GradeSelector grade={grade} setGrade={setGrade} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500' }}>
                  Språk:
                </Typography>

                <Box sx={{ mt: 1, mb: 3 }}>
                  <LanguageSelector language={language} setLanguage={setLanguage} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500' }}>
                  Andra språk:
                </Typography>
                <Box sx={{ mt: 1, mb: 3 }}>
                  <LanguageSelector language={secondLanguage} setLanguage={setSecondLanguage} />
                </Box>
              </Grid>{' '}
            </Grid>

            <Box>
              <Button
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                color='primary'
                onClick={handleGenerateTerms}
                disabled={isGeneratingTerms}
                sx={{ marginTop: '20px' }}
                startIcon={
                  !isGeneratingTerms ? (
                    <GenerateIcon style={{ color: '#fff' }} />
                  ) : (
                    <CircularProgress size={16} />
                  )
                }
              >
                {isGeneratingTerms ? 'Genererar...' : 'Generera lådor'}
              </Button>
            </Box>

            {terms.length > 0 && (
              <>
                <Divider sx={{ mt: 5, mb: 3 }} />

                <Button
                  variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                  color='primary'
                  onClick={() => handleImageButtonClick('facts')}
                  disabled={isGeneratingTermImages}
                  sx={{ marginTop: '15px' }}
                  startIcon={
                    !isGeneratingTermImages ? (
                      <ImageIcon style={{ color: '#fff' }} />
                    ) : (
                      <CircularProgress size={16} />
                    )
                  }
                >
                  {isGeneratingTermImages ? 'Genererar...' : 'Generera termbilder'}
                </Button>

                <Button
                  variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                  color='primary'
                  onClick={() => handleImageButtonClick('topic')}
                  disabled={isGeneratingThemeImage}
                  sx={{ marginTop: '15px', marginLeft: '10px' }}
                  startIcon={
                    !isGeneratingThemeImage ? (
                      <ImageIcon style={{ color: '#fff' }} />
                    ) : (
                      <CircularProgress size={16} />
                    )
                  }
                >
                  {isGeneratingThemeImage ? 'Genererar...' : 'Generera temabild'}
                </Button>

                <ImageGenerationModal
                  open={showImageModal}
                  onClose={handleCloseModal}
                  onGenerate={handleGenerateWithInstructions}
                  isGenerating={
                    imageGenerationType === 'topic'
                      ? isGeneratingThemeImage
                      : isGeneratingTermImages
                  }
                  instructions={imageInstructions}
                  setInstructions={setImageInstructions}
                  generationType={imageGenerationType}
                  theme={theme}
                />

                <Button
                  variant='contained'
                  color='primary'
                  onClick={handlePrintClick}
                  sx={{ marginTop: '15px', marginLeft: '10px' }}
                  startIcon={<PrintIcon />}
                >
                  Skriv ut
                </Button>

                <Box sx={{ mt: 3 }}>
                  <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500' }}>
                    Färgtema:
                  </Typography>

                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 3 }}>
                    <div
                      style={{ display: 'inline-block', position: 'relative', marginRight: '10px' }}
                    >
                      <div
                        style={{
                          width: '60px',
                          height: '20px',
                          borderRadius: '7px',
                          backgroundColor: themeColor,
                          cursor: 'pointer',
                        }}
                      />
                      <input
                        type='color'
                        value={themeColor}
                        onChange={e => setThemeColor(e.target.value)}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          opacity: 0,
                          cursor: 'pointer',
                        }}
                      />
                    </div>

                    <Typography variant='body2'>{themeColor}</Typography>
                  </Box>
                </Box>
                <Grid container spacing={2} sx={{ marginTop: '40px' }}>
                  {terms.slice(0, 4).map((term, index) => (
                    <Grid item xs={3} key={index}>
                      <TermBox
                        term={term}
                        index={index}
                        isGeneratingTermImages={isGeneratingTermImages}
                        generatedTermImages={generatedTermImages}
                        themeColor={themeColor}
                        onSave={handleSaveTerm}
                        theme={theme}
                      />
                    </Grid>
                  ))}

                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          {terms[4] && (
                            <TermBox
                              term={terms[4]}
                              index={4}
                              isGeneratingTermImages={isGeneratingTermImages}
                              generatedTermImages={generatedTermImages}
                              themeColor={themeColor}
                              onSave={handleSaveTerm}
                              theme={theme}
                            />
                          )}
                        </Grid>

                        <Grid item xs={6}>
                          <ThemeBox />
                        </Grid>

                        <Grid item xs={3}>
                          {terms[5] && (
                            <TermBox
                              term={terms[5]}
                              index={5}
                              isGeneratingTermImages={isGeneratingTermImages}
                              generatedTermImages={generatedTermImages}
                              themeColor={themeColor}
                              onSave={handleSaveTerm}
                              theme={theme}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  {terms.slice(6).map((term, index) => (
                    <Grid item xs={3} key={index + 6}>
                      <TermBox
                        term={term}
                        index={index + 6}
                        isGeneratingTermImages={isGeneratingTermImages}
                        generatedTermImages={generatedTermImages}
                        themeColor={themeColor}
                        onSave={handleSaveTerm}
                        theme={theme}
                      />
                    </Grid>
                  ))}
                </Grid>

                <ExampleSentences />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TermsGrid;
