import React, { useState, useEffect, useRef } from 'react';
import { IconButton, Tooltip, CircularProgress } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { callOpenAITTS } from './api';
import Logger from './Logger';
import ReactCountryFlag from 'react-country-flag';

const languageToFlag = {
  amhariska: 'et', // Ethiopia
  arabiska: 'sa', // Saudi Arabia
  bengali: 'bd', // Bangladesh
  'brasiliansk-portugisiska': 'br', // Brazil
  dari: 'af', // Afghanistan
  danska: 'dk', // Denmark
  engelska: 'gb', // United Kingdom
  farsi: 'ir', // Iran
  finska: 'fi', // Finland
  franska: 'fr', // France
  grekiska: 'gr', // Greece
  hebreiska: 'il', // Israel
  hindi: 'in', // India
  indonesiska: 'id', // Indonesia
  italienska: 'it', // Italy
  japanska: 'jp', // Japan
  kinesiska: 'cn', // China
  koreanska: 'kr', // South Korea
  nederländska: 'nl', // Netherlands
  norska: 'no', // Norway
  polska: 'pl', // Poland
  portugisiska: 'pt', // Portugal
  punjabi: 'in', // India (using India's flag as Punjab is a region)
  rumänska: 'ro', // Romania
  ryska: 'ru', // Russia
  somali: 'so', // Somalia
  spanska: 'es', // Spain
  svenska: 'se', // Sweden
  swahili: 'tz', // Tanzania (primary Swahili-speaking country)
  tagalog: 'ph', // Philippines
  tamil: 'in', // India (though also spoken in Sri Lanka)
  telugu: 'in', // India
  thai: 'th', // Thailand
  tjeckiska: 'cz', // Czech Republic
  turkiska: 'tr', // Turkey
  tyska: 'de', // Germany
  ungerska: 'hu', // Hungary
  urdu: 'pk', // Pakistan
  vietnamesiska: 'vn', // Vietnam
};

const Voice = ({
  content,
  voice = 'alloy',
  fontSize,
  userId = null,
  language = null,
  tooltipPlacement = 'bottom',
  showVolumeIcon = false,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const audioRef = useRef(new Audio());
  const abortControllerRef = useRef(null);

  const handleVoice = async () => {
    Logger.log('lang:', language);
    Logger.log('userid voice: ', userId);
    Logger.log('content: ', content);
    if (isPlaying) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        abortControllerRef.current = null;
      }
    } else {
      if (!audioBlob) {
        setIsLoading(true);
        try {
          abortControllerRef.current = new AbortController();
          const blob = await callOpenAITTS(
            content,
            voice,
            abortControllerRef.current.signal,
            userId
          );
          setAudioBlob(blob);
          audioRef.current.src = URL.createObjectURL(blob);
        } catch (error) {
          if (error.name === 'AbortError') {
            Logger.log('TTS request was aborted');
          } else {
            console.error('Error generating speech:', error);
          }
          setIsLoading(false);
          return;
        }
        setIsLoading(false);
      }
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    const audio = audioRef.current;
    audio.onended = () => setIsPlaying(false);
    return () => {
      audio.onended = null;
      URL.revokeObjectURL(audio.src);
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const iconSize = fontSize || 24;
  const progressSize = iconSize / 0.8;
  const buttonStyle = { width: `${iconSize * 1.5}px`, height: `${iconSize * 1.5}px` };

  const renderFlag = countryCode => {
    return (
      <ReactCountryFlag
        countryCode={countryCode.toUpperCase()}
        className='emojiFlag'
        style={{
          width: `${iconSize}px`,
          height: 'auto',
        }}
      />
    );
  };

  return (
    <Tooltip title={isPlaying ? 'Stoppa' : 'Läs upp'} placement={tooltipPlacement}>
      <IconButton
        onClick={handleVoice}
        size='small'
        style={buttonStyle}
        sx={
          {
            // Moonshine: ta bort kommentarerna:
            // '&.MuiIconButton-root': {
            //   color: language ? 'white' : 'inherit',
            //   '& .emojiFlag': {
            //     color: language ? 'white' : 'inherit',
            //   },
            //   '& .MuiTouchRipple-root': {
            //     color: language ? 'white' : 'inherit',
            //   },
            // },
          }
        }
      >
        {isLoading ? (
          <CircularProgress size={progressSize} />
        ) : !showVolumeIcon && language && languageToFlag[language] ? (
          renderFlag(languageToFlag[language])
        ) : isPlaying ? (
          <VolumeOffIcon sx={{ fontSize: iconSize }} />
        ) : (
          <VolumeUpIcon sx={{ fontSize: iconSize }} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default Voice;
