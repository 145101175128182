export const handlePrintIdioms = (
  lessonData,
  theme,
  titleText = 'Idiomatiska uttryck',
  subtitleText = ''
) => {
  const printWindow = window.open('', '_blank');
  const themeColor = theme?.palette?.primary?.main || '#8b5cf6';

  const styles = `
    <style>
      @media print {
        body { 
          margin: 0; 
          padding: 20px; 
          font-family: Arial, sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        .title {
          color: ${themeColor};
          font-size: 2.6em;
          text-align: center;
          font-weight: bold;
          margin-bottom: 1.2rem;
          margin-top: 1.5rem;
        }
        .subtitle {
          text-align: center;
          font-size: 1.2em;
          margin-bottom: 2rem;
          color: #666;
        }
        .idiom-box {
          display: flex;
          align-items: flex-start;
          margin-bottom: 2rem;
          background-color: ${theme?.palette?.mode === 'dark' ? '#1e1e1e' : '#f5f5f5'};
          padding: 20px;
          border-radius: 8px;
          page-break-inside: avoid;
        }
        .image-container {
          flex: 0 0 200px;
          margin-right: 1.5rem;
        }
        .idiom-image {
          width: 200px;
          height: 200px;
          border-radius: 8px;
          object-fit: cover;
          background-color: #f2f2f2;
        }
        .idiom-content {
          flex: 1;
        }
        .idiom-text {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 0.5rem;
        }
        .meaning {
          font-style: italic;
          margin-bottom: 0.5rem;
          color: ${theme?.palette?.mode === 'dark' ? '#aaa' : '#666'};
        }
        .example-sentence {
          line-height: 1.5;
        }
        .story-container {
          margin-top: 4rem;
          margin-bottom: 2rem;
          padding: 30px;
          background-color: ${theme?.palette?.mode === 'dark' ? '#1e1e1e' : '#f5f5f5'};
          border-radius: 8px;
        }
        .story-title {
          font-size: 1.5em;
          margin-bottom: 1.5rem;
          font-weight: 600;
        }
        .story-content {
          line-height: 1.8;
        }
        @page { 
          size: A4; 
          margin: 2cm;
        }
      }
    </style>
  `;

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>${titleText}</title>
        ${styles}
      </head>
      <body>
        <div class="container">
          <h1 class="title">${titleText}</h1>


          ${lessonData.idioms
            .map(
              idiom => `
            <div class="idiom-box">
              <div class="image-container">
                <img 
                  src="${idiom.imageUrl || '/api/placeholder/200/200'}" 
                  alt="${idiom.idiom}"
                  class="idiom-image"
                >
              </div>
              <div class="idiom-content">
                <div class="idiom-text">${idiom.idiom}</div>
                <div class="meaning">${idiom.meaning}</div>
                <div class="example-sentence">${idiom.exampleSentence}</div>
              </div>
            </div>
          `
            )
            .join('')}

          <div class="story-container">
            <div class="story-title">Berättelse med idiomatiska uttryck</div>
            <div class="story-content">${lessonData.story}</div>
          </div>
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrintWeeklyWords = (
  lessonData,
  theme,
  titleText = 'Veckans ord',
  subtitleText = ''
) => {
  const printWindow = window.open('', '_blank');
  const themeColor = theme?.palette?.primary?.main || '#8b5cf6';

  const styles = `
    <style>
      @media print {
        body { 
          margin: 0; 
          padding: 20px; 
          font-family: Arial, sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        .title {
          color: ${themeColor};
          font-size: 2.6em;
          text-align: center;
          font-weight: bold;
          margin-bottom: 1.2rem;
          margin-top: 1.5rem;
        }
        .subtitle {
          text-align: center;
          font-size: 1.2em;
          margin-bottom: 2rem;
          color: #666;
        }
        .word-box {
          display: flex;
          align-items: flex-start;
          margin-bottom: 2rem;
          background-color: ${theme?.palette?.mode === 'dark' ? '#1e1e1e' : '#f5f5f5'};
          padding: 20px;
          border-radius: 8px;
          page-break-inside: avoid;
        }
        .image-container {
          flex: 0 0 200px;
          margin-right: 1.5rem;
        }
        .word-image {
          width: 200px;
          height: 200px;
          border-radius: 8px;
          object-fit: cover;
          background-color: #f2f2f2;
        }
        .word-content {
          flex: 1;
        }
        .word-text {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 0.5rem;
        }
        .pattern {
          font-style: italic;
          margin-bottom: 0.5rem;
          color: ${theme?.palette?.mode === 'dark' ? '#aaa' : '#666'};
        }
        .example-sentence {
          line-height: 1.5;
        }
        .story-container {
          margin-top: 4rem;
          margin-bottom: 2rem;
          padding: 30px;
          background-color: ${theme?.palette?.mode === 'dark' ? '#1e1e1e' : '#f5f5f5'};
          border-radius: 8px;
        }
        .story-title {
          font-size: 1.5em;
          margin-bottom: 1.5rem;
          font-weight: 600;
        }
        .story-content {
          line-height: 1.8;
        }
        @page { 
          size: A4; 
          margin: 2cm;
        }
      }
    </style>
  `;

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>${titleText}</title>
        ${styles}
      </head>
      <body>
        <div class="container">
          <h1 class="title">${titleText}</h1>
          <div class="subtitle">${subtitleText}</div>

          ${lessonData.words
            .map(
              word => `
            <div class="word-box">
              <div class="image-container">
                <img 
                  src="${word.imageUrl || '/api/placeholder/200/200'}" 
                  alt="${word.word}"
                  class="word-image"
                >
              </div>
              <div class="word-content">
                <div class="word-text">${word.word}</div>

                <div class="example-sentence">${word.exampleSentence}</div>
              </div>
            </div>
          `
            )
            .join('')}

          <div class="story-container">
            <div class="story-title">Berättelse med veckans ord</div>
            <div class="story-content">${lessonData.story}</div>
          </div>
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrintConversationLesson = (lessonData, titleText, theme) => {
  const printWindow = window.open('', '_blank');
  const themeColor = theme?.palette?.primary?.main || '#8b5cf6';

  const styles = `
    <style>
      @media print {
        body { 
          margin: 0; 
          padding: 20px; 
          font-family: Arial, sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        .title {
          color: ${themeColor};
          font-size: 2.6em;
          text-align: center;
          font-weight: bold;
          margin-bottom: 3rem;
          margin-top: 2rem;
        }
        .topic-image {
          width: 350px;
          height: 350px;
          border-radius: 10px;
          margin: 0 auto;
          display: block;
          margin-bottom: 3rem;
          object-fit: cover;
          background-color: #f2f2f2;
        }
        .participants-container {
          display: flex;
          justify-content: center;
          gap: 3rem;
          margin-bottom: 2rem;
          flex-wrap: wrap;
        }
        .participant {
          text-align: center;
        }
        .participant-image {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          margin-bottom: 0.5rem;
          object-fit: cover;
          background-color: #f2f2f2;
        }
        .participant-name {
          font-weight: 600;
          font-size: 1rem;
          margin-bottom: 0.25rem;
        }
        .participant-role {
          font-weight: 400;
          font-size: 0.85rem;
          color: #666;
        }
        .conversation {
          margin-top: 3.5rem;
        }
        .message {
          display: flex;
          align-items: flex-start;
          gap: 1rem;
          margin-bottom: 2rem;
        }
        .message-avatar {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
          background-color: #f2f2f2;
        }
        .message-content {
          flex: 1;
        }
        .message-speaker {
          font-weight: 600;
          font-size: 1rem;
          margin-bottom: 0.5rem;
        }
        .message-text {
          background-color: #e0e0e029;
          -webkit-print-color-adjust: exact !important;
          print-color-adjust: exact !important;
          border-radius: 10px;
          padding: 15px;
          margin-top: 0.5rem;
        }
        .message-text.empty {
          min-height: 60px;
        }
        .message-translation-container {
          margin-top: 0.5rem;
          padding-top: 0.5rem;
          border-top: 1px solid rgba(128, 128, 128, 0.2);
        }
        .message-translation {
          font-style: italic;
          min-height: ${theme?.palette?.mode === 'dark' ? '40px' : '30px'};
        }
        .message-translation.empty {
          min-height: 60px;
          border: 1px dashed rgba(128, 128, 128, 0.3);
          border-radius: 5px;
          margin: 5px 0;
        }
        @page { 
          size: A4; 
          margin: 2cm;
        }
      }
    </style>
  `;

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>${titleText}</title>
        ${styles}
      </head>
      <body>
        <div class="container">
          <h1 class="title">${titleText}</h1>

          ${
            lessonData.topicImage
              ? `<img src="${lessonData.topicImage}" alt="Topic" class="topic-image">`
              : ''
          }

          <div class="participants-container">
            ${Object.values(lessonData.participants)
              .map(
                participant => `
              <div class="participant">
                <img 
                  src="${participant.image || '/api/placeholder/150/150'}" 
                  alt="${participant.name}"
                  class="participant-image"
                >
                <div class="participant-name">${participant.name}</div>
                <div class="participant-role">${participant.role}</div>
              </div>
            `
              )
              .join('')}
          </div>

          <div class="conversation">
            ${lessonData.conversation
              .map(message => {
                const participant = Object.values(lessonData.participants).find(
                  p => p.name === message.speaker
                );
                const messageIsEmpty = !message.message || message.message.trim() === '';
                const translationIsEmpty =
                  !message.translation || message.translation.trim() === '';

                return `
                <div class="message">
                  <img 
                    src="${participant?.image || '/api/placeholder/50/50'}" 
                    alt="${message.speaker}"
                    class="message-avatar"
                  >
                  <div class="message-content">
                    <div class="message-speaker">${message.speaker}</div>
                    <div class="message-text ${messageIsEmpty ? 'empty' : ''}">
                      ${message.message || ''}
                      ${
                        'translation' in message
                          ? `
                          <div class="message-translation-container">
                            <div class="message-translation ${translationIsEmpty ? 'empty' : ''}">
                              ${message.translation || ''}
                            </div>
                          </div>
                        `
                          : ''
                      }
                    </div>
                  </div>
                </div>
              `;
              })
              .join('')}
          </div>
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};
export const handlePrintStory = (storyData, themeColor) => {
  const printWindow = window.open('', '_blank');
  const styles = `
    <style>
      @media print {
        body { 
          margin: 0; 
          padding: 20px; 
          font-family: Arial, sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        h1 {
          color: ${themeColor};
          font-size: 24px;
          text-align: center;
          margin-bottom: 30px;
        }
        .characters-section {
          margin: 20px 0;
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
        }
        .character-card {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        .character-image-container {
          width: 200px;
          height: 200px;
          background-color: #f0f0f0;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
        }
        .character-image {
          max-width: 100%;
          max-height: 100%;
        }
        .character-info {
          flex: 1;
        }
        .character-name {
          font-size: 28px;
          font-weight: bold;
          margin-bottom: 8px;
        }
        .main-story-image {
          margin: 20px 0;
          width: 100%;
          max-height: 400px;
          object-fit: contain;
        }
        .story-content {
          line-height: 1.6;
          margin: 20px 0;
        }
        .environment-images {
          margin: 20px 0;
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
        }
        .environment-card {
          width: 300px;
        }
        .environment-image-container {
          width: 100%;
          height: 200px;
          background-color: #f0f0f0;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          margin-bottom: 10px;
        }
        .environment-image {
          max-width: 100%;
          max-height: 100%;
        }
        .environment-description {
          font-style: italic;
          margin-top: 8px;
        }
        @page { 
          size: A4; 
          margin: 2cm;
        }
      }
    </style>
  `;

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>${storyData.title}</title>
        ${styles}
      </head>
      <body>
        <div class="container">
          <h1>${storyData.title}</h1>
          
          <div class="characters-section">
            ${storyData.characters
              .map(
                char => `
              <div class="character-card">
                <div class="character-image-container">
                  ${
                    char.imageUrl
                      ? `<img src="${char.imageUrl}" alt="${char.name}" class="character-image">`
                      : 'Bild hamnar här'
                  }
                </div>
                <div class="character-info">
                  <div class="character-name">${char.name}</div>
                  ${char.description}
                </div>
              </div>
            `
              )
              .join('')}
          </div>

          ${
            storyData.mainStoryImage
              ? `
            <div>
              <img src="${storyData.mainStoryImage}" alt="Huvudbild" class="main-story-image">
            </div>
          `
              : ''
          }

          <div class="story-content">
            ${storyData.storyContent
              .map(
                section => `
              <p>${section.content}</p>
            `
              )
              .join('')}
          </div>

          <div class="environment-images">
            ${storyData.environmentImages
              .map(
                env => `
              <div class="environment-card">
                <div class="environment-image-container">
                  ${
                    env.imageUrl
                      ? `<img src="${env.imageUrl}" alt="${env.description}" class="environment-image">`
                      : 'Miljöbild hamnar här'
                  }
                </div>
                <p class="environment-description">${env.description}</p>
              </div>
            `
              )
              .join('')}
          </div>
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrintQuizResults = (topic, questions) => {
  const printWindow = window.open('', '_blank');

  const styles = `
    <style>
      @media print {
        body { 
          margin: 0; 
          padding: 20px; 
          font-family: Arial, sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        h1 {
          color: #6424c1;
          font-size: 24px;
          text-align: center;
          margin-bottom: 30px;
        }
        .question {
          border: 1px solid #e0e0e0;
          border-radius: 8px;
          padding: 20px;
          margin-bottom: 20px;
          page-break-inside: avoid;
        }
        .question-text {
          font-weight: bold;
          margin-bottom: 15px;
        }
        .options {
          margin-left: 20px;
        }
        .option {
          margin-bottom: 8px;
        }
        .answer-key {
          page-break-before: always;
        }
        .answer-key h1 {
          margin-bottom: 40px;
        }
        .answer-item {
          margin-bottom: 15px;
          page-break-inside: avoid;
        }
        .student-info {
          margin-top: 30px;
          margin-bottom: 40px;
          border-top: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0;
          padding: 20px 0;
        }
        .info-line {
          display: flex;
          margin-bottom: 10px;
        }
        .info-label {
          width: 100px;
        }
        .info-value {
          flex: 1;
          border-bottom: 1px solid #999;
        }
        @page { 
          size: A4; 
          margin: 2cm;
        }
      }
    </style>
  `;

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>Quiz: ${topic}</title>
        ${styles}
      </head>
      <body>
        <div class="container">
          <h1>Quiz: ${topic}</h1>

          <div class="student-info">
            <div class="info-line">
              <div class="info-label">Namn:</div>
              <div class="info-value">&nbsp;</div>
            </div>
            <div class="info-line">
              <div class="info-label">Klass:</div>
              <div class="info-value">&nbsp;</div>
            </div>
            <div class="info-line">
              <div class="info-label">Datum:</div>
              <div class="info-value">&nbsp;</div>
            </div>
          </div>
          
          ${questions
            .map(
              (question, index) => `
            <div class="question">
              <div class="question-text">${index + 1}. ${question.question}</div>
              <div class="options">
                ${question.options
                  .map(
                    option => `
                  <div class="option">${option}</div>
                `
                  )
                  .join('')}
              </div>
            </div>
          `
            )
            .join('')}

          <!-- Answer key on a new page -->
          <div class="answer-key">
            <h1>Facit: ${topic}</h1>
            ${questions
              .map(
                (question, index) => `
              <div class="answer-item">
                <strong>Fråga ${index + 1}:</strong> ${question.correctAnswer}
              </div>
            `
              )
              .join('')}
          </div>
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrintPaSparet = (clueData, titleText, theme) => {
  const printWindow = window.open('', '_blank');
  const themeColor = theme?.palette?.primary?.main || '#8b5cf6';

  const styles = `
    <style>
      @media print {
        body { 
          margin: 0; 
          padding: 20px; 
          font-family: Arial, sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        .title {
          color: ${themeColor};
          font-size: 2.6em;
          text-align: center;
          font-weight: bold;
          margin-bottom: 3.0rem;
        }
        .answer {
          text-align: center;
          color: #666;
          font-size: 1.1em;
          margin-bottom: 3rem;
        }
        .clue-container {
          margin-bottom: 2.5rem;
          page-break-inside: avoid;
        }
        .difficulty {
          font-size: 2.0em;
          color: ${themeColor};
          font-weight: bold;
          margin-bottom: 1rem;
        }
        .clue-box {
          display: grid;
          grid-template-columns: 1fr 2fr;
          gap: 20px;
          background-color: #f5f5f5;
          padding: 20px;
          border-radius: 10px;
          min-height: 240px;
        }
        .clue-image {
          width: 100%;
          height: 240px;
          border-radius: 10px;
          object-fit: cover;
          background-color: #e0e0e0;
        }
        .clue-text {
          font-size: 1.1em;
          line-height: 1.7;
          display: flex;
          align-items: center;
        }
        @page { 
          size: A4; 
          margin: 2cm;
        }
      }
    </style>
  `;

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>${titleText}</title>
        ${styles}
      </head>
      <body>
        <div class="container">
          <h1 class="title">${titleText}</h1>

          
          ${Object.entries(clueData.clues)
            .map(
              ([_, clue]) => `
                <div class="clue-container">
                  <div class="difficulty">${clue.difficulty}</div>
                  <div class="clue-box">
                    <img 
                      class="clue-image" 
                      src="${clue.image || '/api/placeholder/600/300'}" 
                      alt="Ledtråd bild"
                    />
                    <div class="clue-text">${clue.text}</div>
                  </div>
                </div>
              `
            )
            .join('')}
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrintQuiz = (quiz, quizName, questionType, themeColor) => {
  const printWindow = window.open('', '_blank');
  const styles = `
    <style>
      @media print {
        body { 
          margin: 0; 
          padding: 20px; 
          font-family: Arial, sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        .header {
          margin-bottom: 40px;
          text-align: center;
        }
        h1 {
          color: ${themeColor};
          font-size: 24px;
          margin-bottom: 10px;
        }
        .quiz-info {
          color: #666;
          font-size: 14px;
        }
        .question-box {
          border: 1px solid #e0e0e0;
          border-radius: 8px;
          padding: 20px;
          margin-bottom: 20px;
          page-break-inside: avoid;
        }
        .question-header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          color: #666;
          font-size: 14px;
        }
        .question-text {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 16px;
        }
        .options-list {
          list-style-type: none;
          padding: 0;
          margin: 0;
        }
        .option-item {
          display: flex;
          align-items: center;
          padding: 8px 12px;
          margin-bottom: 8px;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
        }
        .option-bullet {
          width: 20px;
          height: 20px;
          border: 2px solid #666;
          border-radius: 50%;
          margin-right: 12px;
        }
        .answer-key {
          page-break-before: always;  /* Forces answer key to start on new page */
          margin-top: 40px;
          padding-top: 20px;
          border-top: 2px solid #e0e0e0;
        }
        .answer-key h2 {
          color: ${themeColor};
          font-size: 20px;
          margin-bottom: 16px;
        }
        .answer-item {
          margin-bottom: 8px;
        }
        @page { 
          size: A4; 
          margin: 2cm;
        }
      }
    </style>
  `;

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>${quizName}</title>
        ${styles}
      </head>
      <body>
        <div class="container">
          <div class="header">
            <h1>${quizName}</h1>
            <div class="quiz-info">
              ${quiz.length} frågor • ${
                questionType === 'multiple-choice'
                  ? 'Flervalsfrågor'
                  : questionType === 'fill-in-the-blank'
                    ? 'Lucktexter'
                    : questionType === 'true-or-false'
                      ? 'Sant/Falskt-frågor'
                      : 'Flervalsfrågor'
              }
            </div>
          </div>

          ${quiz
            .map(
              (question, index) => `
            <div class="question-box">
              <div class="question-header">
                <span>Fråga ${index + 1}</span>
                
              </div>
              <div class="question-text">${question.question}</div>
              <ul class="options-list">
                ${question.options
                  .map(
                    option => `
                  <li class="option-item">
                    <div class="option-bullet"></div>
                    ${option}
                  </li>
                `
                  )
                  .join('')}
              </ul>
            </div>
          `
            )
            .join('')}

          <div class="answer-key">
            <h2>Facit - ${quizName}</h2>
            ${quiz
              .map(
                (question, index) => `
              <div class="answer-item">
                <strong>Fråga ${index + 1}:</strong> ${question.correct}
              </div>
            `
              )
              .join('')}
          </div>
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handleMysticImageWritingLesson = (words, lineCount, lineHeight, themeColor) => {
  const PRINT_SCALE_FACTOR = 0.4;
  const printLineHeight = Math.round(lineHeight * PRINT_SCALE_FACTOR);

  const printWindow = window.open('', '_blank');
  const styles = `
    <style>
      @media print {
        body { 
          margin: 0; 
          padding: 20px; 
          font-family: Arial, sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        h1 {
          color: ${themeColor};
          text-align: center;
          font-size: 24px;
          margin-bottom: 30px;
          font-weight: bold;
        }
        .writing-box {
          border: 5px solid ${themeColor};
          border-radius: 13px;
          padding: 24px;
          margin-bottom: 24px;
          page-break-inside: avoid;
          background-color: #f9f9f94f;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .image-container {
          width: 200px;
          height: 200px;
          margin-bottom: 24px;
        }
        .word-image {
          width: 100%;
          height: 100%;
          border-radius: 13px;
          object-fit: cover;
          background-color: #f2f2f2;
        }
        .lines-container {
          width: 100%;
          padding-top: ${printLineHeight}px;
        }
        .writing-line {
          position: relative;
          border-bottom: 2px solid black;
          height: ${printLineHeight}px;
          margin-bottom: ${printLineHeight}px;
        }
        .writing-line::after {
          content: '';
          position: absolute;
          top: -10px;
          left: 0;
          right: 0;
          height: 1px;
          background-color: rgba(0,0,0,0.2);
        }
        @page { 
          size: A4; 
          margin: 1.5cm;
        }
      }
    </style>
  `;

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>Skriv om den mystiska bilden</title>
        ${styles}
      </head>
      <body>
        <div class="container">
          <h1>Skriv om den mystiska bilden</h1>
          
          ${words
            .map(
              word => `
            <div class="writing-box">
              <div class="image-container">
                <img src="${word.image}" alt="${word.word}" class="word-image">
              </div>
              
              <div class="lines-container">
                ${Array(lineCount)
                  .fill(0)
                  .map(() => '<div class="writing-line"></div>')
                  .join('')}
              </div>
            </div>
          `
            )
            .join('')}
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrintFindWordsLesson = (titleText, themeImage, wordGrid, words, themeColor) => {
  const printWindow = window.open('', '_blank');
  const gridSize = wordGrid.length;
  const styles = `
    <style>
      @media print {
        body {
          margin: 0;
          padding: 20px;
          font-family: 'Arial', sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
          text-align: center;
        }
        h1 {
          color: ${themeColor};
          font-size: 1.8em;
          margin-bottom: 30px;
          font-weight: bold;
        }
        .theme-image {
          width: 200px;
          height: 200px;
          object-fit: cover;
          border-radius: 16px;
          margin-bottom: 30px;
        }
        .word-grid {
          display: inline-grid;
          grid-template-columns: repeat(${gridSize}, 60px);
          gap: 8px;
          padding: 16px;
          background: #ffffff;
          border-radius: 12px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          margin-bottom: 40px;
        }
        .grid-cell {
          width: 60px;
          height: 60px;
          border: 2px solid #2c2c2c;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          font-size: 24px;
          font-weight: 600;
          background-color: white;
          color: #333;
          text-transform: uppercase;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
          transition: all 0.2s ease;
        }
        .word-list {
          margin-top: 40px;
        }
        .word-items {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
          gap: 16px;
          padding: 16px;
        }
        .word-item {
          display: flex;
          align-items: center;
          gap: 16px;
          border: 1px solid #e0e0e0;
          border-radius: 12px;
          padding: 12px;
          background-color: white;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
        }
        .word-item img {
          width: 80px;
          height: 80px;
          object-fit: cover;
          border-radius: 8px;
          background-color: #f7f7f7;
        }
        .word-item span {
          font-size: 1.2em;
          text-transform: capitalize;
          color: #333;
        }
        h2 {
          color: ${themeColor};
          font-size: 1.5em;
          margin: 20px 0;
          font-weight: bold;
        }
        @page {
          size: A4 portrait;
          margin: 2cm;
        }
      }
    </style>
  `;
  const puzzleContent = `
    <div class="container">
      <img src="${themeImage}" alt="Theme Image" class="theme-image">
      <h1>${titleText}</h1>
      <div class="word-grid">
        ${wordGrid
          .map(row => row.map(letter => `<div class="grid-cell">${letter}</div>`).join(''))
          .join('')}
      </div>
      <div class="word-list">
        <h2>Ordlista</h2>
        <div class="word-items">
          ${words
            .map(
              word => `
            <div class="word-item">
              <img src="${word.image}" alt="${word.word}">
              <span>${word.word}</span>
            </div>
          `
            )
            .join('')}
        </div>
      </div>
    </div>
  `;
  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>${titleText}</title>
        ${styles}
      </head>
      <body>
        ${puzzleContent}
      </body>
    </html>
  `;
  printWindow.document.write(content);
  printWindow.document.close();
  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrintWritingLesson = (words, lineCount, lineHeight, themeColor) => {
  // Convert screen pixels to print-appropriate measurements
  const PRINT_SCALE_FACTOR = 0.4;
  const printLineHeight = Math.round(lineHeight * PRINT_SCALE_FACTOR);

  const printWindow = window.open('', '_blank');
  const styles = `
    <style>
      @media print {
        body { 
          margin: 0; 
          padding: 20px; 
          font-family: Arial, sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        h1 {
          color: ${themeColor};
          text-align: center;
          font-size: 24px;
          margin-bottom: 30px;
          font-weight: bold;
        }
        .word-box {
          display: flex;
          align-items: flex-start;
          gap: 24px;
          border: 5px solid ${themeColor};
          border-radius: 13px;
          padding: 16px;
          margin-bottom: 16px;
          page-break-inside: avoid;
          background-color: #f9f9f94f;
          min-height: 200px;
        }
        .image-container {
          width: 200px;
          height: 200px;
          flex-shrink: 0;
        }
        .word-image {
          width: 100%;
          height: 100%;
          border-radius: 13px;
          object-fit: cover;
          background-color: #f2f2f2;
        }
        .lines-container {
          flex: 1;
          padding-top: ${printLineHeight}px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          min-height: 200px;
          box-sizing: border-box;
        }
        .writing-line {
          position: relative;
          border-bottom: 2px solid black;
          height: ${printLineHeight}px;
          margin-bottom: ${printLineHeight}px;
        }
        .writing-line::after {
          content: '';
          position: absolute;
          top: -10px;
          left: 0;
          right: 0;
          height: 1px;
          background-color: rgba(0,0,0,0.2);
        }
        @page { 
          size: A4; 
          margin: 1.5cm;
        }
      }
    </style>
  `;
  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>Skrivövningar</title>
        ${styles}
      </head>
      <body>
        <div class="container">
          <h1>Skrivövningar</h1>
          
          ${words
            .map(
              word => `
              <div class="word-box">
                <div class="image-container">
                  <img src="${word.image}" alt="${word.word}" class="word-image">
                </div>
                
                <div class="lines-container">
                  ${Array(lineCount)
                    .fill(0)
                    .map(() => '<div class="writing-line"></div>')
                    .join('')}
                </div>
              </div>
            `
            )
            .join('')}
        </div>
      </body>
    </html>
  `;
  printWindow.document.write(content);
  printWindow.document.close();
  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};
export const handlePrintLetterBox = (words, selectedLetters, themeColor) => {
  const printWindow = window.open('', '_blank');
  const styles = `
    <style>
      @media print {
        body { 
          margin: 0; 
          padding: 20px; 
          font-family: Arial, sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        .word-box {
          display: flex;
          align-items: center;
          gap: 24px;
          border: 5px solid ${themeColor};
          border-radius: 13px;
          padding: 16px;
          margin-bottom: 24px;
          page-break-inside: avoid;
          background-color: #f9f9f94f;
        }
        .image-container {
          width: 200px;
          height: 200px;
          flex-shrink: 0;
        }
        .word-image {
          width: 100%;
          height: 100%;
          border-radius: 13px;
          object-fit: cover;
          background-color: #f2f2f2;
        }
        .word-content {
          flex: 1;
        }
        .letters-grid {
          display: flex;
          flex-wrap: wrap;
          gap: 16px;
          margin-bottom: 16px;
        }
        .letter-circle {
          width: 44px;
          height: 44px;
          border-radius: 50%;
          border: 2px solid ${themeColor};
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          color: ${themeColor};
          background-color: transparent;
        }
        .letter-circle.selected {
          background-color: #8b5cf6;
          color: white;
        }
        .selected-letters {
          display: flex;
          align-items: center;
          gap: 8px;
          min-height: 45px;
        }
        .selected-letter {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          background-color: #8b5cf6;
          color: white;
          font-size: 17px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        h1 {
          color: ${themeColor};
          text-align: center;
          font-size: 24px;
          margin-bottom: 30px;
        }
        .word-list {
          text-align: center;
          font-weight: 500;
          font-size: 23px;
          margin-bottom: 32px;
        }
        @page { 
          size: A4; 
          margin: 2cm;
        }
      }
    </style>
  `;

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>Bokstavslådor</title>
        ${styles}
      </head>
      <body>
        <div class="container">
          <h1>Bokstavslådor</h1>
          


          ${words
            .map((word, wordIndex) => {
              const selected = selectedLetters[wordIndex] || [];
              return `
                <div class="word-box">
                  <div class="image-container">
                    <img src="${word.image}" alt="${word.word}" class="word-image">
                  </div>
                  
                  <div class="word-content">
                    <div class="letters-grid">
                      ${word.letters
                        .map((letter, letterIndex) => {
                          const isSelected = selected.find(item => item.index === letterIndex);
                          return `
                            <div class="letter-circle ${isSelected ? 'selected' : ''}">
                              ${letter}
                            </div>
                          `;
                        })
                        .join('')}
                    </div>
                    
                    <div class="selected-letters">
                      ${selected
                        .map(
                          item => `
                            <div class="selected-letter">
                              ${item.letter}
                            </div>
                          `
                        )
                        .join('')}
                    </div>
                  </div>
                </div>
              `;
            })
            .join('')}
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrintNarrativeLesson = (planData, goal, develop, additionalInfo, themeColor) => {
  const printWindow = window.open('', '_blank');
  const styles = `
    <style>
      @media print {
        body { 
          margin: 0; 
          padding: 20px; 
          font-family: Arial, sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        h1 {
          color: ${themeColor};
          font-size: 24px;
          margin-bottom: 20px;
        }
        h2 {
          color: ${themeColor};
          font-size: 20px;
          margin-top: 30px;
          margin-bottom: 10px;
        }
        .section {
          margin-bottom: 30px;
          page-break-inside: avoid;
        }
        .mindmap-container {
          position: relative;
          width: 520px;
          height: 520px;
          margin: 40px auto;
          page-break-inside: avoid;
        }
        .center-image-container {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
          width: 130px;
          height: 130px;
        }
        .center-image {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: #f2f2f2;
          object-fit: cover;
        }
        .character-circle {
          position: absolute;
          width: 180px;
          height: 180px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          z-index: 3;
          transform: translate(-50%, -50%);
        }
        .character-image-container {
          position: relative;
          width: 96px;
          height: 96px;
          margin-bottom: 8px;
        }
        .character-image {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: #f2f2f2;
          object-fit: cover;
        }
        .character-name {
          width: 240px;
          text-align: center;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 1.2;
          height: 2.4em;
          font-weight: bold;
          font-size: 16px;
          color: ${themeColor};
          margin-top: 8px;
        }
        .story-images {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;
          margin: 20px 0;
        }
        .story-image {
          width: 100%;
          height: 300px;
          object-fit: cover;
          border-radius: 8px;
        
        }
        .list {
          padding-left: 20px;
        }
        .list-item {
          margin-bottom: 10px;
          color: #333;
        }
        @page { 
          size: A4; 
          margin: 2cm; 
        }
      }
    </style>
  `;

  // Calculate positions for mind map
  const generateCharacterPositions = characters => {
    const radius = 200;
    return characters.map((char, index) => {
      const angle = (index / characters.length) * 2 * Math.PI - Math.PI / 2;
      const x = Math.cos(angle) * radius + 260; // Container width/2
      const y = Math.sin(angle) * radius + 260; // Container height/2
      return { character: char, x, y };
    });
  };

  const characterPositions = generateCharacterPositions(planData.whoOrWhich);

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>${planData.storyTitle}</title>
        ${styles}
      </head>
      <body>
        <div class="container">
          <h1>En berättande text: ${planData.storyTitle}</h1>
          
          <div class="section">
            <h2>Mål</h2>
            <p>${goal}</p>
          </div>
          
          <div class="section">
            <h2>Färdigheter att utveckla</h2>
            <p>${develop}</p>
          </div>
          
          ${
            additionalInfo
              ? `
            <div class="section">
              <h2>Ytterligare information</h2>
              <p>${additionalInfo}</p>
            </div>
          `
              : ''
          }
          
          <div class="section">
            <h2>Karaktärer (Vem eller vilka)</h2>
            <div class="mindmap-container">
              <div class="center-image-container">
                <img src="${planData.storyImage}" class="center-image" alt="Story theme">
              </div>
              ${characterPositions
                .map(
                  ({ character, x, y }) => `
                <div class="character-circle" style="left: ${x}px; top: ${y}px;">
                  <div class="character-image-container">
                    <img src="${character.imageUrl}" class="character-image" alt="${character.name}">
                  </div>
                  <div class="character-name">${character.name}</div>
                </div>
              `
                )
                .join('')}
            </div>
          </div>


          
          <div class="section">
            <h2>Miljöer (Var)</h2>
            <ul class="list">
              ${planData.where
                .map(
                  place => `
                <li class="list-item">${place}</li>
              `
                )
                .join('')}
            </ul>

          ${
            planData.additionalStoryImages && planData.additionalStoryImages.length > 0
              ? `
            <div class="section">
             
              <div class="story-images">
                ${planData.additionalStoryImages
                  .map(
                    imageUrl => `
                  <img src="${imageUrl}" class="story-image" alt="Story scene">
                `
                  )
                  .join('')}
              </div>
            </div>
          `
              : ''
          }

          </div>
          
          <div class="section">
            <h2>Handlingsidéer (Vad)</h2>
            <p>

I alla berättelser inträffar något oväntat och spännande. Utifrån vem/vilka och var får eleverna två och två några minuter för att komma på vad som kommer att hända. Variera uppgiften med att eleverna skriver meningar som de delar med sig av på talvan. Ge minst ett exempel själv.
            </p>
            <ul class="list">
              ${planData.what
                .map(
                  action => `
                <li class="list-item">${action}</li>
              `
                )
                .join('')}
            </ul>
          </div>
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrint = (terms, storyTheme, themeImage, themeTranslation, themeColor) => {
  const printWindow = window.open('', '_blank');
  const styles = `
    <style>
      @media print {
        body { 
          margin: 0; 
          padding: 20px; 
          font-family: Arial, sans-serif;
        }
        .print-grid { 
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 16px;
          page-break-inside: avoid;
          margin-bottom: 16px;
        }
        .term-box {
          border: 2px solid ${themeColor};
          border-radius: 8px;
          padding: 10px;
          text-align: center;
          height: 180px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
        }
        .middle-row {
          display: grid;
          grid-template-columns: 1fr 2fr 1fr;
          gap: 16px;
          margin: 16px 0;
          page-break-inside: avoid;
        }
        .theme-box {
          border: 2px solid ${themeColor};
          border-radius: 8px;
          padding: 20px;
          text-align: center;
          background-color: #f0f0f0;
          height: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
        }
        .word { 
          font-family: Arial, sans-serif;
          font-weight: bold; 
          font-size: 18px; 
          margin: 5px 0; 
        }
        .translation { 
          font-family: Arial, sans-serif;
          font-weight: bold; 
          font-size: 18px; 
          margin: 5px 0; 
        }
        .theme-title { 
          font-family: Arial, sans-serif;
          font-weight: bold; 
          font-size: 24px; 
          color: ${themeColor}; 
          margin: 0;
        }
        .theme-translation { 
          font-family: Arial, sans-serif;
          font-weight: bold; 
          font-size: 20px; 
          color: ${themeColor}; 
          margin: 0;
        }
        img { 
          width: 80px; 
          height: 80px; 
          border-radius: 50%; 
          object-fit: cover; 
        }
        .theme-img { 
          width: 60px; 
          height: 60px; 
        }
        h1 {
          font-family: Arial, sans-serif;
          text-align: center;
          margin-bottom: 30px;
          color: ${themeColor};
        }
        @page { 
          size: A4; 
          margin: 2cm; 
        }
      }
    </style>
  `;

  const generateTermBoxes = termsArray => {
    return termsArray
      .map(
        term => `
        <div class="term-box">
          <div class="word">${term.word}</div>
          <img src="${term.image}" alt="${term.word}">
          <div class="translation">${term.translation}</div>
        </div>
      `
      )
      .join('');
  };

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>Glosor i lådor</title>
        ${styles}
      </head>
      <body>
        <h1>Glosor i lådor</h1>
        
        <div class="print-grid">
          ${generateTermBoxes(terms.slice(0, 4))}
        </div>
        
        <div class="middle-row">
          ${
            terms[4]
              ? `
            <div class="term-box">
              <div class="word">${terms[4].word}</div>
              <img src="${terms[4].image}" alt="${terms[4].word}">
              <div class="translation">${terms[4].translation}</div>
            </div>
          `
              : '<div></div>'
          }
          
          <div class="theme-box">
            <div class="theme-title">${storyTheme}</div>
            <img class="theme-img" src="${themeImage}" alt="${storyTheme}">
            <div class="theme-translation">${themeTranslation}</div>
          </div>
          
          ${
            terms[5]
              ? `
            <div class="term-box">
              <div class="word">${terms[5].word}</div>
              <img src="${terms[5].image}" alt="${terms[5].word}">
              <div class="translation">${terms[5].translation}</div>
            </div>
          `
              : '<div></div>'
          }
        </div>
        
        <div class="print-grid">
          ${generateTermBoxes(terms.slice(6))}
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          // Close window after 500ms if printing dialog was closed
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};
