import React, { useEffect } from 'react';
import {
  Container,
  Typography,
  Grid,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
  Chip,
  Card,
  CardContent,
  Avatar,
  TextField,
  Button, // Add if not already imported
  Divider,
} from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TeacherStatsSection from './TeacherStatsSection';
import { StyledButton, GradientBackground } from './StyledComponents';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Helmet } from 'react-helmet-async';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Header from './Header';
import Footer from './Footer';
import TestimonialsSection from './TestimonialsSection';
import { motion } from 'framer-motion';

import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import UniversitySection from './UniversitySection';
import MediaSection from './MediaSection';

import StarIcon from '@mui/icons-material/Star';
import PeopleIcon from '@mui/icons-material/People';
import TimelineIcon from '@mui/icons-material/Timeline';

const PricesPage = ({ toggleDarkMode, isDark }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Testimonials Data
  const testimonials = [
    {
      name: 'Anna Svensson',
      title: 'Lärare i Grundskolan',
      photo: 'https://via.placeholder.com/80',
      feedback:
        'Studera.ai har revolutionerat mitt sätt att undervisa. Eleverna älskar mikrolektionerna!',
    },
    {
      name: 'Erik Johansson',
      title: 'Gymnasielärare',
      photo: 'https://via.placeholder.com/80',
      feedback:
        'Med AI-genererade Grej of the day sparar jag massor av tid och kan fokusera mer på elevernas behov.',
    },
  ];

  // Pricing Data
  const pricingPlans = [
    {
      title: 'Gratis',
      price: '0 kr',
      features: ['Skapa upp till 5 lektioner', 'Grundläggande funktioner', 'Community support'],
      buttonText: 'Kom igång gratis',
    },
    {
      title: 'Premium',
      price: '99 kr/mån',
      features: ['Obegränsat antal lektioner', 'Avancerade funktioner', 'Prioriterad support'],
      buttonText: 'Uppgradera nu',
    },
  ];

  // Team Data
  const teamMembers = [
    {
      name: 'Maria Karlsson',
      position: 'Grundare & VD',
      photo: 'https://via.placeholder.com/150',
    },
    {
      name: 'Johan Lindberg',
      position: 'CTO',
      photo: 'https://via.placeholder.com/150',
    },
    {
      name: 'Emma Nilsson',
      position: 'Produktchef',
      photo: 'https://via.placeholder.com/150',
    },
  ];

  return (
    <>
      <Helmet>
        <title>{`Priser | Studera.ai`}</title>
        <meta
          name='description'
          content='Lär dig mer kostnaderna för lärare, pedagoger och elever för att använda Studera.ai.'
        />
        <meta property='og:title' content='Priser | Studera.ai' />
        <meta
          property='og:description'
          content='Lär dig mer kostnaderna för lärare, pedagoger och elever för att använda Studera.ai.'
        />
        <meta
          property='og:image'
          content='https://via.placeholder.com/1200x630.png?text=Grej+of+the+Day'
        />
        <meta property='og:url' content='https://studera.ai/funktioner/grej-of-the-day' />
        <meta name='twitter:card' content='summary_large_image' />
      </Helmet>

      <Header />

      <GradientBackground className='gradient-background'>
        <Container
          sx={{ pt: { xs: 10, md: 20 }, pb: 15, position: 'relative', textAlign: 'center' }}
        >
          <Grid container spacing={6} alignItems='center'>
            <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                  <Chip
                    label={
                      <span>
                        Studera.ai{' '}
                        <span style={{ opacity: 0.7 }}>
                          &nbsp;•&nbsp; Revolutionerar lärande med AI
                        </span>
                      </span>
                    }
                    sx={{
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      color: 'white',
                      fontWeight: '600',
                      fontSize: '0.8rem',
                      padding: '2px 14px',
                      borderRadius: '16px',
                      '& .MuiChip-label': {
                        padding: '0 4px',
                      },
                    }}
                  />
                </Box>

                <Typography
                  variant='h2'
                  component='h1'
                  gutterBottom
                  sx={{
                    fontWeight: 900,
                    letterSpacing: -1,
                    fontSize: { xs: '2.5rem', md: '3.35rem' },
                  }}
                >
                  Priser för Studera.ai
                </Typography>
                <Typography
                  variant='h5'
                  sx={{
                    mb: 4,
                    opacity: 0.8,
                    fontSize: '1.3rem',
                    width: { xs: '100%', md: '64%' },
                    margin: '0 auto',
                    fontWeight: '500',
                    lineHeight: '31px',
                    display: 'block',
                    mt: 4,
                  }}
                >
                  Se de olika prismodeller vi erbjuder för lärare, pedagoger & elever individuellt.
                  Men även våra prismodeller för skolor, kommuner och koncerner.
                </Typography>

                <Box sx={{ textAlign: 'center', mt: 7 }}>
                  <Link to='/app' style={{ textDecoration: 'none' }}>
                    <StyledButton
                      variant='blue'
                      color='primary'
                      size='large'
                      endIcon={<KeyboardDoubleArrowRightIcon />}
                      sx={{ borderRadius: '28px', padding: '10px 40px', fontSize: '17px' }}
                    >
                      Testa Studera.ai helt gratis
                    </StyledButton>
                  </Link>
                </Box>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </GradientBackground>

      <Container sx={{ my: 15 }}>
        <Typography
          variant='h3'
          gutterBottom
          sx={{
            fontWeight: 'bold',
            fontSize: '2.3em',
            mb: 2,
            mt: 10,
            display: 'block',
            textAlign: 'center',
          }}
        >
          Välj prisplan
        </Typography>

        <Typography
          variant='body1'
          sx={{
            display: 'block',
            color: 'text.secondary',
            fontWeight: '400',
            fontSize: '1.15rem',
            lineHeight: '1.6',
            textAlign: 'center',
            width: '80%',
            margin: '0 auto',
            mb: 7,
          }}
        >
          Välj prisplan för för skola, kommun, eller lärare.
        </Typography>

        <Grid container spacing={4} justifyContent='center'>
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                height: '100%',
                background: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'white',
                backdropFilter: 'blur(10px)',
                borderRadius: '20px',
                border: '1px solid rgba(255, 255, 255, 0.1)',
                boxShadow: '0 20px 40px rgba(0,0,0,0.08)',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-10px)',
                  boxShadow: '0 20px 40px rgba(0,0,0,0.08)',
                },
              }}
            >
              <CardContent sx={{ p: 4 }}>
                <Box
                  sx={{
                    display: 'inline-block',
                    background: 'linear-gradient(45deg, #FFD700, #FFA500)',
                    borderRadius: '12px',
                    px: 2,
                    py: 0.5,
                    mb: 2,
                  }}
                >
                  <Typography variant='subtitle2' sx={{ color: 'white' }}>
                    BASIC
                  </Typography>
                </Box>
                <Typography variant='h3' sx={{ my: 2, fontWeight: '800' }}>
                  0 kr
                </Typography>
                <Typography variant='body2' sx={{ mb: 4, color: 'text.secondary' }}>
                  Testa vår tjänst helt gratis
                </Typography>
                <Box sx={{ mb: 4 }}>
                  {[
                    'Generera 10 lektioner',
                    'Generera 10 biler',
                    'Grundläggande lektionstyper',
                    'AI-verktyg för elever',
                    'Ingen registrering krävs',
                    'Personlig support',
                  ].map((feature, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          borderRadius: '50%',
                          backgroundColor: 'rgba(255, 215, 0, 0.1)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          mr: 2,
                        }}
                      >
                        <CheckIcon sx={{ fontSize: 14, color: '#FFD700' }} />
                      </Box>
                      <Typography variant='body2'>{feature}</Typography>
                    </Box>
                  ))}
                </Box>
                <Button
                  fullWidth
                  variant='yellow'
                  sx={{
                    py: 1.5,
                    borderRadius: '10px',
                  }}
                >
                  Testa helt gratis
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card
              sx={{
                height: '100%',
                background: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'white',
                backdropFilter: 'blur(10px)',
                borderRadius: '20px',
                border: '1px solid rgba(255, 255, 255, 0.1)',
                boxShadow: '0 20px 40px rgba(0,0,0,0.12)',
                transition: 'all 0.3s ease',
                transform: 'scale(1.05)',
                '&:hover': {
                  transform: 'scale(1.05) translateY(-10px)',
                  boxShadow: '0 20px 40px rgba(0,0,0,0.12)',
                },
              }}
            >
              <CardContent sx={{ p: 4 }}>
                <Box
                  sx={{
                    display: 'inline-block',
                    background: 'linear-gradient(45deg, #7B68EE, #9370DB)',
                    borderRadius: '12px',
                    px: 2,
                    py: 0.5,
                    mb: 2,
                  }}
                >
                  <Typography variant='subtitle2' sx={{ color: 'white' }}>
                    LÄRARE
                  </Typography>
                </Box>
                <Typography variant='h3' sx={{ my: 2, fontWeight: '800' }}>
                  299 kr
                  <Typography component='span' variant='body2' sx={{ ml: 1 }}>
                    / månad
                  </Typography>
                </Typography>
                <Typography variant='body2' sx={{ mb: 4, color: 'text.secondary' }}>
                  Mest populär bland lärare
                </Typography>
                <Box sx={{ mb: 4 }}>
                  {[
                    '100 AI-genererade lektioner per år',
                    'Generera bilder (50 st/mån)',
                    'Åtkomst till 10 lektionstyper',

                    'AI-verktyg för elever',
                    'Personlig support',
                  ].map((feature, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          borderRadius: '50%',
                          backgroundColor: 'rgba(123, 104, 238, 0.1)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          mr: 2,
                        }}
                      >
                        <CheckIcon sx={{ fontSize: 14, color: '#7B68EE' }} />
                      </Box>
                      <Typography variant='body2'>{feature}</Typography>
                    </Box>
                  ))}
                </Box>
                <Button
                  fullWidth
                  variant='blue'
                  sx={{
                    py: 1.5,
                    borderRadius: '10px',
                    background: 'linear-gradient(45deg, #7B68EE, #9370DB)',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #6A5ACD, #7B68EE)',
                    },
                  }}
                >
                  Uppgradera nu
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card
              sx={{
                height: '100%',
                background: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'white',
                backdropFilter: 'blur(10px)',
                borderRadius: '20px',
                border: '1px solid rgba(255, 255, 255, 0.1)',
                boxShadow: '0 20px 40px rgba(0,0,0,0.08)',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-10px)',
                  boxShadow: '0 20px 40px rgba(0,0,0,0.08)',
                },
              }}
            >
              <CardContent sx={{ p: 4 }}>
                <Box
                  sx={{
                    display: 'inline-block',
                    background: 'linear-gradient(45deg, #20B2AA, #48D1CC)',
                    borderRadius: '12px',
                    px: 2,
                    py: 0.5,
                    mb: 2,
                  }}
                >
                  <Typography variant='subtitle2' sx={{ color: 'white' }}>
                    SKOLA
                  </Typography>
                </Box>
                <Typography variant='h3' sx={{ my: 2, fontWeight: '800' }}>
                  999 kr
                  <Typography component='span' variant='body2' sx={{ ml: 1 }}>
                    / månad
                  </Typography>
                </Typography>
                <Typography variant='body2' sx={{ mb: 4, color: 'text.secondary' }}>
                  För hela skolan eller arbetslaget
                </Typography>
                <Box sx={{ mb: 4 }}>
                  {[
                    '30 konton',
                    '1000 AI-genererade lektioner per år',
                    'Åtkomst till samtliga lektionstyper',
                    'Generera bilder (500/mån)',
                    'Moderering för elevsäkerhet',
                    'Nya lektioner varje vecka',
                    'Utbildning & onboarding',
                  ].map((feature, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          borderRadius: '50%',
                          backgroundColor: 'rgba(32, 178, 170, 0.1)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          mr: 2,
                        }}
                      >
                        <CheckIcon sx={{ fontSize: 14, color: '#20B2AA' }} />
                      </Box>
                      <Typography variant='body2'>{feature}</Typography>
                    </Box>
                  ))}
                </Box>
                <Button
                  fullWidth
                  variant='turquoise'
                  sx={{
                    py: 1.5,
                    borderRadius: '10px',
                  }}
                >
                  Kontakta oss
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      {/* Testimonials Section */}
      <TestimonialsSection source='teacher' />

      {/* Stats Section */}
      <Box sx={{ py: 10, backgroundColor: theme.palette.background.default }}>
        <TeacherStatsSection />
      </Box>

      {/* Universitet och Media Sektion */}
      <UniversitySection bgcolor='background.paper' showHeadline={false} />
      <MediaSection bgcolor='background.default' />

      <Footer />

      {!isMobile && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 9999,
          }}
        >
          <IconButton onClick={toggleDarkMode}>
            {isDark ? <LightModeIcon /> : <DarkModeIcon />}
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default PricesPage;
