import React from 'react';
import { Box, Avatar, Typography } from '@mui/material';
import { OrbitProgress } from 'react-loading-indicators';

const CharacterMindMap = ({
  characters,
  storyImage,
  themeColor,
  isGeneratingImages,
  isGeneratingStoryImage,
  generatedCharacterImages = new Set(), // Track which images have been generated
}) => {
  const centerSize = 130;
  const itemSize = 120;
  const radius = 200;

  return (
    <Box
      sx={{
        position: 'relative',
        width: radius * 2 + itemSize,
        height: radius * 2 + itemSize,
        margin: '0 auto',
      }}
    >
      {/* Center Story Image with Loading Indicator */}
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 2,
        }}
      >
        <Avatar
          src={storyImage}
          alt='Story'
          sx={{
            width: centerSize,
            height: centerSize,
            bgcolor: '#f2f2f2',
          }}
        />
        {isGeneratingStoryImage && (
          <Box
            sx={{
              position: 'absolute',
              top: '5px',
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
            }}
          >
            <OrbitProgress color='#8b5cf6' size='small' text='' textColor='' />
          </Box>
        )}
      </Box>

      {/* Character Avatars with Loading Indicators */}
      {characters.map((character, index) => {
        const angle = (index / characters.length) * 2 * Math.PI - Math.PI / 2; // Start from top
        const x = Math.cos(angle) * radius + radius + itemSize / 2;
        const y = Math.sin(angle) * radius + radius + itemSize / 2;

        const isLoading = isGeneratingImages && !generatedCharacterImages.has(index);

        return (
          <Box
            key={index}
            sx={{
              position: 'absolute',
              top: y,
              left: x,
              transform: 'translate(-50%, -50%)',
              width: itemSize * 1.5,
              height: itemSize * 1.5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 3,
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <Avatar
                src={character.imageUrl}
                sx={{
                  width: itemSize * 0.8,
                  height: itemSize * 0.8,
                  mb: 1,
                  //bgcolor: `hsl(${(index * 137) % 360}, 50%, 60%)`,
                  bgcolor: '#f2f2f2',
                }}
              >
                {character.name[0]}
              </Avatar>
              {isLoading && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '5px',
                    left: 0,
                    right: 0,
                    bottom: '8px', // Adjust for margin-bottom of Avatar
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                  }}
                >
                  <OrbitProgress color='#8b5cf6' size='small' text='' textColor='' />
                </Box>
              )}
            </Box>
            <Typography
              variant='subtitle2'
              align='center'
              sx={{
                width: itemSize * 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                lineHeight: 1.2,
                height: '2.4em',
                fontWeight: 'bold',
                fontSize: '1rem',
                color: themeColor,
                mt: 1,
              }}
            >
              {character.name}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default CharacterMindMap;
