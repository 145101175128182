import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Breadcrumbs,
  Grid,
  Card,
  CardContent,
  Chip,
  useTheme,
} from '@mui/material';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PublicIcon from '@mui/icons-material/Public';
import BiotechIcon from '@mui/icons-material/Biotech';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ScienceIcon from '@mui/icons-material/Science';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import CalculateIcon from '@mui/icons-material/Calculate';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import GradingIcon from '@mui/icons-material/Grading';
import TableChartIcon from '@mui/icons-material/TableChart';
import Person3Icon from '@mui/icons-material/Person3';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import CreateIcon from '@mui/icons-material/Create';
import TranslateIcon from '@mui/icons-material/Translate';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { useNavigate, Link } from 'react-router-dom';
import { Palette as PaletteIcon } from '@mui/icons-material';
import { Abc as AbcIcon } from '@mui/icons-material';
import GridOnIcon from '@mui/icons-material/GridOn';

import SubjectIcon from '@mui/icons-material/Subject';
import { subjects } from './ThemeSelector';

import ForestIcon from '@mui/icons-material/Forest';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import NatureIcon from '@mui/icons-material/Nature';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import HistoryIcon from '@mui/icons-material/History';
import FilterVintageIcon from '@mui/icons-material/FilterVintage';

import WindowIcon from '@mui/icons-material/Window';

export const themeBasedShortcuts = {
  'Grej of the day': {
    icon: <LightbulbIcon sx={{ fontSize: 40 }} />,
    themes: [
      {
        icon: <BeachAccessIcon />,
        title: 'Sommar-tema',
        subtitle: 'Skapa grej of the day med ett Sommar-tema.',
        path: '/app/lektioner/grej-of-the-day?tema=sommar',
        label: 'NYTT',
      },

      {
        icon: <CoronavirusIcon />,
        title: 'Halloween-tema',
        subtitle: 'Skapa grej of the day med ett Halloween-tema.',
        path: '/app/lektioner/glosor-i-lador?tema=halloween',
        label: 'POPULÄR',
      },
      {
        icon: <HolidayVillageIcon />,
        title: 'Jul-tema',
        subtitle: 'Skapa grej of the day med ett Jul-tema.',
        path: '/app/lektioner/grej-of-the-day?tema=jul',
        label: 'POPULÄR',
      },

      {
        icon: <HistoryIcon />,
        title: 'Historia-tema',
        subtitle: 'Skapa grej of the day med historiska händelser och personer.',
        path: '/app/lektioner/grej-of-the-day?tema=historia',
        label: 'NYTT',
      },
      {
        icon: <FilterVintageIcon />,
        title: 'Vår-tema',
        subtitle: 'Skapa grej of the day med ett vårinspirerat tema.',
        path: '/app/lektioner/grej-of-the-day?tema=var',
        label: 'SÄSONG',
      },
      {
        icon: <AutoAwesomeIcon />,
        title: 'Anpassad',
        subtitle: 'Skriv in det tema du själv vill ha.',
        path: '/app/lektioner/grej-of-the-day',
        label: 'POPULÄR',
      },
    ],
  },

  'Glosor i lådor': {
    icon: <WindowIcon sx={{ fontSize: 40 }} />,
    themes: [
      {
        icon: <ForestIcon />,
        title: 'Höst-tema',
        subtitle: 'Skapa glosor i lådor med ett höst-tema.',
        path: '/app/lektioner/glosor-i-lador?tema=host',
        label: 'NYTT',
      },
      {
        icon: <CoronavirusIcon />,
        title: 'Halloween-tema',
        subtitle: 'Skapa glosor i lådor med ett Halloween-tema.',
        path: '/app/lektioner/glosor-i-lador?tema=halloween',
        label: 'POPULÄR',
      },

      {
        icon: <HolidayVillageIcon />,
        title: 'Jul-tema',
        subtitle: 'Skapa glosor i lådor med biologiska termer och begrepp.',
        path: '/app/lektioner/glosor-i-lador?tema=jul',
        label: 'NYTT',
      },
      {
        icon: <AcUnitIcon />,
        title: 'Vinter-tema',
        subtitle: 'Skapa glosor i lådor med ett vintrigt tema.',
        path: '/app/lektioner/glosor-i-lador?tema=vinter',
        label: 'SÄSONG',
      },

      {
        icon: <HistoryIcon />,
        title: 'Historia-tema',
        subtitle: 'Skapa glosor i lådor med ett historie-tema.',
        path: '/app/lektioner/glosor-i-lador?tema=historia',
        label: 'SÄSONG',
      },
      {
        icon: <AutoAwesomeIcon />,
        title: 'Anpassad',
        subtitle: 'Skriv in det tema du själv vill ha.',
        path: '/app/lektioner/glosor-i-lador',
        label: 'POPULÄR',
      },
    ],
  },
};

const TeacherShortcuts = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const theme = useTheme();
  const navigate = useNavigate();

  const shortcuts = {
    Svenska: {
      clickable: true,
      icon: <MenuBookIcon sx={{ fontSize: 40 }} />,
      content: {
        Text: [
          {
            icon: <TranslateIcon />,
            title: 'Glosor i lådor',
            subtitle: 'Öva och lära sig nya ord och begrepp med bilder på ett engagerande sätt.',
            path: '/app/lektioner/glosor-i-lador',
            label: 'NYTT',
          },

          {
            icon: <LightbulbIcon />,
            title: 'Grej of the Day',
            subtitle: 'Korta, engagerande kuriositeter om spännande ämnen.',
            path: '/app/lektioner/grej-of-the-day',
            label: 'POPULÄR',
          },

          {
            icon: <PaletteIcon />,
            title: 'Målarbilder',
            subtitle: '',
            path: '/app/lektioner/malarbilder',
            label: 'NYTT',
            roles: ['teacher'],
          },
          {
            icon: <GridOnIcon />,
            title: 'Hitta ordet',
            subtitle: '',
            path: '/app/lektioner/hitta-ordet',
            label: 'POPULÄR',
            roles: ['teacher'],
          },

          {
            icon: <AbcIcon />,
            title: 'Bilda ordet',
            subtitle: '',
            path: '/app/lektioner/bokstaver',
            label: 'NYTT',
            roles: ['teacher'],
          },

          {
            icon: <SubjectIcon />,
            title: 'Skriv fint',
            subtitle: '',
            path: '/app/lektioner/skrivlektion',
            label: 'NYTT',
            roles: ['teacher'],
          },
        ],
        'Läsa och skriva': [
          {
            icon: <AutoStoriesIcon />,
            title: 'Fortsätt berättelsen',
            subtitle: 'Kreativ skrivövning för att utveckla fantasin och berättarförmågan.',
            path: '/app/lektioner/fortsatt-berattelsen',
          },
          {
            icon: <CreateIcon />,
            title: 'Berättande text',
            subtitle: 'Skapa och strukturera berättande texter med stöd för olika genrer.',
            path: '/app/lektioner/berattande-text',
          },
        ],

        Planering: [
          {
            icon: <EventNoteIcon />,
            title: 'Lektionsplanering',
            subtitle: 'Skapa en lektionsplanering baserat på tid och ämne.',
            path: '/app/planering/lektionsplanering',
          },
        ],
        Bedömning: [
          {
            icon: <GradingIcon />,
            title: 'Formativ bedömning',
            subtitle: 'Bedöm en text baserat på fördefinierade eller egna bedömningsmatriser.',
            path: '/app/bedomning/formativ-bedomning',
          },
        ],
      },
    },
    Samhällskunskap: {
      clickable: false,
      icon: <PublicIcon sx={{ fontSize: 40 }} />,
      content: {
        Text: [
          {
            icon: <GradingIcon />,
            title: 'Grej of the day',
            subtitle: 'Kort förklarande text om vad denna modul gör',
            path: '/app/uppsats/ny',
            label: 'POPULÄR',
          },
        ],
      },
    },
    Biologi: {
      clickable: false,
      icon: <BiotechIcon sx={{ fontSize: 40 }} />,
      content: {
        Text: [
          {
            icon: 'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/teacherImages%2FZ6BLSMsm38Un5mgW0O6Z3TToHq12%2Fimg-oTRPTuwic0Dfh30rq4hiU.jpg?alt=media&token=4adee3a3-909e-43f5-8f17-cc3fcd327b67',
            title: 'Lärarassistent Max',
            subtitle: 'Assisterar lärare med allt de kan tänkas behöva',
            path: '/app/chat/MCkZkDEFiSKducNHuMvW',
            label: 'POPULÄR',
          },
        ],
      },
    },
    Kemi: {
      clickable: false,
      icon: <ScienceIcon sx={{ fontSize: 40 }} />,
      content: {
        Text: [
          {
            icon: <AssignmentTurnedInIcon />,
            title: 'Skapa individuell studieplan',
            subtitle: 'Generera en anpassad studieplan för en elev',
            path: '/app/fraga-ai?prompt=individuell-studieplan',
          },
        ],
      },
    },
    'Idrott och hälsa': {
      clickable: false,
      icon: <FitnessCenterIcon sx={{ fontSize: 40 }} />,
      content: {
        Text: [
          {
            icon: <AssignmentTurnedInIcon />,
            title: 'Skapa individuell studieplan',
            subtitle: 'Generera en anpassad studieplan för en elev',
            path: '/app/fraga-ai?prompt=individuell-studieplan',
          },
        ],
      },
    },
    Fysik: {
      clickable: false,
      icon: <CalculateIcon sx={{ fontSize: 40 }} />,
      content: {
        Text: [
          {
            icon: <AssignmentTurnedInIcon />,
            title: 'Skapa individuell studieplan',
            subtitle: 'Generera en anpassad studieplan för en elev',
            path: '/app/fraga-ai?prompt=individuell-studieplan',
          },
        ],
      },
    },
  };

  const getLabelColor = labelText => {
    if (labelText === 'NYTT') {
      return {
        backgroundColor: theme.palette.mode === 'dark' ? '#2B4C2A' : '#C5F2B8',
        color: theme.palette.mode === 'dark' ? '#3bbd28' : '#249314',
      };
    }
    return {
      backgroundColor: theme.palette.mode === 'dark' ? '#2a3b4c' : '#e0e7ff',
      color: theme.palette.mode === 'dark' ? '#60a5fa' : '#3b82f6',
    };
  };

  const MainCategoryView = () => (
    <Grid container spacing={3}>
      {Object.entries(shortcuts).map(([category, categoryData]) => (
        <Grid item xs={12} md={4} key={category}>
          <Card
            onClick={() => categoryData.clickable && setSelectedCategory(category)}
            sx={{
              backgroundColor: categoryData.clickable
                ? theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'rgba(139, 92, 246, 0.1)'
                : theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.05)'
                  : 'rgba(223, 222, 225, 0.19)',
              borderRadius: '12px',
              padding: '30px',
              paddingTop: '20px',
              paddingBottom: '20px',
              display: 'flex',
              alignItems: 'center',
              backgroundImage: 'none',
              boxShadow: 'none',
              justifyContent: 'space-between',

              transition: 'all 0.3s ease',
              position: 'relative',
              textAlign: 'center',
              border: theme.palette.mode === 'dark' ? undefined : '1px solid #e0e0e080',
              cursor: categoryData.clickable ? 'pointer' : 'not-allowed',
              filter: !categoryData.clickable ? 'blur(2px) brightness(0.95) saturate(0.8)' : 'none',
              pointerEvents: !categoryData.clickable ? 'none' : 'auto',
              //opacity: !categoryData.clickable ? 0.7 : 1,
              '&:hover': categoryData.clickable
                ? {
                    backgroundColor:
                      theme.palette.mode === 'dark'
                        ? 'rgba(255, 255, 255, 0.1)'
                        : 'rgba(139, 92, 246, 0.1)',
                    transform: 'translateY(-2px)',
                    '& .icon, & .star': {
                      color: theme.palette.mode === 'dark' ? 'rgb(16, 151, 218)' : '#6b46c1',
                    },
                  }
                : {},
            }}
          >
            <CardContent sx={{ textAlign: 'center', width: '100%' }}>
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                gap={1}
              >
                <Box
                  className='icon'
                  sx={{
                    color:
                      theme.palette.mode === 'dark'
                        ? 'rgba(255, 255, 255, 0.7)'
                        : 'rgba(0, 0, 0, 0.7)',
                    transition: 'color 0.3s ease',
                  }}
                >
                  {categoryData.icon}
                </Box>
                <Box>
                  <Typography
                    variant='h6'
                    sx={{ textAlign: 'center', fontWeight: '600', fontSize: '1.45rem' }}
                  >
                    {category}
                  </Typography>
                  <Typography variant='body2' color='textSecondary' sx={{ mt: 1 }}>
                    {Object.values(categoryData.content).flat().length} verktyg
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  const SubCategoryView = () => (
    <Box>
      {Object.entries(shortcuts[selectedCategory].content).map(([subcategory, items]) => (
        <Box key={subcategory} sx={{ mb: 4 }}>
          <Typography variant='h6' sx={{ mb: 2, mt: 7, fontWeight: '600', fontSize: '23px' }}>
            {subcategory}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Grid container spacing={2}>
              {items.map((item, index) => (
                <Grid item xs={12} sm={6} md={6} key={index}>
                  <Link
                    to={item.path}
                    style={{ textDecoration: 'none', color: 'inherit', display: 'block' }}
                  >
                    <Card
                      sx={{
                        backgroundColor:
                          theme.palette.mode === 'dark'
                            ? 'rgba(255, 255, 255, 0.05)'
                            : 'rgba(223, 222, 225, 0.19)',
                        borderRadius: '12px',
                        padding: '30px',
                        paddingTop: '15px',
                        paddingBottom: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                        backgroundImage: 'none',
                        boxShadow: 'none',
                        transition: 'all 0.3s ease',
                        position: 'relative',
                        textAlign: 'center',
                        border: theme.palette.mode === 'dark' ? undefined : '1px solid #e0e0e080',
                        '&:hover': {
                          backgroundColor:
                            theme.palette.mode === 'dark'
                              ? 'rgba(255, 255, 255, 0.1)'
                              : 'rgba(139, 92, 246, 0.1)',
                          transform: 'translateY(-2px)',
                          '& .icon, & .star': {
                            color: theme.palette.mode === 'dark' ? 'rgb(16, 151, 218)' : '#6b46c1',
                          },
                        },
                      }}
                    >
                      <CardContent>
                        <Box display='flex' alignItems='center' gap={2}>
                          <Box
                            sx={{
                              width: 48,
                              height: 48,
                              borderRadius: '50%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              flexShrink: 0,
                            }}
                          >
                            {typeof item.icon === 'string' ? (
                              <Box
                                component='img'
                                src={item.icon}
                                alt=''
                                sx={{ width: 48, height: 48, borderRadius: '50%' }}
                              />
                            ) : (
                              React.cloneElement(item.icon, {
                                sx: {
                                  color: '#8b5cf6',
                                  fontSize: '40px',
                                },
                              })
                            )}
                          </Box>
                          <Box flex={1}>
                            <Box
                              display='flex'
                              alignItems='center'
                              gap={1}
                              sx={{ textAlign: 'left' }}
                            >
                              <Typography
                                variant='subtitle1'
                                sx={{ fontWeight: '600', fontSize: '1.1rem' }}
                              >
                                {item.title}
                              </Typography>
                              {item.label && (
                                <Chip
                                  label={item.label}
                                  size='small'
                                  sx={{
                                    position: 'absolute',
                                    top: '8px',
                                    right: '8px',

                                    backgroundColor:
                                      theme.palette.mode === 'dark' ? '#2a3b4c' : '#e0e7ff',
                                    color: theme.palette.mode === 'dark' ? '#60a5fa' : '#3b82f6',
                                    fontWeight: 'bold',
                                    fontSize: '0.7rem',
                                    height: '20px',
                                    marginLeft: '8px',
                                    ...getLabelColor(item.label),
                                  }}
                                />
                              )}
                            </Box>
                            <Typography
                              variant='body2'
                              color='textSecondary'
                              sx={{ textAlign: 'left' }}
                            >
                              {item.subtitle}
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      ))}
    </Box>
  );

  return (
    <Box>
      {selectedCategory && (
        <Breadcrumbs
          sx={{
            mb: 0,
            '& .MuiBreadcrumbs-ol': {
              paddingLeft: 0,
            },
          }}
        >
          <Link
            component='button'
            variant='body1'
            onClick={() => setSelectedCategory(null)}
            underline='none'
            sx={{ cursor: 'pointer' }}
          >
            <Chip
              icon={<KeyboardDoubleArrowLeftIcon sx={{ fontSize: 25 }} />}
              label='Tillbaka'
              clickable
              sx={{
                fontSize: '14px',
                padding: '22px',
                paddingLeft: '10px',
                paddingRight: '10px',
                borderRadius: '8px',
                fontWeight: '500',
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? 'rgba(255, 255, 255, 0.1)'
                    : 'rgba(139, 92, 246, 0.1)',
                '&:hover': {
                  backgroundColor:
                    theme.palette.mode === 'dark'
                      ? 'rgba(255, 255, 255, 0.2)'
                      : 'rgba(139, 92, 246, 0.2)',
                },
              }}
            />
          </Link>
        </Breadcrumbs>
      )}

      {selectedCategory ? <SubCategoryView /> : <MainCategoryView />}
    </Box>
  );
};

export default TeacherShortcuts;
