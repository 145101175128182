import React from 'react';
import { Box, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';

const ScrollContainer = styled(Box)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: '100%',
});

const ScrollContent = styled(Box)({
  display: 'inline-block',
  whiteSpace: 'nowrap',
  animation: 'scroll 50s linear infinite',
  '@keyframes scroll': {
    '0%': { transform: 'translateX(0%)' },
    '100%': { transform: 'translateX(-50%)' },
  },
});

const UniversityLogoWrapper = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '200px', // Adjust as needed
  height: '120px',
  margin: '0 30px',
  padding: theme.spacing(2),
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  borderRadius: '11px',
  verticalAlign: 'middle',
}));

const UniversityLogo = styled('img')({
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'contain',
});

const UniversitySection = ({ bgcolor, showHeadline = true }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const universities = [
    { name: 'Stockholms universitet', logo: '/img/lund.png' },
    { name: 'Uppsala universitet', logo: '/img/uppsala.png' },
    { name: 'Lunds universitet', logo: '/img/umea.png' },
    { name: 'Kungliga Tekniska högskolan', logo: '/img/kth.png' },
    { name: 'Göteborgs universitet', logo: '/img/goteborg.png' },
    { name: 'Linköpings universitet', logo: '/img/linkoping.png' },
  ];

  return (
    <Box
      sx={{
        bgcolor: bgcolor,
        py: showHeadline ? 8 : 8,
        paddingBottom: showHeadline ? undefined : '60px',
      }}
    >
      <Container maxWidth='xl'>
        <Typography
          variant='h3'
          align='center'
          sx={{
            fontWeight: 'bold',
            fontSize: '2.3em',
            mb: 2,
            display: showHeadline ? 'block' : 'none',
          }}
        >
          Används av både studenter och lärare
        </Typography>
        {/*
        <Typography
          variant='subtitle1'
          align='center'
          color='text.secondary'
          sx={{ mb: showHeadline ? 6 : 3 }}
        >
          Över 3000 studenter & lärare använder Studera.ai just nu
        </Typography>
        */}
        <ScrollContainer>
          <ScrollContent>
            {[...universities, ...universities].map((university, index) => (
              <UniversityLogoWrapper key={`${university.name}-${index}`}>
                <UniversityLogo
                  src={university.logo}
                  alt={`${university.name} logotyp`}
                  title={university.name}
                />
              </UniversityLogoWrapper>
            ))}
          </ScrollContent>
        </ScrollContainer>
      </Container>
    </Box>
  );
};

export default UniversitySection;
