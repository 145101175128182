import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Divider,
  TextField,
  Tooltip,
  CircularProgress,
  Grid,
  Avatar,
} from '@mui/material';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import InfoBox from './InfoBox';
import { callApi } from './api';
import TrainIcon from '@mui/icons-material/Train';
import { useSnackbar } from './SnackbarHandler';
import LanguageSelector from './LanguageSelector';
import { RiLightbulbLine } from 'react-icons/ri';
import ProgressBar from './ProgressBar';
import ImageGenerationModal from './ImageGenerationModal';
import { handlePrintPaSparet } from './printFunctions';
import { FourSquare } from 'react-loading-indicators';

import { ThemeSelector } from './ThemeSelector';
import EditIcon from '@mui/icons-material/Edit';
import ImageIcon from '@mui/icons-material/Image';
import { generateAndProcessImage } from './functions';
import PrintIcon from '@mui/icons-material/Print';

const ClueGenerator = ({ user, theme, styles, grade }) => {
  const [topic, setTopic] = useState('customTheme');
  const [subject, setSubject] = useState('');
  const [customTheme, setCustomTheme] = useState('');
  const [language, setLanguage] = useState('svenska');
  const [clueCount, setClueCount] = useState(6);
  const [isGenerating, setIsGenerating] = useState(false);

  const [clueData, setClueData] = useState(null);
  const [isGeneratingTopicImage, setIsGeneratingTopicImage] = useState(false);
  const [isGeneratingClueImages, setIsGeneratingClueImages] = useState(false);
  const [progress, setProgress] = useState(0);
  const [generatedClueImages, setGeneratedClueImages] = useState(new Set());
  const { showSnackbar } = useSnackbar();
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const [imageGenerationType, setImageGenerationType] = useState(null);
  const [openAiInstructions, setOpenAiInstructions] = useState('');

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [titleText, setTitleText] = useState('På spåret-lektion');

  const topicSectionRef = useRef(null);
  const cluesSectionRef = useRef(null);

  const handleImageButtonClick = type => {
    setImageGenerationType(type);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
  };

  const handleGenerateWithInstructions = async () => {
    handleCloseModal();
    if (imageGenerationType === 'topic') {
      await handleGenerateTopicImage();
    } else if (imageGenerationType === 'clues') {
      await handleGenerateClueImages();
    }
  };

  const simulateProgress = (startProgress, endProgress, duration, setProgressFn) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgressFn(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateClues = async () => {
    setIsGenerating(true);
    setProgress(0);
    let stopProgressSimulation = null;
    try {
      stopProgressSimulation = simulateProgress(0, 90, 15000, setProgress);

      const generateTheme = topic === 'generateWithAi';
      const themeToUse = generateTheme
        ? '[Hitta på ett slumpmässigt svar som t.ex. en historisk händelse eller person]'
        : topic === 'customTheme'
          ? customTheme
          : topic;

      //const themeToUse = topic === 'customTheme' ? customTheme : topic;

      const response = await callApi(
        [
          {
            role: 'system',
            content: `You are an assistant that generates interesting clues for guessing games. The clues should start VERY VERY difficult and become progressively easier. ${openAiInstructions}`,
          },
          {
            role: 'user',
            content: `Generate a JSON object with exactly ${clueCount} clues about ${themeToUse} in "${language}". 


            The clues should be ordered from most difficult to easiest, with the last clue being very obvious.
            Each clue should contain at least 3 sentences and max 3 sentences.
            Include empty image placeholders (null) for "topicImage" and each clue's "image" field.
            Example response:
{
  "topic": "Miguel de Cervantes",

  "answer": "Miguel de Cervantes",
    "title": "Famous Authors in History",
  "topicImage": null,
  "clues": {
    "Clue 1": {
      "text": "This author spent five years as a captive in Algiers",
      "difficulty": "12 poäng",
      "image": null
    },
    "Clue 2": {
      "text": "They worked as a tax collector before achieving literary fame",
      "difficulty": "10 poäng",
      "image": null
    },
    "Clue 3": {
      "text": "They lost the use of their left hand in a famous naval battle",
      "difficulty": "8 poäng",
      "image": null
    },
    "Clue 4": {
      "text": "This Spanish author's masterpiece features a delusional knight",
      "difficulty": "6 poäng",
      "image": null
    },
    "Clue 5": {
      "text": "They wrote Don Quixote",
      "difficulty": "4 poäng",
      "image": null
    }

        "Clue 6": {
      "text": "They wrote Don Quixote",
      "difficulty": "2 poäng",
      "image": null
    }
  }
}`,
          },
        ],
        4000,
        true,
        user.uid
      );

      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setProgress(100);
      await new Promise(resolve => setTimeout(resolve, 800));

      setClueData(data);
      setTitleText(data.answer);
      showSnackbar('På spåret-lektionen har genererats!', 'success');
    } catch (error) {
      console.error('Error generating clues:', error);
      showSnackbar('Något gick fel. Försök igen.', 'error');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleGenerateTopicImage = async () => {
    setIsGeneratingTopicImage(true);
    try {
      const prompt = `Skapa en bild som representerar ${clueData.topic}, men gör det inte för uppenbart vad det är. Lägg inte till någon text i bilden. ${imageInstructions}`;
      const imageUrl = await generateAndProcessImage(prompt, user.uid);
      setClueData(prevData => ({
        ...prevData,
        topicImage: imageUrl,
      }));
      showSnackbar('Ämnesbild genererad framgångsrikt.', 'success');
    } catch (error) {
      showSnackbar('Misslyckades med att generera ämnesbild. Försök igen.', 'error');
    } finally {
      setIsGeneratingTopicImage(false);
    }
  };

  const handleGenerateClueImages = async () => {
    setIsGeneratingClueImages(true);
    setGeneratedClueImages(new Set());
    try {
      const entries = Object.entries(clueData.clues);
      for (const [key, value] of entries) {
        try {
          const prompt = `Skapa en bildledtråd för ledtråden: "${value.text}" om ${clueData.topic}. Gör den subtil och inte för uppenbar. Bilden får absolut INTE avslöja svaret. Lägg inte till någon text. ${imageInstructions}`;
          const imageUrl = await generateAndProcessImage(prompt, user.uid);

          setClueData(prevData => ({
            ...prevData,
            clues: {
              ...prevData.clues,
              [key]: {
                ...prevData.clues[key],
                image: imageUrl,
              },
            },
          }));

          setGeneratedClueImages(prev => new Set([...prev, key]));
        } catch (error) {
          console.error(`Error generating image for clue "${key}":`, error);
          continue;
        }
      }
      showSnackbar('Alla ledtrådsbilder har genererats!', 'success');
    } catch (error) {
      showSnackbar('Fel vid generering av ledtrådsbilder.', 'error');
    } finally {
      setIsGeneratingClueImages(false);
    }
  };

  const isGeneratingAnyImages = isGeneratingTopicImage || isGeneratingClueImages;

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: '100%', md: '87%' },
          margin: '0 auto',
        }}
      >
        <Typography
          variant='h1'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
        >
          På spåret-lektion
        </Typography>

        <InfoBox
          icon={TrainIcon}
          title='Instruktioner för Ledtråds Generator'
          instructions={[
            'Ange ett svar som elever ska gissa sig till (t.ex. Penicillin, Kalla kriget)',
            'Välj antal ledtrådar du vill att eleverna ska få',
            'Lägg till bilder för varje ledtråd och skriv ut lektionen',
          ]}
          theme={theme}
          styles={styles}
        />

        <Box sx={{ ...styles.freshBox, padding: '30px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <ThemeSelector
                value={topic}
                onChange={e => setTopic(e.target.value)}
                theme={theme}
                customTheme={customTheme}
                onCustomThemeChange={e => setCustomTheme(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                Antal ledtrådar:
              </Typography>
              <FormControl fullWidth margin='normal' sx={{ mb: 1, mt: 1 }}>
                <Select value={clueCount} onChange={e => setClueCount(e.target.value)}>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                Språk:
              </Typography>
              <Box sx={{ mt: 1, mb: 1 }}>
                <LanguageSelector language={language} setLanguage={setLanguage} />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                Ytterligare instruktioner till AI:
              </Typography>
              <FormControl fullWidth margin='normal' sx={{ mb: 3, mt: 1 }}>
                <TextField
                  value={openAiInstructions}
                  onChange={e => setOpenAiInstructions(e.target.value)}
                  placeholder='Ex. Fokusera på vetenskapliga prestationer'
                  variant='outlined'
                />
              </FormControl>
            </Grid>
          </Grid>

          <Button
            variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
            color='primary'
            onClick={handleGenerateClues}
            disabled={isGenerating || isGeneratingAnyImages}
            sx={{ mt: 2 }}
            startIcon={
              !isGenerating ? (
                <GenerateIcon style={{ color: '#fff' }} />
              ) : (
                <CircularProgress style={{ color: '#fff' }} size={16} />
              )
            }
          >
            {isGenerating ? 'Genererar ledtrådar...' : 'Generera lektionen'}
          </Button>

          {isGenerating && (
            <Box sx={{ mt: 7 }}>
              <ProgressBar progress={progress} label='Genererar på spåret-lektion...' />
            </Box>
          )}

          {clueData && (
            <>
              <Divider sx={{ mt: 5, mb: 3 }} />
              {/*
              <Button
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                color='primary'
                onClick={() => handleImageButtonClick('topic')}
                disabled={isGeneratingAnyImages}
                sx={{ mt: 2 }}
                startIcon={
                  !isGeneratingTopicImage ? (
                    <ImageIcon style={{ color: '#fff' }} />
                  ) : (
                    <CircularProgress style={{ color: '#fff' }} size={16} />
                  )
                }
              >
                {isGeneratingTopicImage ? 'Genererar...' : 'Lägg till ämnesbild'}
              </Button>
*/}
              <Button
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                color='primary'
                onClick={() => handleImageButtonClick('clues')}
                disabled={isGeneratingAnyImages}
                sx={{ mt: 2 }}
                startIcon={
                  !isGeneratingClueImages ? (
                    <ImageIcon style={{ color: '#fff' }} />
                  ) : (
                    <CircularProgress style={{ color: '#fff' }} size={16} />
                  )
                }
              >
                {isGeneratingClueImages ? 'Genererar...' : 'Lägg till ledtrådsbilder'}
              </Button>

              <Button
                variant='contained'
                color='primary'
                onClick={() => handlePrintPaSparet(clueData, titleText, theme)}
                startIcon={<PrintIcon />}
                sx={{ ml: 2, mt: 2 }}
              >
                Skriv ut lektion
              </Button>

              <ImageGenerationModal
                open={showImageModal}
                onClose={handleCloseModal}
                onGenerate={handleGenerateWithInstructions}
                isGenerating={
                  imageGenerationType === 'topic' ? isGeneratingTopicImage : isGeneratingClueImages
                }
                instructions={imageInstructions}
                setInstructions={setImageInstructions}
                generationType={imageGenerationType}
                theme={theme}
              />

              <Box sx={{ mt: 8 }}>
                <Typography
                  variant='h6'
                  sx={{ ...styles.grayText, fontWeight: '600', mt: 0, fontSize: '18px' }}
                >
                  (Korrekt svar: {clueData.answer})
                </Typography>

                {isEditingTitle ? (
                  <TextField
                    value={titleText}
                    onChange={e => setTitleText(e.target.value)}
                    onBlur={() => {
                      setIsEditingTitle(false);
                      showSnackbar('Titeln på lektionen har uppdaterats', 'success');
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        setIsEditingTitle(false);
                        showSnackbar('Titeln på lektionen har uppdaterats', 'success');
                      }
                    }}
                    autoFocus
                    fullWidth
                    variant='standard'
                    sx={{ backgroundColor: 'transparent' }}
                    InputProps={{
                      style: {
                        fontWeight: 'bold',
                        fontSize: '2.6em',
                        textAlign: 'center',
                        marginBottom: '1.2rem',
                        marginTop: '1.5rem',

                        backgroundColor: 'transparent',
                      },
                      disableUnderline: true,
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'inline-block',
                      margin: '0 auto',
                      mb: 3,
                      mt: 5,
                      ':hover .edit-icon': {
                        opacity: 1,
                      },
                    }}
                  >
                    <Typography
                      variant='h1'
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '2.6em',
                        cursor: 'pointer',
                      }}
                      onClick={() => setIsEditingTitle(true)}
                    >
                      {titleText}
                    </Typography>
                    <EditIcon
                      className='edit-icon'
                      onClick={() => setIsEditingTitle(true)}
                      sx={{
                        position: 'absolute',
                        right: -35,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                        opacity: 0,
                        transition: 'opacity 0.2s ease',
                        color: theme => theme.palette.primary.main,
                      }}
                    />
                  </Box>
                )}

                <Grid container spacing={4}>
                  {Object.entries(clueData.clues).map(([key, clue], index) => (
                    <Grid item xs={12} key={index}>
                      <Typography
                        variant='subtitle1'
                        sx={{
                          fontWeight: 'bold',
                          mb: 1,
                          mt: 5,
                          color: theme => theme.palette.primary.main,
                          fontSize: '40px',
                        }}
                      >
                        {clue.difficulty}
                      </Typography>

                      <Box
                        sx={{
                          backgroundColor: theme.palette.mode === 'dark' ? '#15202b' : '#e0e0e029',
                          borderRadius: '10px',
                          padding: '20px',
                        }}
                      >
                        <Grid container spacing={3} alignItems='center'>
                          <Grid item xs={12} md={4}>
                            <Box sx={{ position: 'relative', mb: { xs: 2, md: 0 } }}>
                              <Avatar
                                variant='rounded'
                                src={clue.image ? clue.image : '/api/placeholder/600/300'}
                                alt={`Ledtråd ${index + 1} Bild`}
                                sx={{
                                  width: '100%',
                                  height: '240px',
                                  aspectRatio: '16 / 9',
                                  backgroundColor: '#e0e0e0',
                                  borderRadius: '10px',
                                }}
                              />
                              {isGeneratingClueImages && !generatedClueImages.has(key) && (
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#d7d7d7',
                                    borderRadius: '10px',
                                  }}
                                >
                                  <FourSquare color='#8b5cf6' size='medium' text='' textColor='' />
                                </Box>
                              )}
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={8}
                            sx={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <Typography variant='body1' sx={{ lineHeight: '1.7rem' }}>
                              {clue.text}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ClueGenerator;
