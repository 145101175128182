import React, { useEffect } from 'react';
import { Container, Typography, Grid, Box, useTheme, useMediaQuery, Chip } from '@mui/material';
import { GradientBackground } from './StyledComponents';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import { motion } from 'framer-motion';

const PrivacyPolicyPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Integritetspolicy & GDPR | Studera.ai</title>
        <meta
          name='description'
          content="Studera.ai's integritetspolicy och information om GDPR-efterlevnad."
        />
      </Helmet>

      <Header />

      <GradientBackground className='gradient-background'>
        <Container sx={{ pt: { xs: 10, md: 20 }, pb: 15, position: 'relative' }}>
          <Grid container spacing={6} alignItems='center'>
            <Grid item xs={12} md={12}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                  <Chip
                    label={
                      <span>
                        Studera.ai{' '}
                        <span style={{ opacity: 0.7 }}>
                          &nbsp;•&nbsp; Revolutionerar lärande med AI
                        </span>
                      </span>
                    }
                    sx={{
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      color: 'white',
                      fontWeight: '600',
                      fontSize: '0.8rem',
                      padding: '2px 14px',

                      borderRadius: '16px',
                      '& .MuiChip-label': {
                        padding: '0 4px',
                      },
                    }}
                  />
                </Box>

                <Typography
                  variant='h2'
                  component='h1'
                  gutterBottom
                  sx={{
                    fontWeight: 900,
                    letterSpacing: -1,
                    fontSize: { xs: '2.5rem', md: '3.35rem' },
                    textAlign: 'center',
                  }}
                >
                  Integritetspolicy & GDPR
                </Typography>

                <Typography
                  variant='h5'
                  sx={{
                    mb: 4,
                    opacity: 0.8,
                    fontSize: '1.3rem',
                    width: { xs: '100%', md: '64%' },
                    margin: '0 auto',
                    fontWeight: '500',
                    lineHeight: '31px',
                    display: 'block',
                    mt: 4,
                    textAlign: 'center',
                  }}
                >
                  Vi på Studera.ai värnar om din integritet. Här förklarar vi hur vi hanterar dina
                  personuppgifter och hur vi säkerställer att din data är skyddad enligt GDPR.
                </Typography>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </GradientBackground>

      <Container sx={{ my: 15 }}>
        <Typography variant='h4' gutterBottom sx={{ fontWeight: 'bold', mb: 4 }}>
          Studera.ai och din integritet
        </Typography>
        <Typography variant='body1' paragraph>
          På Studera.ai tar vi din integritet på allvar. Denna policy beskriver hur vi samlar in,
          använder och skyddar dina personuppgifter i enlighet med Dataskyddsförordningen (GDPR).
        </Typography>

        <Typography variant='h5' gutterBottom sx={{ fontWeight: 'bold', mt: 4, mb: 2 }}>
          1. Insamling av personuppgifter
        </Typography>
        <Typography variant='body1' paragraph>
          Vi samlar in personuppgifter som du frivilligt lämnar till oss när du använder vår tjänst,
          såsom namn, e-postadress och studiehistorik. Vi samlar även in anonymiserad användardata
          för att förbättra våra tjänster.
        </Typography>

        <Typography variant='h5' gutterBottom sx={{ fontWeight: 'bold', mt: 4, mb: 2 }}>
          2. Användning av personuppgifter
        </Typography>
        <Typography variant='body1' paragraph>
          Dina personuppgifter används för att:
          <ul>
            <li>Tillhandahålla och förbättra våra tjänster</li>
            <li>Anpassa din inlärningsupplevelse</li>
            <li>Kommunicera med dig om din användning av tjänsten</li>
            <li>Följa lagliga skyldigheter</li>
          </ul>
        </Typography>

        <Typography variant='h5' gutterBottom sx={{ fontWeight: 'bold', mt: 4, mb: 2 }}>
          3. Delning av personuppgifter
        </Typography>
        <Typography variant='body1' paragraph>
          Vi delar inte dina personuppgifter med tredje part, förutom när det krävs för att
          tillhandahålla våra tjänster eller när vi är skyldiga att göra det enligt lag.
        </Typography>

        <Typography variant='h5' gutterBottom sx={{ fontWeight: 'bold', mt: 4, mb: 2 }}>
          4. Dina rättigheter enligt GDPR
        </Typography>
        <Typography variant='body1' paragraph>
          Som användare har du rätt att:
          <ul>
            <li>Få tillgång till dina personuppgifter</li>
            <li>Begära rättelse av felaktiga uppgifter</li>
            <li>Begära radering av dina uppgifter</li>
            <li>Begära begränsning av behandling</li>
            <li>Invända mot behandling</li>
            <li>Begära dataportabilitet</li>
          </ul>
        </Typography>

        <Typography variant='h5' gutterBottom sx={{ fontWeight: 'bold', mt: 4, mb: 2 }}>
          5. Datasäkerhet
        </Typography>
        <Typography variant='body1' paragraph>
          Vi använder lämpliga tekniska och organisatoriska åtgärder för att skydda dina
          personuppgifter mot obehörig åtkomst, förlust eller missbruk.
        </Typography>

        <Typography variant='h5' gutterBottom sx={{ fontWeight: 'bold', mt: 4, mb: 2 }}>
          6. Kontakta oss
        </Typography>
        <Typography variant='body1' paragraph>
          Om du har frågor om vår integritetspolicy eller vill utöva dina rättigheter, vänligen
          kontakta oss på info@studera.ai.
        </Typography>
      </Container>

      <Footer />

      {!isMobile && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 9999,
          }}
        >
          {/* Du kan lägga till en knapp här för att växla mellan ljust och mörkt läge om det behövs */}
        </Box>
      )}
    </>
  );
};

export default PrivacyPolicyPage;
