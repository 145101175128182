import React from 'react';
import { Box, Container, Grid, Typography, useTheme, Link as MuiLink } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const Footer = () => {
  const theme = useTheme();

  const linkStyle = {
    color: theme.palette.text.secondary,
    textDecoration: 'none',

    marginBottom: '10px',
    display: 'block',
    '&:hover': {
      color:
        theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
    },
  };

  return (
    <Box
      component='footer'
      sx={{
        bgcolor: theme.palette.background.default,
        padding: '60px',
        paddingTop: '100px',
        paddingBottom: '100px',
      }}
    >
      <Container maxWidth='xl'>
        <Grid container spacing={4}>
          {/* First column */}
          <Grid item xs={12} sm={6} md={3}>
            <Box
              component='img'
              src='/img/AI.png'
              alt='Studera.AI Logo'
              sx={{ height: 40, mb: 2 }}
            />
            <Typography
              variant='h6'
              component='div'
              sx={{
                fontWeight: 'bold',
                color: theme.palette.mode === 'dark' ? 'white' : 'black',
                fontSize: '1.45rem',
              }}
            >
              Studera.ai
            </Typography>

            <Typography variant='body2' color='text.secondary' sx={{ fontSize: '1.0rem' }}>
              AI-verktyg för lärare, pedagoger, elever och studenter.
              <br />
              <br />
              Vi revolutionerar lärande med AI-teknologi för att göra utbildning mer tillgänglig och
              effektiv för alla.
            </Typography>
          </Grid>

          {/* Third column */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant='h6' gutterBottom sx={{ mb: 3.4 }}>
              Resurser
            </Typography>

            <MuiLink component={RouterLink} to='/ai-verktyg-larare' sx={linkStyle}>
              AI-verktyg för lärare
            </MuiLink>
            <MuiLink component={RouterLink} to='/ai-verktyg-elever' sx={linkStyle}>
              AI-verktyg för elever
            </MuiLink>
            <MuiLink component={RouterLink} to='/integritetspolicy-gdpr' sx={linkStyle}>
              Integritetspolicy & GDPR
            </MuiLink>
            <MuiLink component={RouterLink} to='/om-oss' sx={linkStyle}>
              Om Studera.ai
            </MuiLink>
            <MuiLink component={RouterLink} to='#' sx={linkStyle}>
              Artiklar
            </MuiLink>
          </Grid>

          {/* Second column */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant='h6' gutterBottom sx={{ mb: 3.4 }}>
              Populärt
            </Typography>
            <MuiLink component={RouterLink} to='/funktioner/fraga-ai' sx={linkStyle}>
              Fråga AI
            </MuiLink>
            <MuiLink component={RouterLink} to='/skapa-kurser-med-ai' sx={linkStyle}>
              Skapa kurser med AI
            </MuiLink>
            <MuiLink component={RouterLink} to='/funktioner/lar-dig-sprak-med-ai' sx={linkStyle}>
              Lär dig språk med AI
            </MuiLink>
            <MuiLink component={RouterLink} to='/funktioner/skapa-kurser-med-ai' sx={linkStyle}>
              Skapa kurser med AI
            </MuiLink>
            <MuiLink component={RouterLink} to='/funktioner/skapa-quiz-med-ai' sx={linkStyle}>
              Skapa quiz med AI
            </MuiLink>
          </Grid>

          {/* Fourth column */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant='h6' gutterBottom sx={{ mb: 3.4 }}>
              Kontakta oss
            </Typography>
            <Box display='flex' alignItems='center' mb={1}>
              <EmailIcon sx={{ mr: 1, color: 'text.secondary' }} />
              <Typography variant='body2' color='text.secondary'>
                info@studera.ai
              </Typography>
            </Box>
            <Box display='flex' alignItems='center' mb={1}>
              <PhoneIcon sx={{ mr: 1, color: 'text.secondary' }} />
              <Typography variant='body2' color='text.secondary'>
                +46 735 91 33 20
              </Typography>
            </Box>
            {/*
            <Box display='flex' alignItems='center'>
              <LocationOnIcon sx={{ mr: 1, color: 'text.secondary' }} />
              <Typography variant='body2' color='text.secondary'>
                Gladiovägen 167
              </Typography>
            </Box>
            */}
          </Grid>
        </Grid>
      </Container>
      <Box mt={10} bgcolor={theme.palette.background.default} py={2}>
        <Typography variant='body2' color='text.secondary' align='center'>
          © {new Date().getFullYear()} Studera.ai. Alla rättigheter förbehållna.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
