import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Fade,
  Grid,
  Divider,
  FormControl,
  ButtonGroup,
  FormLabel,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { callApi } from './api'; // Adjust import path as needed
import ReactMarkdown from 'react-markdown';
import InfoBox from './InfoBox';
import { useTheme } from '@mui/material/styles';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import ImageIcon from '@mui/icons-material/Image';
import CharacterMindMap from './CharacterMindMap'; // Adjust the import path as needed
import { generateAndProcessImage } from './functions'; // New import from TermsGrid
import { useSnackbar } from './SnackbarHandler'; // New import from TermsGrid
import { OrbitProgress } from 'react-loading-indicators'; // New import from TermsGrid
import { handlePrintNarrativeLesson } from './printFunctions';
import { getStyles } from '../index';
import PrintIcon from '@mui/icons-material/Print';
import LanguageSelector from './LanguageSelector';
import { CustomFormControlLabel } from './functions';
import { ThemeSelector } from './ThemeSelector';
import ImageGenerationModal from './ImageGenerationModal';
import GradeSelector, { gradeInstructions } from './GradeSelector';
import Logger from './Logger';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';

import NarrativeLessonStoryGenerator from './NarrativeLessonStoryGenerator';

const NarrativeLesson = ({ user }) => {
  const [goal, setGoal] = useState('');
  const [develop, setDevelop] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [lessonTheme, setLessonTheme] = useState('generateWithAi');
  const [customTheme, setCustomTheme] = useState('');
  const [isGeneratingInfo, setIsGeneratingInfo] = useState(false);
  const [isGeneratingPlan, setIsGeneratingPlan] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [isGeneratingStoryImages, setIsGeneratingStoryImages] = useState(false);
  const [lessonPlanKey, setLessonPlanKey] = useState(0);
  const [themeColor, setThemeColor] = useState('#000000');
  const [planData, setPlanData] = useState(null);
  const theme = useTheme();
  const styles = getStyles(theme);
  const { showSnackbar } = useSnackbar(); // New hook from TermsGrid
  const [generatedCharacterImages, setGeneratedCharacterImages] = useState(new Set());
  const [grade, setGrade] = useState('åk6');
  const [language, setLanguage] = useState('svenska');
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const [imageGenerationType, setImageGenerationType] = useState(null);

  const handleImageButtonClick = type => {
    setImageGenerationType(type);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
  };

  const handleGenerateWithInstructions = async () => {
    handleCloseModal();

    if (imageGenerationType === 'topic') {
      await handleGenerateCharacterImages();
    } else if (imageGenerationType === 'facts') {
      await handleGenerateStoryImages();
    }
  };

  const handleGenerateCharacterImages = async () => {
    setIsGeneratingImages(true);
    setGeneratedCharacterImages(new Set()); // Reset the set when starting generation
    try {
      // Create a copy of current planData to update
      let updatedPlanData = { ...planData };

      // Generate images for each character sequentially
      for (let i = 0; i < updatedPlanData.whoOrWhich.length; i++) {
        const character = updatedPlanData.whoOrWhich[i];

        try {
          const prompt = `Create a portrait image of a character described as "${character.name}". The character should be expressive and appealing. Do not include any text in the image. ${imageInstructions}`;

          const imageUrl = await generateAndProcessImage(prompt, user.uid, {
            size: '1024x1024',
            model: 'dall-e-3',
            quality: 'standard',
          });

          // Update the specific character with their new image
          updatedPlanData.whoOrWhich[i] = {
            ...character,
            imageUrl: imageUrl,
          };

          // Update state after each image generation
          setPlanData({ ...updatedPlanData });
          setGeneratedCharacterImages(prev => new Set([...prev, i]));
          setLessonPlanKey(prevKey => prevKey + 1);

          showSnackbar(
            `Genererat karaktärsbild ${i + 1} av ${updatedPlanData.whoOrWhich.length}`,
            'success'
          );
        } catch (error) {
          console.error(`Error generating image for character "${character.name}":`, error);
          showSnackbar(`Kunde inte generera bild för "${character.name}"`, 'warning');

          // Set placeholder if image generation fails
          updatedPlanData.whoOrWhich[i] = {
            ...character,
            imageUrl: '/api/placeholder/1024/1024',
          };
          setPlanData({ ...updatedPlanData });
        }
      }

      showSnackbar('Alla karaktärsbilder har genererats!', 'success');
    } catch (error) {
      console.error('Error in character image generation process:', error);
      showSnackbar('Ett fel uppstod i bildgenereringsprocessen', 'error');
    } finally {
      setIsGeneratingImages(false);
    }
  };
  const handleGenerateStoryImages = async () => {
    setIsGeneratingStoryImages(true);
    try {
      // Create a copy of current planData to update
      let updatedPlanData = { ...planData };
      updatedPlanData.additionalStoryImages = updatedPlanData.additionalStoryImages || [];

      // Generate main story image first
      const mainStoryPrompt = `Create an image representing the story theme "${planData.storyTitle}". Do not include any text in the image. ${imageInstructions}`;

      // Use a try-catch block to generate each image sequentially
      try {
        const mainImageUrl = await generateAndProcessImage(mainStoryPrompt, user.uid, {
          size: '1024x1024',
          model: 'dall-e-3',
          quality: 'standard',
        });

        // Update the state for the main story image
        updatedPlanData = {
          ...updatedPlanData,
          storyImage: mainImageUrl,
        };
        setPlanData(updatedPlanData); // Update state with the new story image
        setLessonPlanKey(prevKey => prevKey + 1);
        showSnackbar('Huvudbild har genererats', 'success');
      } catch (error) {
        console.error('Error generating main story image:', error);
        showSnackbar('Kunde inte generera huvudbild', 'warning');
        updatedPlanData.storyImage = '/api/placeholder/1024/1024';
        setPlanData(updatedPlanData);
      }

      // Create prompts based on different aspects of the story
      const scenePrompts = [
        `Create an establishing shot showing the environment "${planData.where[0]}" for the story "${planData.storyTitle}". Style: cinematic, engaging, no text.`,
        //`Create an image showing a character "${planData.whoOrWhich[0].name}" in the story "${planData.storyTitle}". Style: character-focused, emotive, no text.`,
        `Create a dramatic scene showing "${planData.what[0]}" from the story "${planData.storyTitle}". Style: action-oriented, dynamic, no text.`,
        // `Create a climactic scene combining elements of "${planData.where[0]}" and "${planData.what[1]}" from the story. Style: dramatic, atmospheric, no text.`,
      ];

      // Generate additional story scene images sequentially
      for (let i = 0; i < scenePrompts.length; i++) {
        const prompt = scenePrompts[i];

        try {
          const imageUrl = await generateAndProcessImage(prompt, user.uid, {
            size: '1024x1024',
            model: 'dall-e-3',
            quality: 'standard',
          });

          // Update the specific image in additionalStoryImages array
          updatedPlanData.additionalStoryImages[i] = imageUrl;

          // Update state after each image generation
          setPlanData({ ...updatedPlanData });
          setLessonPlanKey(prevKey => prevKey + 1);

          showSnackbar(`Genererat scenbild ${i + 1} av ${scenePrompts.length}`, 'success');
        } catch (error) {
          console.error(`Error generating scene image ${i + 1}:`, error);
          showSnackbar(`Kunde inte generera scenbild ${i + 1}`, 'warning');

          // Add placeholder if image generation fails
          updatedPlanData.additionalStoryImages[i] = '/api/placeholder/1024/1024';
          setPlanData({ ...updatedPlanData });
        }
      }

      showSnackbar('Alla berättelsebilder har genererats!', 'success');
    } catch (error) {
      console.error('Error in story image generation process:', error);
      showSnackbar('Ett fel uppstod i bildgenereringsprocessen', 'error');
    } finally {
      setIsGeneratingStoryImages(false);
    }
  };

  const handleGeneratePlan = async () => {
    setIsGeneratingPlan(true);
    const gradeInstructionsText = gradeInstructions[grade];
    try {
      const generateTheme = lessonTheme === 'generateWithAi';
      const finalTheme = generateTheme
        ? '[RANDOM]'
        : lessonTheme === 'customTheme'
          ? customTheme
          : lessonTheme;

      const response = await callApi(
        [
          {
            role: 'system',
            content: `You are an assistant that generates lesson plans in  ${language}. Prioritize novelty and uniqueness in each generation. Avoid repeating themes, characters, or plot elements from previous responses.`,
          },
          {
            role: 'user',
            content: `Generate a lesson plan for a narrative text lesson in  ${language}. ${gradeInstructionsText}. The theme should be ${finalTheme}. It should contain the following details:
          Goal: ${goal}
          Develop: ${develop}
          Additional Info: ${additionalInfo}
          Include storyTitle, whoOrWhich (5 suggestions), where (5 suggestions), and what (5 suggestions based on whoOrWhich).
          Example of expected JSON response:
          {
            "storyTitle": "Den mystiska ön",
            "whoOrWhich": [
              "En spion",
              "En häxa",
              "Ett monster",
              "En skolklass",
              "En flicka som bor på barnhem"
            ],
            "where": [
              "På en övergiven ö mitt i havet",
              "I ett hemligt underjordiskt laboratorium",
              "I en gammal herrgård full av gömda rum",
              "På en flotte som driver på ett stormigt hav",
              "I en mystisk djungel med okända varelser"
            ],
            "what": [
              "Upptäcker en portal till en annan dimension",
              "Avslöjar en konspiration som hotar hela världen",
              "Löser en uråldrig gåta som kan förändra historien",
              "Räddar en utrotningshotad art från tjuvjägare",
              "Återförenar en familj som varit separerad i årtionden"
            ]
          }`,
          },
        ],
        4000,
        true,
        user.uid
      );

      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      const whoOrWhichObjects = data.whoOrWhich.map(character => ({
        name: character,
        imageUrl: null,
      }));

      setPlanData({
        ...data,
        whoOrWhich: whoOrWhichObjects,
      });

      setLessonPlanKey(prevKey => prevKey + 1);
    } catch (error) {
      console.error('Error generating lesson plan:', error);
    } finally {
      setIsGeneratingPlan(false);
    }
  };

  const renderLessonPlan = () => {
    if (!planData) return null;

    const markdownBefore = `
# En berättande text: ${planData.storyTitle}


### Karaktärer (Vem eller vilka)
    `;

    const markdownAfter = `
${planData.whoOrWhich.map((item, index) => `${index + 1}. ${item.name}`).join('\n')}


### Miljöer (Var)
${planData.where.map((item, index) => `${index + 1}. ${item}`).join('\n')}
    `;

    const storyImagesMarkdown = planData.additionalStoryImages
      ? `
${planData.additionalStoryImages
  .map(
    (imageUrl, index) =>
      `<img src="${imageUrl}" alt="Bild ${index + 1}" width="400" height="300" style="object-fit: cover; margin: 10px; border-radius:7px" />`
  )
  .join('\n')}
`
      : '';

    const markdownAfterImages = `
### Handlingsidéer (Vad)

${planData.what.map((item, index) => `${index + 1}. ${item}`).join('\n')}
    `;

    return (
      <>
        <ReactMarkdown>{markdownBefore}</ReactMarkdown>
        <Box sx={{ mt: 8 }}>
          <CharacterMindMap
            characters={planData.whoOrWhich}
            storyImage={planData.storyImage}
            themeColor={themeColor}
            isGeneratingImages={isGeneratingImages}
            isGeneratingStoryImage={isGeneratingStoryImages}
            generatedCharacterImages={generatedCharacterImages}
          />
        </Box>
        <ReactMarkdown>{markdownAfter}</ReactMarkdown>
        <div dangerouslySetInnerHTML={{ __html: storyImagesMarkdown }} />
        <ReactMarkdown>{markdownAfterImages}</ReactMarkdown>
      </>
    );
  };

  return (
    <>
      <Box sx={styles.container}>
        <Box
          sx={{
            ...styles.innerContainer,
            width: { xs: '100%', md: '87%' },
            margin: '0 auto',
          }}
        >
          <Typography
            variant='h1'
            gutterBottom
            sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
          >
            Berättande text
          </Typography>
          <InfoBox
            icon={HistoryEduIcon}
            title='Powerpoint Instructions'
            instructions={[
              'Eleven skriver en berättelse baserad på genererade karaktärer och miljöer',
              'Lägg till fantasieggande AI-genererade bilder för karaktärer och miljöer',
              'Skriv ut lektionen',
            ]}
            theme={theme}
            styles={styles}
          />
          <Box sx={{ ...styles.freshBox, padding: '30px' }}>
            <Box>
              <ThemeSelector
                value={lessonTheme}
                onChange={e => setLessonTheme(e.target.value)}
                theme={theme}
                customTheme={customTheme}
                onCustomThemeChange={e => setCustomTheme(e.target.value)}
              />
            </Box>
            <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
              Årskurs:
            </Typography>
            <Box sx={{ mt: 1, mb: 3 }}>
              <GradeSelector grade={grade} setGrade={setGrade} />
            </Box>

            <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
              Språk:
            </Typography>
            <Box sx={{ mt: 1, mb: 3 }}>
              <LanguageSelector language={language} setLanguage={setLanguage} />
            </Box>

            {/*
            <Box>
              <Button
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                onClick={handleGenerateInfo}
                disabled={isGeneratingInfo}
                sx={{ mb: 2 }}
                startIcon={
                  !isGeneratingInfo ? (
                    <KeyboardDoubleArrowDownIcon style={{ color: '#fff' }} />
                  ) : (
                    <CircularProgress size={16} />
                  )
                }
              >
                {isGeneratingInfo ? 'Genererar...' : 'Generera mål & syfte'}
              </Button>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500' }}>
                Mål för lektionen:
              </Typography>
            </Box>
            <TextField
              fullWidth
              multiline
              rows={3}
              sx={{ mt: 1 }}
              margin='normal'
              placeholder='T.ex. Att skriva en berättelse med början, mitten och avslut. Självständigt eller med hjälp.'
              value={goal}
              onChange={e => setGoal(e.target.value)}
            />
            <Box sx={{ mt: 3 }}>
              <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500' }}>
                Jag vill utveckla elevernas:
              </Typography>
            </Box>
            <TextField
              fullWidth
              multiline
              rows={3}
              sx={{ mt: 1 }}
              margin='normal'
              placeholder='T.ex. tilltro till sin språkliga förmåga genom att de får fantisera och använda språket fritt.'
              value={develop}
              onChange={e => setDevelop(e.target.value)}
            />
            <Box sx={{ mt: 3 }}>
              <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500' }}>
                Valfritt tillägg:
              </Typography>
            </Box>
            <TextField
              fullWidth
              multiline
              rows={3}
              sx={{ mt: 1, mb: 4 }}
              margin='normal'
              placeholder='t.ex. Jag kommer INTE rätta stavning eller andra skrivregler. Fokus är endast på berättelsens innehåll.'
              value={additionalInfo}
              onChange={e => setAdditionalInfo(e.target.value)}
            />
            <Box sx={{ mt: 3 }}>
              <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500' }}>
                Generera lektionsplan
              </Typography>
            </Box>

            */}
            <Box>
              <Button
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                onClick={handleGeneratePlan}
                disabled={isGeneratingPlan}
                sx={{ mt: 2, mb: 2 }}
                startIcon={
                  !isGeneratingPlan ? (
                    <GenerateIcon style={{ color: '#fff' }} />
                  ) : (
                    <CircularProgress size={16} />
                  )
                }
              >
                {isGeneratingPlan ? 'Genererar...' : 'Generera lektion'}
              </Button>
            </Box>
            {planData && (
              <>
                <Divider sx={{ mt: 3, mb: 3 }} />

                <Button
                  variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                  onClick={() => handleImageButtonClick('topic')}
                  disabled={isGeneratingImages}
                  sx={{ mt: 2, mb: 2 }}
                  startIcon={
                    !isGeneratingImages ? (
                      <ImageIcon style={{ color: '#fff' }} />
                    ) : (
                      <CircularProgress size={16} />
                    )
                  }
                >
                  {isGeneratingImages ? 'Genererar...' : 'Generera karaktärsbilder'}
                </Button>
                <Button
                  variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                  onClick={() => handleImageButtonClick('facts')}
                  disabled={isGeneratingStoryImages}
                  sx={{ mt: 2, mb: 2, ml: 2 }}
                  startIcon={
                    !isGeneratingStoryImages ? (
                      <ImageIcon style={{ color: '#fff' }} />
                    ) : (
                      <CircularProgress size={16} />
                    )
                  }
                >
                  {isGeneratingStoryImages ? 'Genererar...' : 'Generera berättelsebilder'}
                </Button>

                <ImageGenerationModal
                  open={showImageModal}
                  onClose={handleCloseModal}
                  onGenerate={handleGenerateWithInstructions}
                  isGenerating={
                    imageGenerationType === 'topic' ? isGeneratingImages : isGeneratingStoryImages
                  }
                  instructions={imageInstructions}
                  setInstructions={setImageInstructions}
                  generationType={imageGenerationType}
                  theme={theme}
                />

                <Button
                  variant='contained'
                  color='primary'
                  onClick={() =>
                    handlePrintNarrativeLesson(planData, goal, develop, additionalInfo, themeColor)
                  }
                  sx={{ ml: 2 }}
                  startIcon={<PrintIcon />}
                >
                  Skriv ut
                </Button>

                <Box sx={{ mt: 3 }}>
                  <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500' }}>
                    Färgtema:
                  </Typography>

                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 3 }}>
                    <div
                      style={{ display: 'inline-block', position: 'relative', marginRight: '10px' }}
                    >
                      <div
                        style={{
                          width: '60px',
                          height: '20px',
                          borderRadius: '7px',
                          backgroundColor: themeColor,
                          cursor: 'pointer',
                        }}
                      />
                      <input
                        type='color'
                        value={themeColor}
                        onChange={e => setThemeColor(e.target.value)}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          opacity: 0,
                          cursor: 'pointer',
                        }}
                      />
                    </div>

                    <Typography variant='body2'>{themeColor}</Typography>
                  </Box>
                </Box>

                <Fade in={true} key={lessonPlanKey} timeout={1000}>
                  <Box
                    sx={{
                      mt: 8,
                      '& p': {
                        lineHeight: '27px',
                      },
                      '& li': {
                        marginBottom: '12px',
                        lineHeight: '25px',
                        '& strong': {
                          fontWeight: '600',
                        },
                      },
                      '& h1': {
                        color: themeColor,
                      },
                      '& h2': {
                        marginTop: '60px',
                        marginBottom: '0px',
                        color: themeColor,
                      },
                      '& h3': {
                        marginTop: '40px',
                        marginBottom: '0px',
                        color: themeColor,
                      },
                      '& hr': {
                        height: '1px',
                        border: 'none',
                        backgroundColor: theme => theme.palette.divider,
                        marginTop: '50px',
                        marginBottom: '50px',
                      },
                      '& ul, & ol': {
                        '& li::marker': {
                          color: themeColor,
                        },
                      },
                    }}
                  >
                    {renderLessonPlan()}
                  </Box>
                </Fade>
              </>
            )}
          </Box>
        </Box>
        <NarrativeLessonStoryGenerator
          planData={planData}
          themeColor={themeColor}
          theme={theme}
          styles={styles}
          user={user}
        />
      </Box>
    </>
  );
};

export default NarrativeLesson;
