import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
  Chip,
  Card,
  CardContent,
} from '@mui/material';

import { StyledButton, GradientBackground } from './StyledComponents';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';

import { motion } from 'framer-motion';

import LightModeIcon from '@mui/icons-material/LightMode';
import FAQSection from './FAQSection';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { getAIModelImage } from './app/TestComponents2';
import UniversitySection from './UniversitySection';
import MediaSection from './MediaSection';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FeedbackIcon from '@mui/icons-material/Feedback';

import WebIcon from '@mui/icons-material/Web';

const AboutPage = ({ toggleDarkMode, isDark }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const cardStyles = {
    height: '100%',
    boxShadow: 'none',
    borderRadius: 4,
    padding: 3,
  };

  const iconBoxStyles = {
    marginBottom: 3,
  };

  const titleStyles = {
    fontWeight: 600,
    marginBottom: 2,
    lineHeight: 1.2,
    color: '#1A1A1A',
  };

  const descriptionStyles = {
    color: '#4A5568',
  };

  const features = [
    {
      icon: <AccessTimeIcon sx={{ fontSize: 40, color: '#6B46C1' }} />,
      title: 'Time efficiency & streamlined grading',
      description:
        'Simplify and speed up the grading process with intuitive features, allowing you to focus on impactful teaching.',
      bgcolor: '#FAF5FF',
    },
    {
      icon: <EmojiEventsIcon sx={{ fontSize: 40, color: '#ECC94B' }} />,
      title: 'Consistency & fairness',
      description:
        'Ensure fair and accurate grading by eliminating human bias, all while aligning with your curriculum standards.',
      bgcolor: '#FAF5FF',
    },
    {
      icon: <WebIcon sx={{ fontSize: 40, color: '#6B46C1' }} />,
      title: 'Actionable insights & feedback',
      description:
        'Receive detailed feedback and growth insights on each essay and seamlessly share them with your students.',
      bgcolor: '#F3E8FF',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{`Om oss | Studera.ai`}</title>
        <meta
          name='description'
          content='Studera.ai använder AI för att revolutionera lärande. Vi erbjuder AI-verktyg för lärare, pedagoger, elever och studenter.'
        />
      </Helmet>

      <Header />

      <GradientBackground className='gradient-background'>
        <Container
          sx={{ pt: { xs: 10, md: 20 }, pb: 15, position: 'relative', textAlign: 'center' }}
        >
          <Grid container spacing={6} alignItems='center'>
            <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                  <Chip
                    label={
                      <span>
                        Studera.ai{' '}
                        <span style={{ opacity: 0.7 }}>
                          &nbsp;•&nbsp; Revolutionerar lärande med AI
                        </span>
                      </span>
                    }
                    sx={{
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      color: 'white',
                      fontWeight: '600',
                      fontSize: '0.8rem',
                      padding: '2px 14px',

                      borderRadius: '16px',
                      '& .MuiChip-label': {
                        padding: '0 4px',
                      },
                    }}
                  />
                </Box>

                <Typography
                  variant='h2'
                  component='h1'
                  gutterBottom
                  sx={{
                    fontWeight: 900,
                    letterSpacing: -1,
                    fontSize: { xs: '2.5rem', md: '3.35rem' },
                  }}
                >
                  Om Studera.ai
                </Typography>
                <Typography
                  variant='h5'
                  sx={{
                    mb: 4,
                    opacity: 0.8,
                    fontSize: '1.3rem',
                    width: { xs: '100%', md: '64%' },
                    margin: '0 auto',
                    fontWeight: '500',
                    lineHeight: '31px',
                    display: 'block',
                    mt: 4,
                  }}
                >
                  Vi använder AI för att revolutionera lärande och göra det mer effektivt,
                  engagerande och tillgängligt för alla. Vi erbjuder AI-verktyg för lärare,
                  pedagoger, elever och studenter.
                </Typography>

                <Box sx={{ textAlign: 'center', mt: 7 }}>
                  <Link to='/app' style={{ textDecoration: 'none' }}>
                    <StyledButton
                      variant='blue'
                      color='primary'
                      size='large'
                      endIcon={<ArrowForwardIcon />}
                    >
                      Testa Studera.ai nu
                    </StyledButton>
                  </Link>
                </Box>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </GradientBackground>

      {/*
Moonshine: lägg till på startsidan
      <Box sx={{ maxWidth: '1200px', margin: '0 auto', padding: 2 }}>
        <Grid container spacing={3}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ ...cardStyles, backgroundColor: feature.bgcolor }}>
                <CardContent sx={{ padding: 0 }}>
                  <Box sx={iconBoxStyles}>{feature.icon}</Box>
                  <Typography variant='h5' component='h2' sx={titleStyles}>
                    {feature.title}
                  </Typography>
                  <Typography sx={descriptionStyles}>{feature.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
*/}
      {/*
      <Container id='om-studera-ai' sx={{ my: 15 }}>
        <Grid container spacing={4}>

          <Grid item xs={12} md={6}>
            <Typography
              variant='h3'
              gutterBottom
              sx={{ fontWeight: 'bold', fontSize: '2.3em', mb: 4, mt: 0 }}
            >
              För studenter
            </Typography>

            <Typography
              variant='h4'
              gutterBottom
              sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 6 }}
            >
              Revolutionera ditt lärande
            </Typography>
            <Typography
              variant='body1'
              sx={{
                mb: 4,
                color: 'text.secondary',
                fontWeight: '400',
                fontSize: '1.15rem',
                lineHeight: '1.6',
              }}
            >
              Med Studera.AI får du tillgång till nästa generations studiemetoder. Våra AI-verktyg
              skapar personliga quiz, flashcards och studieuppgifter direkt från ditt kursmaterial.
              Du kan till och med föra dialoger med historiska personer, ha djupgående diskussioner
              med AI-lärare, och få direkt hjälp inom allt från språk till matematik.
            </Typography>

            <Typography
              variant='h4'
              gutterBottom
              sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 6 }}
            >
              Lär dig på ditt sätt
            </Typography>
            <Typography
              variant='body1'
              sx={{
                mb: 4,
                color: 'text.secondary',
                fontWeight: '400',
                fontSize: '1.15rem',
                lineHeight: '1.6',
              }}
            >
              Oavsett hur du lär dig bäst har vi verktyg som passar just dig. Med vår AI-teknologi
              kan du studera i din egen takt, få omedelbar feedback, och upptäcka nya sätt att
              förstå komplexa ämnen. Vi anpassar oss efter dina behov och hjälper dig att nå dina
              studiemål snabbare och effektivare än någonsin tidigare.
            </Typography>

            <Typography
              variant='h4'
              gutterBottom
              sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 6 }}
            >
              Förstå på djupet
            </Typography>
            <Typography
              variant='body1'
              sx={{
                mb: 4,
                color: 'text.secondary',
                fontWeight: '400',
                fontSize: '1.15rem',
                lineHeight: '1.6',
              }}
            >
              Hos oss handlar det inte om genvägar - vi ger dig verktygen för att verkligen förstå
              ditt ämne. Vår AI hjälper dig att tänka kritiskt, se sammanhang och bygga en solid
              kunskapsgrund. Du får intelligent studiestöd som utmanar dig att tänka själv och
              utveckla din förståelse, inte bara memorera fakta.
            </Typography>
          </Grid>


          <Grid item xs={12} md={6}>
            <Typography
              variant='h3'
              gutterBottom
              sx={{ fontWeight: 'bold', fontSize: '2.3em', mb: 4, mt: 0 }}
            >
              För lärare & pedagoger
            </Typography>

            <Typography
              variant='h4'
              gutterBottom
              sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 6 }}
            >
              Effektivisera din undervisning
            </Typography>
            <Typography
              variant='body1'
              sx={{
                mb: 4,
                color: 'text.secondary',
                fontWeight: '400',
                fontSize: '1.15rem',
                lineHeight: '1.6',
              }}
            >
              Våra AI-verktyg revolutionerar hur du planerar och genomför din undervisning. Skapa
              engagerande lektionsplaner på minuter istället för timmar, generera skräddarsydda
              övningar för olika kunskapsnivåer, och få intelligent hjälp med bedömning och
              feedback. Med Studera.ai blir administrativa uppgifter enklare så du kan fokusera på
              det du gör bäst - att inspirera och undervisa.
            </Typography>

            <Typography
              variant='h4'
              gutterBottom
              sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 6 }}
            >
              Individanpassa lärandet
            </Typography>
            <Typography
              variant='body1'
              sx={{
                mb: 4,
                color: 'text.secondary',
                fontWeight: '400',
                fontSize: '1.15rem',
                lineHeight: '1.6',
              }}
            >
              Med våra verktyg kan du enkelt skapa personliga lärstigar för varje elev.
              AI-assistenten hjälper dig att identifiera kunskapsluckor, föreslå anpassade övningar,
              och ge realtidsfeedback på elevernas framsteg. Du får konkreta insikter om varje elevs
              utveckling och kan ge rätt stöd vid rätt tillfälle.
            </Typography>

            <Typography
              variant='h4'
              gutterBottom
              sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 6 }}
            >
              Framtidens klassrum
            </Typography>
            <Typography
              variant='body1'
              sx={{
                mb: 4,
                color: 'text.secondary',
                fontWeight: '400',
                fontSize: '1.15rem',
                lineHeight: '1.6',
              }}
            >
              Integrera AI sömlöst i din undervisning och skapa ett modernt, interaktivt klassrum.
              Våra verktyg ger dig 'superkrafter' som lärare - från automatiserad rättning och
              analys till intelligenta läromedel som anpassar sig efter elevernas behov. Tillsammans
              skapar vi en ny era av utbildning där teknologi och pedagogik samverkar för maximalt
              lärande.
            </Typography>
          </Grid>
        </Grid>

        <Box sx={{ textAlign: 'center', mt: 8 }}>
          <Link to='/app' style={{ textDecoration: 'none' }}>
            <StyledButton
              variant='contained'
              color='primary'
              size='large'
              endIcon={<ArrowForwardIcon />}
            >
              Börja din resa nu
            </StyledButton>
          </Link>
        </Box>
      </Container>


*/}

      <Container id='om-studera-ai' sx={{ my: 15, textAlign: 'center' }}>
        <Typography
          variant='h3'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '2.3em', mb: 4, mt: 0 }}
        >
          En ny era för lärande med AI
        </Typography>
        <Typography
          variant='body1'
          sx={{
            mb: 4,
            color: 'text.secondary',
            fontWeight: '400',
            fontSize: '1.15rem',
            lineHeight: '1.6',
          }}
        >
          Studera.AI föddes ur en vision om att revolutionera hur vi närmar oss lärande i en modern
          digital värld som i allt större utsträckning präglas av artificiell intelligens. Vi har
          skapat ett dynamiskt ekosystem där AI-drivna verktyg samverkar sömlöst för att stödja
          varje aspekt av studentens studieresa. Från AI-genererade quiz och flashcards baserade på
          ditt studiematerial, till simulerade debatter med historiska figurer och djupgående
          dialoger med specialiserade AI-lärare för att lära sig olika språk och ämnen som matematik
          och historia.
        </Typography>

        <Typography
          variant='h3'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '2.3em', mb: 4, mt: 10 }}
        >
          Tillgänglig utbildning för alla
        </Typography>
        <Typography
          variant='body1'
          sx={{
            mb: 4,
            color: 'text.secondary',
            fontWeight: '400',
            fontSize: '1.15rem',
            lineHeight: '1.6',
          }}
        >
          Vår vision är att göra utbildning tillgänglig och anpassad för alla. Vi tror att alla
          studenter, oavsett bakgrund eller mål, ska ha tillgång till verktyg som hjälper dem att nå
          sin fulla potential. Genom att integrera avancerad AI-teknologi med pedagogiska metoder
          skapar vi en lärandemiljö som inspirerar och motiverar till kontinuerlig utveckling.
        </Typography>
        <Typography
          variant='h3'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '2.3em', mb: 4, mt: 10 }}
        >
          AI som ett stöd, inte en genväg
        </Typography>
        <Typography
          variant='body1'
          sx={{
            mb: 4,
            color: 'text.secondary',
            fontWeight: '400',
            fontSize: '1.15rem',
            lineHeight: '1.6',
          }}
        >
          Studera.AI handlar om att ge eleverna verktyg för att lyckas, inte för att låta AI göra
          allt arbete åt dem. Vår filosofi är enkel: AI ska hjälpa till att förstärka elevens
          förståelse och uppmuntra dem till att tänka kritiskt och kreativt. Genom att använda våra
          AI-verktyg på ett smart och balanserat sätt, kan eleverna ta kontroll över sitt lärande
          och nå längre än de trodde var möjligt – med teknologin som ett stöd, inte en ersättning.
        </Typography>

        <Typography
          variant='h3'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '2.3em', mb: 4, mt: 10 }}
        >
          AI-verktyg för lärare och pedagoger
        </Typography>
        <Typography
          variant='body1'
          sx={{
            mb: 4,
            color: 'text.secondary',
            fontWeight: '400',
            fontSize: '1.15rem',
            lineHeight: '1.6',
          }}
        >
          På Studera.ai är vi dedikerade till att stödja inte bara studenter, utan även lärare och
          pedagoger i deras viktiga arbete. Vi utvecklar innovativa AI-verktyg som är specifikt
          utformade för att effektivisera och förbättra undervisningsprocessen. Våra lösningar
          hjälper lärare att skapa engagerande lektionsplaner på nolltid, automatisera tidskrävande
          uppgifter som betygsättning baserat på anpassningsbara bedömningsmatriser, och generera
          inspirerande lektionsmaterial.
        </Typography>
        <Typography
          variant='body1'
          sx={{
            mb: 4,
            color: 'text.secondary',
            fontWeight: '400',
            fontSize: '1.15rem',
            lineHeight: '1.6',
          }}
        >
          Genom att utnyttja kraften i AI kan vi frigöra värdefull tid för lärare, så att de kan
          fokusera på det som verkligen betyder något - att inspirera och vägleda sina elever. Våra
          verktyg är utformade för att vara intuitiva och anpassningsbara, vilket gör det möjligt
          för lärare att skräddarsy sin undervisning efter varje elevs unika behov. Från att skapa
          personliga inlärningsvägar till att ge realtidsåterkoppling, ger våra AI-assistenter
          lärare möjlighet att revolutionera sin undervisningsmetodik.
        </Typography>
        <Typography
          variant='body1'
          sx={{
            mb: 4,
            color: 'text.secondary',
            fontWeight: '400',
            fontSize: '1.15rem',
            lineHeight: '1.6',
          }}
        >
          Med Studera.ai:s verktyg kan lärare nu enkelt integrera AI-teknologi i klassrummet, vilket
          skapar en mer dynamisk och interaktiv lärmiljö. Vår vision är att ge lärare superkrafter,
          så att de kan inspirera nästa generation av tänkare, innovatörer och ledare. Genom att
          kombinera pedagogisk expertis med AI:s möjligheter banar vi väg för en ny era av
          utbildning - en era där teknologi och mänsklig insikt samverkar för att skapa de bästa
          möjliga förutsättningarna för lärande och utveckling.
        </Typography>

        <Link to='/app' style={{ textDecoration: 'none' }}>
          <StyledButton
            variant='contained'
            color='primary'
            size='large'
            endIcon={<ArrowForwardIcon />}
          >
            Börja din resa nu
          </StyledButton>
        </Link>
      </Container>

      <UniversitySection bgcolor='background.paper' showHeadline={true} />

      <MediaSection bgcolor='background.default' />

      <Footer />

      {!isMobile && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 9999,
          }}
        >
          <IconButton onClick={toggleDarkMode}>
            {isDark ? <LightModeIcon /> : <DarkModeIcon />}
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default AboutPage;
