import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Divider,
  Tooltip,
  IconButton,
  TextField,
  Avatar,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InfoBox from './InfoBox';
import ImageIcon from '@mui/icons-material/Image';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { handlePrintIdioms } from './printFunctions';
import { callApi } from './api';
import { generateAndProcessImage } from './functions';
import { useSnackbar } from './SnackbarHandler';
import ImageGenerationModal from './ImageGenerationModal';
import PrintIcon from '@mui/icons-material/Print';
import ProgressBar from './ProgressBar';
import Voice from './Voice';
import LanguageSelector from './LanguageSelector';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { FourSquare } from 'react-loading-indicators';

const IdiomsLesson = ({ theme, styles, user }) => {
  const [idiomCount, setIdiomCount] = useState(4);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [lessonData, setLessonData] = useState(null);
  const [progress, setProgress] = useState(0);
  const { showSnackbar } = useSnackbar();
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const [selectedIdiomIndex, setSelectedIdiomIndex] = useState(null);
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [language, setLanguage] = useState('svenska');

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingSubtitle, setIsEditingSubtitle] = useState(false);
  const [titleText, setTitleText] = useState('Idiomatiska uttryck');
  const [editingExampleId, setEditingExampleId] = useState(null);
  const [editingIdiomId, setEditingIdiomId] = useState(null);
  const [editingMeaningId, setEditingMeaningId] = useState(null);

  const getCurrentWeek = () => {
    const now = new Date();
    const onejan = new Date(now.getFullYear(), 0, 1);
    const week = Math.ceil(
      ((now.getTime() - onejan.getTime()) / 86400000 + onejan.getDay() + 1) / 7
    );
    return week;
  };

  const [subtitleText, setSubtitleText] = useState(`Vecka ${getCurrentWeek()}`);

  const [isEditingStory, setIsEditingStory] = useState(false);

  const handleEditStory = newText => {
    setLessonData(prevData => ({
      ...prevData,
      story: newText,
    }));
    setIsEditingStory(false);
    showSnackbar('Berättelsen har uppdaterats', 'success');
  };

  const handleEditIdiom = (index, newText, field) => {
    setLessonData(prevData => ({
      ...prevData,
      idioms: prevData.idioms.map((idiom, i) =>
        i === index ? { ...idiom, [field]: newText } : idiom
      ),
    }));
    if (field === 'idiom') setEditingIdiomId(null);
    else if (field === 'meaning') setEditingMeaningId(null);
    else if (field === 'exampleSentence') setEditingExampleId(null);
    showSnackbar(
      `${field === 'idiom' ? 'Idiomet' : field === 'meaning' ? 'Betydelsen' : 'Exempelmeningen'} har uppdaterats`,
      'success'
    );
  };

  const simulateProgress = (startProgress, endProgress, duration, setProgressFn) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgressFn(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateIdioms = async () => {
    setIsGenerating(true);
    setProgress(0);
    let stopProgressSimulation = null;

    try {
      stopProgressSimulation = simulateProgress(0, 90, 15000, setProgress);

      const response = await callApi(
        [
          {
            role: 'system',
            content: `Du är en språklärare som specialiserar sig på idiomatiska uttryck och metaforer.`,
          },
          {
            role: 'user',
            content: `Skapa en lektion med ${idiomCount} idiomatiska uttryck på ${language}. 

      Returnera ett JSON-objekt med följande struktur:
      {
        "title": "Idiomatiska uttryck",
        "instructions": "En kort instruktion om hur man ska arbeta med idiomen",
        "idioms": [
          {
            "idiom": "idiomatiskt uttryck",
            "meaning": "uttryckets bokstavliga betydelse på ${language}",
            "exampleSentence": "En mening som använder uttrycket i kontext",
            "imagePrompt": "beskrivning för bildgenerering som illustrerar uttrycket",
            "imageUrl": null
          }
        ],
        "story": "En kort berättelse (12 meningar) som använder de idiomatiska uttrycken på ett naturligt sätt i en sammanhängande historia"
      }`,
          },
        ],
        8000,
        true,
        user.uid
      );

      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setProgress(100);
      await new Promise(resolve => setTimeout(resolve, 800));

      setLessonData(data);

      showSnackbar('Idiomatiska uttryck har genererats!', 'success');
    } catch (error) {
      console.error('Error generating idioms:', error);
      showSnackbar('Ett fel uppstod när idiomen skulle genereras', 'error');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleImageButtonClick = () => {
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
    setSelectedIdiomIndex(null);
  };

  const handleGenerateSpecificImage = async index => {
    setIsGeneratingImages(true);
    setLoadingImageIndex(index);
    handleCloseModal();

    try {
      const idiom = lessonData.idioms[index];
      const imageUrl = await generateAndProcessImage(
        `${idiom.imagePrompt}. ${imageInstructions}`,
        user.uid
      );

      const updatedIdioms = [...lessonData.idioms];
      updatedIdioms[index] = { ...idiom, imageUrl };

      setLessonData({
        ...lessonData,
        idioms: updatedIdioms,
      });

      showSnackbar('Bilden har genererats!', 'success');
    } catch (error) {
      console.error('Error generating image:', error);
      showSnackbar('Ett fel uppstod när bilden skulle genereras', 'error');
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleGenerateImages = async () => {
    if (!lessonData) return;

    setIsGeneratingImages(true);
    handleCloseModal();

    let successCount = 0;
    const totalImages = lessonData.idioms.length;

    try {
      const updatedIdioms = await Promise.all(
        lessonData.idioms.map(async idiom => {
          try {
            const imageUrl = await generateAndProcessImage(
              `${idiom.imagePrompt}. ${imageInstructions}`,
              user.uid
            );
            successCount++;
            return { ...idiom, imageUrl };
          } catch (error) {
            console.error(`Error generating image for ${idiom.idiom}:`, error);
            return idiom;
          }
        })
      );

      setLessonData({
        ...lessonData,
        idioms: updatedIdioms,
      });

      if (successCount === totalImages) {
        showSnackbar('Alla bilder genererades framgångsrikt!', 'success');
      } else {
        showSnackbar(`${successCount}/${totalImages} bilder genererades framgångsrikt.`, 'warning');
      }
    } finally {
      setIsGeneratingImages(false);
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: '100%', md: '87%' },
          margin: '0 auto',
        }}
      >
        <Typography
          variant='h1'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
        >
          Idiomatiska uttryck
        </Typography>

        <InfoBox
          icon={FormatQuoteIcon}
          title='Instruktioner för Idiomatiska uttryck'
          instructions={[
            'Välj antalet idiom att generera',
            'Välj språk för idiomen',
            'Få betydelse och exempel för varje uttryck',
            'Generera illustrativa bilder',
          ]}
          theme={theme}
          styles={styles}
        />

        <Box sx={{ ...styles.freshBox, padding: '30px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mb: 1 }}>
                Antal idiom:
              </Typography>
              <FormControl fullWidth>
                <Select value={idiomCount} onChange={e => setIdiomCount(e.target.value)}>
                  {[2, 4, 6, 8, 10].map(num => (
                    <MenuItem key={num} value={num}>
                      {num} idiom
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mb: 1 }}>
                Språk:
              </Typography>
              <LanguageSelector language={language} setLanguage={setLanguage} />
            </Grid>
          </Grid>

          <Button
            variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
            onClick={handleGenerateIdioms}
            disabled={isGenerating}
            sx={{ mt: 6 }}
            startIcon={isGenerating ? <CircularProgress size={16} /> : <GenerateIcon />}
          >
            {isGenerating ? 'Genererar...' : 'Generera idiom'}
          </Button>

          {isGenerating && (
            <Box sx={{ mt: 7 }}>
              <ProgressBar progress={progress} label='Genererar idiomatiska uttryck...' />
            </Box>
          )}

          {lessonData && (
            <Box sx={{ mt: 4 }}>
              <Divider sx={{ mt: 3, mb: 4 }} />

              <Button
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                onClick={handleImageButtonClick}
                disabled={isGeneratingImages}
                sx={{ mr: 2 }}
                startIcon={isGeneratingImages ? <CircularProgress size={16} /> : <ImageIcon />}
              >
                {isGeneratingImages ? 'Genererar bilder...' : 'Generera bilder'}
              </Button>

              <Button
                variant='contained'
                onClick={() => handlePrintIdioms(lessonData, theme, titleText, subtitleText)}
                startIcon={<PrintIcon />}
              >
                Skriv ut
              </Button>

              <ImageGenerationModal
                open={showImageModal}
                onClose={handleCloseModal}
                onGenerate={
                  selectedIdiomIndex !== null
                    ? () => handleGenerateSpecificImage(selectedIdiomIndex)
                    : handleGenerateImages
                }
                isGenerating={isGeneratingImages}
                instructions={imageInstructions}
                setInstructions={setImageInstructions}
                generationType='idioms'
                theme={theme}
              />

              <Box sx={{ mt: 7 }}>
                {/* Title */}
                {isEditingTitle ? (
                  <TextField
                    value={titleText}
                    onChange={e => setTitleText(e.target.value)}
                    onBlur={() => setIsEditingTitle(false)}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        setIsEditingTitle(false);
                      }
                    }}
                    autoFocus
                    fullWidth
                    variant='standard'
                    sx={{
                      backgroundColor: 'transparent',
                      '& .MuiInputBase-input': {
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fontSize: '2.6em',
                        marginBottom: '1.2rem',
                        marginTop: '1.5rem',
                      },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'inline-block',
                      margin: '0 auto',
                      width: '100%',
                      mb: 7,
                      mt: 5,
                      ':hover .edit-icon': {
                        opacity: 1,
                      },
                    }}
                  >
                    <Typography
                      variant='h1'
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '2.6em',
                        cursor: 'pointer',
                        textAlign: 'center',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                      }}
                    >
                      {titleText || ' '}
                      <Box
                        component='span'
                        sx={{
                          opacity: 0,
                          transition: 'opacity 0.2s',
                          display: 'inline-flex',
                          gap: 0.5,
                        }}
                        className='edit-icon'
                      >
                        <Tooltip title='Redigera' placement='top'>
                          <IconButton size='small' onClick={() => setIsEditingTitle(true)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Typography>
                  </Box>
                )}

                {lessonData &&
                  lessonData.idioms.map((idiom, index) => (
                    <Box
                      key={index}
                      sx={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        mb: 5,
                        backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#f5f5f5',
                        padding: '20px',
                        borderRadius: '8px',
                        '&:hover': {
                          '& .delete-button': {
                            opacity: 1,
                            visibility: 'visible',
                          },
                        },
                      }}
                    >
                      <IconButton
                        className='delete-button'
                        size='small'
                        onClick={() => {
                          const updatedIdioms = lessonData.idioms.filter((_, i) => i !== index);
                          setLessonData({ ...lessonData, idioms: updatedIdioms });
                          showSnackbar('Idiomet har tagits bort', 'success');
                        }}
                        sx={{
                          position: 'absolute',
                          top: 8,
                          right: 8,
                          opacity: 0,
                          visibility: 'hidden',
                          transition: 'all 0.2s ease',
                          bgcolor:
                            theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.2)' : 'transparent',
                          '&:hover': {
                            bgcolor:
                              theme.palette.mode === 'dark'
                                ? 'rgba(0,0,0,0.3)'
                                : 'rgba(255,255,255,0.9)',
                          },
                        }}
                      >
                        <Tooltip title='Ta bort idiom' placement='top'>
                          <DeleteOutlineIcon fontSize='small' />
                        </Tooltip>
                      </IconButton>

                      <Box sx={{ flex: '0 0 200px', mr: 3, position: 'relative' }}>
                        <Avatar
                          variant='rounded'
                          src={idiom.imageUrl || '/api/placeholder/200/200'}
                          alt={idiom.idiom}
                          sx={{
                            width: 200,
                            height: 200,
                            borderRadius: '8px',
                          }}
                        />

                        {/* Loading overlay */}
                        {isGeneratingImages &&
                          (loadingImageIndex === null || loadingImageIndex === index) && (
                            <Box
                              sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'rgba(215, 215, 215, 0.9)',
                                borderRadius: '8px',
                              }}
                            >
                              <FourSquare color='#8b5cf6' size='medium' text='' textColor='' />
                            </Box>
                          )}

                        {/* Hover overlay */}
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            borderRadius: '8px',
                            opacity: 0,
                            transition: 'opacity 0.2s',
                            cursor: 'pointer',
                            '&:hover': {
                              opacity: 1,
                            },
                            pointerEvents: isGeneratingImages ? 'none' : 'auto',
                            visibility: isGeneratingImages ? 'hidden' : 'visible',
                          }}
                          onClick={() => {
                            setSelectedIdiomIndex(index);
                            setShowImageModal(true);
                          }}
                        >
                          <ImageIcon sx={{ color: 'white', fontSize: '2rem', mb: 1 }} />
                          <Typography sx={{ color: 'white', fontSize: '0.9rem' }}>
                            {idiom.imageUrl ? 'Generera ny bild' : 'Generera bild'}
                          </Typography>
                        </Box>
                      </Box>

                      <Box sx={{ flex: 1 }}>
                        {/* Idiom */}
                        <Box
                          sx={{
                            '&:hover .controls': {
                              opacity: 1,
                            },
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            mb: 1,
                          }}
                        >
                          {editingIdiomId === index ? (
                            <TextField
                              fullWidth
                              value={idiom.idiom}
                              onChange={e => {
                                const updatedIdioms = [...lessonData.idioms];
                                updatedIdioms[index] = { ...idiom, idiom: e.target.value };
                                setLessonData({ ...lessonData, idioms: updatedIdioms });
                              }}
                              onBlur={() => handleEditIdiom(index, idiom.idiom, 'idiom')}
                              onKeyDown={e => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                  e.preventDefault();
                                  handleEditIdiom(index, idiom.idiom, 'idiom');
                                }
                              }}
                              autoFocus
                              sx={{
                                '& .MuiInputBase-input': {
                                  fontSize: '24px',
                                  fontWeight: '600',
                                },
                              }}
                            />
                          ) : (
                            <Typography
                              variant='h2'
                              sx={{
                                fontSize: '24px',
                                fontWeight: '600',
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                              }}
                            >
                              {idiom.idiom}
                              <Box
                                component='span'
                                className='controls'
                                sx={{
                                  opacity: 0,
                                  transition: 'opacity 0.2s',
                                  display: 'inline-flex',
                                  gap: 0.5,
                                  ml: 1,
                                }}
                              >
                                <Voice
                                  content={idiom.idiom}
                                  fontSize={20}
                                  userId={user.uid}
                                  language={language}
                                  tooltipPlacement='top'
                                  showVolumeIcon={true}
                                />
                                <Tooltip title='Redigera' placement='top'>
                                  <IconButton size='small' onClick={() => setEditingIdiomId(index)}>
                                    <EditIcon fontSize='small' />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Typography>
                          )}
                        </Box>

                        {/* Meaning */}
                        <Box
                          sx={{
                            '&:hover .controls': {
                              opacity: 1,
                            },
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            mb: 1,
                          }}
                        >
                          {editingMeaningId === index ? (
                            <TextField
                              fullWidth
                              value={idiom.meaning}
                              onChange={e => {
                                const updatedIdioms = [...lessonData.idioms];
                                updatedIdioms[index] = { ...idiom, meaning: e.target.value };
                                setLessonData({ ...lessonData, idioms: updatedIdioms });
                              }}
                              onBlur={() => handleEditIdiom(index, idiom.meaning, 'meaning')}
                              onKeyDown={e => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                  e.preventDefault();
                                  handleEditIdiom(index, idiom.meaning, 'meaning');
                                }
                              }}
                              autoFocus
                            />
                          ) : (
                            <Typography
                              variant='body1'
                              sx={{
                                fontStyle: 'italic',
                                color: theme.palette.mode === 'dark' ? '#aaa' : '#666',
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                              }}
                            >
                              {idiom.meaning}
                              <Box
                                component='span'
                                className='controls'
                                sx={{
                                  opacity: 0,
                                  transition: 'opacity 0.2s',
                                  display: 'inline-flex',
                                  gap: 0.5,
                                  ml: 1,
                                }}
                              >
                                <Voice
                                  content={idiom.meaning}
                                  fontSize={20}
                                  userId={user.uid}
                                  language={language}
                                  tooltipPlacement='top'
                                  showVolumeIcon={true}
                                />
                                <Tooltip title='Redigera' placement='top'>
                                  <IconButton
                                    size='small'
                                    onClick={() => setEditingMeaningId(index)}
                                  >
                                    <EditIcon fontSize='small' />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Typography>
                          )}
                        </Box>

                        {/* Example Sentence */}
                        <Box
                          sx={{
                            '&:hover .controls': {
                              opacity: 1,
                            },
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          {editingExampleId === index ? (
                            <TextField
                              fullWidth
                              multiline
                              value={idiom.exampleSentence}
                              onChange={e => {
                                const updatedIdioms = [...lessonData.idioms];
                                updatedIdioms[index] = {
                                  ...idiom,
                                  exampleSentence: e.target.value,
                                };
                                setLessonData({ ...lessonData, idioms: updatedIdioms });
                              }}
                              onBlur={() =>
                                handleEditIdiom(index, idiom.exampleSentence, 'exampleSentence')
                              }
                              onKeyDown={e => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                  e.preventDefault();
                                  handleEditIdiom(index, idiom.exampleSentence, 'exampleSentence');
                                }
                              }}
                              autoFocus
                            />
                          ) : (
                            <Typography
                              variant='body1'
                              sx={{
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                              }}
                            >
                              {idiom.exampleSentence}
                              <Box
                                component='span'
                                className='controls'
                                sx={{
                                  opacity: 0,
                                  transition: 'opacity 0.2s',
                                  display: 'inline-flex',
                                  gap: 0.5,
                                  ml: 1,
                                }}
                              >
                                <Voice
                                  content={idiom.exampleSentence}
                                  fontSize={20}
                                  userId={user.uid}
                                  language={language}
                                  tooltipPlacement='top'
                                  showVolumeIcon={true}
                                />
                                <Tooltip title='Redigera' placement='top'>
                                  <IconButton
                                    size='small'
                                    onClick={() => setEditingExampleId(index)}
                                  >
                                    <EditIcon fontSize='small' />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  ))}

                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 4 }}>
                  <Button
                    variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                    startIcon={<AddIcon />}
                    onClick={() => {
                      const newIdiom = {
                        idiom: 'Nytt idiom',
                        meaning: 'Skriv betydelsen här',
                        exampleSentence: 'Skriv en exempelmening här.',
                        imagePrompt: '',
                        imageUrl: null,
                      };
                      setLessonData({
                        ...lessonData,
                        idioms: [...lessonData.idioms, newIdiom],
                      });
                      showSnackbar('Nytt idiom har lagts till', 'success');
                    }}
                    sx={{
                      mt: 2,
                      mb: 4,
                    }}
                  >
                    Lägg till idiom
                  </Button>
                </Box>

                <Box
                  sx={{
                    mt: 8,
                    mb: 4,
                    padding: '30px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#f5f5f5',
                    borderRadius: '8px',
                  }}
                >
                  <Typography variant='h2' sx={{ fontSize: '1.5em', mb: 3, fontWeight: '600' }}>
                    Berättelse med idiomatiska uttryck
                  </Typography>
                  <Box
                    sx={{
                      '&:hover .controls': {
                        opacity: 1,
                      },
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: 1,
                    }}
                  >
                    {isEditingStory ? (
                      <TextField
                        fullWidth
                        multiline
                        rows={10}
                        defaultValue={lessonData.story}
                        onBlur={e => handleEditStory(e.target.value)}
                        onKeyDown={e => {
                          if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleEditStory(e.target.value);
                          }
                        }}
                        autoFocus
                        sx={{
                          '& .MuiInputBase-input': {
                            lineHeight: 1.8,
                          },
                        }}
                      />
                    ) : (
                      <Typography
                        variant='body1'
                        sx={{
                          lineHeight: 1.8,
                          flex: 1,
                          display: 'flex',
                          alignItems: 'flex-start',
                          gap: 1,
                        }}
                      >
                        {lessonData.story || ' '}
                        <Box
                          component='span'
                          className='controls'
                          sx={{
                            opacity: 0,
                            transition: 'opacity 0.2s',
                            display: 'inline-flex',
                            gap: 0.5,
                            ml: 1,
                            mt: 0.5,
                          }}
                        >
                          <Voice
                            content={lessonData.story}
                            fontSize={20}
                            userId={user.uid}
                            language={language}
                            tooltipPlacement='top'
                            showVolumeIcon={true}
                          />
                          <Tooltip title='Redigera' placement='top'>
                            <IconButton size='small' onClick={() => setIsEditingStory(true)}>
                              <EditIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default IdiomsLesson;
