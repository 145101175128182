import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Divider,
  TextField,
  CircularProgress,
  FormControlLabel,
  Switch,
  Grid,
  Tooltip,
  Avatar,
  Chip,
  IconButton,
} from '@mui/material';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import { handlePrintConversationLesson } from './printFunctions';
import InfoBox from './InfoBox';
import { callApi } from './api';
import ChatIcon from '@mui/icons-material/Chat';
import { useSnackbar } from './SnackbarHandler';
import LanguageSelector from './LanguageSelector';
import GradeSelector, { gradeInstructions } from './GradeSelector';
import ProgressBar from './ProgressBar';
import ImageGenerationModal from './ImageGenerationModal';
import Voice from './Voice';

import Logger from './Logger';

import { FourSquare } from 'react-loading-indicators';
import { ThemeSelector } from './ThemeSelector';

import ImageIcon from '@mui/icons-material/Image';
import PrintIcon from '@mui/icons-material/Print';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { generateAndProcessImage } from './functions';

import RestaurantIcon from '@mui/icons-material/Restaurant';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PhoneIcon from '@mui/icons-material/Phone';
import HotelIcon from '@mui/icons-material/Hotel';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import LibraryIcon from '@mui/icons-material/LocalLibrary';
import MovieIcon from '@mui/icons-material/Movie';
import FlightIcon from '@mui/icons-material/Flight';
import SportsIcon from '@mui/icons-material/Sports';
import SchoolIcon from '@mui/icons-material/School';
import StoreIcon from '@mui/icons-material/Store';

const ConversationLesson = ({ user, theme, styles }) => {
  const [topic, setTopic] = useState('airport');
  const [customTheme, setCustomTheme] = useState('');
  const [language, setLanguage] = useState('svenska');
  const [participantCount, setParticipantCount] = useState(2);
  const [conversationTone, setConversationTone] = useState('neutral');
  const [isGenerating, setIsGenerating] = useState(false);
  const [messageCount, setMessageCount] = useState(6);

  const [lessonData, setLessonData] = useState(null);
  const [isGeneratingTopicImage, setIsGeneratingTopicImage] = useState(false);
  const [isGeneratingParticipantImages, setIsGeneratingParticipantImages] = useState(false);
  const [progress, setProgress] = useState(0);
  const [generatedImages, setGeneratedImages] = useState(new Set());
  const { showSnackbar } = useSnackbar();
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const [imageGenerationType, setImageGenerationType] = useState(null);
  const [openAiInstructions, setOpenAiInstructions] = useState('');

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [titleText, setTitleText] = useState('Konversationslektion');
  const [grade, setGrade] = useState('åk6');
  const [showTranslation, setShowTranslation] = useState(false);
  const [translationLanguage, setTranslationLanguage] = useState('spanska');

  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editingTranslationId, setEditingTranslationId] = useState(null);

  const [selectedParticipantId, setSelectedParticipantId] = useState(null);
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);

  const handleGenerateSpecificImage = async () => {
    handleCloseModal();
    if (imageGenerationType === 'topic') {
      setIsGeneratingTopicImage(true);
      try {
        const prompt = `Skapa en bild som representerar ämnet ${lessonData.topic}. ${imageInstructions}`;
        const imageUrl = await generateAndProcessImage(prompt, user.uid);
        setLessonData(prevData => ({
          ...prevData,
          topicImage: imageUrl,
        }));
        showSnackbar('Ämnesbild genererad framgångsrikt.', 'success');
      } catch (error) {
        showSnackbar('Misslyckades med att generera ämnesbild. Försök igen.', 'error');
      } finally {
        setIsGeneratingTopicImage(false);
      }
    } else if (imageGenerationType === 'participants' && selectedParticipantId) {
      const participant = lessonData.participants[selectedParticipantId];
      setIsGeneratingParticipantImages(true);
      setLoadingImageIndex(selectedParticipantId);
      try {
        const prompt = `Skapa en porträttbild som representerar ${participant.name}, ${participant.role}. Bilden ska reflektera deras karakteristik: ${participant.characteristics}. ${imageInstructions}`;
        const imageUrl = await generateAndProcessImage(prompt, user.uid);

        setLessonData(prevData => ({
          ...prevData,
          participants: {
            ...prevData.participants,
            [selectedParticipantId]: {
              ...prevData.participants[selectedParticipantId],
              image: imageUrl,
            },
          },
        }));
        showSnackbar('Deltagarbild har genererats!', 'success');
      } catch (error) {
        showSnackbar('Fel vid generering av deltagarbild.', 'error');
      } finally {
        setIsGeneratingParticipantImages(false);
        setLoadingImageIndex(null);
        setSelectedParticipantId(null);
      }
    }
  };

  const handleClearText = (index, type = 'message') => {
    setLessonData(prevData => ({
      ...prevData,
      conversation: prevData.conversation.map((msg, i) =>
        i === index ? { ...msg, [type]: '' } : msg
      ),
    }));
    showSnackbar(`${type === 'message' ? 'Meddelandet' : 'Översättningen'} har tömts`, 'success');
  };

  const handleEditMessage = (index, newText, type = 'message') => {
    setLessonData(prevData => ({
      ...prevData,
      conversation: prevData.conversation.map((msg, i) =>
        i === index ? { ...msg, [type]: newText } : msg
      ),
    }));
    type === 'message' ? setEditingMessageId(null) : setEditingTranslationId(null);
    showSnackbar(
      `${type === 'message' ? 'Meddelandet' : 'Översättningen'} har uppdaterats`,
      'success'
    );
  };

  const toneOptions = [
    { value: 'lekfull', label: 'Lekfull' },
    { value: 'akademisk', label: 'Akademisk' },
    { value: 'informell', label: 'Informell' },
    { value: 'formell', label: 'Formell' },
    { value: 'debatt', label: 'Debatt' },
    { value: 'neutral', label: 'Neutral' },
  ];

  const handleImageButtonClick = type => {
    setImageGenerationType(type);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
  };

  const handleGenerateWithInstructions = async () => {
    handleCloseModal();
    if (imageGenerationType === 'topic') {
      await handleGenerateTopicImage();
    } else if (imageGenerationType === 'participants') {
      await handleGenerateParticipantImages();
    }
  };

  const simulateProgress = (startProgress, endProgress, duration, setProgressFn) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgressFn(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateConversation = async () => {
    setIsGenerating(true);
    setProgress(0);
    let stopProgressSimulation = null;
    try {
      stopProgressSimulation = simulateProgress(0, 90, 15000, setProgress);

      const generateTheme = topic === 'generateWithAi';
      let themeToUse;

      if (generateTheme) {
        themeToUse = '[Hitta på ett slumpmässigt samtalsämne]';
      } else if (topic === 'customTheme') {
        themeToUse = customTheme;
      } else {
        switch (topic) {
          case 'airport':
            themeToUse =
              'Simulera en slumpmässig konversation på en flygplats. Den kan utspela sig vid incheckningen, säkerhetskontrollen, gate-området eller bagagehämtningen. Fokusera på realistiska interaktioner som kan uppstå i dessa situationer, som att checka in bagage, visa boardingkort, fråga om förseningar eller hitta rätt gate';
            break;
          case 'restaurant':
            themeToUse =
              'Simulera en realistisk konversation på en restaurang. Den kan inkludera att beställa mat, fråga om menyn, specialkost, eller betala notan. Använd naturliga fraser som används i restaurangmiljö';
            break;
          case 'grocery':
            themeToUse =
              'Simulera en konversation i mataffären. Den kan handla om att hitta varor, fråga om priser, erbjudanden, eller färskvaror. Gör dialogen praktisk och vardagsnära';
            break;
          case 'publicTransport':
            themeToUse =
              'Simulera en konversation om kollektivtrafik. Det kan vara vid biljettköp, på bussen/tåget eller vid en hållplats. Inkludera vanliga frågor om avgångar, priser eller rutter';
            break;
          case 'doctor':
            themeToUse =
              'Simulera ett läkarbesök med fokus på vanliga symptom och enkel vård. Håll samtalet enkelt och undvik komplexa medicinska termer';
            break;
          case 'shopping':
            themeToUse =
              'Simulera ett besök i en klädbutik. Inkludera konversationer om storlekar, priser, material eller att prova kläder. Håll dialogen praktisk och vardaglig';
            break;
          case 'phoneCall':
            themeToUse =
              'Simulera ett telefonsamtal i vardaglig situation. Det kan vara att boka tid, beställa något eller prata med en vän. Fokusera på tydlig kommunikation';
            break;
          case 'hotel':
            themeToUse =
              'Simulera en konversation vid hotellbokning eller incheckning. Inkludera frågor om rum, faciliteter, priser eller service';
            break;
          case 'cafe':
            themeToUse =
              'Simulera ett cafébesök med beställning av dryck och fika. Inkludera vanliga fraser vid beställning, specialönskemål eller betalning';
            break;
          case 'library':
            themeToUse =
              'Simulera en konversation på ett bibliotek. Det kan handla om att låna/lämna böcker, hitta material eller få hjälp av bibliotekarien';
            break;
          case 'cinema':
            themeToUse =
              'Simulera en konversation på bio. Inkludera biljettköp, val av platser, snacks eller diskussion om filmer';
            break;
          case 'gym':
            themeToUse =
              'Simulera en konversation på ett gym. Det kan handla om medlemskap, träningsråd eller användning av utrustning';
            break;
          case 'school':
            themeToUse =
              'Simulera en konversation i skolmiljö. Fokusera på vardagliga situationer mellan elever eller mellan elev och lärare';
            break;
          case 'store':
            themeToUse =
              'Simulera en konversation i en butik. Inkludera kundbetjäning, produktfrågor eller köp/retur av varor';
            break;
          default:
            themeToUse = topic;
        }
      }

      Logger.log('themeToUse: ', themeToUse);

      const gradeInstructionsText = gradeInstructions[grade];

      const response = await callApi(
        [
          {
            role: 'system',
            content: `You are an assistant that generates engaging educational conversations between multiple participants. The conversation should be natural and flow well while maintaining the specified tone. ${openAiInstructions}. ${gradeInstructionsText}.`,
          },
          {
            role: 'user',
            content: `Generate a JSON object with a conversation between ${participantCount} participants about ${themeToUse} in "${language}"${showTranslation ? ` with translations in "${translationLanguage}"` : ''}. The conversation should have ${messageCount} messages and be in a ${conversationTone} tone.

      Include characteristics for each participant that influences their perspective in the conversation.
      
      Example response:
{
  "topic": "Klimatförändringar",
  "topicImage": null,
  "title": "Dialog om klimatförändringarnas påverkan",
  "participants": {
    "participant1": {
      "name": "Maria",
      "role": "Klimatforskare",
      "characteristics": "Faktadriven och analytisk",
      "image": null
    },
    "participant2": {
      "name": "Erik",
      "role": "Miljöaktivist",
      "characteristics": "Passionerad och handlingsinriktad",
      "image": null
    }
  },
 "conversation": [
    {
      "speaker": "Maria",
      "message": "Enligt de senaste forskningsrapporterna ser vi en alarmerande ökning av den globala medeltemperaturen"
      ${showTranslation ? `,"translation": "Översättning på ${translationLanguage}"` : ''}
    },
    {
      "speaker": "Erik",
      "message": "Det är precis vad vi har varnat för! Vi måste agera nu innan det är för sent"
      ${showTranslation ? `,"translation": "Översättning på ${translationLanguage}"` : ''}
    }
  ]
}`,
          },
        ],
        8000,
        true,
        user.uid
      );

      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setProgress(100);
      await new Promise(resolve => setTimeout(resolve, 800));

      setLessonData(data);
      setTitleText(data.title);
      showSnackbar('Konversationslektionen har genererats!', 'success');
    } catch (error) {
      console.error('Error generating conversation:', error);
      showSnackbar('Något gick fel. Försök igen.', 'error');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleGenerateTopicImage = async () => {
    setIsGeneratingTopicImage(true);
    try {
      const prompt = `Skapa en bild som representerar ämnet ${lessonData.topic}. ${imageInstructions}`;
      const imageUrl = await generateAndProcessImage(prompt, user.uid);
      setLessonData(prevData => ({
        ...prevData,
        topicImage: imageUrl,
      }));
      showSnackbar('Ämnesbild genererad framgångsrikt.', 'success');
    } catch (error) {
      showSnackbar('Misslyckades med att generera ämnesbild. Försök igen.', 'error');
    } finally {
      setIsGeneratingTopicImage(false);
    }
  };

  const handleGenerateParticipantImages = async () => {
    setIsGeneratingParticipantImages(true);
    setGeneratedImages(new Set());
    try {
      for (const [id, participant] of Object.entries(lessonData.participants)) {
        try {
          const prompt = `Skapa en porträttbild som representerar ${participant.name}, ${participant.role}. Bilden ska reflektera deras karakteristik: ${participant.characteristics}. ${imageInstructions}`;
          const imageUrl = await generateAndProcessImage(prompt, user.uid);

          setLessonData(prevData => ({
            ...prevData,
            participants: {
              ...prevData.participants,
              [id]: {
                ...prevData.participants[id],
                image: imageUrl,
              },
            },
          }));

          setGeneratedImages(prev => new Set([...prev, id]));
        } catch (error) {
          console.error(`Error generating image for participant "${id}":`, error);
          continue;
        }
      }
      showSnackbar('Alla deltagarbilder har genererats!', 'success');
    } catch (error) {
      showSnackbar('Fel vid generering av deltagarbilder.', 'error');
    } finally {
      setIsGeneratingParticipantImages(false);
    }
  };

  const conversationThemes = [
    { type: 'subheader', label: 'Konversationstyper' },
    { value: 'restaurant', label: 'Restaurangbesök', Icon: RestaurantIcon },
    { value: 'hairdresser', label: 'Frisör', Icon: ContentCutIcon },
    { value: 'grocery', label: 'I mataffären', Icon: LocalGroceryStoreIcon },
    { value: 'publicTransport', label: 'Kollektivtrafik', Icon: DirectionsBusIcon },
    { value: 'doctor', label: 'Läkarbesök', Icon: LocalHospitalIcon },
    { value: 'shopping', label: 'Klädbutik', Icon: ShoppingCartIcon },
    { value: 'phoneCall', label: 'Telefonsamtal', Icon: PhoneIcon },
    { value: 'hotel', label: 'Hotellbokning', Icon: HotelIcon },
    { value: 'cafe', label: 'På caféet', Icon: LocalCafeIcon },
    { value: 'library', label: 'Biblioteket', Icon: LibraryIcon },
    { value: 'cinema', label: 'Biograf', Icon: MovieIcon },
    { value: 'airport', label: 'På flygplatsen', Icon: FlightIcon },
    { value: 'gym', label: 'På gymmet', Icon: SportsIcon },
    { value: 'school', label: 'I klassrummet', Icon: SchoolIcon },
    { value: 'store', label: 'I butiken', Icon: StoreIcon },
  ];

  const isGeneratingAnyImages = isGeneratingTopicImage || isGeneratingParticipantImages;

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: '100%', md: '87%' },
          margin: '0 auto',
        }}
      >
        <Typography
          variant='h1'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
        >
          Konversation
        </Typography>

        <InfoBox
          icon={ChatIcon}
          title='Instruktioner för Konversations Generator'
          instructions={[
            'Välj antal deltagare i konversationen',
            'Ange samtalets tema och ton och simulera en konversation',
            'Generera bilder för tema och deltagare',
          ]}
          theme={theme}
          styles={styles}
        />

        <Box sx={{ ...styles.freshBox, padding: '30px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <ThemeSelector
                value={topic}
                onChange={e => setTopic(e.target.value)}
                theme={theme}
                customTheme={customTheme}
                onCustomThemeChange={e => setCustomTheme(e.target.value)}
                additionalThemes={conversationThemes}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 0 }}>
                Årskurs:
              </Typography>
              <Box sx={{ mt: 1, mb: 1 }}>
                <GradeSelector grade={grade} setGrade={setGrade} />
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                Antal deltagare:
              </Typography>
              <FormControl fullWidth margin='normal' sx={{ mb: 1, mt: 1 }}>
                <Select
                  value={participantCount}
                  onChange={e => setParticipantCount(e.target.value)}
                >
                  {[2, 3, 4, 5].map(num => (
                    <MenuItem key={num} value={num}>
                      {num}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                Antal meddelanden:
              </Typography>
              <FormControl fullWidth margin='normal' sx={{ mb: 1, mt: 1 }}>
                <Select value={messageCount} onChange={e => setMessageCount(e.target.value)}>
                  {[4, 6, 8, 10, 12].map(num => (
                    <MenuItem key={num} value={num}>
                      {num}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                Samtalston:
              </Typography>
              <FormControl fullWidth margin='normal' sx={{ mb: 1, mt: 1 }}>
                <Select
                  value={conversationTone}
                  onChange={e => setConversationTone(e.target.value)}
                >
                  {toneOptions.map(tone => (
                    <MenuItem key={tone.value} value={tone.value}>
                      {tone.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                Språk:
              </Typography>
              <Box sx={{ mt: 1, mb: 1 }}>
                <LanguageSelector language={language} setLanguage={setLanguage} />
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              <FormControl component='fieldset' sx={{ mt: 1 }}>
                <Typography variant='subtitle1' sx={{ fontWeight: '500', mb: 1 }}>
                  Visa översättning för varje meddelande?
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showTranslation}
                      onChange={e => setShowTranslation(e.target.checked)}
                      color='primary'
                    />
                  }
                  label={showTranslation ? 'Ja' : 'Nej'}
                />
              </FormControl>
            </Grid>

            {showTranslation && (
              <Grid item xs={12} md={12}>
                <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 2, mb: 1 }}>
                  Välj språk för översättning:
                </Typography>
                <LanguageSelector
                  language={translationLanguage}
                  setLanguage={setTranslationLanguage}
                />
              </Grid>
            )}

            {/*
            <Grid item xs={12}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                Ytterligare instruktioner till AI:
              </Typography>
              <FormControl fullWidth margin='normal' sx={{ mb: 3, mt: 1 }}>
                <TextField
                  value={openAiInstructions}
                  onChange={e => setOpenAiInstructions(e.target.value)}
                  placeholder='Ex. Fokusera på vetenskapliga aspekter'
                  variant='outlined'
                />
              </FormControl>
            </Grid>
            */}
          </Grid>

          <Button
            variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
            color='primary'
            onClick={handleGenerateConversation}
            disabled={isGenerating || isGeneratingAnyImages}
            sx={{ mt: 5 }}
            startIcon={
              !isGenerating ? (
                <GenerateIcon style={{ color: '#fff' }} />
              ) : (
                <CircularProgress style={{ color: '#fff' }} size={16} />
              )
            }
          >
            {isGenerating ? 'Genererar konversation...' : 'Generera lektion'}
          </Button>

          {isGenerating && (
            <Box sx={{ mt: 7 }}>
              <ProgressBar progress={progress} label='Genererar konversationslektion...' />
            </Box>
          )}

          {lessonData && (
            <>
              <Divider sx={{ mt: 5, mb: 3 }} />

              <Button
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                color='primary'
                onClick={() => handleImageButtonClick('topic')}
                disabled={isGeneratingAnyImages}
                sx={{ mt: 2 }}
                startIcon={
                  !isGeneratingTopicImage ? (
                    <ImageIcon style={{ color: '#fff' }} />
                  ) : (
                    <CircularProgress style={{ color: '#fff' }} size={16} />
                  )
                }
              >
                {isGeneratingTopicImage ? 'Genererar...' : 'Lägg till ämnesbild'}
              </Button>

              <Button
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                color='primary'
                onClick={() => handleImageButtonClick('participants')}
                disabled={isGeneratingAnyImages}
                sx={{ mt: 2, ml: 2 }}
                startIcon={
                  !isGeneratingParticipantImages ? (
                    <ImageIcon style={{ color: '#fff' }} />
                  ) : (
                    <CircularProgress style={{ color: '#fff' }} size={16} />
                  )
                }
              >
                {isGeneratingParticipantImages ? 'Genererar...' : 'Lägg till deltagarbilder'}
              </Button>

              <Button
                variant='contained'
                color='primary'
                onClick={() => handlePrintConversationLesson(lessonData, titleText, theme)}
                startIcon={<PrintIcon />}
                sx={{ ml: 2, mt: 2 }}
              >
                Skriv ut lektion
              </Button>
              <ImageGenerationModal
                open={showImageModal}
                onClose={handleCloseModal}
                onGenerate={
                  selectedParticipantId || imageGenerationType === 'topic'
                    ? handleGenerateSpecificImage
                    : handleGenerateWithInstructions
                }
                isGenerating={isGeneratingTopicImage || isGeneratingParticipantImages}
                instructions={imageInstructions}
                setInstructions={setImageInstructions}
                generationType={imageGenerationType}
                theme={theme}
              />

              <Box sx={{ mt: 8 }}>
                {/* Title */}
                {isEditingTitle ? (
                  <TextField
                    value={titleText}
                    onChange={e => setTitleText(e.target.value)}
                    onBlur={() => {
                      setIsEditingTitle(false);
                      showSnackbar('Titeln på lektionen har uppdaterats', 'success');
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        setIsEditingTitle(false);
                        showSnackbar('Titeln på lektionen har uppdaterats', 'success');
                      }
                    }}
                    autoFocus
                    fullWidth
                    variant='standard'
                    sx={{
                      backgroundColor: 'transparent',
                      '& .MuiInputBase-input': {
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fontSize: '2.6em',
                        marginBottom: '1.2rem',
                        marginTop: '1.5rem',
                      },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'inline-block',
                      margin: '0 auto',
                      width: '100%',
                      mb: 7,
                      mt: 5,
                      ':hover .edit-icon': {
                        opacity: 1,
                      },
                    }}
                  >
                    <Typography
                      variant='h1'
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '2.6em',
                        cursor: 'pointer',
                        textAlign: 'center',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                      }}
                    >
                      {titleText || ' '}
                      <Box
                        component='span'
                        sx={{
                          opacity: 0,
                          transition: 'opacity 0.2s',
                          display: 'inline-flex',
                          gap: 0.5,
                        }}
                        className='edit-icon'
                      >
                        <Tooltip title='Redigera' placement='top'>
                          <IconButton size='small' onClick={() => setIsEditingTitle(true)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Typography>
                  </Box>
                )}

                {/* Topic Image */}

                <Box sx={{ mb: 6, mt: 2, position: 'relative' }}>
                  <Avatar
                    variant='rounded'
                    src={lessonData.topicImage || '/api/placeholder/300/300'}
                    alt='Ämnesbild'
                    sx={{
                      width: '350px',
                      height: '350px',
                      borderRadius: '10px',
                      margin: '0 auto',
                    }}
                  />

                  {/* Loading overlay */}
                  {isGeneratingTopicImage && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '350px',
                        height: '350px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(215, 215, 215, 0.9)',
                        borderRadius: '10px',
                      }}
                    >
                      <FourSquare color='#8b5cf6' size='medium' text='' textColor='' />
                    </Box>
                  )}

                  {/* Hover overlay */}
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: '50%',
                      transform: 'translateX(-50%)',
                      width: '350px',
                      height: '350px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'rgba(0, 0, 0, 0.7)',
                      borderRadius: '10px',
                      opacity: 0,
                      transition: 'opacity 0.2s',
                      cursor: 'pointer',
                      '&:hover': {
                        opacity: 1,
                      },
                      pointerEvents: isGeneratingTopicImage ? 'none' : 'auto',
                      visibility: isGeneratingTopicImage ? 'hidden' : 'visible',
                    }}
                    onClick={() => {
                      setImageGenerationType('topic');
                      setShowImageModal(true);
                    }}
                  >
                    <ImageIcon sx={{ color: 'white', fontSize: '2rem', mb: 1 }} />
                    <Typography sx={{ color: 'white', fontSize: '0.9rem' }}>
                      {lessonData.topicImage ? 'Generera ny bild' : 'Generera bild'}
                    </Typography>
                  </Box>
                </Box>

                {/* Participant Avatars Row */}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 3,
                    mb: 4,
                    flexWrap: 'wrap',
                  }}
                >
                  {Object.entries(lessonData.participants).map(([id, participant]) => (
                    <Box key={id} sx={{ textAlign: 'center', position: 'relative' }}>
                      <Avatar
                        src={participant.image || '/api/placeholder/150/150'}
                        alt={participant.name}
                        sx={{
                          width: 100,
                          height: 100,
                          margin: '0 auto',
                          mb: 1,
                        }}
                      />

                      {/* Loading overlay */}
                      {isGeneratingParticipantImages && loadingImageIndex === id && (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 0,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            width: 100,
                            height: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(215, 215, 215, 0.9)',
                            borderRadius: '50%',
                          }}
                        >
                          <FourSquare color='#8b5cf6' size='medium' text='' textColor='' />
                        </Box>
                      )}

                      {/* Hover overlay */}
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: '50%',
                          transform: 'translateX(-50%)',
                          width: 100,
                          height: 100,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: 'rgba(0, 0, 0, 0.7)',
                          borderRadius: '50%',
                          opacity: 0,
                          transition: 'opacity 0.2s',
                          cursor: 'pointer',
                          '&:hover': {
                            opacity: 1,
                          },
                          pointerEvents: isGeneratingParticipantImages ? 'none' : 'auto',
                          visibility: isGeneratingParticipantImages ? 'hidden' : 'visible',
                        }}
                        onClick={() => {
                          setSelectedParticipantId(id);
                          setImageGenerationType('participants');
                          setShowImageModal(true);
                        }}
                      >
                        <ImageIcon sx={{ color: 'white', fontSize: '2rem', mb: 1 }} />
                        <Typography sx={{ color: 'white', fontSize: '0.8rem' }}>
                          {participant.image ? 'Generera ny bild' : 'Generera bild'}
                        </Typography>
                      </Box>
                      <Typography variant='subtitle2' sx={{ fontWeight: '600', fontSize: '1rem' }}>
                        {participant.name}
                      </Typography>
                      <Typography
                        variant='caption'
                        sx={{
                          display: 'block',
                          fontWeight: '400',
                          fontSize: '0.85rem',
                        }}
                      >
                        {participant.role}
                      </Typography>
                    </Box>
                  ))}
                </Box>

                {/* Conversation Messages */}
                <Box sx={{ mt: 7 }}>
                  {lessonData.conversation.map((message, index) => {
                    const participant = Object.values(lessonData.participants).find(
                      p => p.name === message.speaker
                    );

                    return (
                      <Box
                        key={index}
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          mb: 4,
                          gap: 2,
                        }}
                      >
                        <Avatar
                          src={participant?.image || '/api/placeholder/50/50'}
                          alt={message.speaker}
                          sx={{ width: 40, height: 40 }}
                        />
                        <Box sx={{ flex: 1 }}>
                          <Typography
                            variant='subtitle2'
                            sx={{
                              fontWeight: '600',

                              fontSize: '1rem',
                            }}
                          >
                            {message.speaker}
                          </Typography>
                          <Box
                            sx={{
                              backgroundColor: theme =>
                                theme.palette.mode === 'dark' ? '#15202b' : '#e0e0e029',
                              borderRadius: '10px',
                              padding: '15px',
                              mt: 1,
                            }}
                          >
                            {/* Message */}
                            {editingMessageId === index ? (
                              <TextField
                                fullWidth
                                multiline
                                defaultValue={message.message} // Changed from value to defaultValue
                                onBlur={e => {
                                  handleEditMessage(index, e.target.value, 'message');
                                  setEditingMessageId(null);
                                }}
                                onKeyDown={e => {
                                  if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    handleEditMessage(index, e.target.value, 'message');
                                    setEditingMessageId(null);
                                  }
                                }}
                                autoFocus
                              />
                            ) : (
                              <Typography
                                variant='body1'
                                sx={{
                                  '&:hover .controls': {
                                    opacity: 1,
                                  },
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                }}
                              >
                                {message.message || ' '}
                                <Box
                                  component='span'
                                  className='controls'
                                  sx={{
                                    opacity: 0,
                                    transition: 'opacity 0.2s',
                                    display: 'inline-flex',
                                    gap: 0.5,
                                  }}
                                >
                                  <Voice
                                    content={message.message}
                                    fontSize={20}
                                    userId={user.uid}
                                    language={language}
                                    tooltipPlacement='top'
                                  />

                                  <Tooltip title='Redigera' placement='top'>
                                    <IconButton
                                      size='small'
                                      onClick={() => setEditingMessageId(index)}
                                    >
                                      <EditIcon fontSize='small' />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title='Töm' placement='top'>
                                    <IconButton
                                      size='small'
                                      onClick={() => handleClearText(index, 'message')}
                                    >
                                      <DeleteOutlineIcon fontSize='small' />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Typography>
                            )}

                            {/* Translation */}
                            {message.translation && (
                              <Box
                                sx={{
                                  mt: 1,
                                  pt: 1,
                                  borderTop: '1px solid rgba(128, 128, 128, 0.2)',
                                  fontStyle: 'italic',
                                }}
                              >
                                {editingTranslationId === index ? (
                                  <TextField
                                    fullWidth
                                    multiline
                                    defaultValue={message.translation} // Changed from value to defaultValue
                                    onBlur={e => {
                                      handleEditMessage(index, e.target.value, 'translation');
                                      setEditingTranslationId(null);
                                    }}
                                    onKeyDown={e => {
                                      if (e.key === 'Enter' && !e.shiftKey) {
                                        e.preventDefault();
                                        handleEditMessage(index, e.target.value, 'translation');
                                        setEditingTranslationId(null);
                                      }
                                    }}
                                    autoFocus
                                  />
                                ) : (
                                  <Typography
                                    variant='body1'
                                    sx={{
                                      '&:hover .controls': {
                                        opacity: 1,
                                      },
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 1,
                                    }}
                                  >
                                    {message.translation || '...'}
                                    <Box
                                      component='span'
                                      className='controls'
                                      sx={{
                                        opacity: 0,
                                        transition: 'opacity 0.2s',
                                        display: 'inline-flex',
                                        gap: 0.5,
                                      }}
                                    >
                                      <Voice
                                        content={message.translation}
                                        fontSize={20}
                                        userId={user.uid}
                                        language={translationLanguage}
                                        tooltipPlacement='top'
                                      />

                                      <Tooltip title='Redigera' placement='top'>
                                        <IconButton
                                          size='small'
                                          onClick={() => setEditingTranslationId(index)}
                                        >
                                          <EditIcon fontSize='small' />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title='Töm' placement='top'>
                                        <IconButton
                                          size='small'
                                          onClick={() => handleClearText(index, 'translation')}
                                        >
                                          <DeleteOutlineIcon fontSize='small' />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  </Typography>
                                )}
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ConversationLesson;
