import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
  Switch,
  TextField,
  useTheme,
  styled,
  Avatar,
  Button,
  DialogActions,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import LanguageIcon from '@mui/icons-material/Language';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { sanitizeInput } from './functions';
import FunctionsIcon from '@mui/icons-material/Functions'; // New import for LaTeX icon
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import { updateProfile } from 'firebase/auth';
import { useSnackbar } from './SnackbarHandler';
import { useAuth } from './useAuth';
import { getStyles } from '../index';
import { ref, uploadBytes, getDownloadURL } from './firebase';
import { db, storage, auth } from './firebase';
import { collection, addDoc, doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import BlurryBackdrop from './BlurryBackdrop';
import Logger from './Logger';
const SettingBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: theme.palette.mode === 'dark' ? '#2a3742' : '#f9f9f9',
  marginBottom: '5px',
  borderRadius: '10px',
  padding: '12px',
  paddingTop: '15px',
  paddingBottom: '15px',
  mt: 2,

  marginBottom: '12px',
}));

const SettingIcon = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(2),
  color: theme.palette.primary.main,
}));

const SettingsDialog = ({ open, onClose, onUserUpdate, setOverrideTheme }) => {
  const theme = useTheme();
  const { user, updateUser } = useAuth();

  const styles = getStyles(theme);
  const [notifications, setNotifications] = useState(true);
  const [privacyMode, setPrivacyMode] = useState(false);
  const [language, setLanguage] = useState('Svenska');
  const [avatarImage, setAvatarImage] = useState(null);
  const [avatarPreviewUrl, setAvatarPreviewUrl] = useState(user.photoURL || null);
  const [displayName, setDisplayName] = useState(user.displayName || '');
  const [preferredTheme, setPreferredTheme] = useState('system');
  const [isSaving, setIsSaving] = useState(false);
  const [latexActivated, setLatexActivated] = useState(user.latexActivated || false); // New state for LaTeX support
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    // Uppdatera state när user-objektet ändras
    setDisplayName(user.displayName || '');
    setAvatarPreviewUrl(user.photoURL || null);
  }, [user]);

  const handleAvatarChange = e => {
    if (e.target.files[0]) {
      setAvatarImage(e.target.files[0]);
      setAvatarPreviewUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleSaveAllSettings = async () => {
    if (user) {
      setIsSaving(true);
      try {
        const sanitizedDisplayName = sanitizeInput(displayName);

        let updates = {
          displayName: sanitizedDisplayName,
          notifications,
          privacyMode,
          language,
          preferredTheme,
          latexActivated,
        };
        if (avatarImage) {
          const imageRef = ref(storage, `avatars/${user.uid}/${avatarImage.name}`);
          await uploadBytes(imageRef, avatarImage);
          const downloadURL = await getDownloadURL(imageRef);
          updates.photoURL = downloadURL;
        }
        await onUserUpdate(updates);

        setOverrideTheme(updates.preferredTheme);

        Logger.log('Settings updated, new theme:', updates.preferredTheme);
        Logger.log('All settings have been successfully updated');
        showSnackbar(`Inställningar sparade.`, 'success');
        setIsSaving(false);
        onClose();
      } catch (error) {
        console.error('Error updating settings: ', error);
        showSnackbar(`Knak i fogarna. Försök igen.`, 'error');
        setIsSaving(false);
      }
    }
  };

  useEffect(() => {
    setDisplayName(user.displayName || '');
    setAvatarPreviewUrl(user.photoURL || null);
    setPreferredTheme(user.preferredTheme || 'system');
    setLanguage(user.language || 'Svenska');
    setLatexActivated(user.latexActivated || false); // Initialize LaTeX support state
    // Add other state updates as needed
  }, [user]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth BackdropComponent={BlurryBackdrop}>
      <DialogTitle>
        Inställningar
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 3 }}>
          <input
            accept='image/*'
            type='file'
            onChange={handleAvatarChange}
            style={{ display: 'none' }}
            id='avatar-upload'
          />
          <label htmlFor='avatar-upload'>
            <IconButton component='span'>
              <Avatar
                sx={{
                  width: 60,
                  height: 60,
                  bgcolor: 'orange',
                  cursor: 'pointer',
                }}
              >
                {avatarPreviewUrl ? (
                  <img
                    src={avatarPreviewUrl}
                    alt='Avatar'
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                ) : (
                  <AddAPhotoIcon sx={{ fontSize: 30 }} />
                )}
              </Avatar>
            </IconButton>
          </label>
        </Box>

        <SettingBox>
          <Box display='flex' alignItems='center'>
            <Typography variant='subtitle1'>Namn</Typography>
          </Box>
          <TextField
            value={displayName}
            onChange={e => setDisplayName(e.target.value)}
            variant='outlined'
            size='small'
            sx={styles.whiteBg}
          />
        </SettingBox>
        <SettingBox>
          <Box display='flex' alignItems='center'>
            <Box>
              <Typography variant='subtitle1'>Stöd för LaTeX-formler</Typography>
              <Typography variant='caption' color='textSecondary'>
                Observera att detta kan påverka chattens prestanda.
              </Typography>
            </Box>
          </Box>
          <Switch
            checked={latexActivated}
            onChange={e => setLatexActivated(e.target.checked)}
            color='primary'
          />
        </SettingBox>
        <SettingBox>
          <Box display='flex' alignItems='center'>
            <Typography variant='subtitle1'>Språk</Typography>
          </Box>
          <TextField
            select
            value={language}
            onChange={e => setLanguage(e.target.value)}
            SelectProps={{
              native: true,
            }}
            variant='outlined'
            size='small'
          >
            <option value='Svenska'>Svenska</option>
            <option value='Norska' disabled>
              Norska
            </option>
            <option value='Finska' disabled>
              Finska
            </option>
            <option value='Danska' disabled>
              Danska
            </option>
            <option value='Arabiska' disabled>
              Arabiska
            </option>
          </TextField>
        </SettingBox>

        <SettingBox>
          <Box display='flex' alignItems='center'>
            <Typography variant='subtitle1'>Tema</Typography>
          </Box>
          <Box
            display='flex'
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            mt={{ xs: 1, sm: 0 }}
          >
            <Tooltip title='Ljust tema oavsett tid på dygnet'>
              <Button
                startIcon={<Brightness7Icon />}
                onClick={() => setPreferredTheme('light')}
                variant={preferredTheme === 'light' ? 'contained' : 'outlined'}
                size='small'
                sx={{ mr: { sm: 1 }, mb: { xs: 1, sm: 0 } }}
              >
                Ljus
              </Button>
            </Tooltip>
            <Tooltip title='Mörkt tema oavsett tid på dygnet'>
              <Button
                startIcon={<Brightness4Icon />}
                onClick={() => setPreferredTheme('dark')}
                variant={preferredTheme === 'dark' ? 'contained' : 'outlined'}
                size='small'
                sx={{ mr: { sm: 1 }, mb: { xs: 1, sm: 0 } }}
              >
                Mörk
              </Button>
            </Tooltip>
            <Tooltip title='Ändras automatiskt baserat på tid på dygnet'>
              <Button
                startIcon={<SettingsBrightnessIcon />}
                onClick={() => setPreferredTheme('system')}
                variant={preferredTheme === 'system' ? 'contained' : 'outlined'}
                size='small'
              >
                System
              </Button>
            </Tooltip>
          </Box>
        </SettingBox>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSaveAllSettings}
          color='primary'
          disabled={isSaving}
          variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
          startIcon={isSaving ? <CircularProgress size={20} /> : null}
        >
          {isSaving ? 'Sparar...' : 'Spara inställningar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SettingsDialog;
