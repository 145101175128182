import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import ChatIcon from '@mui/icons-material/Chat';
import QuizIcon from '@mui/icons-material/Quiz';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import SchoolIcon from '@mui/icons-material/School';
import CreateIcon from '@mui/icons-material/Create';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Person3Icon from '@mui/icons-material/Person3';
import MenuOpenIcon from '@mui/icons-material/MenuBook';

const ShortcutBox = ({ icon, title, path }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  return (
    <Link to={path} style={{ textDecoration: 'none' }}>
      <Box
        sx={{
          backgroundColor: isDark ? 'rgba(255, 255, 255, 0.05)' : 'rgba(139, 92, 246, 0.05)',
          borderRadius: '12px',
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          '&:hover': {
            backgroundColor: isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(139, 92, 246, 0.1)',
            transform: 'translateY(-2px)',
            '& .icon': {
              color: isDark ? 'rgb(16, 151, 218)' : '#6b46c1', // Darker purple on hover
            },
          },
        }}
      >
        {React.cloneElement(icon, {
          sx: {
            fontSize: 40,
            color: isDark ? 'rgba(255, 255, 255, 0.7)' : '#8b5cf6',
            mb: 1,
            transition: 'color 0.3s ease',
          },
          className: 'icon',
        })}
        <Typography
          variant='subtitle2'
          sx={{
            color: isDark ? 'rgba(255, 255, 255, 0.7)' : '#4a5568',
            textAlign: 'center',
            fontWeight: 500,
          }}
        >
          {title}
        </Typography>
      </Box>
    </Link>
  );
};

const DashboardShortcuts = () => {
  const shortcuts = [
    { icon: <ChatIcon />, title: 'Fråga AI', path: '/app/fraga-ai' },
    { icon: <Person3Icon />, title: 'AI-karaktärer', path: '/app/personal' },
    { icon: <MenuOpenIcon />, title: 'Kurser', path: '/app/kurser' },
    { icon: <QuizIcon />, title: 'Quiz', path: '/app/quiz' },
    { icon: <ViewAgendaIcon />, title: 'Flashcards', path: '/app/flashcards' },

    { icon: <SchoolIcon />, title: 'Högskoleprovet', path: '/app/hogskoleprov' },
    { icon: <CreateIcon />, title: 'Textlabbet', path: '/app/textlabbet' },

    { icon: <QuestionAnswerIcon />, title: 'Debatt', path: '/app/debatt' },
  ];

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        {shortcuts.map((shortcut, index) => (
          <Grid item xs={6} sm={4} md={3} key={index}>
            <ShortcutBox {...shortcut} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DashboardShortcuts;
