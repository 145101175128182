import React, { useState, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {
  Box,
  Button,
  FormControl,
  Select,
  TextField,
  MenuItem,
  FormControlLabel,
  Tooltip,
  Checkbox,
  Typography,
  Divider,
  CircularProgress,
  Slider,
  Grid,
} from '@mui/material';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import ImageIcon from '@mui/icons-material/Image';
import PrintIcon from '@mui/icons-material/Print';
import GavelIcon from '@mui/icons-material/Gavel';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ProgressBar from './ProgressBar';
import LoadingBar from 'react-top-loading-bar';
import LanguageSelector from './LanguageSelector';
import GradeSelector from './GradeSelector';
import Logger from './Logger';
import InfoBox from './InfoBox';
import ImageGenerationModal from './ImageGenerationModal';
import { callApi } from './api';
import { generateAndProcessImage } from './functions';
import { useSnackbar } from './SnackbarHandler';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';

import { CustomFormControlLabel } from './functions';

import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

import {
  AutoStories,
  MenuBook,
  School,
  Lightbulb,
  CheckCircle,
  Psychology,
  Quiz,
  EmojiObjects,
  Architecture,
  Biotech,
  Science,
} from '@mui/icons-material';

import {
  List as ListIcon,
  Edit as EditIcon,
  AutoAwesome as AutoAwesomeIcon,
  Science as ScienceIcon,
  Psychology as PsychologyIcon,
  Pets as PetsIcon,
  BiotechOutlined as BiotechIcon,
  SmartToy as SmartToyIcon,
  MedicalServices as MedicalServicesIcon,
  ElectricBolt as ElectricBoltIcon,
  Public as PublicIcon,
} from '@mui/icons-material';

import {
  AccountBalance as AccountBalanceIcon,
  School as SchoolIcon,
  Nature as NatureIcon,
  Language as LanguageIcon,
  Home as HomeIcon,
  Diversity3 as Diversity3Icon,
  MonetizationOn as MonetizationOnIcon,
  LocalPolice as LocalPoliceIcon,
  SportsEsports as SportsEsportsIcon,
  Newspaper as NewspaperIcon,
  Medication as MedicationIcon,
  Factory as FactoryIcon,
  Forest as ForestIcon,
} from '@mui/icons-material';

// Predefined debate themes in Swedish
const DEBATE_THEMES = [
  'Dödsstraff',
  'Aborträtt',
  'Vapenkontroll',
  'Basinkomst',
  'Kärnkraft',
  'Sociala medier-reglering',
  'Djurförsök',
  'Genmodifiering',
  'AI-utveckling',
  'Obligatorisk vaccination',
  'Socialism vs Kapitalism',
  'Privatisering av skolan',
  'Klimatkompensation',
  'Engelska som officiellt språk',
  'Hyresreglering',
  'Migration och integration',
  'Skattereform',
  'Polisens befogenheter',
  'E-sport som skolämne',
  'Pressfrihet',
  'Läkemedelspatent',
  'Industrialisering vs miljö',
];

// Tone options in Swedish
const TONE_OPTIONS = [
  'Övertygande',
  'Mild',
  'Akademisk',
  'Känslomässig',
  'Faktabaserad',
  'Professionell',
  'Passionerad',
  'Satirisk',

  'Sarkastisk',
  'Drastisk',
  'Provocerande',
  'Humoristisk',
];

// Add new options for educational content
const EDUCATIONAL_OPTIONS = [
  {
    id: 'exercises',
    label: 'Reflekterande uppgifter',
    tooltip: 'Förslag på reflekterande uppgifter som eleverna kan göra.',
  },
  {
    id: 'reflectionQuestions',
    label: 'Reflekterande frågor',
    tooltip:
      'Frågor för självreflektion, t.ex. "Hur har dina åsikter förändrats?" eller "Vad har du lärt dig om ämnet?"',
  },
  {
    id: 'sentenceStarters',
    label: 'Meningsstartare',
    tooltip: 'Påbörjade meningar som elever kan slutföra',
  },
  { id: 'ethicalDilemmas', label: 'Etiska dilemman', tooltip: 'Situationer för etisk diskussion' },
  { id: 'rolePlay', label: 'Rollspelsscenarier', tooltip: 'Scenarier för rollspel och debatt' },
  { id: 'vocabulary', label: 'Ämnesspecifikt ordförråd', tooltip: 'Viktiga begrepp och termer' },

  {
    id: 'historicalContext',
    label: 'Historisk kontext',
    tooltip: 'Hur debatten har utvecklats genom tiden',
  },
  {
    id: 'statistics',
    label: 'Statistik och fakta',
    tooltip: 'Relevant statistik och forskningsresultat',
  },
  { id: 'realExamples', label: 'Verkliga exempel', tooltip: 'Konkreta exempel från verkligheten' },

  // { id: 'mediaAnalysis', label: 'Medieanalys', tooltip: 'Hur ämnet framställs i olika medier' },
  // {
  //   id: 'celebrities',
  //   label: 'Kända personers åsikter',
  //   tooltip: 'Inkludera citat och åsikter från kända personer med källor',
  // },

  {
    id: 'whatIf',
    label: 'Tänk om-scenarier',
    tooltip: 'Hypotetiska scenarios för diskussion',
  },
  {
    id: 'futureImpact',
    label: 'Framtidsperspektiv',
    tooltip: 'Långsiktiga konsekvenser och möjliga utvecklingar',
  },
  {
    id: 'factOpinion',
    label: 'Fakta vs åsikter',
    tooltip: 'Tydlig uppdelning mellan fakta och åsikter i tabellformat',
  },
];

const ArgumentationLesson = ({ user }) => {
  // Theme state
  const [themeType, setThemeType] = useState('predefined');
  const [debateTheme, setDebateTheme] = useState(DEBATE_THEMES[0]);
  const [customTheme, setCustomTheme] = useState('');

  // Language and grade state
  const [language, setLanguage] = useState('svenska');
  const [grade, setGrade] = useState('åk6');

  const theme = useTheme();
  const styles = getStyles(theme);

  // Arguments configuration
  const [forConfig, setForConfig] = useState({
    tone: 'Övertygande',
    numArguments: 5,
    argumentLength: [1, 3],
  });

  const [againstConfig, setAgainstConfig] = useState({
    tone: 'Övertygande',
    numArguments: 5,
    argumentLength: [1, 3],
  });

  // Content state
  const [introText, setIntroText] = useState('');
  const [forArguments, setForArguments] = useState([]);
  const [againstArguments, setAgainstArguments] = useState([]);
  const [editorContent, setEditorContent] = useState('');

  // UI state
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const [imageGenerationType, setImageGenerationType] = useState(null);

  const editorRef = useRef(null);
  const loadingBarRef = useRef(null);
  const { showSnackbar } = useSnackbar();

  // Single state for educational content options
  const [educationalOptions, setEducationalOptions] = useState({});

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  // Add new state for educational content options
  const [forEducationalOptions, setForEducationalOptions] = useState({});
  const [againstEducationalOptions, setAgainstEducationalOptions] = useState({});

  // Initialize checkboxes
  React.useEffect(() => {
    const initialOptions = EDUCATIONAL_OPTIONS.reduce((acc, option) => {
      acc[option.id] = false;
      return acc;
    }, {});
    setEducationalOptions(initialOptions);
  }, []);

  const handleGenerateDebate = async () => {
    setProgress(0);
    setIsGenerating(true);
    let stopProgressSimulation = null;
    try {
      const selectedTheme = themeType === 'custom' ? customTheme : debateTheme;

      stopProgressSimulation = simulateProgress(0, 90, 15000, setProgress);

      const response = await callApi(
        [
          {
            role: 'system',
            content: 'You are an assistant that generates balanced debate content.',
          },
          {
            role: 'user',
            content: `Generate a debate package in ${language} about "${selectedTheme}" with:
            1. An introductory background text (5 sentences) that spurs excitement in the students to learn more.
            2. ${forConfig.numArguments} arguments FOR with tone: ${forConfig.tone} (${forConfig.argumentLength[0]}-${forConfig.argumentLength[1]} sentences each)
            3. ${againstConfig.numArguments} arguments AGAINST with tone: ${againstConfig.tone} (${againstConfig.argumentLength[0]}-${againstConfig.argumentLength[1]} sentences each)
            4. Additional educational content (EXACTLY 10 items for each) based on selected options:
               ${Object.entries(educationalOptions)
                 .filter(([_, value]) => value)
                 .map(([key]) => `- ${key}`)
                 .join('\n')}
            
            ALWAYS RETURN IN Format as JSON:
             {
            "introduction": "text...",
            "forArguments": ["arg1", "arg2"...],
            "againstArguments": ["arg1", "arg2"...],
           "educationalContent": {

   ${educationalOptions.celebrities ? `"celebrities": [{"name": "", "quote": "", "source": "", "stance": "for/against/neutral"}] (EXACTLY 10 ITEMS),` : ''}
      ${educationalOptions.exercises ? `"exercises": ["question1", "question2", "..."] (EXACTLY 10 ITEMS),` : ''}
${
  educationalOptions.sentenceStarters
    ? `
  "sentenceStarters": {
    "general": ["start1", "start2", "..."] (EXACTLY 10 ITEMS),
    "for": ["for_start1", "for_start2", "..."] (EXACTLY 10 ITEMS),
    "against": ["against_start1", "against_start2", "..."] (EXACTLY 10 ITEMS)
  },`
    : ''
}
      ${educationalOptions.historicalContext ? `"historicalContext": "text..." (EXACTLY 10 SENTENCES),` : ''}
      ${educationalOptions.statistics ? `"statistics": [{"fact": "", "sourceUrl": ""}] (EXACTLY 10 ITEMS),` : ''}
      ${educationalOptions.realExamples ? `"realExamples": ["example1", "example2", "..."] (EXACTLY 10 ITEMS),` : ''}
      ${educationalOptions.ethicalDilemmas ? `"ethicalDilemmas": ["dilemma1", "dilemma2", "..."] (EXACTLY 10 ITEMS),` : ''}
      ${educationalOptions.rolePlay ? `"rolePlay": ["scenario1", "scenario2", "..."] (EXACTLY 10 ITEMS),` : ''}
      ${educationalOptions.vocabulary ? `"vocabulary": [{"term": "", "definition": ""}] (EXACTLY 10 ITEMS),` : ''}
      ${educationalOptions.mediaAnalysis ? `"mediaAnalysis": ["point1", "point2", "..."] (EXACTLY 10 ITEMS),` : ''}
      ${educationalOptions.whatIf ? `"whatIf": ["scenario1", "scenario2", "..."] (EXACTLY 10 ITEMS),` : ''}
      ${educationalOptions.futureImpact ? `"futureImpact": ["impact1", "impact2", "..."] (EXACTLY 10 ITEMS),` : ''}
      ${educationalOptions.factOpinion ? `"factOpinion": [{"fact": "fact1", "opinion": "opinion1"}] (EXACTLY 10 ITEMS),` : ''}
      ${educationalOptions.reflectionQuestions ? `"reflectionQuestions": ["reflectionQuestion1", "reflectionQuestion2", "..."] (EXACTLY 10 ITEMS),` : ''}

  }
            }`,
          },
        ],
        6000,
        true,
        user.uid
      );

      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      // Stop the progress simulation
      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(95);
      const data = JSON.parse(messageContent);

      Logger.log(
        'response Educational Options:',
        Object.keys(educationalOptions).filter(key => educationalOptions[key])
      );
      Logger.log('response: ', JSON.stringify(data, null, 2));

      const formattedContent = `
        <h2 style="font-weight: bold; font-size: 30px; margin-bottom: 20px;">${selectedTheme}</h2>
        
        <h3 style="font-weight: bold; margin-top: 30px;">Introduktion</h3>
        <div class="introduction">
          ${data.introduction}
        </div>
        
        <div id="image-insertion-point"></div>

        <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 40px; margin-top: 40px;">
          <div class="for-arguments">
            <h3 style="font-weight: bold; color: #2e7d32;">Argument för</h3>
            <ul>
              ${data.forArguments.map(arg => `<li>${arg}</li>`).join('')}
            </ul>
          </div>
          
          <div class="against-arguments">
            <h3 style="font-weight: bold; color: #c62828;">Argument emot</h3>
            <ul>
              ${data.againstArguments.map(arg => `<li>${arg}</li>`).join('')}
            </ul>
          </div>
        </div>

        ${Object.entries(data.educationalContent)
          .filter(([key]) => educationalOptions[key])
          .map(([key, content]) => {
            if (!content || (Array.isArray(content) && content.length === 0)) return '';

            return `
              <div class="educational-section" style="margin-top: 40px; page-break-inside: avoid;">
                <h3 style="font-weight: bold;">${getEducationalSectionTitle(key)}</h3>
                ${formatEducationalContent(key, content)}
              </div>
            `;
          })
          .join('')}`;

      setEditorContent(formattedContent);
      setProgress(100);
      await new Promise(resolve => setTimeout(resolve, 800)); // Longer delay for visibility

      showSnackbar('Lektionen är genererad!', 'success');
    } catch (error) {
      console.error('Error generating debate:', error);
      showSnackbar('Ett fel uppstod när lektionen skulle genereras. Försök igen.', 'error');
    } finally {
      setIsGenerating(false);
    }
  };

  // Add the missing function to map educational content types to Swedish titles
  const getEducationalSectionTitle = key => {
    const titles = {
      celebrities: 'Kända personers åsikter',
      exercises: 'Övningar för elever',
      sentenceStarters: 'Meningsstartare',
      historicalContext: 'Historisk kontext',
      statistics: 'Statistik och fakta',
      realExamples: 'Verkliga exempel',
      ethicalDilemmas: 'Etiska dilemman',
      rolePlay: 'Rollspelsscenarier',
      vocabulary: 'Ämnesspecifikt ordförråd',
      mediaAnalysis: 'Medieanalys',
      reflectionQuestions: 'Reflektionsfrågor efter debatten',
      whatIf: 'Tänk om-scenarier',
      futureImpact: 'Framtidsperspektiv',
      factOpinion: 'Fakta vs åsikter',
    };
    return titles[key] || key;
  };

  // Helper function to format educational content based on type
  // Update the formatEducationalContent function to handle all content types
  const formatEducationalContent = (type, content) => {
    if (!content) return '';

    switch (type) {
      case 'celebrities':
        return `
        <div class="quotes">
          ${content
            .map(
              ({ name, quote, source }) => `
            <blockquote style="border-left: 4px solid #ccc; padding-left: 20px; margin: 20px 0;">
              <p>"${quote}"</p>
              <footer>— ${name} <cite><a href="${source}" target="_blank">(Källa)</a></cite></footer>
            </blockquote>
          `
            )
            .join('')}
        </div>`;

      case 'exercises':
      case 'realExamples':
      case 'ethicalDilemmas':
      case 'whatIf':
      case 'rolePlay':
        return `
        <div class="${type}">
          <ol style="margin-left: 20px;">
            ${content.map(item => `<li style="margin-bottom: 10px;">${item}</li>`).join('')}
          </ol>
        </div>`;

      case 'historicalContext':
        return `
        <div class="historical-context">
          <p style="line-height: 1.6;">${content}</p>
        </div>`;

      case 'statistics':
        return `
        <div class="statistics">
          ${content
            .map(
              ({ fact, sourceUrl }) => `
            <div style="margin-bottom: 15px;">
              <p style="margin-bottom: 5px;">${fact}</p>
              <small>Källa: <a href="${sourceUrl}" target="_blank">${sourceUrl}</a></small>
            </div>
          `
            )
            .join('')}
        </div>`;

      case 'vocabulary':
        return `
        <div class="vocabulary">
          <table>
            <thead>
              <tr>
                <th>Ord</th>
                <th>Förklaring</th>
              </tr>
            </thead>
            <tbody>
              ${content
                .map(
                  ({ term, definition }) => `
                <tr>
                  <td>${term}</td>
                  <td>${definition}</td>
                </tr>
              `
                )
                .join('')}
            </tbody>
          </table>
        </div>`;

      case 'mediaAnalysis':
        return `
        <div class="media-analysis">
          <ul style="list-style-type: disc; margin-left: 20px;">
            ${content.map(point => `<li style="margin-bottom: 10px;">${point}</li>`).join('')}
          </ul>
        </div>`;

      case 'reflectionQuestions':
        return `
    <div class="reflection-questions">
      <ul style="list-style-type: disc; margin-left: 20px;">
        ${content.map(question => `<li style="margin-bottom: 10px;">${question}</li>`).join('')}
      </ul>
    </div>`;

      case 'futureImpact':
        return `
        <div class="future-impact">
          <ul>
            ${content.map(scenario => `<li>${scenario}</li>`).join('')}
          </ul>
        </div>`;

      case 'factOpinion':
        return `
        <div class="fact-opinion-table">
          <table >
            <thead>
              <tr>
                <th >Fakta</th>
                <th>Åsikter</th>
              </tr>
            </thead>
            <tbody>
              ${content
                .map(
                  ({ fact, opinion }) => `
                <tr>
                  <td  >${fact}</td>
                  <td>${opinion}</td>
                </tr>
              `
                )
                .join('')}
            </tbody>
          </table>
        </div>`;

      case 'sentenceStarters':
        return `
        <div class="sentence-starters">
          <div>
            <h4>Allmänna meningsstartare</h4>
            <ol>
              ${content.general.map(item => `<li>${item}</li>`).join('')}
            </ol>
          </div>
          <div>
            <h4>Meningsstartare för argument för</h4>
            <ol>
              ${content.for.map(item => `<li>${item}</li>`).join('')}
            </ol>
          </div>
          <div>
            <h4>Meningsstartare för argument mot</h4>
            <ol>
              ${content.against.map(item => `<li>${item}</li>`).join('')}
            </ol>
          </div>
        </div>`;

      default:
        return `<div>${Array.isArray(content) ? content.join('<br>') : content}</div>`;
    }
  };

  const handleImageButtonClick = () => {
    setImageGenerationType('debate');
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
  };

  const handleGenerateImages = async () => {
    setIsGeneratingImages(true);
    try {
      const selectedTheme = themeType === 'custom' ? customTheme : debateTheme;
      const imageUrl = await generateAndProcessImage(
        `Create a symbolic, conceptual image representing the debate topic: "${selectedTheme}". Style: professional, thought-provoking, neutral perspective, no text. ${imageInstructions}`,
        user.uid,
        {
          size: '1024x1024',
          model: 'dall-e-3',
          quality: 'standard',
        }
      );

      const imageHtml = `
        <div style="margin: 24px 0;">
          <img src="${imageUrl}" alt="Debate theme illustration" 
               style="width: 100%; max-width: 1024px; height: auto; border-radius: 8px;">
        </div>
      `;

      const newContent = editorContent.replace(
        '<div id="image-insertion-point"></div>',
        imageHtml + '<div id="image-insertion-point"></div>'
      );

      setEditorContent(newContent);
      showSnackbar('Bild har genererats!', 'success');
    } catch (error) {
      console.error('Error generating image:', error);
      showSnackbar('Ett fel uppstod när bilden skulle genereras', 'error');
    } finally {
      setIsGeneratingImages(false);
      handleCloseModal();
    }
  };

  // Arguments Configuration section fix
  const ArgumentConfig = ({ config, setConfig, title, color }) => (
    <>
      <Typography variant='h6' sx={{ mb: 4, color, fontWeight: '600' }}>
        {title}
      </Typography>

      <Typography sx={{ mb: 1, fontWeight: '500' }}>Ton:</Typography>
      <Select
        fullWidth
        value={config.tone}
        onChange={e => setConfig({ ...config, tone: e.target.value })}
        sx={{ mb: 4 }}
      >
        {TONE_OPTIONS.map(tone => (
          <MenuItem key={tone} value={tone}>
            {tone}
          </MenuItem>
        ))}
      </Select>

      <Typography sx={{ mb: 1, fontWeight: '500' }}>Antal argument:</Typography>
      <Select
        fullWidth
        value={config.numArguments}
        onChange={e => setConfig({ ...config, numArguments: e.target.value })}
        sx={{ mb: 4 }}
      >
        {[...Array(11)].map((_, i) => (
          <MenuItem key={i} value={i}>
            {i}
          </MenuItem>
        ))}
      </Select>

      <Typography sx={{ fontWeight: '500' }}>Längd per argument (meningar):</Typography>
      <Slider
        sx={{ mt: 3 }}
        value={config.argumentLength}
        onChange={(e, newValue) => setConfig({ ...config, argumentLength: newValue })}
        valueLabelDisplay='auto'
        min={1}
        max={5}
      />
    </>
  );

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: '100%', md: '87%' },
          margin: '0 auto',
        }}
      >
        <Typography
          variant='h1'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
        >
          Debattövning
        </Typography>

        <InfoBox
          icon={GavelIcon}
          title='Debatt Generator'
          instructions={[
            'Välj ett debattämne eller skapa ett eget',
            'Konfigurera argument för båda sidor',
            'Lägg till en illustrerande bild',
            'Skriv ut lektionen',
          ]}
          theme={theme}
          styles={styles}
          teachersGuide='argumentativeText'
          user={user}
        />

        <Box sx={{ ...styles.freshBox, padding: '30px' }}>
          <LoadingBar color={theme.palette.primary.main} ref={loadingBarRef} shadow={true} />

          {/* Theme Selection */}
          <FormControl fullWidth sx={{ mb: 3 }}>
            <Typography variant='subtitle1' sx={{ mb: 1, fontWeight: '500' }}>
              Välj debattämne:
            </Typography>
            <Select value={themeType} onChange={e => setThemeType(e.target.value)}>
              <MenuItem value='predefined' sx={{ py: 1 }}>
                <Grid container alignItems='center' spacing={2}>
                  <Grid item>
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <KeyboardDoubleArrowDownIcon sx={{ fontSize: 22, color: 'gray' }} />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography>Från lista</Typography>
                  </Grid>
                </Grid>
              </MenuItem>
              <MenuItem value='custom' sx={{ py: 1 }}>
                <Grid container alignItems='center' spacing={2}>
                  <Grid item>
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <EditIcon sx={{ fontSize: 22, color: 'gray' }} />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography>Eget ämne</Typography>
                  </Grid>
                </Grid>
              </MenuItem>
              <MenuItem value='ai' sx={{ py: 1 }}>
                <Grid container alignItems='center' spacing={2}>
                  <Grid item>
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <AutoAwesomeIcon sx={{ fontSize: 22, color: 'gray' }} />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography>Låt AI generera</Typography>
                  </Grid>
                </Grid>
              </MenuItem>
            </Select>

            {themeType === 'predefined' && (
              <Select
                value={debateTheme}
                onChange={e => setDebateTheme(e.target.value)}
                sx={{ mt: 2 }}
              >
                {DEBATE_THEMES.map(theme => {
                  let icon;
                  switch (theme) {
                    case 'Dödsstraff':
                      icon = <GavelIcon />;
                      break;
                    case 'Aborträtt':
                      icon = <MedicalServicesIcon />;
                      break;
                    case 'Vapenkontroll':
                      icon = <GavelIcon />;
                      break;
                    case 'Basinkomst':
                      icon = <PublicIcon />;
                      break;
                    case 'Kärnkraft':
                      icon = <ElectricBoltIcon />;
                      break;
                    case 'Sociala medier-reglering':
                      icon = <PublicIcon />;
                      break;
                    case 'Djurförsök':
                      icon = <PetsIcon />;
                      break;
                    case 'Genmodifiering':
                      icon = <BiotechIcon />;
                      break;
                    case 'AI-utveckling':
                      icon = <SmartToyIcon />;
                      break;
                    case 'Obligatorisk vaccination':
                      icon = <MedicationIcon />;
                      break;
                    case 'Socialism vs Kapitalism':
                      icon = <MonetizationOnIcon />;
                      break;
                    case 'Privatisering av skolan':
                      icon = <SchoolIcon />;
                      break;
                    case 'Klimatkompensation':
                      icon = <NatureIcon />;
                      break;

                    case 'Hyresreglering':
                      icon = <HomeIcon />;
                      break;
                    case 'Migration och integration':
                      icon = <Diversity3Icon />;
                      break;
                    case 'Skattereform':
                      icon = <AccountBalanceIcon />;
                      break;
                    case 'Polisens befogenheter':
                      icon = <LocalPoliceIcon />;
                      break;
                    case 'E-sport som skolämne':
                      icon = <SportsEsportsIcon />;
                      break;
                    case 'Pressfrihet':
                      icon = <NewspaperIcon />;
                      break;
                    case 'Läkemedelspatent':
                      icon = <MedicationIcon />;
                      break;
                    case 'Industrialisering vs miljö':
                      icon = <FactoryIcon />;
                      break;
                    default:
                      icon = <GavelIcon />;
                      break;
                  }

                  return (
                    <MenuItem key={theme} value={theme} sx={{ py: 1 }}>
                      <Grid container alignItems='center' spacing={2}>
                        <Grid item>
                          <Box
                            sx={{
                              width: 24,
                              height: 24,
                              borderRadius: '50%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {React.cloneElement(icon, { sx: { fontSize: 22, color: 'gray' } })}
                          </Box>
                        </Grid>
                        <Grid item>
                          <Typography>{theme}</Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  );
                })}
              </Select>
            )}

            {themeType === 'custom' && (
              <TextField
                value={customTheme}
                onChange={e => setCustomTheme(e.target.value)}
                placeholder='Skriv ditt ämne här'
                sx={{ mt: 2 }}
              />
            )}
          </FormControl>

          {/* Language and Grade Selection */}
          <Typography variant='subtitle1' sx={{ fontWeight: '500' }}>
            Språk:
          </Typography>
          <Box sx={{ mt: 1, mb: 3 }}>
            <LanguageSelector language={language} setLanguage={setLanguage} />
          </Box>

          <Typography variant='subtitle1' sx={{ fontWeight: '500' }}>
            Årskurs:
          </Typography>
          <Box sx={{ mt: 1, mb: 4 }}>
            <GradeSelector grade={grade} setGrade={setGrade} />
          </Box>

          <Divider sx={{ my: 3 }} />

          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <ArgumentConfig
                config={forConfig}
                setConfig={setForConfig}
                title='Argument för'
                color='#197b19'
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ArgumentConfig
                config={againstConfig}
                setConfig={setAgainstConfig}
                title='Argument emot'
                color='#9b1e1e'
              />
            </Grid>
          </Grid>
          {/* Educational Options Section */}
          <Box sx={{ mt: 4 }}>
            <Divider sx={{ mb: 3 }} />
            <Typography variant='h6' sx={{ mb: 2, fontWeight: '600' }}>
              Inkludera läromaterial
            </Typography>

            <Grid container spacing={1}>
              {EDUCATIONAL_OPTIONS.map(option => (
                <Grid item xs={12} sm={6} md={4} key={option.id}>
                  <CustomFormControlLabel
                    control={<Checkbox />}
                    label={option.label}
                    checked={educationalOptions[option.id]}
                    onChange={e =>
                      setEducationalOptions({
                        ...educationalOptions,
                        [option.id]: e.target.checked,
                      })
                    }
                    theme={theme}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Generate Buttons */}
          <Box>
            <Box sx={{ mt: 4, mb: 4 }}>
              <Button
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                color='primary'
                onClick={handleGenerateDebate}
                disabled={isGenerating}
                startIcon={
                  !isGenerating ? (
                    <GenerateIcon style={{ color: '#fff' }} />
                  ) : (
                    <CircularProgress size={16} />
                  )
                }
              >
                {isGenerating ? 'Genererar...' : 'Generera lektion'}
              </Button>
            </Box>

            <Divider sx={{ mb: 4 }} />

            {editorContent && (
              <>
                <Button
                  variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                  color='primary'
                  onClick={handleImageButtonClick}
                  disabled={isGeneratingImages}
                  sx={{}}
                  startIcon={
                    !isGeneratingImages ? (
                      <ImageIcon style={{ color: '#fff' }} />
                    ) : (
                      <CircularProgress size={16} />
                    )
                  }
                >
                  {isGeneratingImages ? 'Genererar...' : 'Generera bild'}
                </Button>

                <Button
                  variant='contained'
                  startIcon={<PrintIcon />}
                  sx={{ ml: 2 }}
                  onClick={() => {
                    if (editorRef.current) {
                      const content = editorRef.current.getContent();
                      const printWindow = window.open('', '_blank');
                      printWindow.document.write(`
                        <html>
                          <head>
                            <style>
                              body { 
                                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 
                                          Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
                                line-height: 1.6;
                                padding: 20px;
                              }
                            </style>
                          </head>
                          <body>${content}</body>
                        </html>
                      `);
                      printWindow.document.close();
                      printWindow.print();
                    }
                  }}
                >
                  Skriv ut
                </Button>
              </>
            )}
          </Box>

          {isGenerating && (
            <Box sx={{ mt: 6 }}>
              <ProgressBar progress={progress} label='Genererar lektion...' />
            </Box>
          )}

          <Box sx={{ mt: 4 }}>
            <Editor
              apiKey='fxkb0ty514q6ak7t7lo34gojjloffnpw3n9fr8tdz3swjxi3'
              onInit={(evt, editor) => (editorRef.current = editor)}
              value={editorContent}
              onEditorChange={(content, editor) => setEditorContent(content)}
              init={{
                height: 800,
                menubar: false,
                plugins: [
                  'advlist',
                  'autolink',
                  'lists',
                  'link',
                  'image',
                  'charmap',
                  'preview',
                  'anchor',
                  'searchreplace',
                  'visualblocks',
                  'code',
                  'fullscreen',
                  'insertdatetime',
                  'media',
                  'table',
                  'code',
                  'help',
                  'wordcount',
                ],
                toolbar:
                  'undo redo | blocks | ' +
                  'bold italic forecolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style:
                  'body { font-family: -apple-system, BlinkMacSystemFont, sans-serif; line-height: 28px !important; } p { line-height: 28px !important; } mark { font-weight: bold; padding: 5px; background: #dcccff; border-radius:5px; } h3 { margin-top: 60px !important; } ul li { margin-bottom: 12px !important; line-height: 28px !important; } ul, ol { margin:20px !important } table { width: 100% !important; border-collapse: collapse !important; } th, td { padding: 10px !important; border: 1px solid #ddd !important; text-align: left !important; } table, th, td { border: 1px solid #ddd !important; }',

                skin: theme.palette.mode === 'dark' ? 'oxide-dark' : 'oxide',
                content_css: theme.palette.mode === 'dark' ? 'dark' : 'default',
              }}
            />
          </Box>

          <ImageGenerationModal
            open={showImageModal}
            onClose={handleCloseModal}
            onGenerate={handleGenerateImages}
            isGenerating={isGeneratingImages}
            instructions={imageInstructions}
            setInstructions={setImageInstructions}
            generationType={imageGenerationType}
            theme={theme}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ArgumentationLesson;
