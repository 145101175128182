import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  useTheme,
} from '@mui/material';

const QuestionDisplay = ({ question, index, type }) => {
  const theme = useTheme();

  const renderQuestionContent = () => {
    switch (type) {
      case 'multiple-choice':
        return (
          <RadioGroup>
            {question.options.map((option, optIdx) => (
              <FormControlLabel
                key={optIdx}
                value={option}
                control={<Radio />}
                label={option}
                sx={{ mb: 1 }}
              />
            ))}
          </RadioGroup>
        );

      case 'fill-in-the-blank':
        return (
          <Box>
            <Typography variant='body1' sx={{ mb: 2 }}>
              {question.question.split('_').map((part, i, arr) => (
                <React.Fragment key={i}>
                  {part}
                  {i < arr.length - 1 && (
                    <TextField
                      variant='standard'
                      size='small'
                      sx={{
                        width: '120px',
                        mx: 1,
                        borderBottom: `1px solid ${theme.palette.divider}`,
                      }}
                    />
                  )}
                </React.Fragment>
              ))}
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Typography
                variant='subtitle2'
                color='textSecondary'
                sx={{ fontSize: '18px', mb: 2 }}
              >
                Alternativ:
              </Typography>
              {question.options.map((option, idx) => (
                <Typography key={idx} variant='body2' sx={{ ml: 2, fontSize: '16px' }}>
                  • {option}
                </Typography>
              ))}
            </Box>
          </Box>
        );

      case 'true-or-false':
        return (
          <RadioGroup>
            <FormControlLabel value='Sant' control={<Radio />} label='Sant' />
            <FormControlLabel value='Falskt' control={<Radio />} label='Falskt' />
          </RadioGroup>
        );

      default:
        return (
          <RadioGroup>
            {question.options.map((option, optIdx) => (
              <FormControlLabel key={optIdx} value={option} control={<Radio />} label={option} />
            ))}
          </RadioGroup>
        );
    }
  };

  return (
    <Card
      elevation={0}
      sx={{
        mb: 3,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography
            variant='h6'
            sx={{
              fontWeight: 600,
              color: theme.palette.text.primary,
            }}
          >
            Fråga {index + 1}:
          </Typography>
          <Typography
            variant='body2'
            sx={{
              color: theme.palette.text.secondary,
              alignSelf: 'center',
            }}
          >
            {type === 'multiple-choice'
              ? 'Flerval'
              : type === 'fill-in-the-blank'
                ? 'Lucktext'
                : type === 'true-or-false'
                  ? 'Sant/Falskt'
                  : 'Flerval'}{' '}
            {/* Default to Flerval if unknown type */}
          </Typography>
        </Box>

        <Typography
          variant='body1'
          sx={{
            mb: 3,
            color: theme.palette.text.primary,
          }}
        >
          {type !== 'fill-in-the-blank' && question.question}
        </Typography>

        {renderQuestionContent()}
      </CardContent>
    </Card>
  );
};

const TeacherQuizDisplay = ({ quiz, quizName, questionType }) => {
  const theme = useTheme();

  return (
    <Box sx={{ width: '100%', mt: 4, mb: 6 }}>
      <Card
        elevation={0}
        sx={{
          mb: 4,
          p: 3,
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography variant='h4' gutterBottom sx={{ fontWeight: '600', fontSize: '27px' }}>
          {quizName}
        </Typography>
        <Typography variant='body2' color='textSecondary'>
          {quiz.length} frågor •{' '}
          {questionType === 'multiple-choice'
            ? 'Flervalsfrågor'
            : questionType === 'fill-in-the-blank'
              ? 'Lucktexter'
              : questionType === 'true-or-false'
                ? 'Sant/Falskt-frågor'
                : 'Flervalsfrågor'}{' '}
          {/* Default to multiple choice if unknown type */}
        </Typography>
      </Card>

      {quiz.map((question, index) => (
        <QuestionDisplay key={index} question={question} index={index} type={questionType} />
      ))}

      <Card
        elevation={0}
        sx={{
          mt: 15,
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <CardContent>
          <Typography variant='h6' gutterBottom sx={{ fontWeight: '600' }}>
            Facit
          </Typography>
          {quiz.map((question, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Typography>
                Fråga {index + 1}: {question.correct}
              </Typography>
            </Box>
          ))}
        </CardContent>
      </Card>
    </Box>
  );
};

export default TeacherQuizDisplay;
