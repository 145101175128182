import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Divider,
  TextField,
  CircularProgress,
  Grid,
  Avatar,
} from '@mui/material';
import GenerateIcon from '@mui/icons-material/AutoAwesome';

import InfoBox from './InfoBox';
import { callApi } from './api'; // Adjust import path as needed

import { useSnackbar } from './SnackbarHandler';
import LanguageSelector from './LanguageSelector';
import { RiFilePpt2Fill } from 'react-icons/ri';

import ProgressBar from './ProgressBar'; // Import the ProgressBar component
import ImageGenerationModal from './ImageGenerationModal';

import Logger from './Logger';
import { FourSquare } from 'react-loading-indicators';

import PptxGenerator from './PptxGenerator';

import ImageIcon from '@mui/icons-material/Image';

import { generateAndProcessImage } from './functions';
import { generateImage } from './api'; // Add this import
import { db, storage } from './firebase'; // Add this import
import { collection, addDoc } from 'firebase/firestore'; // Add this import
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Add this import
import { useSearchParams } from 'react-router-dom';
import { ThemeSelector } from './ThemeSelector';

const PowerPointGenerator = ({ user, theme, styles, grade }) => {
  const [searchParams] = useSearchParams();

  const urlToTheme = {
    jul: 'christmas',
    host: 'fall',
    var: 'spring',
    vinter: 'winter',
    sommar: 'summer',
    pask: 'easter',
    halloween: 'halloween',
    nyar: 'newYear',
  };

  const [lessonTheme, setLessonTheme] = useState(() => {
    const urlTheme = searchParams.get('tema');
    return urlToTheme[urlTheme] || urlTheme || 'generateWithAi';
  });

  const [topic, setTopic] = useState('');
  const [subject, setSubject] = useState('');

  const [language, setLanguage] = useState('svenska');
  const [highLevelFactCount, setHighLevelFactCount] = useState(2);
  const [triviaPerFactCount, setTriviaPerFactCount] = useState(5);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [lessonData, setLessonData] = useState(null);
  const [themeColor, setThemeColor] = useState('#000000');
  const [isGeneratingTopicImage, setIsGeneratingTopicImage] = useState(false);
  const [isGeneratingFactImages, setIsGeneratingFactImages] = useState(false);
  const [progress, setProgress] = useState(0);
  const [lessonProgress, setLessonProgress] = useState(0); // Add this new state
  const [questionType, setQuestionType] = useState('multipleChoice');
  const [isGeneratingQuiz, setIsGeneratingQuiz] = useState(false);
  const [quizData, setQuizData] = useState(null);
  const [generatedFactImages, setGeneratedFactImages] = useState(new Set());
  const { showSnackbar } = useSnackbar();

  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const [imageGenerationType, setImageGenerationType] = useState(null);

  const [openAiInstructions, setOpenAiInstructions] = useState('');

  const [customTheme, setCustomTheme] = useState('');

  const topicSectionRef = useRef(null);
  const factsSectionRef = useRef(null);

  const handleImageButtonClick = type => {
    setImageGenerationType(type);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
  };

  const handleGenerateWithInstructions = async () => {
    handleCloseModal();

    if (imageGenerationType === 'topic') {
      await handleGenerateTopicImage();
    } else if (imageGenerationType === 'facts') {
      await handleGenerateFactImages();
    }
  };

  const scrollToSection = ref => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const simulateProgress = (startProgress, endProgress, duration, setProgressFn) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgressFn(currentProgress);
      }
    }, stepDuration);

    // Return a cleanup function that can be called to stop the simulation
    return () => {
      clearInterval(intervalId);
    };
  };
  const handleGenerateTopicImage = async () => {
    setIsGeneratingTopicImage(true);
    scrollToSection(topicSectionRef);
    try {
      const prompt = `Create a image of ${lessonData.topic}. Do not add any text inside the image. ${imageInstructions}`;

      const imageUrl = await generateAndProcessImage(prompt, user.uid, {
        size: '1024x1024',
        model: 'dall-e-3',
        quality: 'standard',
      });

      setLessonData(prevData => ({
        ...prevData,
        topicImage: imageUrl,
      }));

      showSnackbar('Bild för temat är nu genererad.', 'success');
    } catch (error) {
      showSnackbar('Något gick fel när vi försökte generera bilder. Försök igen.', 'error');
    } finally {
      setIsGeneratingTopicImage(false);
    }
  };

  const handleGenerateFactImages = async () => {
    setIsGeneratingFactImages(true);
    setGeneratedFactImages(new Set());
    scrollToSection(factsSectionRef);
    try {
      const entries = Object.entries(lessonData.highLevelFacts);
      let completedCount = 0;
      const totalCount = entries.length;

      for (const [key, value] of entries) {
        try {
          const prompt = `Create an image for the fact: "${key}" within the topic of "${lessonData.topic}". Do not add any text inside the image. ${imageInstructions}`;

          const imageUrl = await generateAndProcessImage(prompt, user.uid, {
            size: '1024x1024',
            model: 'dall-e-3',
            quality: 'standard',
          });

          setLessonData(prevData => ({
            ...prevData,
            highLevelFacts: {
              ...prevData.highLevelFacts,
              [key]: {
                ...prevData.highLevelFacts[key],
                image: imageUrl,
              },
            },
          }));

          setGeneratedFactImages(prev => new Set([...prev, key]));
          completedCount++;
          showSnackbar(`Genererat bild ${completedCount} av ${totalCount}`, 'info');
        } catch (error) {
          console.error(`Error generating image for fact "${key}":`, error);
          showSnackbar(`Kunde inte generera bild för "${key}"`, 'warning');
          continue;
        }
      }

      showSnackbar('Alla bilder har genererats!', 'success');
    } catch (error) {
      console.error('Error in image generation process:', error);
      showSnackbar('Ett fel uppstod i bildgenereringsprocessen', 'error');
    } finally {
      setIsGeneratingFactImages(false);
    }
  };

  const handleGenerateLesson = async () => {
    setIsGeneratingLesson(true);
    setLessonProgress(0);
    let stopProgressSimulation = null;
    try {
      const generateTheme = lessonTheme === 'generateWithAi';
      const themeToUse = lessonTheme === 'customTheme' ? customTheme : lessonTheme;

      stopProgressSimulation = simulateProgress(0, 90, 15000, setLessonProgress);

      const response = await callApi(
        [
          {
            role: 'system',
            content: `You are an assistant that generates interesting school lessons . ${openAiInstructions}`,
          },
          {
            role: 'user',
            content: `Generate a JSON object with a lesson for students to learn new facts about the topic ${topic} within the subject of ${subject} in "${language}". 
            It should be exactly ${triviaPerFactCount} few and short interesting facts per high-level fact about the topic. 
            The amount of high-level facts should be exactly ${highLevelFactCount}. 
            Include empty image placeholders (null) for "topicImage" and "image" fields in each high-level fact. 
            Prioritize novelty and uniqueness in each generation. Do NOT repeat themes from previous responses. ${
              generateTheme
                ? 'Generate a COMPLETELY UNIQUE and RANDOM theme.'
                : lessonTheme === 'customTheme'
                  ? `Generate content about the specific topic "${themeToUse}".`
                  : `Generate a specific and UNIQUE topic WITHIN the chosen domain "${themeToUse}".`
            } Example response:
{
  "topic": "Gravitation",
  "clue": "En kraft som håller oss kvar på jorden",
  "topicImage": null,
  "highLevelFacts": {
    "Tyngdkraft på jorden": {
      "image": null,
      "facts": [
        "fact 1",
        "fact 2",
        "fact 3",
        "fact 4",
        "fact 5"
      ]
    },
    "Gravitation i rymden": {
      "image": null,
      "facts": [
        "fact 1",
        "fact 2",
        "fact 3",
        "fact 4",
        "fact 5"
      ]
    },
    "Hur gravitation upptäcktes": {
      "image": null,
      "facts": [
        "fact 1",
        "fact 2",
        "fact 3",
        "fact 4",
        "fact 5"
      ]
    }
  }
}`,
          },
        ],
        4000,
        true,
        user.uid
      );

      // Stop the progress simulation
      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setLessonProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);

      setLessonProgress(100);
      await new Promise(resolve => setTimeout(resolve, 800)); // Longer delay for visibility

      setLessonData(data);

      showSnackbar('Förhandsvisningen har blivit genererad.', 'success');
    } catch (error) {
      console.error('Error generating lesson:', error);
      showSnackbar('Nåt gick fel. Vänligen försök igen.', 'error');
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const isGeneratingAnyImages = isGeneratingTopicImage || isGeneratingFactImages;

  return (
    <>
      <Box sx={styles.container}>
        <Box
          sx={{
            ...styles.innerContainer,
            width: { xs: '100%', md: '87%' },
            margin: '0 auto',
          }}
        >
          <Typography
            variant='h1'
            gutterBottom
            sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
          >
            Generera Powerpoint-slides
          </Typography>
          <InfoBox
            icon={RiFilePpt2Fill}
            title='Powerpoint Instructions'
            instructions={[
              'Generera förhandsvisning av din powerpoint',
              'Lägg till AI-genererade bilder',
              'Ladda ned dina slides',
            ]}
            theme={theme}
            styles={styles}
          />
          <Box sx={{ ...styles.freshBox, padding: '30px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                  Ämne:
                </Typography>
                <FormControl fullWidth margin='normal' sx={{ mb: 1, mt: 1 }}>
                  <TextField
                    value={subject}
                    onChange={e => setSubject(e.target.value)}
                    placeholder='T.ex. Litteratur'
                    variant='outlined'
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                  Specifikt område:
                </Typography>
                <FormControl fullWidth margin='normal' sx={{ mb: 1, mt: 1 }}>
                  <TextField
                    value={topic}
                    onChange={e => setTopic(e.target.value)}
                    placeholder='T.ex. Virginia Woolf'
                    variant='outlined'
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                  Antal slides:
                </Typography>
                <FormControl fullWidth margin='normal' sx={{ mb: 1, mt: 1 }}>
                  <Select
                    value={highLevelFactCount}
                    onChange={e => setHighLevelFactCount(e.target.value)}
                  >
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                  Antal punkter per slide:
                </Typography>
                <FormControl fullWidth margin='normal' sx={{ mb: 1, mt: 1 }}>
                  <Select
                    value={triviaPerFactCount}
                    onChange={e => setTriviaPerFactCount(e.target.value)}
                  >
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                  Språk:
                </Typography>
                <Box sx={{ mt: 1, mb: 1 }}>
                  <LanguageSelector language={language} setLanguage={setLanguage} />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                  Specifika instruktioner till AI:n:
                </Typography>
                <FormControl fullWidth margin='normal' sx={{ mb: 3, mt: 1 }}>
                  <TextField
                    value={openAiInstructions}
                    onChange={e => setOpenAiInstructions(e.target.value)}
                    placeholder='T.ex. Ta endast med information mellan åren 1933-1936.'
                    variant='outlined'
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Box>
              <Button
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                color='primary'
                onClick={handleGenerateLesson}
                disabled={isGeneratingLesson || isGeneratingAnyImages}
                sx={{ mt: 2 }}
                startIcon={
                  !isGeneratingLesson ? (
                    <GenerateIcon style={{ color: '#fff' }} />
                  ) : (
                    <CircularProgress style={{ color: '#fff' }} size={16} />
                  )
                }
              >
                {isGeneratingLesson ? 'Genererar slides...' : 'Generera slides'}
              </Button>
            </Box>

            {isGeneratingLesson && (
              <Box sx={{ mt: 7 }}>
                <ProgressBar progress={lessonProgress} label='Genererar slides...' />
              </Box>
            )}

            {lessonData && (
              <>
                <Divider sx={{ mt: 5, mb: 3 }} />
                <Button
                  variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                  color='primary'
                  onClick={() => handleImageButtonClick('topic')}
                  disabled={isGeneratingTopicImage || isGeneratingFactImages}
                  sx={{ mt: 2 }}
                  startIcon={
                    !isGeneratingTopicImage ? (
                      <ImageIcon style={{ color: '#fff' }} />
                    ) : (
                      <CircularProgress style={{ color: '#fff' }} size={16} />
                    )
                  }
                >
                  {isGeneratingTopicImage ? 'Genererar...' : 'Lägg till temabild'}
                </Button>

                <Button
                  variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                  color='primary'
                  onClick={() => handleImageButtonClick('facts')}
                  disabled={isGeneratingTopicImage || isGeneratingFactImages}
                  sx={{ mt: 2, ml: 2 }}
                  startIcon={
                    !isGeneratingFactImages ? (
                      <ImageIcon style={{ color: '#fff' }} />
                    ) : (
                      <CircularProgress style={{ color: '#fff' }} size={16} />
                    )
                  }
                >
                  {isGeneratingFactImages ? 'Genererar...' : 'Lägg till bilder/slides'}
                </Button>

                <ImageGenerationModal
                  open={showImageModal}
                  onClose={handleCloseModal}
                  onGenerate={handleGenerateWithInstructions}
                  isGenerating={
                    imageGenerationType === 'topic'
                      ? isGeneratingTopicImage
                      : isGeneratingFactImages
                  }
                  instructions={imageInstructions}
                  setInstructions={setImageInstructions}
                  generationType={imageGenerationType}
                  theme={theme}
                />

                <PptxGenerator
                  lessonData={{
                    ...lessonData,
                    quizData: quizData, // Pass quiz data if you want to include it
                  }}
                  theme={theme}
                  disabled={isGeneratingAnyImages}
                />

                <Box sx={{ mt: 12 }}>
                  <Typography
                    variant='h6'
                    sx={{ fontWeight: 'bold', mb: 2, mt: 5, fontSize: '25px' }}
                  >
                    Titel-slide:
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={12} ref={topicSectionRef}>
                      {/* Topic Section */}
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '500px',
                          flexDirection: 'column',
                          backgroundColor: theme.palette.mode === 'dark' ? '#15202b' : '#e0e0e029',
                          borderRadius: '10px',
                        }}
                      >
                        <Typography variant='h3' sx={{ fontWeight: 'bold' }}>
                          {lessonData.topic}
                        </Typography>

                        <Box sx={{ position: 'relative', mt: 7 }}>
                          <Avatar
                            variant='rounded'
                            src={
                              lessonData.topicImage
                                ? lessonData.topicImage
                                : '/api/placeholder/800/400'
                            }
                            alt='Topic Image'
                            sx={{
                              width: '200px',
                              height: '200px',
                              borderRadius: '100%',
                            }}
                          />
                          {isGeneratingTopicImage && (
                            <Box
                              sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#d7d7d7',
                                borderRadius: '100%',
                              }}
                            >
                              <FourSquare color='#8b5cf6' size='medium' text='' textColor='' />
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sx={{
                        mt: 5,
                        '& p': {
                          lineHeight: '27px',
                        },
                        '& li': {
                          marginBottom: '12px',
                          lineHeight: '25px',
                          '& strong': {
                            fontWeight: '600',
                          },
                        },
                        '& h1': {
                          color: themeColor,
                        },
                        '& h2': {
                          marginTop: '60px',
                          marginBottom: '0px',
                          color: themeColor,
                        },
                        '& h3': {
                          marginTop: '40px',
                          marginBottom: '0px',
                          color: themeColor,
                        },
                        '& hr': {
                          height: '1px',
                          border: 'none',
                          backgroundColor: theme => theme.palette.divider,
                          marginTop: '50px',
                          marginBottom: '50px',
                        },
                        '& ul, & ol': {
                          '& li::marker': {
                            color: themeColor,
                          },
                        },
                      }}
                    >
                      {/* High-Level Facts */}
                      {/* High-Level Facts */}
                      {/* High-Level Facts */}
                      <Grid container spacing={4} ref={factsSectionRef}>
                        {Object.keys(lessonData.highLevelFacts).map((section, index) => (
                          <Grid item xs={12} key={index}>
                            <Typography
                              variant='h6'
                              sx={{
                                fontWeight: 'bold',
                                marginBottom: '20px',
                                mt: 5,
                                fontSize: '25px',
                                display: 'block',
                              }}
                            >
                              Slide {index + 1}: {section}
                            </Typography>

                            <Box
                              sx={{
                                backgroundColor:
                                  theme.palette.mode === 'dark' ? '#15202b' : '#e0e0e029',
                                borderRadius: '10px',
                                padding: '20px',
                              }}
                            >
                              <Grid container spacing={3} alignItems='center'>
                                <Grid item xs={12} md={4}>
                                  {/* High-Level Fact Image */}
                                  <Box
                                    sx={{
                                      position: 'relative',
                                      mb: { xs: 2, md: 0 },
                                      display: 'flex',
                                      alignItems: 'center',
                                      height: '100%',
                                    }}
                                  >
                                    <Avatar
                                      variant='rounded'
                                      src={
                                        lessonData.highLevelFacts[section].image
                                          ? lessonData.highLevelFacts[section].image
                                          : '/api/placeholder/600/300'
                                      }
                                      alt={`${section} Image`}
                                      sx={{
                                        width: '100%',
                                        height: '240px',
                                        aspectRatio: '16 / 9',
                                        backgroundColor: '#e0e0e0',
                                        borderRadius: '10px',
                                      }}
                                    />
                                    {isGeneratingFactImages &&
                                      !generatedFactImages.has(section) && (
                                        <Box
                                          sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: '#d7d7d7',
                                            borderRadius: '10px',
                                          }}
                                        >
                                          <FourSquare
                                            color='#8b5cf6'
                                            size='medium'
                                            text=''
                                            textColor=''
                                          />
                                        </Box>
                                      )}
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={8}
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  <ul>
                                    {lessonData.highLevelFacts[section].facts.map((fact, idx) => (
                                      <li key={idx}>
                                        <Typography variant='body1'>{fact}</Typography>
                                      </li>
                                    ))}
                                  </ul>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PowerPointGenerator;
