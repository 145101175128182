import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  Grid,
  CircularProgress,
  Typography,
} from '@mui/material';
import { Close, Image } from '@mui/icons-material';
import { styled } from '@mui/system';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import { useSnackbar } from './SnackbarHandler';
import BlurryBackdrop from './BlurryBackdrop';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  bottom: 8,
  left: 8,
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)',
  border: `1px dotted ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)'}`,
  borderRadius: '4px',
  padding: '8px',
  '&:hover': {
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.08)',
  },
}));

const FeedbackDialog = React.memo(({ open, onClose, user }) => {
  const [feedback, setFeedback] = useState('');
  const [email, setEmail] = useState('');
  const [images, setImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const theme = useTheme();
  const styles = getStyles(theme);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (user && user.email) {
      setEmail(user.email);
    }
  }, [user]);

  const handleFileUpload = useCallback(async event => {
    const files = Array.from(event.target.files);
    const storage = getStorage();

    setIsUploading(true);

    try {
      const uploadPromises = files.map(async file => {
        const storageRef = ref(storage, `feedback_images/${file.name}`);
        await uploadBytes(storageRef, file);
        return getDownloadURL(storageRef);
      });
      const uploadedUrls = await Promise.all(uploadPromises);
      setImages(prevImages => [...prevImages, ...uploadedUrls]);
    } catch (error) {
      console.error('Error uploading images: ', error);
    } finally {
      setIsUploading(false);
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    const db = getFirestore();
    try {
      await addDoc(collection(db, 'feedback'), {
        feedback,
        email,
        images,
        timestamp: new Date(),
      });
      onClose();
      // Reset form
      setFeedback('');
      setEmail('');
      setImages([]);

      showSnackbar(`Tack för din feedback.`, 'success');
    } catch (error) {
      console.error('Error submitting feedback: ', error);
      showSnackbar(`Knak i fogarna. Försök igen.`, 'error');
    }
  }, [feedback, email, images, onClose, showSnackbar]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='md'
      fullWidth
      BackdropComponent={BlurryBackdrop}
      PaperProps={{
        sx: {
          borderRadius: '20px',
          backgroundColor: theme.palette.mode === 'dark' ? '#22303C' : 'white',
          backgroundImage: theme.palette.mode === 'dark' ? 'none' : 'white',
          padding: { xs: '0px', md: '20px' },
        },
      }}
    >
      <DialogTitle>
        <strong>Ge oss din feedback</strong>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: 4 }}>
        <Typography variant='formLabelSinUnderline'>
          <strong>Feedback</strong>
        </Typography>

        <TextField
          margin='dense'
          id='feedback'
          label='Hjälp oss att förbättra verktyget genom att föreslå förbättringar & nya funktioner.'
          multiline
          rows={4}
          fullWidth
          variant='outlined'
          value={feedback}
          onChange={e => setFeedback(e.target.value)}
          sx={{ mb: 4 }}
          InputProps={{
            endAdornment: (
              <StyledIconButton component='label' disabled={isUploading}>
                {isUploading ? <CircularProgress size={24} /> : <Image />}
                <VisuallyHiddenInput
                  type='file'
                  multiple
                  onChange={handleFileUpload}
                  disabled={isUploading}
                />
              </StyledIconButton>
            ),
          }}
        />

        <Typography variant='formLabelSinUnderline'>
          <strong>E-post</strong> (Om du vill ha svar på din feedback. Givetvis frivilligt.)
        </Typography>
        <TextField
          margin='dense'
          id='email'
          type='email'
          fullWidth
          variant='outlined'
          value={email}
          onChange={e => setEmail(e.target.value)}
        />

        <Grid container spacing={2} sx={{ mt: 2 }}>
          {images.map((image, index) => (
            <Grid item key={index} xs={4} sm={3} md={2}>
              <img
                src={image}
                alt={`Uploaded ${index + 1}`}
                style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
          color='primary'
          disabled={isUploading}
        >
          Skicka feedback
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default FeedbackDialog;
