import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Divider,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Radio,
  RadioGroup,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import ImageIcon from '@mui/icons-material/Image';
import PrintIcon from '@mui/icons-material/Print';
import { useSearchParams } from 'react-router-dom';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import QuizIcon from '@mui/icons-material/Quiz';
import { callApi } from './api';
import LoadingBar from 'react-top-loading-bar';
import LanguageSelector from './LanguageSelector';
import InfoBox from './InfoBox';
import { GiBookshelf } from 'react-icons/gi';
import GavelIcon from '@mui/icons-material/Gavel';
import { generateAndProcessImage } from './functions';
import { useSnackbar } from './SnackbarHandler';
import ProgressBar from './ProgressBar'; // Import the ProgressBar component
import { ThemeSelector } from './ThemeSelector';
import ImageGenerationModal from './ImageGenerationModal';
import GradeSelector, { gradeInstructions } from './GradeSelector';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import { MenuBook, CompareArrows, Create, TextFields } from '@mui/icons-material';
import { CustomFormControlLabel } from './functions';

const ReadingComprehensionLesson = ({ user }) => {
  const [lessonType, setLessonType] = useState('basic');
  const [lessonTheme, setLessonTheme] = useState('generateWithAi');
  const [language, setLanguage] = useState('svenska');
  const [titleText, setTitleText] = useState('');
  const [grade, setGrade] = useState('åk6');
  const [progress, setProgress] = useState(0);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [editorContent, setEditorContent] = useState('');
  const [customTheme, setCustomTheme] = useState('');
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const [openAiInstructions, setOpenAiInstructions] = useState('');
  const editorRef = useRef(null);
  const loadingBarRef = useRef(null);
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);

  const [selectedExercises, setSelectedExercises] = useState({
    'on-the-lines': true,
    'between-the-lines': true,
    'beyond-the-lines': true,
    'true-false': false,
    'multiple-choice': false,
    'open-ended': false,
  });

  const [wordCount, setWordCount] = useState(5);
  const [wordType, setWordType] = useState('random');

  const [searchParams, setSearchParams] = useSearchParams();

  // Effect to handle URL query parameter
  useEffect(() => {
    const typeFromQuery = searchParams.get('type');
    if (typeFromQuery && lessonTypes.hasOwnProperty(typeFromQuery)) {
      setLessonType(typeFromQuery);
    }
  }, [searchParams]); // Dependencies array includes searchParams

  // Modify the lesson type change handler to update URL
  const handleLessonTypeChange = newType => {
    setLessonType(newType);
    setSearchParams({ type: newType });
  };

  // Uppdatera lessonTypes objektet med exempel-svar för varje typ
  const lessonTypes = {
    basic: {
      label: 'Grundläggande läsförståelse',
      description: 'Text med olika typer av förståelsefrågor',
      prompt: `Generera en text skriven på ${language} uppdelad i tydliga stycken på  nivå för ${grade} med följande frågetyper:
      - 5 sant/falskt påståenden
      - 5 flervalsfrågor med 3 alternativ var
      - 3 öppna frågor som testar djupare förståelse
      Texten ska vara engagerande och åldersanpassad.
      
Svara ALLTID i följande JSON-format:
      {
        "title": "Lektionens titel",
        "theme": "Lektionens tema",

        "content": "Huvudtexten här...",
        "exercises": [

   {
            "type": "on-the-lines",
            "title": "På raderna (svaret finns i texten)",
            "content": "<ol><li>Fråga som kan besvaras direkt från texten 1...</li><li>Fråga som kan besvaras direkt från texten 2...</li><li>Fråga som kan besvaras direkt från texten 3...</li></ol>"
          },
          {
            "type": "between-the-lines",
            "title": "Mellan raderna (dra slutsatser från texten)",
            "content": "<ol><li>Fråga som kräver att man drar slutsatser 1...</li><li>Fråga som kräver att man drar slutsatser 2...</li><li>Fråga som kräver att man drar slutsatser 3...</li></ol>"
          },
          {
            "type": "beyond-the-lines",
            "title": "Bortom raderna (egna tankar och analys)",
            "content": "<ol><li>Analytisk fråga som kräver eget tänkande 1...</li><li>Analytisk fråga som kräver eget tänkande 2...</li><li>Analytisk fråga som kräver eget tänkande 3...</li></ol>"
          },

          {
            "type": "true-false",
            "title": "Sant eller falskt?",
            "content": "<ol><li>Påstående 1</li>...</ol>"
          },
          {
            "type": "multiple-choice",
            "title": "Flervalsfrågor",
            "content": "<ol><li style='margin-bottom:25px'>Fråga 1<ul style='margin-top:6px'><li>Alt 1</li>...</ul></li>...</ol>"
          },
          {
            "type": "open-ended",
            "title": "Diskussionsfrågor",
            "content": "<ol><li>Öppen fråga 1...</li>...</ol>"
          }
        ],

      }`,
    },
    // {
    //   "type": "clues",
    //   "title": "Viktiga ledtrådar",
    //   "content": "<ul><li>Ledtråd 1...</li>...</ul>"
    // },
    // {
    //   "type": "red-herrings",
    //   "title": "Se upp för dessa villospår",
    //   "content": "<ul><li>Villospår 1...</li>...</ul>"
    // },
    detective: {
      label: 'Läsdetektiven',
      description: 'Hitta ledtrådar och dra slutsatser från texten',
      prompt: `Skapa ett mordfall med en förutbestämd lösning i form av en kortnovell. Texten ska vara skriven på ${language}.

      Denna berättelse är skapad för elever som ska agera detektiver och försöka lösa fallet. Novellen ska vara mellan 50 och 60 meningar och uppdelad i tydliga stycken.

    Stämning: Ge novellen en mystisk och engagerande ton, som fångar elevernas intresse från början.

    Intrig och ledtrådar: Introducera en gåta där ett mord har skett under märkliga omständigheter. Presentera misstänkta, möjliga motiv, och gömda ledtrådar genom hela berättelsen. Se till att några ledtrådar är subtila, så att eleverna måste läsa noggrant.

Ingen lösning i texten: Beskriv händelseförloppet men avslöja inte lösningen. Se till att eleverna behöver analysera ledtrådarna själva för att lista ut vem som är skyldig och varför.
      
      Svara i följande JSON-format:
      {
        "title": "Lektionens titel",
        "theme": "Mysterietema",
        "content": "Mysterietexten här...",
        "solution": "Lösningen på fallet här...",
        "exercises": [
 

   {
            "type": "scoring-clues",
            "title": "Poängledtrådar",
            "content": "<div class='scoring-clues'>
              <div class='clue-group'>
                <h4>12 poäng</h4>
                <ul><li>Mycket svår ledtråd som kräver noggrann läsning och analys...</li></ul>
              </div>
              <div class='clue-group'>
                <h4>10 poäng</h4>
                <ul><li>Svår ledtråd som kräver god analytisk förmåga...</li></ul>
              </div>
              <div class='clue-group'>
                <h4>8 poäng</h4>
                <ul><li>Medelnivå ledtråd som ger tydligare koppling till lösningen...</li></ul>
              </div>
              <div class='clue-group'>
                <h4>6 poäng</h4>
                <ul><li>Enklare ledtråd som pekar mer direkt mot lösningen...</li></ul>
              </div>
              <div class='clue-group'>
                <h4>4 poäng</h4>
                <ul><li>Tydlig ledtråd som ger stark indikation på lösningen...</li></ul>
              </div>
              <div class='clue-group'>
                <h4>2 poäng</h4>
                <ul><li>Mycket tydlig ledtråd som nästan avslöjar lösningen...</li></ul>
              </div>
            </div>"
          },

          {
            "type": "questions",
            "title": "Utredningsfrågor",
            "content": "<ol><li>Fråga 1...</li>...</ol>"
          },

                  {
            "type": "clues",
            "title": "Ledtrådarna",
            "content": "<ul><li>Ledtråd 1...</li>...</ul>"
          },
          {
            "type": "red-herrings",
            "title": "Villospår",
            "content": "<ul><li>Villospår 1...</li>...</ul>"
          },
        ],
        "teacherNotes": "Noter för läraren här..."
      }`,
    },

    compare: {
      label: 'Jämförande läsning',
      description: 'Analysera två relaterade texter',
      prompt: `Skapa två  texter om samma ämne från olika perspektiv. Texten ska vara skriven på ${language}.
      
Svara ALLTID i följande JSON-format:
      {
        "title": "Lektionens titel",
        "theme": "Jämförelsetema",

        "content": "<div class='text-1'><h3>Text 1</h3>Text här...</div><div class='text-2'><h3>Text 2</h3>Text här...</div>",
        "exercises": [
        {
            "type": "analysis",
            "title": "Analysera perspektiven",
            "content": "<ol><li>Analysfråga 1...</li>...</ol>"
          },
   {
          "type": "reading-comp",
          "title": "Läsförståelse",
          "content": "<ol><li>Vad är den viktigaste skillnaden mellan text 1 och text 2?</li><li>Vilken av texterna ger mest fakta för sina argument?</li><li>Hur skiljer sig språket åt mellan texterna?</li><li>Vilka olika slutsatser drar texterna och varför?</li></ol>"
        },
        {
          "type": "argument-comp",
          "title": "Jämför argumenten",
          "content": "<ol><li>Vilket argument i text 1 tycker du är starkast? Förklara varför.</li><li>Finns det några svagheter i argumenten i text 2?</li><li>Hittar du argument som går emot varandra i texterna? Ge exempel.</li><li>Vilken text övertygar dig mest? Motivera ditt svar.</li></ol>"
        },
        {
          "type": "analysis",
          "title": "Fördjupning",
          "content": "<ol><li>Finns det viktiga saker som båda texterna är överens om?</li><li>Vad tror du är orsaken till att författarna har olika syn på saken?</li><li>Saknas det någon viktig information i någon av texterna?</li><li>Om du fick ställa en fråga till varje författare, vad skulle du fråga?</li></ol>"
        }
          
        ],
        "teacherNotes": "Noter för läraren här..."
      }`,
    },
    creative: {
      label: 'Kreativ läsförståelse',
      description: 'Läs och skapa egen fortsättning',
      prompt: `Generera en ofullständig berättelse uppdelad i tydliga stycken med kreativa uppgifter. Texten ska vara skriven på ${language}.
      
Svara ALLTID i följande JSON-format:
      {
        "title": "Lektionens titel",
        "theme": "Berättelsetema",

        "content": "Den ofullständiga berättelsen här...",
        "exercises": [
          {
            "type": "comprehension",
            "title": "Förstå berättelsen",
            "content": "<ol><li>Förståelsefråga 1...</li>...</ol>"
          },
          {
            "type": "suggestions",
            "title": "Möjliga fortsättningar",
            "content": "<ul><li>Förslag 1...</li>...</ul>"
          },
          {
            "type": "writing",
            "title": "Skriv din egen fortsättning",
            "content": "<ol><li>Skrivuppgift 1...</li>...</ol>"
          }
        ],
        "teacherNotes": "Noter för läraren här..."
      }`,
    },
    // ...previous code remains the same...

    vocab: {
      label: 'Ordförståelse',
      description: 'Fokus på ordkunskap och språkutveckling',
      prompt: `Skapa en text mellan 50-60 meningar som är UPPDELAD I TYDLIGA STYCKEN med fokus på ordförståelse. 
      Använd '\\n\\n' för att separera styckena.
  Texten ska vara skriven på ${language}.
  
  FÖLJ DENNA STRUKTUR EXAKT:
  1. Välj först EXAKT ${wordCount} ord ${
    wordType === 'verbs'
      ? 'som alla ska vara verb (handlingsord, t.ex. springa, läsa, skriva)'
      : wordType === 'adjectives'
        ? 'som alla ska vara adjektiv (beskrivande ord, t.ex. glad, stor, vacker)'
        : wordType === 'nouns'
          ? 'som alla ska vara substantiv (namn på saker/personer/platser, t.ex. bord, lärare, park)'
          : wordType === 'pronouns'
            ? 'som alla ska vara pronomen (ersättningsord, t.ex. han, hon, den, det, denna)'
            : wordType === 'adverbs'
              ? 'som alla ska vara adverb (beskriver hur/när/var något sker, t.ex. snabbt, ofta, här)'
              : wordType === 'prepositions'
                ? 'som alla ska vara prepositioner (lägesord, t.ex. på, under, bredvid, genom)'
                : wordType === 'conjunctions'
                  ? 'som alla ska vara konjunktioner (bindeord, t.ex. och, men, eller, eftersom)'
                  : wordType === 'interjections'
                    ? 'som alla ska vara interjektioner (utrop, t.ex. oj, hej, aj)'
                    : wordType === 'numerals'
                      ? 'som alla ska vara räkneord (t.ex. tre, första, dussin)'
                      : 'av blandade ordtyper'
  }


  I "selectedWords" ska du lista orden i EXAKT samma form som de förekommer i texten.

  OBS: All text i responsen ska vara på ${language}, så även titeln på excercises och texten i tabellcellerna.


  SVARA I FÖLJANDE JSON-FORMAT:
  {
    "title": "Lektionens titel",
    "theme": "Ordtema",
    "selectedWords": "ord1, ord2, ord3...", // EXAKT ${wordCount} ord här, kommaseparerade. Varje ord MÅSTE finnas i texten.
    "content": "Text på ${language} här... Se till att ALLA ord från selectedWords finns i texten, men ANVÄND INTE mark-taggar här.",
    "exercises": [
      {
        "type": "definitions",
        "title": "Ordförklaringar",
        "content": "<table><tr><th>Ord</th><th>Förklaring</th></tr><tr><td>ord1</td><td>Förklaring...</td></tr>... ANVÄND EXAKT SAMMA ORD SOM I selectedWords</table>"
      },
      {
        "type": "synonyms",
        "title": "Synonymer och motsatsord",
        "content": "<table><tr><th>Ord</th><th>Synonymer</th><th>Motsatsord</th></tr><tr><td>ord1</td><td>...</td><td>...</td></tr>... ANVÄND EXAKT SAMMA ORD SOM I selectedWords</table>"
      },
      {
        "type": "usage",
        "title": "Använd orden",
        "content": "<ol><li>Uppgift...</li>...</ol>"
      }
    ]
  }`,
    },

    // ...rest of the code remains the same...
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateLesson = async () => {
    setProgress(0);
    let stopProgressSimulation = null;
    setIsGeneratingLesson(true);
    const gradeInstructionsText = gradeInstructions[grade];

    try {
      const generateTheme = lessonTheme === 'generateWithAi';
      const finalTheme = generateTheme
        ? '[RANDOM]'
        : lessonTheme === 'customTheme'
          ? customTheme
          : lessonTheme;
      stopProgressSimulation = simulateProgress(0, 90, 15000, setProgress);

      let response = await callApi(
        [
          {
            role: 'system',
            content: `You are an expert teacher creating engaging reading comprehension lessons 
          in ${language}.  Follow the exact JSON structure provided in the prompt.`,
          },
          {
            role: 'user',
            content: `${lessonTypes[lessonType].prompt}
          Theme: "${finalTheme}".
           ${openAiInstructions}.
          ${gradeInstructionsText}`,
          },
        ],
        6000,
        true,
        user.uid
      );

      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(95);
      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setProgress(100);

      // Add this new section right here, after parsing but before formatting:
      if (lessonType === 'vocab' && data.selectedWords) {
        const selectedWords = data.selectedWords.split(',').map(word => word.trim());
        console.log('Selected words from API:', selectedWords);

        // Add mark tags around only the first occurrence of each selected word in the content
        let markedContent = data.content;
        const markedWords = new Set(); // Keep track of words we've already marked

        selectedWords.forEach(word => {
          if (!markedWords.has(word.toLowerCase())) {
            // Create regex that matches the whole word, case-insensitive
            const regex = new RegExp(`\\b${word}\\b`, 'i');

            // Log before attempting to mark
            console.log(`Attempting to mark word: ${word}`);

            // Check if the word exists in the content
            if (markedContent.match(regex)) {
              // Only replace the first occurrence
              markedContent = markedContent.replace(regex, match => {
                console.log(`Found and marking: ${match}`);
                markedWords.add(match.toLowerCase());
                return `<mark>${match}</mark>`;
              });
            } else {
              console.warn(`Word not found in content: ${word}`);
            }
          } else {
            console.log(`Word already marked: ${word}`);
          }
        });

        console.log('Final marked words:', Array.from(markedWords));
        console.log(
          "Words that should have been marked but weren't:",
          selectedWords.filter(
            word =>
              !Array.from(markedWords).some(marked => marked.toLowerCase() === word.toLowerCase())
          )
        );

        // Update the content with marked words
        data.content = markedContent;
      }

      // Format the lesson content based on lesson type
      let formattedExercises = '';
      if (data.exercises && Array.isArray(data.exercises)) {
        // Only filter exercises if it's the basic lesson type
        const exercisesToShow =
          lessonType === 'basic'
            ? data.exercises.filter(exercise => selectedExercises[exercise.type])
            : data.exercises;

        formattedExercises = exercisesToShow
          .map(
            exercise => `
      <div class="exercise" style="background-color: ${exercise.type === 'definitions' ? 'transparent' : 'transparent'}">
        <h3 style="font-weight: bold; font-size: 20px; margin-bottom: 15px;">
          ${exercise.title}
        </h3>
        <div class="exercise-content">
          ${exercise.content}
        </div>
      </div>
    `
          )
          .join('');
      }

      const formattedContent = `
      <div class="lesson-container">
        <h4 style="text-transform: uppercase; color: #666; font-size: 13px; margin-bottom: 4px;">
          ${data.theme || finalTheme}
        </h4>
        <h2 style="font-weight: bold; font-size: 30px; margin-bottom: 20px;">
          ${data.title || 'Läsförståelse'}
        </h2>
        
      

        <div id="image-insertion-point"></div>
        
   <div class="main-content">
        ${
          data.content
            ? data.content
                .split('\n\n')
                .map(paragraph => `<p style="margin-bottom: 1.5em;">${paragraph.trim()}</p>`)
                .join('\n')
            : ''
        }
      </div>
        
        <div class="exercises">
          ${formattedExercises} 
        </div>
        
  
    ${
      data.solution
        ? `
        <div class="solution">
          <h3 style="font-weight: bold; font-size: 20px; margin-bottom: 15px;">
            Lösning
          </h3>
          ${data.solution}
        </div>
        `
        : ''
    }
        
      </div>
    `;

      setTitleText(data.title);
      await new Promise(resolve => setTimeout(resolve, 800));
      setEditorContent(formattedContent);
    } catch (error) {
      console.error('Error generating lesson:', error);
      showSnackbar('Ett fel uppstod när lektionen skulle genereras. Försök igen!', 'error');
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleImageButtonClick = () => {
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
  };

  const handleGenerateWithInstructions = async () => {
    handleCloseModal();
    await handleGenerateImages();
  };

  const handleGenerateImages = async () => {
    setIsGeneratingImages(true);
    try {
      const currentContent = editorContent;
      const insertPoint = currentContent.indexOf('<div id="image-insertion-point"></div>');

      const imagePrompt = `Create an image about a story with the theme: "${titleText}". 
         ${imageInstructions}`;

      const imageUrl = await generateAndProcessImage(imagePrompt, user.uid, {
        size: '1024x1024',
        model: 'dall-e-3',
        quality: 'standard',
      });

      const imageHtml = `
        <div style="margin: 24px 0;">
          <img src="${imageUrl}" alt="Lesson illustration" 
               style="width: 100%; max-width: 1024px; height: auto; border-radius: 8px;">
        </div>
      `;

      const newContent = currentContent.replace(
        '<div id="image-insertion-point"></div>',
        imageHtml + '<div id="image-insertion-point"></div>'
      );

      setEditorContent(newContent);
      showSnackbar('Bild har genererats!', 'success');
    } catch (error) {
      console.error('Error generating image:', error);
      showSnackbar('Ett fel uppstod när bilden skulle genereras', 'error');
    } finally {
      setIsGeneratingImages(false);
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: '100%', md: '87%' },
          margin: '0 auto',
        }}
      >
        <Typography
          variant='h1'
          gutterBottom
          sx={{
            fontWeight: 'bold',
            fontSize: '1.8em',
            mb: 3,
            mt: 5,
            textAlign: 'center',
          }}
        >
          Läsförståelse
        </Typography>

        <InfoBox
          icon={GiBookshelf}
          title='Läsförståelse Generator'
          instructions={[
            'Välj typ av läsförståelseövning',
            'Generera en anpassad text med övningar',
            'Lägg till bilder och skriv ut materialet',
          ]}
          theme={theme}
          styles={styles}
        />

        <Box sx={{ ...styles.freshBox, padding: '30px' }}>
          <Typography variant='subtitle1' sx={{ fontWeight: '500', mb: 1 }}>
            Välj typ av läsförståelse:
          </Typography>

          <FormControl fullWidth sx={{ mb: 5 }}>
            <Select value={lessonType} onChange={e => handleLessonTypeChange(e.target.value)}>
              {Object.entries(lessonTypes).map(([key, value]) => (
                <MenuItem key={key} value={key} sx={{ py: 1 }}>
                  <Grid container alignItems='center' spacing={2}>
                    <Grid item>
                      <Box
                        sx={{
                          width: 24,
                          height: 24,
                          borderRadius: '50%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {key === 'basic' && <MenuBook sx={{ fontSize: 22, color: 'gray' }} />}
                        {key === 'compare' && (
                          <CompareArrows sx={{ fontSize: 22, color: 'gray' }} />
                        )}
                        {key === 'creative' && <Create sx={{ fontSize: 22, color: 'gray' }} />}
                        {key === 'vocab' && <TextFields sx={{ fontSize: 22, color: 'gray' }} />}
                        {key === 'detective' && <GavelIcon sx={{ fontSize: 22, color: 'gray' }} />}
                      </Box>
                    </Grid>
                    <Grid item xs>
                      <Typography variant='subtitle1' sx={{ fontWeight: 500 }}>
                        {value.label}
                      </Typography>
                      <Typography variant='body2' color='text.secondary'>
                        {value.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Grid container spacing={2}>
            {/* First row - Theme Selector */}
            <Grid item xs={12} md={6}>
              <Box>
                <ThemeSelector
                  value={lessonTheme}
                  onChange={e => setLessonTheme(e.target.value)}
                  theme={theme}
                  customTheme={customTheme}
                  onCustomThemeChange={e => setCustomTheme(e.target.value)}
                />
              </Box>
            </Grid>

            {/* Second row - Grade Selector */}
            <Grid item xs={12} md={6}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mb: 0, mt: 0 }}>
                Årskurs:
              </Typography>
              <Box sx={{ mt: 0.5, mb: 3 }}>
                <GradeSelector grade={grade} setGrade={setGrade} />
              </Box>
            </Grid>

            {/* Third row - Language Selector */}
            <Grid item xs={12} md={6}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 0 }}>
                Språk:
              </Typography>
              <Box sx={{ mt: 1, mb: 3 }}>
                <LanguageSelector language={language} setLanguage={setLanguage} />
              </Box>
            </Grid>

            {/* Fourth row - AI Instructions */}
            <Grid item xs={12} md={6}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500' }}>
                Specifika instruktioner till AI:n:
              </Typography>
              <FormControl fullWidth margin='normal' sx={{ mb: 3, mt: 1 }}>
                <TextField
                  value={openAiInstructions}
                  onChange={e => setOpenAiInstructions(e.target.value)}
                  placeholder='T.ex. Inkludera lite svårare ord såsom defenestrera, kalibrera och frekventera.'
                  variant='outlined'
                />
              </FormControl>
            </Grid>

            {/* Vocab-specific controls that only appear when lessonType is 'vocab' */}
            {lessonType === 'vocab' && (
              <>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <Typography variant='subtitle1' sx={{ fontWeight: '500', mb: 1 }}>
                      Antal ord att markera:
                    </Typography>
                    <Select
                      value={wordCount}
                      onChange={e => setWordCount(e.target.value)}
                      variant='outlined'
                      sx={{ mb: 3 }}
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={11}>11</MenuItem>
                      <MenuItem value={13}>13</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <Typography variant='subtitle1' sx={{ fontWeight: '500', mb: 1 }}>
                      Ordklasser:
                    </Typography>
                    <Select
                      value={wordType}
                      onChange={e => setWordType(e.target.value)}
                      variant='outlined'
                      sx={{ mb: 3 }}
                    >
                      <MenuItem value='random'>Blandade ordklasser</MenuItem>
                      <MenuItem value='nouns'>Substantiv (namn på saker/personer)</MenuItem>
                      <MenuItem value='verbs'>Verb (handlingsord)</MenuItem>
                      <MenuItem value='adjectives'>Adjektiv (beskrivande ord)</MenuItem>
                      <MenuItem value='pronouns'>Pronomen (han, hon, den, det)</MenuItem>
                      <MenuItem value='adverbs'>Adverb (hur/när/var)</MenuItem>
                      <MenuItem value='prepositions'>Prepositioner (på, under, i)</MenuItem>
                      <MenuItem value='conjunctions'>Konjunktioner (och, men, eller)</MenuItem>
                      <MenuItem value='numerals'>Räkneord (tre, första, dussinet)</MenuItem>
                      <MenuItem value='interjections'>Interjektioner (utrop)</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}

            {lessonType === 'basic' && (
              <Grid item xs={12} md={12}>
                <Typography variant='subtitle1' sx={{ fontWeight: '500', mb: 1 }}>
                  Välj övningstyper:
                </Typography>
                <Grid container spacing={2}>
                  {[
                    ['on-the-lines', 'På raderna (svaret finns i texten)'],
                    ['between-the-lines', 'Mellan raderna (dra slutsatser)'],
                    ['beyond-the-lines', 'Bortom raderna (egna tankar)'],
                    ['true-false', 'Sant eller falskt (påståenden)'],
                    ['multiple-choice', 'Flervalsfrågor'],
                    ['open-ended', 'Diskussionsfrågor'],
                  ].map(([key, label]) => (
                    <Grid item xs={12} sm={6} key={key}>
                      <CustomFormControlLabel
                        control={<Checkbox />}
                        label={label}
                        checked={selectedExercises[key]}
                        onChange={e =>
                          setSelectedExercises(prev => ({
                            ...prev,
                            [key]: e.target.checked,
                          }))
                        }
                        theme={theme}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
          <Button
            variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
            color='primary'
            onClick={handleGenerateLesson}
            disabled={isGeneratingLesson}
            sx={{ mt: 4 }}
            startIcon={
              !isGeneratingLesson ? (
                <GenerateIcon style={{ color: '#fff' }} />
              ) : (
                <CircularProgress size={16} />
              )
            }
          >
            {isGeneratingLesson ? 'Genererar...' : 'Generera lektion'}
          </Button>

          {isGeneratingLesson && (
            <Box sx={{ mt: 6 }}>
              <ProgressBar progress={progress} label='Genererar lektion...' />
            </Box>
          )}

          {editorContent && (
            <>
              <Divider sx={{ mt: 3, mb: 3 }} />

              <Button
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                color='primary'
                onClick={handleImageButtonClick}
                disabled={isGeneratingImages}
                sx={{ mt: 3 }}
                startIcon={
                  !isGeneratingImages ? (
                    <ImageIcon style={{ color: '#fff' }} />
                  ) : (
                    <CircularProgress size={16} />
                  )
                }
              >
                {isGeneratingImages ? 'Genererar...' : 'Generera bild'}
              </Button>

              <Button
                variant='contained'
                startIcon={<PrintIcon />}
                sx={{ mt: 3, ml: 2 }}
                onClick={() => {
                  if (editorRef.current) {
                    const content = editorRef.current.getContent();
                    const printWindow = window.open('', '_blank');
                    printWindow.document.write(`
                      <html>
                        <head>
                          <style>
                 body { 
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 
                           Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
                line-height: 28px !important; 
                padding: 20px;
              }
              /* Editor content styles */
              body { 
                font-family: -apple-system, BlinkMacSystemFont, sans-serif; 
                line-height: 28px !important; 
              } 
              p { 
                line-height: 34px !important; 
              } 
              h3 { 
                margin-top: 60px !important; 
              } 
              li { 
                margin-bottom: 12px !important; 
                line-height: 28px !important; 
              } 
              ul, ol { 
                margin: 20px !important 
              } 

/* Add these exact lines */
mark { 
    font-weight: bold !important;
    padding: 5px !important;
    background: #dcccff !important;
}
@media print {
    mark {
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
    }
}

/* Replace ALL your table-related styles with just these */
table {
    border-collapse: collapse !important;
    width: 100% !important;
    margin-bottom: 20px !important;
}

table, th, td {
    border: 2px solid black !important;
}

th, td {
    padding: 10px !important;
    text-align: left !important;
}
              /* Print-specific styles */
              .exercise {
                margin-top: 30px;
                page-break-inside: avoid;
              }
              .teacher-notes {
                page-break-before: always;
              }
              @media print {
                .no-print {
                  display: none;
                }
              }
              /* Additional styles for better print layout */
              @page {
                margin: 2cm;
              }
              img {
                max-width: 100%;
                page-break-inside: avoid;
              }
              h2, h3, h4 {
                page-break-after: avoid;
              }
              table {
                page-break-inside: avoid;
              }
                          </style>
                        </head>
                        <body>${content}</body>
                      </html>
                    `);
                    printWindow.document.close();
                    printWindow.print();
                  }
                }}
              >
                Skriv ut
              </Button>

              <ImageGenerationModal
                open={showImageModal}
                onClose={handleCloseModal}
                onGenerate={handleGenerateWithInstructions}
                isGenerating={isGeneratingImages}
                instructions={imageInstructions}
                setInstructions={setImageInstructions}
                theme={theme}
              />
            </>
          )}

          <Box sx={{ mt: 7 }}>
            <Editor
              apiKey='fxkb0ty514q6ak7t7lo34gojjloffnpw3n9fr8tdz3swjxi3'
              onInit={(evt, editor) => (editorRef.current = editor)}
              value={editorContent}
              onEditorChange={content => setEditorContent(content)}
              init={{
                height: 800,
                menubar: false,
                plugins: [
                  'advlist',
                  'autolink',
                  'lists',
                  'link',
                  'image',
                  'charmap',
                  'preview',
                  'anchor',
                  'searchreplace',
                  'visualblocks',
                  'code',
                  'fullscreen',
                  'insertdatetime',
                  'media',
                  'table',
                  'code',
                  'help',
                  'wordcount',
                ],
                toolbar:
                  'undo redo | blocks | ' +
                  'bold italic forecolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style:
                  'body { font-family: -apple-system, BlinkMacSystemFont, sans-serif; line-height: 28px !important; } p { line-height: 28px !important; } mark { font-weight: bold; padding: 5px; background: #dcccff; border-radius:5px; } h3 { margin-top: 60px !important; } li { margin-bottom: 12px !important; line-height: 28px !important; } ul, ol { margin:20px !important } table { width: 100% !important; border-collapse: collapse !important; } th, td { padding: 10px !important; border: 1px solid #ddd !important; text-align: left !important; } table, th, td { border: 1px solid #ddd !important; }',

                skin: theme.palette.mode === 'dark' ? 'oxide-dark' : 'oxide',
                content_css: theme.palette.mode === 'dark' ? 'dark' : 'default',
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ReadingComprehensionLesson;
