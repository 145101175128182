import React from 'react';
import { Box, Typography, Card, CardContent, Grid, Chip } from '@mui/material';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { themeBasedShortcuts } from './DashboardTeacherShortcuts';
import { getStyles } from '../index';

const DashboardThemedShortcuts = () => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const getLabelColor = labelText => {
    if (labelText === 'NYTT') {
      return {
        backgroundColor: theme.palette.mode === 'dark' ? '#2B4C2A' : '#C5F2B8',
        color: theme.palette.mode === 'dark' ? '#3bbd28' : '#249314',
      };
    }
    return {
      backgroundColor: theme.palette.mode === 'dark' ? '#2a3b4c' : '#e0e7ff',
      color: theme.palette.mode === 'dark' ? '#60a5fa' : '#3b82f6',
    };
  };

  return (
    <>
      {Object.entries(themeBasedShortcuts).map(([shortcutName, shortcutData]) => (
        <>
          <Box sx={{ mt: 8, mb: 2 }}>
            <Typography
              variant='subtitle'
              sx={{
                ...styles.grayText,
                fontWeight: '600',
                fontSize: '14px',
                display: 'block',
                mb: 0.5,
              }}
            >
              LEKTION
            </Typography>
            <Typography
              variant='h2'
              sx={{ ...styles.header, ...styles.dashboardHeader, fontSize: '27px' }}
            >
              {shortcutName}
            </Typography>
          </Box>

          <Box
            key={shortcutName}
            sx={{
              ...styles.newBox,
              padding: '20px',
              width: '100%',
              marginBottom: 3,
            }}
          >
            <Grid container spacing={2}>
              {shortcutData.themes.map((themeItem, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Link
                    to={`${themeItem.path}`}
                    style={{ textDecoration: 'none', color: 'inherit', height: '100%' }}
                  >
                    <Card
                      sx={{
                        height: '100%',
                        backgroundColor:
                          themeItem.title === 'Anpassad'
                            ? theme.palette.mode === 'dark'
                              ? 'rgba(193, 225, 193, 0.1)'
                              : 'rgba(193, 225, 193, 0.3)'
                            : theme.palette.mode === 'dark'
                              ? 'rgba(255, 255, 255, 0.05)'
                              : 'rgba(223, 222, 225, 0.19)',
                        borderRadius: '12px',
                        padding: '30px',
                        paddingTop: '15px',
                        paddingBottom: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                        backgroundImage: 'none',
                        boxShadow: 'none',
                        transition: 'all 0.3s ease',
                        position: 'relative',
                        textAlign: 'center',
                        border: theme.palette.mode === 'dark' ? undefined : '1px solid #e0e0e080',
                        '&:hover': {
                          backgroundColor:
                            themeItem.title === 'Anpassad'
                              ? theme.palette.mode === 'dark'
                                ? 'rgba(193, 225, 193, 0.15)'
                                : 'rgba(193, 225, 193, 0.4)'
                              : theme.palette.mode === 'dark'
                                ? 'rgba(255, 255, 255, 0.1)'
                                : 'rgba(139, 92, 246, 0.1)',
                          transform: 'translateY(-2px)',
                          '& .icon, & .star': {
                            color: theme.palette.mode === 'dark' ? 'rgb(16, 151, 218)' : '#6b46c1',
                          },
                        },
                      }}
                    >
                      <CardContent>
                        <Box display='flex' flexDirection='column' alignItems='center' gap={2}>
                          <Box
                            sx={{
                              width: 48,
                              height: 48,
                              borderRadius: '50%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              flexShrink: 0,
                            }}
                          >
                            {React.cloneElement(themeItem.icon, {
                              sx: {
                                color: '#8b5cf6',
                                fontSize: '60px',
                              },
                            })}
                          </Box>
                          <Box flex={1} width='100%'>
                            <Box
                              display='flex'
                              alignItems='center'
                              gap={1}
                              sx={{ textAlign: 'center', justifyContent: 'center', mb: 1 }}
                            >
                              <Typography
                                variant='subtitle1'
                                sx={{ fontWeight: '600', fontSize: '1.2rem' }}
                              >
                                {themeItem.title}
                              </Typography>
                            </Box>
                            <Typography
                              variant='body2'
                              color='textSecondary'
                              sx={{
                                ...styles.grayText,
                                textAlign: 'center',
                                // display: themeItem.title === 'Anpassad' ? 'block' : 'none',
                              }}
                            >
                              {themeItem.subtitle}
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      ))}
    </>
  );
};

export default DashboardThemedShortcuts;
