import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  FormControl,
  Select,
  TextField,
  MenuItem,
  Typography,
  Divider,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
  Avatar,
  Grid,
} from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import ProgressBar from './ProgressBar';
import ImageIcon from '@mui/icons-material/Image';
import { callApi } from './api';
import LoadingBar from 'react-top-loading-bar';
import LanguageSelector from './LanguageSelector';
import PrintIcon from '@mui/icons-material/Print';
import InfoBox from './InfoBox';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { generateAndProcessImage } from './functions';
import { useSnackbar } from './SnackbarHandler';

import { ThemeSelector } from './ThemeSelector';
import ImageGenerationModal from './ImageGenerationModal';
import GradeSelector, { gradeInstructions } from './GradeSelector';

import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';

const SentenceFinisherLesson = ({ user }) => {
  const [lessonTheme, setLessonTheme] = useState('generateWithAi');
  const [language, setLanguage] = useState('svenska');
  const [story, setStory] = useState('');
  const [storyTitle, setStoryTitle] = useState('');
  const [storyTheme, setStoryTheme] = useState('');
  const [progress, setProgress] = useState(0);
  const [continuations, setContinuations] = useState([]);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [images, setImages] = useState([]);
  const [plotTwists, setPlotTwists] = useState([]);
  const [environments, setEnvironments] = useState([]);
  const [editorContent, setEditorContent] = useState('');
  const editorRef = useRef(null);
  const [customTheme, setCustomTheme] = useState('');
  const [isGeneratingStoryImages, setIsGeneratingStoryImages] = useState(false);
  const { showSnackbar } = useSnackbar();
  const loadingBarRef = useRef(null);
  const [grade, setGrade] = useState('åk6');
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const [imageGenerationType, setImageGenerationType] = useState(null);

  const theme = useTheme();
  const styles = getStyles(theme);

  const handleImageButtonClick = () => {
    setImageGenerationType('story');
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
  };

  const handleGenerateWithInstructions = async () => {
    handleCloseModal();
    await handleGenerateImages();
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateLesson = async () => {
    setProgress(0);
    setIsGeneratingLesson(true);
    const gradeInstructionsText = gradeInstructions[grade];
    try {
      const generateTheme = lessonTheme === 'generateWithAi';
      const finalTheme = generateTheme
        ? '[RANDOM]'
        : lessonTheme === 'customTheme'
          ? customTheme
          : lessonTheme;
      const stopSimulation = simulateProgress(0, 90, 15000);
      let response = await callApi(
        [
          {
            role: 'system',
            content:
              'You are an assistant that generates short stories with continuation suggestions, plot twists, and environment suggestions for language lessons.',
          },
          {
            role: 'user',
            content: `Generate a JSON object with a short story, 5 sentence continuation suggestions, 3 plot twists, and 3 environment suggestions in "${language}". Prioritize novelty and uniqueness in each generation. Avoid repeating themes, characters, or plot elements from previous responses. 

 ${gradeInstructionsText}
             The theme should be "${finalTheme}".

            Example response:
            {
              "theme": "theme in ${language}",
              "story": "Short story text in 10 sentences in ${language}...",
              "storyTitle": "Story title in ${language}...",
              "continuations": [
                "Continuation suggestion 1",
                "Continuation suggestion 2",
                "Continuation suggestion 3",
                "Continuation suggestion 4",
                "Continuation suggestion 5"
              ],
              "plotTwists": [
                "Plot twist 1",
                "Plot twist 2",
                "Plot twist 3"
              ],
              "environments": [
                "Environment suggestion 1",
                "Environment suggestion 2",
                "Environment suggestion 3"
              ]
            }`,
          },
        ],
        6000,
        true,
        user.uid
      );
      setProgress(95);
      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setProgress(100);
      setStory(data.story);
      setStoryTitle(data.storyTitle);
      setStoryTheme(data.theme);
      setContinuations(data.continuations);
      setPlotTwists(data.plotTwists);
      setEnvironments(data.environments);

      const formattedContent = `
        <h4 style="text-transform: uppercase; color: #666; font-size: 13px; margin-bottom: 4px;">${data.theme}</h4>
        <h2 style="font-weight: bold; font-size: 30px; margin-bottom: 20px;">${data.storyTitle}</h2>
        <p>${data.story}</p>
        <div id="image-insertion-point"></div>
        <h3 style="font-weight: bold; font-size: 20px; margin-top: 40px; margin-bottom: 10px;">Förslag på nästa mening:</h3>
        <ul>
          ${data.continuations.map(cont => `<li>${cont}</li>`).join('')}
        </ul>
        
        <h3 style="font-weight: bold; font-size: 20px; margin-top: 40px; margin-bottom: 10px;">Förslag på plötsliga vändningar:</h3>
        <ul>
          ${data.plotTwists.map(twist => `<li>${twist}</li>`).join('')}
        </ul>
        
        <h3 style="font-weight: bold; font-size: 20px; margin-top: 40px; margin-bottom: 10px;">Förslag på miljöer för nästa del av berättelsen:</h3>
        <ul>
          ${data.environments.map(env => `<li>${env}</li>`).join('')}
        </ul>`;

      setEditorContent(formattedContent);
    } catch (error) {
      console.error('Error generating lesson:', error);
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleGenerateImages = async () => {
    setIsGeneratingStoryImages(true);
    try {
      // Create prompts using various story elements
      const storyPrompts = [
        // Main story image based on title and theme
        `Create an image representing the story theme "${storyTitle}". Do not include any text in the image. ${imageInstructions}`,

        // Image based on a suggested continuation
        `Create a scene showing this moment from the story: "${continuations[0]}". Set in the context of "${storyTitle}". Style: narrative, dynamic, emotive, no text. ${imageInstructions}`,

        // Image based on a suggested environment and plot twist
        `Create an atmospheric scene combining the environment "${environments[0]}" with this dramatic moment: "${plotTwists[0]}". Style: atmospheric, dramatic, detailed, no text. ${imageInstructions}`,
      ];

      // Track the current editor content
      let currentContent = editorContent;
      let insertPoint = currentContent.indexOf('<div id="image-insertion-point"></div>');

      // Generate images sequentially
      for (let i = 0; i < storyPrompts.length; i++) {
        try {
          const imageUrl = await generateAndProcessImage(storyPrompts[i], user.uid, {
            size: '1024x1024',
            model: 'dall-e-3',
            quality: 'standard',
          });

          // Create image HTML with descriptive caption
          const imageCaption =
            i === 0
              ? `Huvudbild: ${storyTitle}`
              : i === 1
                ? `Fortsättning: ${continuations[0]}`
                : `Miljö & Vändning: ${environments[0]}`;

          const imageHtml = `
            <div style="display: inline-block; margin: 12px;">
              <img src="${imageUrl}" alt="Generated story image ${i + 1}" 
                   style="width: 328px; height: auto; border-radius: 8px;">
            </div>
          `;

          // Moonshine: lägg till i diven ovan för img text:
          //<p style="margin-top: 8px; font-style: italic; color: #666;">${imageCaption}</p>

          // Update content
          if (i === 0) {
            // Replace the insertion point with first image
            currentContent = currentContent.replace(
              '<div id="image-insertion-point"></div>',
              imageHtml + '<div id="image-insertion-point"></div>'
            );
          } else {
            // Insert additional images at the insertion point
            insertPoint = currentContent.indexOf('<div id="image-insertion-point"></div>');
            currentContent =
              currentContent.slice(0, insertPoint) + imageHtml + currentContent.slice(insertPoint);
          }

          // Update editor content
          setEditorContent(currentContent);
          showSnackbar(`Genererat bild ${i + 1} av ${storyPrompts.length}`, 'success');
        } catch (error) {
          console.error(`Error generating image ${i + 1}:`, error);

          let errorMessage = `Kunde inte generera bild  ${i + 1}`;
          if (error.response?.data?.details) {
            errorMessage = error.response.data.details;
          } else if (error.response?.data?.error) {
            errorMessage = error.response.data.error;
          } else if (error.message) {
            errorMessage = error.message;
          }

          showSnackbar(errorMessage, 'warning');

          // Add placeholder image on error
          const placeholderHtml = `
            <div style="margin: 24px 0;">
              <img src="/api/placeholder/1024/1024" alt="Placeholder image ${i + 1}" 
                   style="width: 100%; max-width: 1024px; height: auto; border-radius: 8px;">
              <p style="margin-top: 8px; font-style: italic; color: #666;">Kunde inte generera bild ${i + 1}</p>
            </div>
          `;

          if (i === 0) {
            currentContent = currentContent.replace(
              '<div id="image-insertion-point"></div>',
              placeholderHtml + '<div id="image-insertion-point"></div>'
            );
          } else {
            insertPoint = currentContent.indexOf('<div id="image-insertion-point"></div>');
            currentContent =
              currentContent.slice(0, insertPoint) +
              placeholderHtml +
              currentContent.slice(insertPoint);
          }

          setEditorContent(currentContent);
        }
      }

      showSnackbar('Alla bilder har genererats!', 'success');
    } catch (error) {
      console.error('Error in story image generation process:', error);
      showSnackbar('Ett fel uppstod i bildgenereringsprocessen', 'error');
    } finally {
      setIsGeneratingStoryImages(false);
    }
  };

  const handleEditorChange = (content, editor) => {
    setEditorContent(content);
  };

  return (
    <>
      <Box sx={styles.container}>
        <Box
          sx={{
            ...styles.innerContainer,
            width: { xs: '100%', md: '87%' },
            margin: '0 auto',
          }}
        >
          <Typography
            variant='h1'
            gutterBottom
            sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
          >
            Fortsätt berättelsen
          </Typography>

          <InfoBox
            icon={AutoStoriesIcon}
            title='Powerpoint Instructions'
            instructions={[
              'Generera en kort berättelse med förslag på fortsättningar för eleverna',
              'Lägg till fantasieggande AI-genererade bilder',
              'Skriv ut lektionen',
            ]}
            theme={theme}
            styles={styles}
          />

          <Box sx={{ ...styles.freshBox, padding: '30px' }}>
            <LoadingBar
              color={theme.palette.primary.main} // Use your theme color
              ref={loadingBarRef}
              shadow={true}
            />
            <Box>
              <ThemeSelector
                value={lessonTheme}
                onChange={e => setLessonTheme(e.target.value)}
                theme={theme}
                customTheme={customTheme}
                onCustomThemeChange={e => setCustomTheme(e.target.value)}
              />
            </Box>

            <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
              Årskurs:
            </Typography>
            <Box sx={{ mt: 1, mb: 3 }}>
              <GradeSelector grade={grade} setGrade={setGrade} />
            </Box>

            <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 0 }}>
              Språk:
            </Typography>
            <Box sx={{ mt: 1, mb: 3 }}>
              <LanguageSelector language={language} setLanguage={setLanguage} />
            </Box>
            <Box>
              <Button
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                color='primary'
                onClick={handleGenerateLesson}
                disabled={isGeneratingLesson}
                sx={{ mt: 2 }}
                startIcon={
                  !isGeneratingLesson ? (
                    <GenerateIcon style={{ color: '#fff' }} />
                  ) : (
                    <CircularProgress size={16} />
                  )
                }
              >
                {isGeneratingLesson ? 'Genererar...' : 'Generera lektion'}
              </Button>
            </Box>
            {isGeneratingLesson && (
              <Box sx={{ mt: 6 }}>
                <ProgressBar progress={progress} label='Genererar lektion...' />
              </Box>
            )}

            {story && (
              <>
                <Divider sx={{ mt: 3, mb: 3 }} />

                <Button
                  variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                  color='primary'
                  onClick={handleImageButtonClick}
                  disabled={isGeneratingStoryImages}
                  sx={{ mt: 3 }}
                  startIcon={
                    !isGeneratingStoryImages ? (
                      <ImageIcon style={{ color: '#fff' }} />
                    ) : (
                      <CircularProgress size={16} />
                    )
                  }
                >
                  {isGeneratingStoryImages ? 'Genererar...' : 'Generera bilder'}
                </Button>

                <Button
                  variant='contained'
                  startIcon={<PrintIcon />}
                  sx={{ mt: 3, ml: 2 }}
                  onClick={() => {
                    if (editorRef.current) {
                      const content = editorRef.current.getContent();
                      const printWindow = window.open('', '_blank');
                      printWindow.document.write(`
                    <html>
                      <head>
                        <style>
                          body { 
                            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 
                                       Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
                            line-height: 1.6;
                          }
                        </style>
                      </head>
                      <body>${content}</body>
                    </html>
                  `);
                      printWindow.document.close();
                      printWindow.print();
                    }
                  }}
                >
                  Skriv ut
                </Button>

                <ImageGenerationModal
                  open={showImageModal}
                  onClose={handleCloseModal}
                  onGenerate={handleGenerateWithInstructions}
                  isGenerating={isGeneratingStoryImages}
                  instructions={imageInstructions}
                  setInstructions={setImageInstructions}
                  generationType={imageGenerationType}
                  theme={theme}
                />
              </>
            )}
            <Box sx={{ mt: 4 }}>
              <Typography
                variant='body1'
                sx={{
                  ...styles.grayText,
                  mb: 0.4,
                  mt: 5,
                  textTransform: 'uppercase',
                  fontSize: '13px',
                  fontWeight: '500',
                }}
              >
                {storyTheme}
              </Typography>

              <Typography variant='h6' sx={{ fontWeight: 'bold', mb: 2, fontSize: '30px' }}>
                {storyTitle}
              </Typography>

              <Box sx={{ mt: 4 }}>
                <Editor
                  apiKey='fxkb0ty514q6ak7t7lo34gojjloffnpw3n9fr8tdz3swjxi3'
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  value={editorContent}
                  onEditorChange={handleEditorChange}
                  init={{
                    height: 800,
                    menubar: false,
                    plugins: [
                      'advlist',
                      'autolink',
                      'lists',
                      'link',
                      'image',
                      'charmap',
                      'preview',
                      'anchor',
                      'searchreplace',
                      'visualblocks',
                      'code',
                      'fullscreen',
                      'insertdatetime',
                      'media',
                      'table',
                      'code',
                      'help',
                      'wordcount',
                    ],
                    toolbar:
                      'undo redo | blocks | ' +
                      'bold italic forecolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style:
                      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    skin: theme.palette.mode === 'dark' ? 'oxide-dark' : 'oxide',
                    content_css: theme.palette.mode === 'dark' ? 'dark' : 'default',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SentenceFinisherLesson;
