import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  TextField,
  IconButton,
  Button,
  useTheme,
  Autocomplete,
} from '@mui/material';
import { School, Person, ArrowBack } from '@mui/icons-material';
import { useSnackbar } from './SnackbarHandler';
import { motion, AnimatePresence } from 'framer-motion';
import { StyledButton, GradientBackground } from '../StyledComponents';
import { CheckCircle } from '@mui/icons-material';
const swedishMunicipalities = [
  'Ale',
  'Alingsås',
  'Alvesta',
  'Aneby',
  'Arboga',
  'Arjeplog',
  'Arvidsjaur',
  'Arvika',
  'Askersund',
  'Avesta',
  'Bengtsfors',
  'Berg',
  'Bjurholm',
  'Bjuv',
  'Boden',
  'Bollebygd',
  'Bollnäs',
  'Borgholm',
  'Borlänge',
  'Borås',
  'Botkyrka',
  'Boxholm',
  'Bramhult',
  'Bräcke',
  'Burlöv',
  'Båstad',
  'Dals-Ed',
  'Danderyd',
  'Degerfors',
  'Dorotea',
  'Eda',
  'Ekerö',
  'Eksjö',
  'Emmaboda',
  'Enköping',
  'Eskilstuna',
  'Eslöv',
  'Essunga',
  'Fagersta',
  'Falkenberg',
  'Falköping',
  'Falun',
  'Filipstad',
  'Finspång',
  'Flen',
  'Forshaga',
  'Färgelanda',
  'Gagnef',
  'Gislaved',
  'Gnesta',
  'Gnosjö',
  'Gotland',
  'Grums',
  'Grästorp',
  'Gullspång',
  'Gällivare',
  'Gävle',
  'Göteborg',
  'Götene',
  'Habo',
  'Hagfors',
  'Hallsberg',
  'Hallstahammar',
  'Halmstad',
  'Hammarö',
  'Haninge',
  'Haparanda',
  'Heby',
  'Hedemora',
  'Helsingborg',
  'Herrljunga',
  'Hjo',
  'Hofors',
  'Huddinge',
  'Hudiksvall',
  'Hultsfred',
  'Hylte',
  'Håbo',
  'Hällefors',
  'Härjedalen',
  'Härnösand',
  'Härryda',
  'Hässleholm',
  'Höganäs',
  'Högsby',
  'Hörby',
  'Höör',
  'Jokkmokk',
  'Järfälla',
  'Jönköping',
  'Kalix',
  'Kalmar',
  'Karlsborg',
  'Karlshamn',
  'Karlskoga',
  'Karlskrona',
  'Karlstad',
  'Katrineholm',
  'Kil',
  'Kinda',
  'Kiruna',
  'Klippan',
  'Knivsta',
  'Kramfors',
  'Kristianstad',
  'Kristinehamn',
  'Krokom',
  'Kumla',
  'Kungsbacka',
  'Kungsör',
  'Kungälv',
  'Kävlinge',
  'Köping',
  'Laholm',
  'Landskrona',
  'Laxå',
  'Lekeberg',
  'Leksand',
  'Lerum',
  'Lessebo',
  'Lidingö',
  'Lidköping',
  'Lilla Edet',
  'Lindesberg',
  'Linköping',
  'Ljungby',
  'Ljusdal',
  'Ljusnarsberg',
  'Lomma',
  'Ludvika',
  'Luleå',
  'Lund',
  'Lycksele',
  'Lysekil',
  'Malmö',
  'Malung-Sälen',
  'Malå',
  'Mariestad',
  'Mark',
  'Markaryd',
  'Mellerud',
  'Mjölby',
  'Mora',
  'Motala',
  'Mullsjö',
  'Munkedal',
  'Munkfors',
  'Mölndal',
  'Mönsterås',
  'Mörbylånga',
  'Nacka',
  'Nora',
  'Norberg',
  'Nordanstig',
  'Nordmaling',
  'Norrköping',
  'Norrtälje',
  'Norsjö',
  'Nybro',
  'Nykvarn',
  'Nyköping',
  'Nynäshamn',
  'Nässjö',
  'Ockelbo',
  'Olofström',
  'Orsa',
  'Orust',
  'Osby',
  'Oskarshamn',
  'Ovanåker',
  'Oxelösund',
  'Pajala',
  'Partille',
  'Perstorp',
  'Piteå',
  'Ragunda',
  'Robertsfors',
  'Ronneby',
  'Rättvik',
  'Sala',
  'Salem',
  'Sandviken',
  'Sigtuna',
  'Simrishamn',
  'Sjöbo',
  'Skara',
  'Skellefteå',
  'Skinnskatteberg',
  'Skurup',
  'Skövde',
  'Smedjebacken',
  'Sollefteå',
  'Sollentuna',
  'Solna',
  'Sorsele',
  'Sotenäs',
  'Staffanstorp',
  'Stenungsund',
  'Stockholm',
  'Storfors',
  'Storuman',
  'Strängnäs',
  'Strömstad',
  'Strömsund',
  'Sundbyberg',
  'Sundsvall',
  'Sunne',
  'Surahammar',
  'Svalöv',
  'Svedala',
  'Svenljunga',
  'Säffle',
  'Säter',
  'Sävsjö',
  'Söderhamn',
  'Söderköping',
  'Södertälje',
  'Sölvesborg',
  'Tanum',
  'Tibro',
  'Tidaholm',
  'Tierp',
  'Timrå',
  'Tingsryd',
  'Tjörn',
  'Tomelilla',
  'Torsby',
  'Torsås',
  'Tranemo',
  'Tranås',
  'Trelleborg',
  'Trollhättan',
  'Trosa',
  'Tyresö',
  'Täby',
  'Töreboda',
  'Uddevalla',
  'Ulricehamn',
  'Umeå',
  'Upplands Väsby',
  'Upplands-Bro',
  'Uppsala',
  'Uppvidinge',
  'Vadstena',
  'Vaggeryd',
  'Valdemarsvik',
  'Vallentuna',
  'Vansbro',
  'Vara',
  'Varberg',
  'Vaxholm',
  'Vellinge',
  'Vetlanda',
  'Vilhelmina',
  'Vimmerby',
  'Vindeln',
  'Vingåker',
  'Vårgårda',
  'Vänersborg',
  'Vännäs',
  'Värmdö',
  'Värnamo',
  'Västervik',
  'Västerås',
  'Växjö',
  'Ydre',
  'Ystad',
  'Åmål',
  'Ånge',
  'Åre',
  'Årjäng',
  'Åsele',
  'Åstorp',
  'Åtvidaberg',
  'Älmhult',
  'Älvdalen',
  'Älvkarleby',
  'Älvsbyn',
  'Ängelholm',
  'Öckerö',
  'Ödeshög',
  'Örebro',
  'Örkelljunga',
  'Örnsköldsvik',
  'Östersund',
  'Österåker',
  'Östhammar',
  'Östra Göinge',
  'Överkalix',
  'Övertorneå',
];

const SuccessView = () => {
  const [confetti, setConfetti] = useState([]);

  useEffect(() => {
    // Create confetti pieces spread across the full dialog width
    const pieces = Array.from({ length: 550 }, () => ({
      x: Math.random() * window.innerWidth, // Use absolute pixels instead of percentages
      delay: Math.random() * 0.1,
      rotation: Math.random() * 360,
    }));
    setConfetti(pieces);
  }, []);

  return (
    <Box
      sx={{
        width: { xs: '100%', md: '600px' },
        height: { xs: '100%', md: '400px' },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
        p: 4,
      }}
    >
      {/* Confetti container taking full width */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: 'hidden',
          zIndex: 999,
          pointerEvents: 'none',
          width: '100%', // Ensure full width
        }}
      >
        {confetti.map((piece, i) => (
          <motion.div
            key={i}
            initial={{
              y: -20,
              x: piece.x, // Use absolute pixel position
              rotate: piece.rotation,
              scale: 0,
            }}
            animate={{
              y: '1000%',
              x: piece.x + (Math.random() * 200 - 100), // Add some horizontal movement
              rotate: piece.rotation + 360,
              scale: 0.5 + Math.random() * 0.5,
            }}
            transition={{
              duration: 2 + Math.random() * 2,
              delay: piece.delay,
              ease: 'linear',
              repeat: Infinity,
            }}
            style={{
              position: 'absolute',
              width: 12, // Slightly larger confetti pieces
              height: 12,
              backgroundColor: ['#43CBFF', '#9708CC', '#F6D242', '#FF52E5', '#4CAF50', '#2196F3'][
                Math.floor(Math.random() * 6)
              ],
              borderRadius: 2,
            }}
          />
        ))}
      </Box>

      {/* Rest of the success view remains the same */}
      <motion.div
        initial={{ scale: 0, rotate: -180 }}
        animate={{
          scale: 1,
          rotate: 0,
          y: [0, -20, 0],
        }}
        transition={{
          type: 'spring',
          stiffness: 260,
          damping: 20,
          duration: 1.5,
        }}
      >
        <CheckCircle
          sx={{
            fontSize: 120,
            background: 'linear-gradient(135deg, #43CBFF 10%, #9708CC 100%)',
            borderRadius: '50%',
            color: 'white',
          }}
        />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
      >
        <Typography
          variant='h4'
          sx={{
            fontWeight: 600,
            textAlign: 'center',
            mt: 4,
            background: 'linear-gradient(135deg, #43CBFF 10%, #9708CC 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Registrering slutförd!
        </Typography>
        <Typography
          variant='h6'
          color='text.secondary'
          sx={{
            textAlign: 'center',
            mt: 2,
          }}
        >
          Välkommen till Studera.AI
        </Typography>
      </motion.div>
    </Box>
  );
};

const AccountTypeDialog = ({ open, onSave }) => {
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  const [step, setStep] = useState(1);
  const [accountType, setAccountType] = useState('');
  const [municipality, setMunicipality] = useState('');
  const [school, setSchool] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const getCardStyle = variant => {
    return {
      gradient: 'linear-gradient(135deg, #8B00FF 10%, #6A0DB7 100%)',
      iconColor: 'white',
      textColor: 'white',
      textShadow: '2px 2px 4px rgba(0,0,0,0.2)',
    };

    // Light theme variants
    const variants = {
      titanium: {
        gradient: 'linear-gradient(135deg, #F4F5F7 0%, #E5E7EB 100%)',
        iconColor: '#6B7280',
        textColor: '#4B5563',
        textShadow: 'none',
      },
    };

    return variants[variant] || variants.purple;
  };

  const handleTypeSelect = type => {
    setAccountType(type);
    setStep(2);
  };

  const handleBack = () => {
    setStep(1);
    setAccountType('');
  };

  const handleSave = () => {
    if (!municipality || !school) {
      showSnackbar('Fyll i alla fält', 'error');
      return;
    }

    setIsSuccess(true);

    onSave({
      accountType,
      municipality,
      school,
    });
  };

  const AccountTypeCard = ({ type, Icon, title, variant = 'purple' }) => {
    const style = getCardStyle(variant);

    return (
      <Box
        onClick={() => handleTypeSelect(type)}
        sx={{
          width: 240,
          height: 240,
          borderRadius: '24px',
          position: 'relative',
          cursor: 'pointer',
          background: style.gradient,
          transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
          boxShadow: isDark ? '0 8px 16px rgba(0,0,0,0.1)' : '0 8px 16px rgba(0,0,0,0.05)',
          '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: isDark ? '0 12px 24px rgba(0,0,0,0.15)' : '0 12px 24px rgba(0,0,0,0.1)',
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            inset: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon
            sx={{
              fontSize: 64,
              color: style.iconColor,
              mb: 2,
              filter: isDark ? 'drop-shadow(2px 2px 2px rgba(0,0,0,0.2))' : 'none',
            }}
          />
          <Typography
            variant='h5'
            sx={{
              color: style.textColor,
              fontWeight: 600,
              textShadow: style.textShadow,
            }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          showSnackbar('Vänligen slutför registreringen', 'warning');
          return;
        }
      }}
      disableEscapeKeyDown
      PaperProps={{
        sx: {
          borderRadius: 4,
          bgcolor: isDark ? '#121212' : 'background.paper',
          backgroundImage: 'none',
          overflow: 'hidden',
        },
      }}
    >
      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ position: 'relative', width: '100%', overflow: 'hidden' }}>
          {/* Progress Steps */}
          <Box
            sx={{
              position: 'absolute',
              top: 32,
              left: '50%',
              transform: 'translateX(-50%)',
              display: isSuccess ? 'none' : 'flex',
              gap: 2,
              zIndex: 10,
            }}
          >
            {[1, 2].map(s => (
              <Box
                key={s}
                sx={{
                  width: 40,
                  height: 40,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                  bgcolor:
                    step >= s
                      ? theme.palette.primary.main
                      : isDark
                        ? 'rgba(255,255,255,0.1)'
                        : 'rgba(0,0,0,0.1)',
                  color: step >= s ? '#fff' : 'text.secondary',
                  fontWeight: 600,
                }}
              >
                {s}
              </Box>
            ))}
          </Box>

          {/* Back Button in Step 2 */}
          {step === 2 && (
            <IconButton
              onClick={handleBack}
              sx={{
                position: 'absolute',
                top: 32,
                left: 32,
                zIndex: 10,
                display: isSuccess ? 'none' : undefined,
              }}
            >
              <ArrowBack />
            </IconButton>
          )}

          <AnimatePresence>
            {!isSuccess ? (
              <Box
                sx={{
                  display: 'flex',
                  transition: 'transform 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                  transform: `translateX(-${(step - 1) * 100}%)`,
                }}
              >
                {/* Step 1 */}
                <Box sx={{ minWidth: '100%', p: 4 }}>
                  <Box sx={{ mt: 8, mb: 4 }}>
                    <Typography variant='h4' align='center' sx={{ fontWeight: 600 }}>
                      Välkommen till Studera.AI
                    </Typography>
                    <Typography
                      variant='h6'
                      align='center'
                      sx={{
                        mt: 2,
                        color: 'text.secondary',
                        fontWeight: 500,
                        fontSize: '1.255rem',
                      }}
                    >
                      Jag är...
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: 4,
                      mt: 6,
                    }}
                  >
                    <AccountTypeCard
                      type='student'
                      Icon={Person}
                      title='Elev'
                      variant={isDark ? undefined : 'titanium'}
                    />

                    <AccountTypeCard
                      type='teacher'
                      Icon={School}
                      title='Lärare'
                      variant={isDark ? undefined : 'titanium'}
                    />
                  </Box>
                </Box>

                {/* Step 2 */}
                <Box sx={{ minWidth: '100%', p: 4 }}>
                  <Box sx={{ mt: 8, mb: 6 }}>
                    <Typography variant='h5' sx={{ fontWeight: 600, mb: 8, textAlign: 'center' }}>
                      Välj kommun & skola
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 3,
                      }}
                    >
                      <Autocomplete
                        fullWidth
                        options={swedishMunicipalities}
                        value={municipality}
                        onChange={(event, newValue) => setMunicipality(newValue)}
                        onInputChange={(event, value, reason) => {
                          if (reason === 'reset') {
                            setTimeout(() => document.querySelector('[name="school"]').focus(), 0);
                          }
                        }}
                        filterOptions={(options, { inputValue }) =>
                          options.filter(option =>
                            option.toLowerCase().startsWith(inputValue.toLowerCase())
                          )
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            label='Kommun'
                            sx={{
                              bgcolor: isDark ? 'rgba(255,255,255,0.03)' : 'transparent',
                            }}
                          />
                        )}
                        autoComplete
                        autoHighlight
                        freeSolo
                      />

                      <TextField
                        fullWidth
                        name='school'
                        label='Skola'
                        value={school}
                        onChange={e => setSchool(e.target.value)}
                        sx={{
                          bgcolor: isDark ? 'rgba(255,255,255,0.03)' : 'transparent',
                        }}
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            handleSave();
                          }
                        }}
                      />

                      <Button
                        variant='purp'
                        color='primary'
                        size='large'
                        onClick={handleSave}
                        sx={{
                          mt: 4,
                          height: 56,
                          borderRadius: '13px',
                          fontSize: '1.04rem',
                          textTransform: 'uppercase',
                          fontWeight: 600,
                        }}
                      >
                        Slutför registrering
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              <SuccessView />
            )}
          </AnimatePresence>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AccountTypeDialog;
