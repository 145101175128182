import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  Avatar,
  CircularProgress,
  IconButton,
  Paper,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import ImageIcon from '@mui/icons-material/Image';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { callApi } from './api';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import { generateAndProcessImage } from './functions';
import { OrbitProgress } from 'react-loading-indicators';
import { useSnackbar } from './SnackbarHandler';
import LanguageSelector from './LanguageSelector';
import PrintIcon from '@mui/icons-material/Print';
import { handlePrintLetterBox } from './printFunctions'; // Adjust the import path as needed
import { TbChartGridDotsFilled } from 'react-icons/tb';
import { ThemeSelector } from './ThemeSelector';
import ImageGenerationModal from './ImageGenerationModal';
import GradeSelector, { gradeInstructions } from './GradeSelector';
import InfoBox from './InfoBox';
const LetterBoxLesson = ({ user }) => {
  const [words, setWords] = useState([]);
  const [selectedLetters, setSelectedLetters] = useState({});
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [generatedImages, setGeneratedImages] = useState(new Set());
  const [themeColor, setThemeColor] = useState('#8a59f4');
  const { showSnackbar } = useSnackbar();
  const [grade, setGrade] = useState('åk6');
  const theme = useTheme();
  const styles = getStyles(theme);
  const [language, setLanguage] = useState('svenska');
  // New configuration states
  const [themeType, setThemeType] = useState('generateWithAi');
  const [customTheme, setCustomTheme] = useState('');
  const [wordCount, setWordCount] = useState(5);
  const [extraLettersCount, setExtraLettersCount] = useState(30);

  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const [imageGenerationType, setImageGenerationType] = useState(null);

  const handleImageButtonClick = type => {
    setImageGenerationType(type);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
  };

  const handleGenerateWithInstructions = async () => {
    handleCloseModal();

    if (imageGenerationType === 'topic') {
      await handleGenerateImages();
    } else if (imageGenerationType === 'facts') {
      await handleGenerateImages();
    }
  };

  const generateLetterPool = word => {
    const wordLetters = word.split('');
    const alphabet = 'abcdefghijklmnopqrstuvwxyzåäö'.split('');
    const additionalLetters = Array.from(
      { length: extraLettersCount - wordLetters.length },
      () => alphabet[Math.floor(Math.random() * alphabet.length)]
    );
    return [...wordLetters, ...additionalLetters].sort(() => Math.random() - 0.5);
  };

  const handleGenerateLesson = async () => {
    setIsGeneratingLesson(true);
    try {
      const themePrompt =
        themeType === 'customTheme'
          ? customTheme
          : themeType === 'generateWithAi'
            ? '[RANDOM]'
            : themeType;

      const gradeInstructionsText = gradeInstructions[grade];

      const response = await callApi(
        [
          {
            role: 'system',
            content: 'Du är en assistent som genererar enkla ord för bokstavsövningar.',
          },
          {
            role: 'user',
            content: `Generera JSON med exakt ${wordCount}   ord ${themePrompt !== '[RANDOM]' ? ` relaterade till temat "${themePrompt}"` : ''}. Orden ska vara på ${language}. Varje ord ska vara 3-8 bokstäver långt.
            ${gradeInstructionsText}

             Säkerställ att allt som genereras är enbart en JSON array. Exempel på format:
            {
              "words": [
                { "word": "katt" },
                { "word": "bil" },
                { "word": "hund" },
                { "word": "boll" },
                { "word": "fisk" }
              ]
            }`,
          },
        ],
        4000,
        true,
        user.uid
      );

      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);

      const wordsWithLetters = data.words.map(wordObj => ({
        ...wordObj,
        letters: generateLetterPool(wordObj.word),
        image: '/api/placeholder/200/200',
      }));

      setWords(wordsWithLetters);
      setSelectedLetters({});
      showSnackbar('Nya ord har genererats', 'success');
    } catch (error) {
      console.error('Error generating lesson:', error);
      showSnackbar('Kunde inte generera nya ord', 'error');
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleGenerateImages = async () => {
    setIsGeneratingImages(true);
    setGeneratedImages(new Set());

    try {
      const updatedWords = [...words];

      for (const [index, word] of words.entries()) {
        try {
          const prompt = `Create a simple, clear image representing the Swedish word "${word.word}". Style: clean, colorful, suitable for children's education. No text in the image. ${imageInstructions}`;

          const imageUrl = await generateAndProcessImage(prompt, user.uid, {
            size: '1024x1024',
            model: 'dall-e-3',
            quality: 'standard',
          });

          updatedWords[index] = {
            ...updatedWords[index],
            image: imageUrl,
          };

          setGeneratedImages(prev => new Set([...prev, index]));
          setWords(updatedWords);
          showSnackbar(`Genererat bild ${index + 1} av ${words.length}`, 'info');
        } catch (error) {
          console.error(`Error generating image for word "${word.word}":`, error);
          showSnackbar(`Kunde inte generera bild för "${word.word}"`, 'error');
          continue;
        }
      }
      showSnackbar('Alla bilder har genererats!', 'success');
    } catch (error) {
      console.error('Error in image generation process:', error);
      showSnackbar('Ett fel uppstod när bilderna skulle genereras', 'error');
    } finally {
      setIsGeneratingImages(false);
    }
  };

  const handleLetterClick = (wordIndex, letter, letterIndex) => {
    setSelectedLetters(prev => {
      const currentSelected = prev[wordIndex] || [];
      if (!currentSelected.find(item => item.index === letterIndex)) {
        return {
          ...prev,
          [wordIndex]: [...currentSelected, { letter, index: letterIndex }],
        };
      }
      return prev;
    });
  };

  const handleClearWord = wordIndex => {
    setSelectedLetters(prev => ({
      ...prev,
      [wordIndex]: [],
    }));
  };

  const isWordComplete = wordIndex => {
    const selected = selectedLetters[wordIndex] || [];
    const targetWord = words[wordIndex]?.word || '';
    const selectedWord = selected.map(s => s.letter).join('');
    return selectedWord === targetWord;
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: '100%', md: '87%' },
          margin: '0 auto',
        }}
      >
        <Typography
          variant='h1'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
        >
          Generera bokstavslådor
        </Typography>
        <InfoBox
          icon={TbChartGridDotsFilled}
          title='Powerpoint Instructions'
          instructions={[
            'Generera ord som eleven ska hitta bland mängder av bokstäver',
            'Lägg till AI-genererade bilder för varje ord',
            'Skriv ut lektionen',
          ]}
          theme={theme}
          styles={styles}
        />

        <Box sx={{ ...styles.freshBox, padding: '30px' }}>
          {/* Configuration Section */}
          <Box sx={{ mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box>
                  <ThemeSelector
                    value={themeType}
                    onChange={e => setThemeType(e.target.value)}
                    theme={theme}
                    customTheme={customTheme}
                    onCustomThemeChange={e => setCustomTheme(e.target.value)}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                  Årskurs:
                </Typography>
                <Box sx={{ mt: 1, mb: 3 }}>
                  <GradeSelector grade={grade} setGrade={setGrade} />
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                  Antal ord:
                </Typography>
                <FormControl fullWidth margin='normal' sx={{ mb: 3, mt: 1 }}>
                  <Select value={wordCount} onChange={e => setWordCount(e.target.value)}>
                    <MenuItem value={3}>3 ord</MenuItem>
                    <MenuItem value={4}>4 ord</MenuItem>
                    <MenuItem value={5}>5 ord</MenuItem>
                    <MenuItem value={6}>6 ord</MenuItem>
                    <MenuItem value={8}>8 ord</MenuItem>
                    <MenuItem value={10}>10 ord</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                  Antal extra bokstäver:
                </Typography>
                <FormControl fullWidth margin='normal' sx={{ mb: 3, mt: 1 }}>
                  <Select
                    value={extraLettersCount}
                    onChange={e => setExtraLettersCount(e.target.value)}
                  >
                    <MenuItem value={10}>10 bokstäver</MenuItem>
                    <MenuItem value={15}>15 bokstäver</MenuItem>
                    <MenuItem value={20}>20 bokstäver</MenuItem>
                    <MenuItem value={25}>25 bokstäver</MenuItem>
                    <MenuItem value={30}>30 bokstäver</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                <Typography variant='subtitle1' sx={{ fontWeight: '500' }}>
                  Språk:
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <LanguageSelector language={language} setLanguage={setLanguage} />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ mb: 4 }}>
            <Button
              variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
              color='primary'
              onClick={handleGenerateLesson}
              disabled={isGeneratingLesson}
              startIcon={!isGeneratingLesson ? <GenerateIcon /> : <CircularProgress size={20} />}
              sx={{ mr: 2 }}
            >
              {isGeneratingLesson ? 'Genererar...' : 'Generera nya ord'}
            </Button>

            {words.length > 0 && (
              <>
                <Button
                  variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                  color='primary'
                  onClick={() => handleImageButtonClick('facts')}
                  disabled={isGeneratingImages}
                  startIcon={!isGeneratingImages ? <ImageIcon /> : <CircularProgress size={20} />}
                >
                  {isGeneratingImages ? 'Genererar bilder...' : 'Generera bilder'}
                </Button>

                <ImageGenerationModal
                  open={showImageModal}
                  onClose={handleCloseModal}
                  onGenerate={handleGenerateWithInstructions}
                  isGenerating={
                    imageGenerationType === 'topic' ? isGeneratingImages : isGeneratingImages
                  }
                  instructions={imageInstructions}
                  setInstructions={setImageInstructions}
                  generationType={imageGenerationType}
                  theme={theme}
                />

                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => handlePrintLetterBox(words, selectedLetters, themeColor)}
                  startIcon={<PrintIcon />}
                  sx={{ ml: 2 }}
                >
                  Skriv ut
                </Button>
              </>
            )}
          </Box>

          {words.length > 0 && (
            <>
              <Box sx={{ mt: 0, mb: 2 }}>
                <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500' }}>
                  Färgtema:
                </Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 3 }}>
                  <div
                    style={{ display: 'inline-block', position: 'relative', marginRight: '10px' }}
                  >
                    <div
                      style={{
                        width: '60px',
                        height: '20px',
                        borderRadius: '7px',
                        backgroundColor: themeColor,
                        cursor: 'pointer',
                      }}
                    />
                    <input
                      type='color'
                      value={themeColor}
                      onChange={e => setThemeColor(e.target.value)}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        opacity: 0,
                        cursor: 'pointer',
                      }}
                    />
                  </div>

                  <Typography variant='body2'>{themeColor}</Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  borderRadius: '13px',
                }}
              >
                <Grid container spacing={3}>
                  {words.map((word, wordIndex) => (
                    <>
                      <Typography
                        sx={{
                          fontWeight: '500',
                          fontSize: '23px',
                          display: 'block',
                          mt: 8,
                          ml: 2,
                          textTransform: 'capitalize',
                        }}
                      >
                        {word.word}
                      </Typography>

                      <Grid item xs={12} key={wordIndex}>
                        <Box
                          sx={{
                            ...styles.freshBox,
                            p: 2,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 3,
                            borderRadius: '13px',
                            border: `5px solid ${themeColor}`,
                            backgroundColor:
                              theme.palette.mode === 'dark' ? '#1a2027' : '#f9f9f94f',
                          }}
                        >
                          <Box
                            sx={{ position: 'relative', width: 200, height: 200, flexShrink: 0 }}
                          >
                            <Avatar
                              src={word.image}
                              alt={word.word}
                              variant='rounded'
                              sx={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '13px',
                                bgcolor: '#f2f2f2',
                              }}
                            />
                            {isGeneratingImages && !generatedImages.has(wordIndex) && (
                              <Box
                                sx={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  backgroundColor: '#f2f2f2',
                                  borderRadius: 1,
                                }}
                              >
                                <OrbitProgress color='#8b5cf6' size='small' />
                              </Box>
                            )}
                          </Box>

                          <Box sx={{ flex: 1 }}>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
                              {word.letters.map((letter, letterIndex) => (
                                <Box
                                  key={letterIndex}
                                  onClick={() => handleLetterClick(wordIndex, letter, letterIndex)}
                                  sx={{
                                    width: 44,
                                    height: 44,
                                    fontWeight: '600',
                                    borderRadius: '50%',
                                    border: `2px solid ${themeColor}`,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    backgroundColor: selectedLetters[wordIndex]?.find(
                                      item => item.index === letterIndex
                                    )
                                      ? '#8b5cf6'
                                      : 'transparent',
                                    color: selectedLetters[wordIndex]?.find(
                                      item => item.index === letterIndex
                                    )
                                      ? '#fff'
                                      : `${themeColor}`,
                                    '&:hover': {
                                      backgroundColor: '#8b5cf6',
                                      color: '#fff',
                                    },
                                    transition: 'all 0.2s',
                                  }}
                                >
                                  {letter}
                                </Box>
                              ))}
                            </Box>

                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center', // Center the entire container vertically
                                gap: 2,
                                position: 'relative',
                                height: '100%', // Take full height of parent
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                  height: '100%', // Take full height
                                }}
                              >
                                {(selectedLetters[wordIndex] || []).map((item, i) => (
                                  <Box
                                    key={i}
                                    sx={{
                                      width: 45,
                                      height: 45,
                                      borderRadius: '50%',
                                      backgroundColor: '#8b5cf6',
                                      color: '#fff',
                                      fontSize: '17px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    {item.letter}
                                  </Box>
                                ))}
                              </Box>

                              <IconButton
                                onClick={() => handleClearWord(wordIndex)}
                                size='small'
                                sx={{ position: 'absolute', bottom: '-20px', right: '0px' }}
                              >
                                <RefreshIcon />
                              </IconButton>

                              {isWordComplete(wordIndex) && (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    color: 'success.main',
                                  }}
                                >
                                  <CheckCircleIcon />
                                  <Typography>Rätt!</Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  ))}
                </Grid>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LetterBoxLesson;
